@if (total > 0) {
<button class="prev" (click)="changePage(pageIndex! - 1)" [disabled]="pageIndex === 1">
  <span nz-icon nzType="left" nzTheme="outline"></span>
</button>

@for (page of pages; track $index) { @if (page !== '...') {
<button [class.active]="page === pageIndex" (click)="changePage(page)">
  {{ page }}
</button>
} @else {
{{ page }}
} }

<button class="next" (click)="changePage(pageIndex! + 1)" [disabled]="pageIndex === pagesCount">
  <span nz-icon nzType="right" nzTheme="outline"></span>
</button>

}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { RxPush } from '@rx-angular/template/push';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { LanguageService } from 'src/app/services/language.service';

import { SanctionsPolicyEnComponent } from './components/sanctions-policy-en/sanctions-policy-en.component';
import { SanctionsPolicyRuComponent } from './components/sanctions-policy-ru/sanctions-policy-ru.component';
import { en } from './locale/en';
import { ru } from './locale/ru';
import { SanctionsPolicyPageComponent } from './sanctions-policy-page.component';

@NgModule({
  declarations: [
    SanctionsPolicyEnComponent,
    SanctionsPolicyRuComponent,
    SanctionsPolicyPageComponent,
  ],
  imports: [CommonModule, TranslateModule, RouterModule, RxPush, NzPageHeaderModule],
  exports: [SanctionsPolicyPageComponent],
})
export class SanctionsPolicyPageModule {
  constructor(languageService: LanguageService) {
    languageService.addLocales({ en, ru });
  }
}

import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Output } from '@angular/core';

@Component({
  selector: 'app-lots-list-filter-buttons',
  templateUrl: './lots-list-filter-buttons.component.html',
  styleUrl: './lots-list-filter-buttons.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotsListFilterButtonsComponent {
  @Output() resetFilters = new EventEmitter<void>();
  @Output() applyFilters = new EventEmitter<void>();
}

import { Injectable } from '@angular/core';
import { debounceTime, map, Observable, startWith } from 'rxjs';

@Injectable()
export class ResizeObserverService {
  observe(element: Element, options?: ResizeObserverOptions): Observable<ResizeObserverEntry[]> {
    return new Observable((subscribe) => {
      const observer = new ResizeObserver((entries) => subscribe.next(entries));
      observer.observe(element, options);
      return () => observer.disconnect();
    });
  }

  isOverflowing(element: HTMLElement, debounceTimeValue = 100): Observable<boolean> {
    return this.observe(element).pipe(
      debounceTime(debounceTimeValue),
      startWith(),
      map(() => {
        const { offsetWidth, scrollWidth } = element;
        return scrollWidth > offsetWidth;
      }),
    );
  }
}

<nz-row
  nzJustify="space-between"
  nzAlign="middle"
  class="lot-page-header"
  [nzGutter]="[{ xs: 0, sm: 20 }, { xs: 20, sm: 20 }]"
>
  <nz-col>
    <h1 class="title">
      {{ lot.maker }} {{ lot.mark }} · {{ lot.year }}
      @if (lot.isSold) {
        <span class="sold">{{ 'LOT.SOLD' | translate }}</span>
      }
    </h1>
    <nz-row nzAlign="middle" class="id-link">
      <h3 class="id">#{{ lot.id }}</h3>
      @if ((hasAccessToAkbOffice$ | async) && link) {
        <a
          href="{{ link }}"
          class="link-to-manager"
          target="_blank"
        >
          {{ 'manager.akebono' }}
        </a>
      }
    </nz-row>
  </nz-col>
  <nz-col>
    <nz-row nzJustify="space-between" nzAlign="middle" [nzGutter]="[{ xs: 0, sm: 10 }, { xs: 10, sm: 10 }]">
      <nz-col>
        <div class="visited-date">
          {{ 'LOT.VISITED' | translate }}:
          {{ lastVisitedTimestamp | date:'dd.MM.yyyy, HH:mm' }}
        </div>
      </nz-col>
      <nz-col>
        <button class="share-link" (click)="copyToClipboard()">
          {{ 'LOT.SHARE' | translate }}
          <span nz-icon nzType="share-alt" nzTheme="outline" [style.color]="'#2c7df6'"></span>
        </button>
      </nz-col>
    </nz-row>
  </nz-col>
</nz-row>

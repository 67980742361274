import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { distinctUntilChanged, skip } from 'rxjs';

import { LanguageService } from '../../../../../services/language.service';
import { Language } from '../../../../../utilities/types/language.type';
import { Country } from '../services/country/country.type';

@Pipe({
  name: 'countryName',
  pure: false,
})
export class CountryNamePipe implements PipeTransform {
  private currentLanguage!: Language;

  constructor(languageService: LanguageService, cdr: ChangeDetectorRef) {
    this.currentLanguage = languageService.currentLanguage;
    languageService.currentLanguage$
      .pipe(distinctUntilChanged(), skip(1), takeUntilDestroyed())
      .subscribe((language) => {
        this.currentLanguage = language;
        cdr.markForCheck();
      });
  }

  transform(country: Country): string {
    return country?.name?.[this.currentLanguage] || country?.name?.en;
  }
}

import { ChangeDetectionStrategy, Component, DestroyRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { first, of } from 'rxjs';
import {
  DonateBuhankaGQL,
  DonateBuhankaMutation,
  DonateBuhankaMutationVariables,
} from 'src/app/graphql/shared-familiar-graphql.service';
import { MutationHandlingServiceV2 } from 'src/app/services/mutation-handling-service-v2/mutation-handling-v2.service';

@Component({
  selector: 'app-buhanka-donate-modal',
  templateUrl: './buhanka-donate-modal.component.html',
  styleUrl: './buhanka-donate-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BuhankaDonateModalComponent {
  loading$ = of(false);

  donateAmount: number = 1000;
  confirmPayment = false;

  constructor(
    private modal: NzModalRef,
    private destroy: DestroyRef,
    private mhs: MutationHandlingServiceV2,
    private donateBuhankaGQL: DonateBuhankaGQL,
  ) {}

  donate(): void {
    if (!this.canDonate) return;

    const mutationRef = this.mhs.mutate<DonateBuhankaMutation, DonateBuhankaMutationVariables>(
      this.donateBuhankaGQL,
      {
        input: {
          amount: this.donateAmount,
        },
      },
      { refetch: true },
    );

    this.loading$ = mutationRef.loading;
    mutationRef.data.pipe(first(), takeUntilDestroyed(this.destroy)).subscribe(() => this.close());
  }

  get canDonate(): boolean {
    return this.donateAmount >= 1000 && this.confirmPayment;
  }

  close(): void {
    this.modal.destroy();
  }
}

import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
} from '@angular/core';
import {NzImage, NzImageService} from 'ng-zorro-antd/image';
import {fromEvent, map, merge, Subject, takeUntil} from 'rxjs';

import {ImageStatus} from './enums/image-status.enum';
import {ImageGroupComponent} from './image-group.component';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'img[app-image]',
  exportAs: 'appImage',
})
export class ImageDirective implements OnInit, OnChanges, OnDestroy {
  @Input({required: true}) source!: string;
  @Input() thumbnail?: string | null;
  @Input() previewEnabled = true;

  private _status: ImageStatus = ImageStatus.Loading;

  private destroy$ = new Subject<void>();

  constructor(
    private readonly imageService: NzImageService,
    private readonly elementRef: ElementRef<HTMLImageElement>,
    @Optional() private readonly group?: ImageGroupComponent,
  ) {
  }

  ngOnInit(): void {
    this.group?.addImage(this);
    this.reinitialize();
  }

  ngOnChanges(): void {
    this.reinitialize();
  }

  @HostListener('click')
  preview(): void {
    if (!this.canOpenPreview) {
      return;
    }

    if (this.group) {
      const previewImages = this.group.images.filter((img) => img.canOpenPreview);
      const images: NzImage[] = previewImages.map((img) => ({src: img.source}));
      const currentImageIndex = previewImages.findIndex((img) => img === this);

      this.openPreview(images, currentImageIndex);
    } else {
      const images: NzImage[] = [{src: this.source}];
      this.openPreview(images);
    }
  }

  get canOpenPreview(): boolean {
    return this.previewEnabled && this.status !== ImageStatus.Error;
  }

  private set status(status: ImageStatus) {
    this._status = status;
  }

  get status(): ImageStatus {
    return this._status;
  }

  private openPreview(images: NzImage[], startIndex?: number): void {
    const preview = this.imageService.preview(images);

    if (startIndex) {
      preview.switchTo(startIndex);
    }
  }

  private reinitialize(): void {
    const img = this.elementRef.nativeElement;
    const src = this.thumbnail || this.source;

    if (img.src === src) {
      return;
    } else {
      img.src = src;
    }

    this.resetDestroySubject();

    merge(
      fromEvent(img, 'load').pipe(map(() => ImageStatus.Success)),
      fromEvent(img, 'error').pipe(map(() => ImageStatus.Error)),
    )
      .pipe(takeUntil(this.destroy$))
      .subscribe((status) => (this.status = status));
  }

  private resetDestroySubject(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.destroy$ = new Subject<void>();
  }

  ngOnDestroy(): void {
    this.group?.removeImage(this);
    this.destroy$.next();
    this.destroy$.complete();
  }
}

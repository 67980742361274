import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';
import plural from 'plural-ru';
import { LanguageService } from 'src/app/services/language.service';

@Pipe({
  name: 'plural',
  pure: false,
})
export class PluralPipe implements PipeTransform {
  constructor(
    languageService: LanguageService,
    cdr: ChangeDetectorRef,
    private translate: TranslateService,
  ) {
    languageService.currentLanguage$.pipe(takeUntilDestroyed()).subscribe(() => {
      cdr.markForCheck();
    });
  }

  transform(value: number | null | undefined, plurals: [string, string, string]): string {
    if (typeof value === 'number') {
      const valuePlural = plural(
        value,
        this.translate.instant(plurals[0]),
        this.translate.instant(plurals[1]),
        this.translate.instant(plurals[2]),
      );

      return `${value} ${valuePlural}`;
    } else {
      return '';
    }
  }
}

import { Injectable, OnDestroy } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, map, Observable, ReplaySubject, shareReplay, tap } from 'rxjs';

import { LanguageService } from '../language.service';
import { Titles } from './types/titles.type';

@Injectable()
export class PageTitleService implements OnDestroy {
  private readonly titleChanges = new ReplaySubject<Titles>(1);
  private readonly titles$: Observable<Titles>;

  constructor(
    ngTitle: Title,
    languageService: LanguageService,
    private readonly translate: TranslateService,
  ) {
    this.titles$ = combineLatest([this.titleChanges, languageService.currentLanguage$]).pipe(
      map(
        ([titles]) =>
          <Titles>{
            pageTitle: translate.instant(titles.pageTitle),
            tabTitle: this.translate.instant(titles.tabTitle),
          },
      ),
      tap((titles) => ngTitle.setTitle(titles.tabTitle)),
      shareReplay(1),
    );

    const currentTitle = ngTitle.getTitle();
    this.setTitle(currentTitle);
    this.titles$.pipe(takeUntilDestroyed()).subscribe();
  }

  get pageTitle$(): Observable<string> {
    return this.titles$.pipe(map((titles) => titles.pageTitle));
  }

  get tabTitle$(): Observable<string> {
    return this.titles$.pipe(map((titles) => titles.tabTitle));
  }

  setTitle(title: string): void;
  setTitle(pageTitle: string, tabTitle: string): void;
  setTitle(pageTitle: string, tabTitle?: string) {
    this.titleChanges.next({
      pageTitle,
      tabTitle: tabTitle ?? pageTitle,
    });
  }

  reset(): void {
    this.setTitle('');
  }

  ngOnDestroy(): void {
    this.titleChanges.complete();
  }
}

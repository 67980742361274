import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-buhanka-donate-banner',
  templateUrl: './buhanka-donate-banner.component.html',
  styleUrl: './buhanka-donate-banner.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BuhankaDonateBannerComponent {

}

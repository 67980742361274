import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-thumbs-slider-not-authorized-overlay',
  templateUrl: './thumbs-slider-not-authorized-overlay.component.html',
  styleUrl: './thumbs-slider-not-authorized-overlay.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ThumbsSliderNotAuthorizedOverlayComponent {

}

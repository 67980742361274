import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-delivery-statistics-steps-block',
  templateUrl: './delivery-statistics-steps-block.component.html',
  styleUrl: './delivery-statistics-steps-block.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeliveryStatisticsStepsBlockComponent {

}

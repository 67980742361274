import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LotType } from 'src/app/services/lot-adapter/enums/lot-type.enum';

@Component({
  selector: 'app-category-card',
  templateUrl: './category-card.component.html',
  styleUrl: './category-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategoryCardComponent {
  @Input({ required: true }) title!: string;
  @Input({ required: true }) text!: string;
  @Input({ required: true }) lotType!: LotType;
  @Input() count?: number;
}

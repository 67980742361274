export const ru = {
  "FILTER": {
    "CAR_SEARCH": {
      "SEARCH_CAR": "Поиск автомобилей:",
      "MANUFACTURER": "Производитель",
      "MODEL": "Модель",
      "CHASSIS": "Шасси",
      "BODY": "Тип",
      "ID_OBJECT": "№ лота",
      "PRICE": "Цена, ¥",
      "PRICE_FROM": "От",
      "PRICE_TO": "До"
    },
    "YEAR": {
      "YEAR": "Год",
      "FROM": "От",
      "TO": "До"
    },
    "MILEAGE": {
      "MILEAGE": "Пробег, КМ",
      "FROM": "От",
      "TO": "До"
    },
    "ENGINE_CAPACITY": {
      "TITLE": "Объем двигателя",
      "FROM": "0",
      "TO": "2000",
    },
    "ADDITIONAL_OPTIONS": {
      "ADDITIONAL": "Дополнительные параметры",
      "ENGINE_SIZE_FROM": "От",
      "ENGINE_SIZE_TO": "До",
      "GRADE": "Оценка",
      "COLOR": "Цвет",
      "TRANSMISSION": "Трансмиссия",
      "PRICE": "Цена",
      "NOTE_SOLD": "Не показывать проданные"
    },
    "RESET_BTN": "Сбросить",
    "APPLY_BTN": "Применить",
    "ALL": "Все"
  }
};

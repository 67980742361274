<h1>{{ 'POPULAR_CARS_SECTION.TITLE' | translate }}</h1>

<app-tabs [maxNavigationWidth]="450">
  @for (lotType of availableLotTypes; track lotType ) {
  <app-tab [title]="'POPULAR_CARS_SECTION.' + lotType | uppercase | translate">
    <ng-template appTab>
      <app-popular-cars-list [lotType]="lotType"></app-popular-cars-list>
    </ng-template>
  </app-tab>
  }
</app-tabs>

import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { StopPropagationDirectiveModule } from 'src/app/directives/stop-propagation-directive/stop-propagation-directive.module';
import { ParseHtmlPipeModule } from 'src/app/pipes/parse-html-pipe/parse-html-pipe.module';
import { VinMaskPipeModule } from 'src/app/pipes/vin-mask-pipe/vin-mask-pipe.module';

import { ImageModule } from '../image/image.module';
import { InfoIconComponent } from './components/info-icon/info-icon.component';
import { LotCardGalleryComponent } from './components/lot-card-gallery/lot-card-gallery.component';
import { LotCardInfoComponent } from './components/lot-card-info/lot-card-info.component';
import { LotCardTitleComponent } from './components/lot-card-title/lot-card-title.component';
import { LotCardComponent } from './lot-card.component';

@NgModule({
  declarations: [
    LotCardComponent,
    LotCardGalleryComponent,
    LotCardTitleComponent,
    LotCardInfoComponent,
    InfoIconComponent,
  ],
  imports: [
    CommonModule,
    NzIconModule,
    RouterLink,
    VinMaskPipeModule,
    StopPropagationDirectiveModule,
    TranslateModule,
    ParseHtmlPipeModule,
    ImageModule,
  ],
  exports: [LotCardComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class LotCardModule {}

import { ChangeDetectionStrategy, Component } from '@angular/core';

import { PageTitleService } from './services/page-title/page-title.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  constructor(pageTitle: PageTitleService) {
    pageTitle.setTitle('Akebono Co.,Ltd - Japanese auto auctions, worldwide delivery');
  }
}

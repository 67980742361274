export const ru = {
  "BENEFITS_SECTION": {
    "TITLE": "Наши преимущества",
    "BUTTON_TXT": "Зарегистрироваться",
    "BENEFITS_CARD": {
      "CAR_TITLE": "Широкий выбор",
      "CAR_TEXT": "Разнообразие авто из Японии, подходящих для всех вкусов и потребностей.",
      "DELIVERY_TITLE": "Надежная доставка",
      "DELIVERY_TEXT": "Профессиональная и безопасная доставка автомобилей прямо к вам.",
      "CONDITIONS_TITLE": "Прозрачные условия",
      "CONDITIONS_TEXT": "Полная и актуальная информация о каждом объекте."
    }
  }
};

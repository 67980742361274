import { ChangeDetectionStrategy, Component } from '@angular/core';
import { map, Observable, shareReplay } from 'rxjs';
import {
  DeliveryStatisticsGQL,
  DeliveryStatisticsQuery,
  DeliveryStatisticsQueryVariables,
} from 'src/app/graphql/common-open-graphql.service';
import { QueryHandlingService } from 'src/app/services/query-handling-service/query-handling.service';

@Component({
  selector: 'app-delivery-statistics-block',
  templateUrl: './delivery-statistics-block.component.html',
  styleUrl: './delivery-statistics-block.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeliveryStatisticsBlockComponent {
  today = new Date();
  averageDeliveryDays$: Observable<number>;
  carsDeliveryDays$: Observable<number>;
  motoDeliveryDays$: Observable<number>;
  toVladivostokDeliveryDays$: Observable<number>;

  loading$: Observable<boolean>;

  constructor(qhs: QueryHandlingService, deliveryStatisticsGQL: DeliveryStatisticsGQL) {
    const queryRef = qhs.fetch<DeliveryStatisticsQuery, DeliveryStatisticsQueryVariables>(
      deliveryStatisticsGQL,
    );

    this.loading$ = queryRef.loading;
    const data$ = queryRef.data.pipe(shareReplay(1));
    const statsJp$ = data$.pipe(map((data) => data.averageObjectToTrip || []));
    const statsRu$ = data$.pipe(map((data) => data.averageObjectAucToRussia || []));

    this.averageDeliveryDays$ = statsJp$.pipe(map((stats) => stats[0]?.all || 0));
    this.carsDeliveryDays$ = statsJp$.pipe(map((stats) => stats[1]?.all || 0));
    this.motoDeliveryDays$ = statsJp$.pipe(map((stats) => stats[2]?.all || 0));
    this.toVladivostokDeliveryDays$ = statsRu$.pipe(map((stats) => stats[0]?.avgDays || 0));
  }
}

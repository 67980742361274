import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrl: './pagination.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginationComponent implements OnChanges, OnInit {
  @Input() pageIndex = 1;
  @Input() pageSize = 20;
  @Input() total = 0;
  @Output() pageIndexChange = new EventEmitter<number>();

  pagesCount = 0;
  pages: (string | number)[] = [];
  private allPages: number[] = [];
  private readonly pagesWindowSize = 5;

  ngOnInit(): void {
    if (!this.pagesCount) {
      this.initialize();
    }
  }

  private initialize(): void {
    this.pagesCount = Math.ceil(this.total / this.pageSize) || 1;
    if (this.pagesCount !== this.allPages.length) {
      this.allPages = Array.from({ length: this.pagesCount }, (_, index) => index + 1);
    }
    this.pages = this.generatePages();
  }

  private generatePages(): (string | number)[] {
    const pagesWindow = this.generatePagesWindow();
    const lastWidowIndex = pagesWindow.length - 1;

    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return [
      pagesWindow[0] === 1 ? null : 1,
      pagesWindow[0] >= 2 ? '...' : null,
      ...pagesWindow,
      pagesWindow[lastWidowIndex] <= this.pagesCount - 2 ? '...' : null,
      pagesWindow[lastWidowIndex] === this.pagesCount ? null : this.pagesCount,
    ].filter((page) => !!page) as (string | number)[];
  }

  private generatePagesWindow(): number[] {
    const activePageArrayIndex = this.pageIndex - 1;
    const halfWindow = Math.floor(this.pagesWindowSize / 2);

    let start = Math.max(0, activePageArrayIndex - halfWindow);
    let end = Math.min(this.pagesCount, activePageArrayIndex + halfWindow + 1);

    if (end - start < this.pagesWindowSize) {
      if (start === 0) {
        end = Math.min(this.pagesCount, start + this.pagesWindowSize);
      } else if (end === this.pagesCount) {
        start = Math.max(0, end - this.pagesWindowSize);
      }
    }

    return this.allPages.slice(start, end);
  }

  changePage(pageIndex: number | string): void {
    if (pageIndex !== this.pageIndex) {
      this.pageIndex = <number>pageIndex;
      this.pageIndexChange.emit(this.pageIndex);
      this.pages = this.generatePages();
    }
  }

  ngOnChanges(): void {
    this.pageIndex ||= 1;
    this.pageSize ||= 20;
    this.total ||= 0;

    this.initialize();
  }
}

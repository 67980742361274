import { ChangeDetectionStrategy, Component } from '@angular/core';
import { map, Observable, shareReplay } from 'rxjs';
import { QueryHandlingService } from '../../../../../../services/query-handling-service/query-handling.service';
import {
  ExchangeRateFieldsFragment,
  ExchangeRateGQL,
  ExchangeRateQuery,
  ExchangeRateQueryVariables,
} from 'src/app/graphql/exchange-rate-open-graphql.service';

@Component({
  selector: 'app-exchange-rates',
  templateUrl: './exchange-rates.component.html',
  styleUrls: ['./exchange-rates.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExchangeRatesComponent {
  exchangeRates$: Observable<{
    jpyToRub: number;
    jpyToEur: number;
    jpyToUsd: number;
  }>;
  loading$: Observable<boolean>;

  constructor(qhs: QueryHandlingService, exchangeRateGQL: ExchangeRateGQL) {
    const fetchRateQueryRef = qhs.fetch<ExchangeRateQuery, ExchangeRateQueryVariables>(
      exchangeRateGQL,
    );

    this.exchangeRates$ = fetchRateQueryRef.data.pipe(
      map((exchangeRateData) => ({
        jpyToRub: calculateExchangeRate(exchangeRateData?.rubToJpy),
        jpyToUsd: exchangeRateData.jpyToUsd?.sell ?? 0,
        jpyToEur: exchangeRateData.jpyToEur?.sell ?? 0,
      })),
      shareReplay(1),
    );

    this.loading$ = fetchRateQueryRef.loading;

    function calculateExchangeRate(
      exchangeRate: ExchangeRateFieldsFragment | null | undefined,
    ): number {
      if (!exchangeRate?.nominal || !exchangeRate?.sell || exchangeRate.sell === 0) {
        return 0;
      }
      return +(exchangeRate.nominal / exchangeRate.sell).toFixed(2);
    }
  }
}

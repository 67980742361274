import {NgModule} from "@angular/core";
import {InformationPageComponent} from "./information-page.component";
import {AsyncPipe, CommonModule, LowerCasePipe, NgForOf, NgIf, UpperCasePipe} from "@angular/common";
import {NzBreadCrumbComponent, NzBreadCrumbItemComponent} from "ng-zorro-antd/breadcrumb";
import {NzIconDirective} from "ng-zorro-antd/icon";
import {TranslateModule} from "@ngx-translate/core";
import {LanguageService} from "../../../../services/language.service";
import {en} from "./locale/en";
import {ru} from "./locale/ru";
import {NzColDirective, NzRowDirective} from "ng-zorro-antd/grid";
import {NzSpinComponent} from "ng-zorro-antd/spin";
import {NzMenuDirective, NzMenuItemComponent, NzSubMenuComponent} from "ng-zorro-antd/menu";
import {RouterLink, RouterLinkActive} from "@angular/router";
import {NzDrawerComponent, NzDrawerContentDirective} from "ng-zorro-antd/drawer";
import {NzButtonComponent} from "ng-zorro-antd/button";
import {NzResultComponent} from "ng-zorro-antd/result";
import {NzSkeletonComponent} from "ng-zorro-antd/skeleton";
import {NzTypographyComponent} from "ng-zorro-antd/typography";
import {ButtonModule} from "../../../../components/button/button.module";
import {InformationPageLinksComponent} from './components/information-page-links/information-page-links.component';
import {InformationPageMenuComponent} from './components/information-page-menu/information-page-menu.component';
import {
  InformationPageBreadcrumbComponent
} from './components/information-page-breadcrumb/information-page-breadcrumb.component';
import {RxPush} from "@rx-angular/template/push";
import {NewsContentPipeModule} from "../../../../pipes/news-content-pipe/news-content-pipe.module";

@NgModule({
  declarations: [
    InformationPageComponent,
    InformationPageLinksComponent,
    InformationPageMenuComponent,
    InformationPageBreadcrumbComponent,
  ],
  imports: [
    CommonModule,
    LowerCasePipe,
    NzBreadCrumbComponent,
    NzBreadCrumbItemComponent,
    NzIconDirective,
    TranslateModule,
    UpperCasePipe,
    NgIf,
    AsyncPipe,
    NgForOf,
    NzRowDirective,
    NzColDirective,
    NzSpinComponent,
    NzMenuDirective,
    NzSubMenuComponent,
    RouterLink,
    NzMenuItemComponent,
    NzDrawerComponent,
    NzButtonComponent,
    NzResultComponent,
    NzSkeletonComponent,
    NzDrawerContentDirective,
    NzTypographyComponent,
    ButtonModule,
    RouterLinkActive,
    RxPush,
    NewsContentPipeModule,
  ],
})
export class InformationPageModule {
  constructor(languageService: LanguageService) {
    languageService.addLocales({en, ru});
  }
}

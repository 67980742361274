import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { RxPush } from '@rx-angular/template/push';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { PluralPipeModule } from 'src/app/pipes/plural-pipe/plural-pipe.module';
import { LanguageService } from 'src/app/services/language.service';

import { DeliveryStatisticsStatComponent } from './components/delivery-statistics-block/delivery-statistics-stat/delivery-statistics-stat.component';
import { DeliveryStatisticsSectionComponent } from './delivery-statistics-section.component';
import { en } from './locale/en';
import { ru } from './locale/ru';
import { DeliveryStatisticsStepIconComponent } from './components/delivery-statistics-steps-block/delivery-statistics-step-icon/delivery-statistics-step-icon.component';
import { DeliveryStatisticsStepModalComponent } from './components/delivery-statistics-steps-block/delivery-statistics-step-modal/delivery-statistics-step-modal.component';
import { ButtonModule } from 'src/app/components/button/button.module';
import { DeliveryStatisticsBlockComponent } from './components/delivery-statistics-block/delivery-statistics-block.component';
import { DeliveryStatisticsRegistrationBlockComponent } from './components/delivery-statistics-registration-block/delivery-statistics-registration-block.component';
import { DeliveryStatisticsStepsBlockComponent } from './components/delivery-statistics-steps-block/delivery-statistics-steps-block.component';
import { DeliveryStatisticsStepsComponent } from './components/delivery-statistics-steps-block/delivery-statistics-steps/delivery-statistics-steps.component';
import { DeliveryStatisticsStepComponent } from './components/delivery-statistics-steps-block/delivery-statistics-step/delivery-statistics-step.component';

@NgModule({
  declarations: [
    DeliveryStatisticsSectionComponent,
    DeliveryStatisticsStatComponent,
    DeliveryStatisticsStepComponent,
    DeliveryStatisticsStepIconComponent,
    DeliveryStatisticsStepModalComponent,
    DeliveryStatisticsBlockComponent,
    DeliveryStatisticsRegistrationBlockComponent,
    DeliveryStatisticsStepsBlockComponent,
    DeliveryStatisticsStepsComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    PluralPipeModule,
    RxPush,
    NzIconModule,
    NzGridModule,
    ButtonModule,
  ],
  exports: [DeliveryStatisticsSectionComponent],
})
export class DeliveryStatisticsSectionModule {
  constructor(languageService: LanguageService) {
    languageService.addLocales({ en, ru });
  }
}

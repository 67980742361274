import { Inject, Injectable } from '@angular/core';
import { filter, fromEvent, map, Observable, share, startWith } from 'rxjs';

import { LOCAL_STORAGE } from '../constants/tokens/local-storage.token';
import { WINDOW } from '../constants/tokens/window.token';

@Injectable()
export class LocalStorageService {
  readonly changes$: Observable<StorageEvent>;
  private readonly _length$: Observable<number>;

  constructor(
    @Inject(WINDOW) private readonly window: Window,
    @Inject(LOCAL_STORAGE) private readonly localStorage: Storage,
  ) {
    this.changes$ = fromEvent<StorageEvent>(this.window, 'storage').pipe(share());
    this._length$ = this.changes$.pipe(map(() => this.length));
  }

  set(key: string, value: unknown): void {
    const valueJson = JSON.stringify(value);
    this.localStorage.setItem(key, valueJson);
  }

  get<T>(key: string): T | null;
  get<T>(key: string, defaultValue: T, initItem?: boolean): T;
  get<T>(key: string, defaultValue?: T, initItem = true): T | null {
    const valueJson = this.localStorage.getItem(key);
    if (valueJson === null) {
      if (defaultValue && initItem) {
        this.set(key, defaultValue);
      }

      return defaultValue ?? null;
    } else {
      return JSON.parse(valueJson);
    }
  }

  get$<T>(key: string): Observable<T | null> {
    return this.changes$.pipe(
      filter((change) => (change.key !== null ? change.key === key : true)),
      map(({ newValue }) => newValue),
      map((value) => (value === null ? null : JSON.parse(value))),
    );
  }

  remove(key: string): void {
    this.localStorage.removeItem(key);
  }

  has(key: string): boolean {
    return this.localStorage.getItem(key) !== null;
  }

  clear(): void {
    this.localStorage.clear();
  }

  key(index: number): string | null {
    return this.localStorage.key(index);
  }

  get length(): number {
    return this.localStorage.length;
  }

  get length$(): Observable<number> {
    return this._length$.pipe(startWith(this.length));
  }
}

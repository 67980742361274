//colors
$light-color: #ffffff;
$dark-color: #000000;
$dark-blue-color: #001529;
$blue-color: #2c7df6;
$blue-background-color: #0e66e9;
$blue-background-active-color: #0654c9;
$gray-color: #888e9b;
$gray-color-border: #CFD2D7;
$light-grey-color: #a0a8b7;
$light-grey-color-text: #bababa;
$orange-color: #ffa028;
$light-blue-color: #a2c3f8;
$light-hover-color: #eaeaed;
$dark-active-color: #161616;
$background-color: #f5f5f5;
$border-footer-color: #3b3c60;
$red-color: #fe1d00;
$success-color: #00ef8e;
$disabled-bg-color: #b8b8b878;
$disabled-color: #b8b8b8e3;

$mobile-sm-width: 576px;
$mobile-width: 768px;
$mobile-lg-width: 992px;
$max-content-width: 1440px;

import {Department} from '../../../graphql/mochiron-graphql.service';
import {LotType} from '../enums/lot-type.enum';

export const departmentIdByLotType = {
  [LotType.Dubai]: Department.Dubai,
  [LotType.GreenCorner]: Department.GreenCorner,
  [LotType.Mongolia]: Department.Mongolia,
  [LotType.Recycling]: Department.Utilization,
  [LotType.Usa]: Department.UsaMiami,
};

import { ru } from './ru';

export const en: typeof ru = {
  LOT: {
    VISITED: 'Visited',
    SHARE: 'Share',
    SOLD: 'Sold!',
    ON_REQUEST: 'On request',
    COPY_LINK: 'Copy',
    PRICE_TOOLTIP: 'Prices are based on preliminary calculations. Final costs may vary from those indicated.',
    FIELDS: {
      PRICE: 'Price',
      MONGOLIA_PRICE: 'Price at the destination',
      JAPAN_PRICE: 'Price in Japan',
      TRANSIT_PRICE: 'Price in transit',
      AUCTION_GRADE: 'Auction grade',
      YEAR: 'Year',
      COLOR: 'Color',
      MILEAGE: 'Mileage',
      CLASS: 'Model',
      TRANSMISSION: 'Transmission',
      LOCATION: 'Location',
      ENGINE: 'Engine capacity and type',
      VIN: 'Vin',
    },
    ACTIONS: {
      SELL_CONTACTS: 'Seller contacts',
      SUBMIT_APPLICATION: 'Submit an application',
    },
    NOT_AUTHORIZED_ACTIONS: {
      TITLE: 'Register or Sign-in to your account to view all photos',
      ACCESS_LIMITED: 'Access limited',
    },
    DESCRIPTION: {
      TITLE: 'Description',
    },
    UPCOMING_SAILINGS: {
      BUTTON: 'Upcoming sailings',
      MODAL_TITLE: 'Upcoming sailings',
      UPDATED_AT: 'Updated at',
      TABLE: {
        HEADER: {
          TITLE: 'Trips schedule',
          VESSEL: 'Vessel',
          VOYAGE: 'Voyage',
          DEPARTURE: 'Departure',
          TOYAMA: 'Toyama',
          ARRIVAL: 'Arrival',
        },
      },
    },
    CONTACT_NUMBER_MODAL: {
      MANAGERS: 'Managers',
      LIVE_INSPECTION: 'Live inspection',
      WHATSAPP: 'WhatsApp',
    },
    INSPECTION: {
      TITLE: 'Show inspection',
      FIELDS: {
        AIS_INFO: 'AIS Information',
        AUCNET_INFO: 'AUCNET Information',
        SELLING_POINT: 'Additional notes',
        NOTICE: 'Notice',
        TOTAL_SCORE: 'Total grade',
        EXTERIOR_SCORE: 'Exterior grade',
        INTERIOR_SCORE: 'Interior grade',
      },
    },
    PACKAGE: {
      TITLE: 'Package',
      FIELDS: {
        GENUINE_PART: 'Genuine part',
        THIRD_PARTY_PART: 'Third-party part',
        GENUINE_OR_THIRD_PART: 'Genuine or third-party',
        AIR_CONDITIONER: 'Air-conditioning',
        AIR_BAG: 'Air-bag',
        SUNROOF: 'Sunroof',
        ALUMINUM_WHEEL: 'Alloy wheels',
        LEATHER_SEAT: 'Leather interior',
        NAVIGATION: 'Navigation',
        KEYLESS_ENTRY: 'Keyless access',
        SMART_KEY: 'Smart key',
        FLOOR_MAT: 'Floor mat',
        BACK_CAMERA: 'Rearview camera',
        ETC: 'Other',
        GUARANTEE_BOOK: 'Warranty book',
        MANUAL_BOOK: 'Service manual',
        RECORD_BOOK: 'Service book',
        MAINTENANCE_NOTE: 'Maintenance note',
      },
    },
    MOTO_DAMAGE_SCHEME: {
      TITLE: 'Damage scheme',
    },
    SIMILAR_ADS: {
      TITLE: 'Similar ads',
      EMPTY: 'Unfortunately, there are no similar ads',
    },
  },
  LOT_APPLICATION_MODAL: {
    FILL_OUT_APPLICATION: 'Fill out the application',
    SELLER_CONTACTS: 'Contacts',
    MESSENGERS: {
      TITLE: 'Mark the messengers associated with this number',
    },
    FORM: {
      PRICE: 'Price',
      CURRENCY: 'Currency',
      FIRST_NAME: 'First Name',
      LAST_NAME: 'Last Name',
      PHONE: 'Phone Number',
      EMAIL: 'Email Address',
      COUNTRY: 'Country',
      SHIPPING_TYPE: 'Shipping type',
      PORT_OF_DELIVERY: 'Port of delivery',
      FREIGHT_PAYMENT: 'Freight',
      COMPANY: 'Company Name',
      COMMENT: 'Additional Comments',
      ERROR_TIP: {
        PRICE: 'Price is required',
        CURRENCY: 'Currency not selected',
        FIRST_NAME: 'First name is required',
        LAST_NAME: 'Last name is required',
        PHONE: 'Phone is required',
        EMAIL: 'Email is required',
      },
    },
    BTN: 'Send application',
    BTN_CLOSE: 'Close',
    APPLICATION_SEND: 'Done!',
    APPLICATION_SEND_CONTACT: 'Our managers will contact you shortly. Thank you!',
  },
};

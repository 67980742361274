import {Injectable} from "@angular/core";
import {FormGroup} from "@angular/forms";
import {Params} from "@angular/router";
import {DateTime} from "luxon";
import {NewsFilterInput} from "../../../../../graphql/content-auto-graphql.service";
import {NewsPageFilterForm} from "../components/news-page-filter/types/news-page-filter.types";
import {EnvironmentService} from "../../../../../services/environment.service";
import {FormValue} from "../../../../../utilities/types/form-value.type";

@Injectable()
export class NewsPageFilterBuilderService {
  constructor(private environmentService: EnvironmentService) {
  }

  getParams(formValue: FormValue<NewsPageFilterForm>): Params {
    const {dateFrom, dateTo} = formValue;

    return {
      dateFrom: this.dateToParam(dateFrom),
      dateTo: this.dateToParam(dateTo),
    };
  }

  patchFilterForm(filterForm: FormGroup<NewsPageFilterForm>, params: Params): void {
    filterForm.patchValue({
      dateFrom: this.getDate(params['dateFrom']),
      dateTo: this.getDate(params['dateTo']),
    });
  }

  buildFilter(params: Params): NewsFilterInput {
    return {
      site: {
        domain: {
          eq: this.environmentService.currentEnvironment.autoNewsDomain
        },
      },
      publishedAt: {
        gte: params['dateFrom']?.trim() || DateTime.now().minus({years: 2}).toISO(),
        lte: params['dateTo']?.trim() || DateTime.now().toISO(),
      },
    };
  }

  private dateToParam(date?: Date | null): string | null {
    return date
      ? DateTime.fromJSDate(date).startOf('day').toISO()
      : null;
  }

  private getDate(value?: string): Date | null {
    return value?.trim()
      ? DateTime.fromISO(value.trim()).startOf('day').toJSDate()
      : null;
  }
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Value } from 'src/app/services/lot-adapter/types/value.type';

@Component({
  selector: 'app-lot-card-title',
  templateUrl: './lot-card-title.component.html',
  styleUrl: './lot-card-title.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotCardTitleComponent {
  @Input({ required: true }) mark?: string;
  @Input({ required: true }) model?: string;
  @Input({ required: true }) grade?: string;
  @Input({ required: true }) engineVolume?: Value;
  @Input({ required: true }) bodyType?: string;
  @Input({ required: true }) drive?: string;
  @Input({ required: true }) maker?: string;
  @Input({ required: true }) year?: number;
  @Input({ required: true }) price?: Value;
  @Input({ required: true }) isSold?: boolean;
}

<div class="not-found">
  <a [routerLink]="previousPage" class="back-btn">
    <span nz-icon nzType="arrow-left" nzTheme="outline"></span>
    {{ 'NOT_FOUND_PAGE.BACK' | translate }}
  </a>

  <div class="img-wrapper">
    <h1>404</h1>
    <img class="not-found-car" src="/assets/img/not-found-car.png" alt="not-found">
  </div>

  <p>{{ 'NOT_FOUND_PAGE.TITLE' | translate }}</p>
  <a [routerLink]="['/']">{{ 'NOT_FOUND_PAGE.TO_MAIN' | translate }}</a>
</div>

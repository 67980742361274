import {
  AkebonoLotSource,
  AkebonoLotTypeEnum,
} from '../../../graphql/akebono-main-graphql.service';
import { AucnetTruckLotMinimalFragment } from '../../../graphql/aucnet-trucks-graphql.service';
import { LotSource } from '../enums/lot-source.enum';
import { LotType } from '../enums/lot-type.enum';
import { ILotMinimalBase } from '../interfaces/lot-minimal-base.interface';
import { ILotMinimal } from '../interfaces/lot-minimal.interface';
import { MediaItem } from '../types/media-item.type';
import { Value } from '../types/value.type';
import { LotAdapterUtilities } from '../utilities/lot-adapter.utilities';

export class AucnetTruckLotMinimalAdapter implements ILotMinimal {
  id?: string | undefined;
  media?: MediaItem[] | undefined;
  maker?: string;
  mark?: string;
  model?: string | undefined;
  grade?: string | undefined;
  year?: number | undefined;
  engineVolume?: Value | undefined;
  transmission?: string | undefined;
  fuel?: string | undefined;
  bodyType?: string | undefined;
  drive?: string | undefined;
  rating?: string | undefined;
  price?: Value | undefined;
  mileage?: Value | undefined;
  color?: string | undefined;
  location?: string | undefined;
  vin?: string | undefined;
  accident?: string | undefined;
  isSold?: boolean | undefined;
  type: LotType;
  source: LotSource;
  akebonoLotType: AkebonoLotTypeEnum;
  akebonoLotSource: AkebonoLotSource;
  fields: Set<keyof ILotMinimalBase>;

  constructor(lot: AucnetTruckLotMinimalFragment) {
    this.type = LotType.Truck;
    this.source = LotSource.AucnetAuto;
    this.akebonoLotType = AkebonoLotTypeEnum.Truck;
    this.akebonoLotSource = AkebonoLotSource.Aucnet;

    this.id = lot.id || undefined;
    this.media = LotAdapterUtilities.normalizeMedia(lot.images);
    this.maker = lot.makerEn || undefined;
    this.mark = lot.carnameEn || undefined;
    this.model = lot.modelCd || undefined;
    this.grade = lot.grade || undefined;
    this.year = LotAdapterUtilities.normalizeRegistrationYear(lot.registration);
    this.engineVolume = LotAdapterUtilities.buildValue(lot.exhaust, lot.exhaustCd);
    this.transmission = lot.missionCd === 'NOT_SET' ? undefined : lot.missionCd || undefined;
    this.fuel = lot.fuelCd || undefined;
    this.bodyType = LotAdapterUtilities.normalizeBodyType(lot.bodyTypeCd);
    this.drive = LotAdapterUtilities.normalizeDrive(lot.driveCd);
    this.rating = LotAdapterUtilities.praseRating(lot.aisScoreTotal);
    this.price = LotAdapterUtilities.buildValue(lot.wholesalePrice, 'JPY');
    this.mileage = LotAdapterUtilities.buildValue(lot.mileage, lot.mileageFlag);
    this.location = lot.regionName || undefined;
    this.vin = lot.carbodyNo || undefined;
    this.fields = new Set([
      'id',
      'media',
      'maker',
      'mark',
      'model',
      'grade',
      'year',
      'engineVolume',
      'transmission',
      'fuel',
      'bodyType',
      'drive',
      'rating',
      'price',
      'mileage',
      'location',
      'vin',
    ]);
  }
}

import { ru } from './ru';

export const en: typeof ru = {
  "HEADER_SECTION": {
    "TIME": "Tokyo",
    "TITLE": "Akebono - Your reliable partner for car delivery",
    "TEXT": "Delivery of cars from Japanese auctions around the world",
    "TRACK_PURCHASE": "Track your purchase",
    "MINI_FILTERING_FORM": {
      "MARKA": "Brand",
      "MODEL": "Model",
      "YEAR": "Year",
      "PRICE_FROM": "Price from",
      "PRICE_TO": "Price up to",
      "FIND_CAR": "Find car"
    }
  }
};

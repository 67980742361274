<div class="desktop-actions">
  <a app-button [href]="loginUrl">
    <span>{{ 'NOT_AUTHORIZED_ACTIONS.LOGIN' | translate }}</span>
  </a>
  <a app-button [href]="registrationUrl" appType="ghost"
    ><span>{{ 'NOT_AUTHORIZED_ACTIONS.REGISTER' | translate }}</span></a
  >
</div>

<a
  nz-dropdown
  nzTrigger="click"
  nzPlacement="bottomRight"
  [nzDropdownMenu]="menu"
  class="mobile-actions"
  [class.dropdown-open]="dropdownOpen"
  [(nzVisible)]="dropdownOpen"
>
  <i nz-icon nzType="icons:icon-user"></i>
  <i nz-icon nzType="down" class="icon-down"></i>
</a>

<nz-dropdown-menu #menu="nzDropdownMenu">
  <ul class="dropdown-list">
    <li>
      <a [href]="loginUrl">
        {{ 'NOT_AUTHORIZED_ACTIONS.LOGIN' | translate }}
      </a>
    </li>
    <li>
      <a [href]="registrationUrl">
        {{ 'NOT_AUTHORIZED_ACTIONS.REGISTER' | translate }}
      </a>
    </li>
  </ul>
</nz-dropdown-menu>

import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaginationComponent } from './pagination.component';
import { NzIconModule } from 'ng-zorro-antd/icon';

@NgModule({
  declarations: [PaginationComponent],
  imports: [CommonModule, NzIconModule],
  exports: [PaginationComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PaginationModule {}

import { inject, Injectable } from '@angular/core';
import { ApolloClientOptions, InMemoryCache, NormalizedCacheObject } from '@apollo/client/core';
import { Apollo } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';

import { Environment } from '../utilities/types/environment.type';
import { EnvironmentService } from './environment.service';

@Injectable()
export class ApolloInitializerService {
  private readonly apollo = inject(Apollo);
  private readonly httpLink = inject(HttpLink);
  private readonly environment = inject(EnvironmentService);

  public initialize(): void {
    const { graphqlClients } = this.environment.currentEnvironment;

    graphqlClients.forEach((clientConfig) => {
      const apolloClientOptions = this.buildApolloClientOptions(clientConfig);
      this.apollo.create(apolloClientOptions, clientConfig.clientName);
    });
  }

  private buildApolloClientOptions(
    clientConfig: Environment['graphqlClients'][number],
  ): ApolloClientOptions<NormalizedCacheObject> {
    const cache = new InMemoryCache({});
    const link = this.httpLink.create({
      uri: clientConfig.endpoint,
      withCredentials: true,
      method: clientConfig.method || 'POST',
    });

    return {
      link,
      cache,
      defaultOptions: {
        watchQuery: {
          fetchPolicy: clientConfig.fetchPolicy,
          errorPolicy: 'none',
        },
      },
    };
  }
}

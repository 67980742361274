import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-tab-button',
  templateUrl: './tab-button.component.html',
  styleUrl: './tab-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabButtonComponent {
  @Input({ required: true }) title!: string;
  @Input({ required: true }) index!: number;
  @Input() active = false;

  @Output() selected = new EventEmitter<number>();

  select(): void {
    if (!this.active) {
      this.selected.emit(this.index);
    }
  }
}

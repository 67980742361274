export const ru = {
  "INFORMATION_PAGE": {
    "TITLE": "Информация",
    "TO_MAIN": "На главную",
    "CATEGORIES": "Категорий",
    "ARTICLE_NOT_FOUND": "Статья не найдена",
    "ARTICLES_LIST": "Список статей",
    "REFERENCE_INFO": "Справочная информация",
    "REFERENCE_INFO_TXT": "В этот разделе, мы собрали для Вас всю библиотеку Акебоно. Здесь , Вы сможете изучить деятельность авто-аукционов, структуру, историю, типы аукционов, новости аукционов и авто индустрии в целом.",
  }
};

import { DOCUMENT, registerLocaleData } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import en from '@angular/common/locales/en';
import ru from '@angular/common/locales/ru';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { TranslateModule } from '@ngx-translate/core';
import { ApolloModule } from 'apollo-angular';
import { NZ_CONFIG } from 'ng-zorro-antd/core/config';
import { en_US, NZ_I18N } from 'ng-zorro-antd/i18n';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import {
  AUTH_SERVICE,
  AuthModule,
  PROTECTED_FALLBACK_PAGE_URI,
  PUBLIC_FALLBACK_PAGE_URI,
} from 'ngx-auth';
import { CookieService } from 'ngx-cookie-service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutModule } from './components/layout/layout.module';
import { ngZorroConfiguration } from './constants/configuration/ng-zorro.configuration';
import { LOCAL_STORAGE } from './constants/tokens/local-storage.token';
import { LOCATION } from './constants/tokens/location.token';
import { NAVIGATOR } from './constants/tokens/navigator.token';
import { WINDOW } from './constants/tokens/window.token';
import { MainModule } from './modules/main/main.module';
import { ApolloInitializerService } from './services/apollo-initializer.service';
import { AppInitializerService } from './services/app-initializer.service';
import { AuthenticationService } from './services/authentication.service';
import { CognitoService } from './services/cognito.service';
import { CurrentUserService } from './services/current-user/current-user.service';
import { EnvironmentService } from './services/environment.service';
import { LanguageService } from './services/language.service';
import { LocalStorageService } from './services/local-storage.service';
import { LotAdapterService } from './services/lot-adapter/lot-adapter.service';
import { LotFetcherService } from './services/lot-fetcher/lot-fetcher.service';
import { NotificationService } from './services/notification.service';
import { PopularLotFetcherService } from './services/popular-lot-fetcher/popular-lot-fetcher.service';
import { ResizeObserverService } from './services/resize-observer.service';
import { SizeService } from './services/size/size.service';
import { AuctionModule } from './modules/auction/auction.module';
import { register } from 'swiper/element/bundle';
import { PageTitleServiceModule } from './services/page-title/page-title-service.module';
import { NewsService } from "./services/news/news.service";
import { InformationService } from "./services/information/information.service";
import {ExchangeRateService} from "./services/exchange-rate/exchange-rate.service";

register();
registerLocaleData(en);
registerLocaleData(ru);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AuthModule,
    FormsModule,
    ApolloModule,
    TranslateModule.forRoot(),
    NzNotificationModule,
    LayoutModule,
    MainModule,
    AuctionModule,
    PageTitleServiceModule
  ],
  providers: [
    CookieService,
    LanguageService,
    EnvironmentService,
    AuthenticationService,
    AppInitializerService,
    ApolloInitializerService,
    LocalStorageService,
    SizeService,
    CognitoService,
    CurrentUserService,
    NotificationService,
    ResizeObserverService,
    LotAdapterService,
    LotFetcherService,
    PopularLotFetcherService,
    NewsService,
    InformationService,
    ExchangeRateService,
    {
      provide: APP_INITIALIZER,
      deps: [AppInitializerService],
      useFactory: (appInitializer: AppInitializerService) => appInitializer.initialize(),
    },
    {
      provide: PROTECTED_FALLBACK_PAGE_URI,
      deps: [EnvironmentService],
      useFactory: (environment: EnvironmentService) => environment.currentEnvironment.loginUrl,
    },
    { provide: PUBLIC_FALLBACK_PAGE_URI, useExisting: PROTECTED_FALLBACK_PAGE_URI },
    { provide: AUTH_SERVICE, useExisting: AuthenticationService },
    provideHttpClient(withInterceptorsFromDi()),
    { provide: WINDOW, deps: [DOCUMENT], useFactory: (document: Document) => document.defaultView },
    { provide: NAVIGATOR, deps: [WINDOW], useFactory: (window: Window) => window.navigator },
    { provide: LOCAL_STORAGE, deps: [WINDOW], useFactory: (window: Window) => window.localStorage },
    { provide: LOCATION, deps: [WINDOW], useFactory: (window: Window) => window.location },
    { provide: NZ_I18N, useValue: en_US },
    { provide: NZ_CONFIG, useValue: ngZorroConfiguration },
    provideAnimationsAsync(),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

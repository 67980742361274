import { ru } from './ru';

export const en: typeof ru = {
  "AUCTION": {
    "LINK_CATEGORIES": {
      "DUBAI": "Dubai",
      "JAPAN": "Japan",
      "MONGOLIA": "Mongolia",
      "GREEN": "Green corner 2.0",
      "MOTORCYCLE": "Motorbikes",
      "TRUCK": "Trucks",
      "RECYCLING": "Toyama recycling",
      "USA": "Cars in USA",
    },
    "CATEGORY_INFO": {
      "TITLE": {
        "JAPAN": "Cars in stock in Japan",
        "GREEN": "Cars - Green corner 2.0",
        "MONGOLIA": "Cars - Mongolia",
        "MOTORCYCLE": "Motorbikes auctions",
        "TRUCK": "Cargo and special-purpose vehicles",
        "RECYCLING": "Cars - recycle",
        "DUBAI": "Cars - Dubai",
        "USA": "Cars in USA",
      },
      "TEXT": {
        "JAPAN": "Vehicles available for purchase in Japan. Here you will always find a wide selection of vehicles in stock, offered directly from japanese auctions. Up-to-date descriptions and photos will help you assess the condition of each vehicle. Explore our inventory and choose the one that meets your requirements.",
        "GREEN": "Selection of vehicles approved for export to Russia. These cars are already in our inventory at Japanese lots, ready for shipment. Want your listing to appear on this page? Review the rules for dealers. General guidelines for purchasing a car from Green Corner.",
        "MONGOLIA": "A wide range of vehicles currently en route or available for immediate shipment to Mongolia. Detailed descriptions of each vehicle will help you choose the right option to meet your needs and preferences.",
        "MOTORCYCLE": "Current selection of motorcycles available at Japanese auctions. Relevant photos, descriptions, and additional information will help you find exactly what you need.",
        "TRUCK": "Cargo, passenger, and other special-purpose vehicles available in Japan. In this section, you can also find various types of trailers for trucks.",
        "RECYCLING": "Vehicles designated for export from Japan, either whole or as spare parts. These cars are an excellent source of spare parts and materials for repairs or your personal projects.",
        "DUBAI": "Explore our current selection of vehicles from Dubai. Purchase cars that are already in stock at our Dubai lots, as well as those ready for immediate shipment from Japan or already en route. We provide detailed information about each vehicle to help you make an informed purchasing decision.",
        "USA": "Cars available at our Miami yard. Check out the current list of cars ready for sale",
      }
    },
    "PAGINATION_STATUS": {
      "SHOW": "Displayed",
      "OF": "of",
      "TOTAL": "total"
    },
    "LOTS_PAGE": {
      "SEARCH_CAR": "Car search",
      "LOTS_LIST": {
        "NO_RESULTS": 'No results',
      },
    },
  }
};

export const ru = {
  "AUCTION": {
    "LINK_CATEGORIES": {
      "DUBAI": "Дубай",
      "JAPAN": "Япония",
      "MONGOLIA": "Монголия",
      "GREEN": "Зеленый угол 2.0",
      "MOTORCYCLE": "Мотоциклы",
      "TRUCK": "Грузовые авто",
      "RECYCLING": "Тояма утилизация",
      "USA": "Автомобили в США",
    },
    "CATEGORY_INFO": {
      "TITLE": {
        "JAPAN": "Автомобили в наличии в Японии",
        "GREEN": "Автомобили - Зеленый угол 2.0",
        "MONGOLIA": "Автомобили - Монголия",
        "MOTORCYCLE": "Мотоциклы на аукционах Японии",
        "TRUCK": "Грузовые авто и спец-техника",
        "RECYCLING": "Автомобили под утилизацию",
        "DUBAI": "Автомобили - Дубай",
        "USA": "Автомобили в США",
      },
      "TEXT": {
        "JAPAN": "Автомобили доступные к покупке в Японии. У нас Вы всегда найдете широкий выбор транспортных средств в наличии, предлагаемые напрямую с японских ярдов. Актуальное описание и фотографии помогут вам оценить состояние каждого автомобиля. Ознакомьтесь с нашим ассортиментом и выберите то, что соответствует вашим требованиям.",
        "GREEN": "Ассортимент автомобилей разрешенных для экспорта в Россию. Эти машины уже находятся в нашем запасе на стоянках в Японии, готовые к отправке. Хотите чтобы ваше объявление попало на эту страницу? Ознакомьтесь с правилами для дилеров. Общие правила покупки автомобиля с Зеленого угла.",
        "MONGOLIA": "Широкий ассортимент автомобилей в пути или доступных для немедленной отправки в Монголию. Подробные описания каждого автомобиля помогут вам выбрать подходящий вариант, соответствующий вашим потребностям и предпочтениям.",
        "MOTORCYCLE": "Актуальный ассортимент мото-техники на аукционах Японии. Релевантные фотографии, описание и дополнительная информация помогут вам подобрать то, что вам необходимо.",
        "TRUCK": "Грузовые, пассажирские и другие спец-авто в наличии в Японии. В этом же разделе вы можете найти некоторые виды прицепов.",
        "RECYCLING": "Автомобили, предназначенные для вывоза из Японии как целыми, так и в виде запчастей. Эти автомобили представляют собой отличный источник запасных частей и материалов для ремонта или вашего личного проекта.",
        "DUBAI": "Ознакомьтесь с нашим актуальным автомобильным ассортиментом из города Дубай. Приобретайте машины, которые уже находятся в стоке на наших стоянках в Дубае, а также те, что уже готовы к мгновенной отправке из Японии или уже находятся в пути. Мы предоставляем подробную информацию о каждом автомобиле, чтобы Вам было проще принять верное решение о покупке.",
        "USA": "Автомобили в наличии на нашей стоянке в г. Маями. Ознакомьтесь с актуальным списком машин готовых к продаже",
      }
    },
    "PAGINATION_STATUS": {
      "SHOW": "Отображается",
      "OF": "из",
      "TOTAL": "всего"
    },
    "LOTS_PAGE": {
      "SEARCH_CAR": "Поиск автомобилей",
      "LOTS_LIST": {
        "NO_RESULTS": 'Нет подходящих результатов',
      },
    },
  }
};

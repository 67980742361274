<a [routerLink]="['/', 'news', newsItem?.id]">
  <nz-card [class]="background" [nzTitle]="title">
    <div class="content">
      <div [innerHTML]="newsItem | newsContent: 'content' "></div>
    </div>
  </nz-card>
</a>
<ng-template #title>
  <span class="published-date">{{ newsItem?.publishedAt | date: 'dd.MM.yyyy' }}</span>
  <h1 class="title">{{ newsItem | newsContent: 'title' }}</h1>
</ng-template>

<p><strong>Company Akebono Co., Ltd. Sanctions Compliance Policy</strong></p>

<strong>1. General Provisions</strong>
<p>
  1.1. The Akebono Co., Ltd. Sanctions Compliance Policy (hereinafter referred to as the "Policy")
  is a fundamental top-level document setting forth the minimum requirements for compliance with
  Sanctions in all jurisdictions where the Company operates.
</p>
<p>1.2. The objectives of the Policy are:</p>
<ul>
  <li>
    Minimization of the risk of imposition of Sanctions, including secondary sanctions, on the
    Company, which may impact the Company's commitments to the government, society, business
    partners, clients, and employees;
  </li>
  <li>Prevention of actions that may lead to violations of Sanctions restrictions;</li>
  <li>
    Establishment of a uniform and effective control system within the Company regarding Sanctions
    restrictions.
  </li>
</ul>

<p>
  1.3. The Policy reflects the commitment of the management and employees of the Company to ethical
  standards of conducting legal and transparent business, adhering to the best international
  management practices, and maintaining a proper level of business reputation.
</p>

<p>1.4. The key principles of the Policy include:</p>
<ul>
  <li>
    Compliance with Sanctions restrictions of the jurisdictions in which Akebono Co., Ltd. operates,
    is registered, and/or licensed;
  </li>
  <li>
    Adherence to the requirements of Japan's Sanctions regime wherever the Company operates and
    refusal of business that would violate the regimes of these Sanctions;
  </li>
  <li>
    Consideration by the Company of Sanctions imposed by other jurisdictions, if it is practical for
    transactional purposes;
  </li>
  <li>
    Non-provision of goods, works, or services by the Company, even if allowed by relevant
    regulations, if the circumstances pose a reputational risk;
  </li>
  <li>
    Refusal by the Company and its employees to engage in business relations with any person subject
    to restrictions to avoid violating any applicable Sanctions laws;
  </li>
  <li>Rejection by the Company of business that would violate applicable export laws.</li>
</ul>

<strong>2. Terms and Definitions</strong>
<p>2.1. Secondary Sanctions:</p>
<p>
  Secondary sanctions are economic restrictive measures applied by international organizations, a
  country, or a group of countries against violators of existing Sanctions restrictions.
</p>

<p>2.2. Sanctions/Sanction Restrictions:</p>
<p>
  Sanctions, or sanction restrictions, are economic restrictive measures applied by international
  organizations, a country, or a group of countries against another country or group of countries,
  including its physical and legal entities. The purpose of these measures is to prevent certain
  activities.
</p>

<p>2.3. Counterparty:</p>
<p>
  A counterparty is a party with whom a contract, agreement, or transaction is concluded. A
  counterparty can be an individual or a legal entity.
</p>

<strong>3. Sanctions Compliance Measures</strong>
<p>
  3.1. The leadership of Akebono Co., Ltd. defines the overall strategy for sanctions restrictions
  and regularly updates it.
</p>

<p>
  3.2. The executives of Akebono Co., Ltd. allocate authorities, duties, and responsibilities under
  this Policy among employees and/or organizational units of the Company. They also organize
  activities aimed at implementing the principles and requirements of the Policy, including the
  appointment of individuals responsible for the development, implementation, and monitoring of
  procedures for compliance with sanctions restrictions.
</p>

<p>
  3.3. Before entering into any business relationships or transactions with individuals or
  organizations, the Company must take measures to ensure that such relationships and transactions
  comply with the laws of Japan and other countries (if required). This includes checking these
  individuals or organizations against special blacklists and other relevant sanction lists (e.g.,
  published by OFAC).
</p>

<p>
  3.4. The Company is obligated to scrutinize its business relationships and transactions for
  potential violations of sanctions regarding a specific country, legal entity, or individual.
</p>

<p>3.5. Contracts with counterparties must include provisions:</p>
<ul>
  <li>
    Confirming that the counterparty is not listed in sanction lists or otherwise subject to
    sanctions restrictions;
  </li>
  <li>
    Requiring compliance with UN resolutions, Japanese sanctions legislation, and this Policy;
  </li>
  <li>
    Mandating that counterparties refrain from engaging in business activities that may lead to
    violations of sanctions obligations;
  </li>
  <li>
    Granting the company the right to terminate the contract and not fulfill its obligations in case
    of a counterparty's violation of this Policy, inclusion in sanction lists, or any other
    imposition of sanctions.<br />
  </li>
</ul>
<p>
  3.6. The Company must not enter into business relationships or transactions that directly or
  indirectly involve countries or individuals subject to sanctions restrictions.
</p>

<p>
  3.7. The fact that a country is under sanctions or an individual belongs to such a country does
  not automatically mean that the Company cannot engage in business relationships or transactions
  involving that country or individual. However, the transaction must be thoroughly vetted first to
  ensure compliance with legal obligations related to sanctions.
</p>

<strong>4. Consequences of Non-Compliance with Sanctions Restrictions</strong>
<p>
  4.1. Non-compliance with relevant sanctions restrictions constitutes a violation of legal and/or
  regulatory requirements, which may result in significant reputational damage to the Company,
  potential legal actions, financial losses, and may subject individual employees involved in the
  violation to substantial fines or imprisonment.
</p>

<p>
  4.2. The Company adheres to a zero-tolerance approach to intentional violations of this Policy or
  applicable sanctions regimes. In case of an employee's non-compliance with this Policy,
  disciplinary actions, up to and including termination of employment, may be applied. Disciplinary
  measures will depend on the circumstances of the violation and will be implemented in accordance
  with applicable law and the Company's internal regulations.
</p>

<p>
  4.3. The Company reserves the right to terminate business relationships with any counterparty
  (including the termination of all existing contracts) through written notice to the counterparty,
  with immediate effect, without the need for legal proceedings and without any obligation to
  compensate any type or nature of damages (direct and/or indirect) to the said counterparty, in the
  event that:
</p>
<ul>
  <li>
    The counterparty fails to comply with any provision of this Policy and cannot rectify the
    situation (if correctable) within 10 days from the date of written notification to the
    counterparty of the violation;
  </li>
  <li>
    The counterparty is listed in any sanction list or becomes the subject of other sanctions
    restrictions.
  </li>
</ul>

<strong>5. Final Provisions</strong>
<p>
  5.1. Contracts and agreements between the Company and counterparties may contain more specific
  (including individual) provisions regarding certain issues outlined in this Policy. Nothing in
  this Policy replaces any specific provision in a particular contract or agreement between the
  Company and a counterparty. In case of inconsistency between this Policy and any other provision
  of a specific contract or agreement, the provision in the contract or agreement shall prevail.
</p>

<p>
  5.2. This Policy is intended to complement, not replace, other codes of conduct, policies, rules,
  and procedures that may apply to employees and counterparties from time to time. If any employee
  or counterparty has any doubts about the codes, policies, rules, and procedures applicable in a
  specific situation, or if any employee or counterparty discovers any conflict or inconsistency
  between this Policy and any other code of conduct of the Company or any other policies, they
  should seek clarification from the company's headquarters in Toyama.
</p>

<strong>6. Review and Amendment Procedure</strong>
<p>
  6.1. As sanctions regimes may change rapidly and without prior notice, this Policy may be updated
  as necessary.
</p>

<p>
  6.2. Amendments to the Policy are made in accordance with the established internal regulatory
  procedure, including improvements to the set of measures to prevent sanctions risks.
</p>

import { CommonModule, NgOptimizedImage } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { RxPush } from '@rx-angular/template/push';
import { NzButtonComponent } from 'ng-zorro-antd/button';
import { NzCheckboxComponent, NzCheckboxGroupComponent } from 'ng-zorro-antd/checkbox';
import { NzDividerComponent } from 'ng-zorro-antd/divider';
import { NzEmptyComponent } from 'ng-zorro-antd/empty';
import { NzFlexDirective } from 'ng-zorro-antd/flex';
import { NzFormControlComponent, NzFormDirective } from 'ng-zorro-antd/form';
import { NzColDirective, NzRowDirective } from 'ng-zorro-antd/grid';
import { NzIconDirective } from 'ng-zorro-antd/icon';
import { NzImageModule } from 'ng-zorro-antd/image';
import { NzAutosizeDirective, NzInputDirective, NzInputGroupComponent } from 'ng-zorro-antd/input';
import { NzInputNumberComponent } from 'ng-zorro-antd/input-number';
import {
  NzListComponent,
  NzListItemComponent,
  NzListItemMetaAvatarComponent,
  NzListItemMetaComponent,
  NzListItemMetaTitleComponent,
} from 'ng-zorro-antd/list';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzResultComponent } from 'ng-zorro-antd/result';
import { NzOptionComponent, NzSelectComponent } from 'ng-zorro-antd/select';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzSpinComponent } from 'ng-zorro-antd/spin';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

import { ButtonModule } from '../../../../components/button/button.module';
import { CollapseModule } from '../../../../components/collapse/collapse.module';
import { LotCardModule } from '../../../../components/lot-card/lot-card.module';
import { PhoneInputModule } from '../../../../components/phone-input/phone-input.module';
import { RatingTagModule } from '../../../../components/rating-tag/rating-tag.module';
import { StopPropagationDirectiveModule } from '../../../../directives/stop-propagation-directive/stop-propagation-directive.module';
import { VinMaskPipeModule } from '../../../../pipes/vin-mask-pipe/vin-mask-pipe.module';
import { LanguageService } from '../../../../services/language.service';
import { AuctionBreadcrumbModule } from '../../components/auction-breadcrumb/auction-breadcrumb.module';
import { LotGalleryModule } from '../../components/lot-gallery/lot-gallery.module';
import { LotContactFormModalComponent } from './components/lot-contact-form-modal/lot-contact-form-modal.component';
import { LotContactsModalComponent } from './components/lot-contacts-modal/lot-contacts-modal.component';
import { LotHeaderComponent } from './components/lot-header/lot-header.component';
import { LotInfoDescriptionComponent } from './components/lot-info-description/lot-info-description.component';
import { LotInfoPriceComponent } from './components/lot-info/components/lot-info-price/lot-info-price.component';
import { TripsHeaderComponent } from './components/lot-info/components/lot-upcoming-sailings-modal/components/trips-header/trips-header.component';
import { TripsListComponent } from './components/lot-info/components/lot-upcoming-sailings-modal/components/trips-list/trips-list.component';
import { LotUpcomingSailingsModalComponent } from './components/lot-info/components/lot-upcoming-sailings-modal/lot-upcoming-sailings-modal.component';
import { LotInfoComponent } from './components/lot-info/lot-info.component';
import { LotInspectionComponent } from './components/lot-inspection/lot-inspection.component';
import { LotMotoDamageSchemeComponent } from './components/lot-moto-damage-scheme/lot-moto-damage-scheme.component';
import { LotPackageComponent } from './components/lot-package/lot-package.component';
import { LotSimilarAdsComponent } from './components/lot-similar-ads/lot-similar-ads.component';
import { LotSocMediaShareLinksModalComponent } from './components/lot-soc-media-share-links-modal/lot-soc-media-share-links-modal.component';
import { CanRenderForLotTypeDirective } from './directives/can-render-for-lot-type.directive';
import { CanRenderDirective } from './directives/can-render.directive';
import { en } from './locale/en';
import { ru } from './locale/ru';
import { LotPageComponent } from './lot-page.component';
import { CountryNamePipe } from './pipes/country-name.pipe';

@NgModule({
  declarations: [
    LotPageComponent,
    LotInfoDescriptionComponent,
    LotSimilarAdsComponent,
    LotInfoComponent,
    LotContactsModalComponent,
    LotHeaderComponent,
    LotContactFormModalComponent,
    LotInspectionComponent,
    LotPackageComponent,
    CanRenderDirective,
    CanRenderForLotTypeDirective,
    LotMotoDamageSchemeComponent,
    LotInfoPriceComponent,
    LotUpcomingSailingsModalComponent,
    TripsListComponent,
    TripsHeaderComponent,
    LotSocMediaShareLinksModalComponent,
    CountryNamePipe,
  ],
  imports: [
    CommonModule,
    NzSpinComponent,
    RxPush,
    TranslateModule,
    StopPropagationDirectiveModule,
    AuctionBreadcrumbModule,
    NzRowDirective,
    NzColDirective,
    NzListComponent,
    NzListItemComponent,
    NgOptimizedImage,
    NzListItemMetaAvatarComponent,
    NzListItemMetaTitleComponent,
    NzListItemMetaComponent,
    NzIconDirective,
    LotCardModule,
    ButtonModule,
    RouterLink,
    NzModalModule,
    NzResultComponent,
    ReactiveFormsModule,
    NzButtonComponent,
    NzInputDirective,
    NzSelectComponent,
    NzOptionComponent,
    NzInputNumberComponent,
    NzInputGroupComponent,
    NzFormDirective,
    NzFormControlComponent,
    NzAutosizeDirective,
    NzCheckboxGroupComponent,
    NzCheckboxComponent,
    PhoneInputModule,
    NzImageModule,
    RatingTagModule,
    NzFlexDirective,
    NzToolTipModule,
    NzDividerComponent,
    CollapseModule,
    NzEmptyComponent,
    NzTableModule,
    NzSkeletonModule,
    VinMaskPipeModule,
    LotGalleryModule,
  ],
  exports: [LotPageComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class LotPageModule {
  constructor(languageService: LanguageService) {
    languageService.addLocales({ en, ru });
  }
}

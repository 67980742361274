@if (whatsappContactsOpened$ | async) {
  <a
    href="https://wa.me/{{ contacts.whatsapp.ru }}"
    target="self"
    class="chat-link"
    [@slide]
  >
    <span nz-icon class="icons" nzType="icons:whatsapp-ru"></span>
  </a>
  <a
    href="https://wa.me/{{ contacts.whatsapp.other }}"
    target="self"
    class="chat-link"
  >
    <span nz-icon class="icons" nzType="icons:whatsapp-en"></span>
  </a>
}

@if (whatsappContactsNotOpened$ | async) {
  <span class="chat-link" tabindex="0" (click)="openWhatsappContacts.next()">
  <span nz-icon class="icons" nzType="icons:whatsapp"></span>
</span>
}

import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {LayoutComponent} from './components/layout/layout.component';
import {LayoutConfig} from './components/layout/types/layout-config.type';
import {AuctionModule} from './modules/auction/auction.module';
import {MainModule} from './modules/main/main.module';
import {ErrorsModule} from "./modules/errors/errors.module";

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    loadChildren: () => MainModule,
    data: {
      layoutConfig: <LayoutConfig>{
        hasPaddings: false,
        hasWidthLimit: false,
      },
    },
  },
  {
    path: '',
    component: LayoutComponent,
    loadChildren: () => AuctionModule,
  },
  {
    path: '',
    component: LayoutComponent,
    loadChildren: () => ErrorsModule,
    data: {
      layoutConfig: <LayoutConfig>{
        hasPaddings: false,
        hasWidthLimit: false,
      },
    },
  },
  {path: '**', redirectTo: '/error/404'},
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
  ],

  exports: [RouterModule],
})
export class AppRoutingModule {
}

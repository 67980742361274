import { Injectable } from '@angular/core';
import { CognitoRefreshToken, CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';
import { Observable } from 'rxjs';

import { EnvironmentService } from './environment.service';

@Injectable()
export class CognitoService {
  private readonly cognitoUserPool: CognitoUserPool;

  constructor(environment: EnvironmentService) {
    const { cognitoUserPool } = environment.currentEnvironment;
    this.cognitoUserPool = new CognitoUserPool(cognitoUserPool);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public refreshSession(accessToken: string, refreshToken: string): Observable<any> {
    const cognitoUser = this.createCognitoUser(accessToken);
    const cognitoRefreshToken = new CognitoRefreshToken({
      RefreshToken: refreshToken,
    });

    return new Observable((subscribe) => {
      cognitoUser.refreshSession(cognitoRefreshToken, (error, result) => {
        if (error) {
          subscribe.error(error);
        } else {
          subscribe.next(result);
        }
      });
    });
  }

  private createCognitoUser(accessToken: string): CognitoUser {
    const username = this.extractUsernameFromToken(accessToken);
    const cognitoUser = new CognitoUser({
      Username: username,
      Pool: this.cognitoUserPool,
    });

    return cognitoUser;
  }

  private extractUsernameFromToken(accessToken: string): string {
    const rawPayload = accessToken.split('.')[1];
    const payload = JSON.parse(atob(rawPayload));

    return payload.username;
  }
}

import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LotListFilterComponent } from './lots-list-filter.component';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzInputModule } from 'ng-zorro-antd/input';
import { RxPush } from '@rx-angular/template/push';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { LanguageService } from 'src/app/services/language.service';
import { TranslateModule } from '@ngx-translate/core';
import { en } from './locale/en';
import { ru } from './locale/ru';
import { CollapseModule } from 'src/app/components/collapse/collapse.module';
import { ButtonModule } from 'src/app/components/button/button.module';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LotsListAucnetAutoFilterComponent } from './components/lots-list-aucnet-auto-filter/lots-list-aucnet-auto-filter.component';
import { LotsListAucnetMotoFilterComponent } from './components/lots-list-aucnet-moto-filter/lots-list-aucnet-moto-filter.component';
import { LotsListAucnetTruckFilterComponent } from './components/lots-list-aucnet-truck-filter/lots-list-aucnet-truck-filter.component';
import { LotsListMochironFilterComponent } from './components/lots-list-mochiron-filter/lots-list-mochiron-filter.component';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzFormModule } from 'ng-zorro-antd/form';
import { LotsListFilterButtonsComponent } from './components/lots-list-filter-buttons/lots-list-filter-buttons.component';
import { LotsListFilterBuilderService } from './services/lots-list-filter-builder.service';
import { LotListFilterDataService } from './services/lots-list-filter-data.service';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzDrawerModule, NzDrawerRef } from 'ng-zorro-antd/drawer';
import { LotsListFilterService } from './services/lots-list-filter.service';

@NgModule({
  declarations: [
    LotListFilterComponent,
    LotsListAucnetAutoFilterComponent,
    LotsListAucnetMotoFilterComponent,
    LotsListAucnetTruckFilterComponent,
    LotsListMochironFilterComponent,
    LotsListFilterButtonsComponent,
  ],
  imports: [
    CommonModule,
    NzSelectModule,
    NzInputModule,
    NzInputNumberModule,
    RxPush,
    TranslateModule,
    CollapseModule,
    ButtonModule,
    NzCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    NzSpinModule,
    NzFormModule,
    NzIconModule,
    NzDrawerModule,
  ],
  providers: [
    LotListFilterDataService,
    LotsListFilterBuilderService,
    LotsListFilterService,
  ],
  exports: [LotListFilterComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class LotListFilterModule {
  constructor(languageService: LanguageService) {
    languageService.addLocales({ en, ru });
  }
}

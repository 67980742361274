<h1>
  {{ 'USEFUL_INFORMATION_SECTION.TITLE' | translate }}
</h1>

<p>
  {{ 'USEFUL_INFORMATION_SECTION.DESCRIPTION' | translate }}
</p>

<div class="links">
  <a [routerLink]="['/', 'information', 'cis']">
    {{ 'USEFUL_INFORMATION_SECTION.CIS_GUIDE' | translate }}
    <span nz-icon nzType="right" nzTheme="outline"></span>
  </a>
  <a [routerLink]="['/', 'information', 'other']">
    {{ 'USEFUL_INFORMATION_SECTION.OTHER_COUNTRIES_GUIDE' | translate }}
    <span nz-icon nzType="right" nzTheme="outline"></span>
  </a>
</div>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { RxPush } from '@rx-angular/template/push';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { LanguageService } from 'src/app/services/language.service';

import { en } from './locale/en';
import { ru } from './locale/ru';
import { LotsListSortComponent } from './lots-list-sort.component';
import { LotsListSortBuilderService } from './services/lots-list-sort-builder.service';

@NgModule({
  declarations: [LotsListSortComponent],
  imports: [CommonModule, TranslateModule, RxPush, NzDropDownModule, NzIconModule],
  providers: [LotsListSortBuilderService],
  exports: [LotsListSortComponent],
})
export class LotsListSortModule {
  constructor(languageService: LanguageService) {
    languageService.addLocales({ en, ru });
  }
}

import { ru } from './ru';

export const en: typeof ru = {
  "WHO_WE_ARE": {
    "TITLE": "Who are we?",
    "SANCTIONS_POLICY": "Company's sanctions policy",
    "PARAGRAPHS": {
      "1": "Akebono Co., Ltd is a Japanese company specializing in the export of cars from Japanese auctions with <span class=\"blue\">more than 20 years of experience</span>. The company provides a full range of services, including the purchase of cars at auctions, logistics, documentary support and sea transportation.",
      "2": "We help you purchase all types of vehicles, including passenger cars, motorcycles, trucks and passenger cars, trailers and special equipment. We also offer our clients access to purchase other goods from auctions in Japan."
    }
  }
};

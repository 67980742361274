export const ru = {
  BUHANKA_DONATE: {
    TITLE: 'Собираем на Буханку 📢',
    DESCRIPTION: 'Объявляем о сборе доната на покупку УАЗ 2206!',
    OUT_OF: 'из',
    COLLECTED: 'Собрано',
    DONATE: 'Отправить донат',
    ENTER_AMOUNT: 'Введите сумму доната',
    MESSAGE: 'Пожалуйста, укажите желаемую сумму доната (не менее 1,000 йен). Средства будут списаны с вашего авансового баланса. Мы ценим ваш вклад и благодарим вас за поддержку!',
    MIN_AMOUNT_ERROR: 'Сумма должна быть не ниже 1,000 йен.',
    CONFIRM_PAYMENT: 'Подтверждаю, что согласен с оплатой и списанием средств',
  },
};

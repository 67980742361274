import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TripInfoType } from '../../types/trip-info.type';

@Component({
  selector: 'app-trips-list',
  templateUrl: './trips-list.component.html',
  styleUrl: './trips-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TripsListComponent {
  @Input({ required: true }) trips!: TripInfoType[];
  @Input() compact = false;
}

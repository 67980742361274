import { ru } from './ru';

export const en: typeof ru = {
  BUHANKA_DONATE: {
    TITLE: 'Donate for Buhanka 📢',
    DESCRIPTION: 'We’re launching a fundraiser to buy a UAZ-2206!',
    OUT_OF: 'out of',
    COLLECTED: 'Collected',
    DONATE: 'Donate',
    ENTER_AMOUNT: 'Please input donation amount',
    MESSAGE: 'Please specify your desired donation amount (at least 1,000 yen). The funds will be deducted from your advance balance. We truly appreciate your contribution and thank you for your support!',
    MIN_AMOUNT_ERROR: 'The amount must be at least 1,000 yen.',
    CONFIRM_PAYMENT: 'I confirm that I agree with the payment and debiting of funds',
  },
};

<app-image-group>
  <swiper-container appSwiperInitializer [configuration]="swiperConfiguration">
    @if (media.length) {
      @for (item of media; track item.source) {
        <swiper-slide>
          <app-main-slider-item [media]="item"></app-main-slider-item>
          @if ($last && slidesLimited) {
            <app-main-slider-not-authorized-overlay></app-main-slider-not-authorized-overlay>
          }
        </swiper-slide>
      }
    } @else {
      <swiper-slide>
        <app-main-slider-item [media]="defaultMediaItem"></app-main-slider-item>
      </swiper-slide>
    }
  </swiper-container>
</app-image-group>

<app-information-page-links
  [countryInformationType]="countryInformationType"
></app-information-page-links>
<!-- <app-information-page-breadcrumb [articlePage]="articlePage"></app-information-page-breadcrumb> -->

@let article = article$ | push;

<nz-row [nzGutter]="[32, 32]">
  <nz-col [nzXs]="24" [nzSm]="24" [nzMd]="7" [nzLg]="7" [nzXl]="5">
    <app-information-page-menu
      [lang]="language$ | push"
      [hasArticle]="!!(article$ | push)"
    ></app-information-page-menu>
  </nz-col>

  <nz-col [nzXs]="24" [nzSm]="24" [nzMd]="17" [nzLg]="17" [nzXl]="19">
    @if (article) {
    <nz-spin [nzSpinning]="loading$ | push">
      <nz-row>
        <div class="content">
          <h2>{{ article | newsContent : 'title' }}</h2>
          <nz-col [nzXs]="24">
            <div [innerHTML]="article | newsContent : 'content'"></div>
          </nz-col>
        </div>
      </nz-row>
    </nz-spin>
    } @else {
    <div class="information">
      <h2>{{ 'INFORMATION_PAGE.REFERENCE_INFO' | translate }}</h2>
      <p>{{ 'INFORMATION_PAGE.REFERENCE_INFO_TXT' | translate }}</p>
    </div>
    }
  </nz-col>
</nz-row>

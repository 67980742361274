import {Injectable} from "@angular/core";
import {
  ExchangeRateFieldsFragment,
  ExchangeRatesQuery,
  ExchangeRateQueryVariables,
  ExchangeRatesGQL,
} from "../../graphql/exchange-rate-open-graphql.service";
import {map, Observable} from "rxjs";
import {QueryHandlingService} from "../query-handling-service/query-handling.service";

@Injectable()
export class ExchangeRateService {
  constructor(
    private readonly qhs: QueryHandlingService,
    private exchangeRatesGQL: ExchangeRatesGQL
  ) {
  }

  private get getExchangeRates$(): Observable<ExchangeRatesQuery> {
    return this.qhs.fetch<ExchangeRatesQuery, ExchangeRateQueryVariables>
    (this.exchangeRatesGQL, {}, 'network-only').data.pipe(map(result => result));
  }

  get rubToJpyExchangeRate$(): Observable<number> {
    return this.getExchangeRates$.pipe(map(result => this.calcExchangeRate(result?.jpyToRub)));
  }

  get usdToRubExchangeRate$(): Observable<number> {
    return this.getExchangeRates$.pipe(map(result => this.calcExchangeRate(result?.usdToRub)));
  }

  private calcExchangeRate(
    exchangeRate: ExchangeRateFieldsFragment | null | undefined,
  ): number {
    if (!exchangeRate?.nominal || !exchangeRate?.sell || exchangeRate.sell === 0) {
      return 0;
    }
    return +(exchangeRate.nominal / exchangeRate.sell).toFixed(3);
  }
}

export const ru = {
  DELIVERY_STATISTICS_SECTION: {
    "DELIVERY_FROM": 'Сроки доставки с момента покупки',
    'BEFORE_TO_TOYAMA': 'до погрузки в г. Тояма:',
    "BEFORE_TO_VLADIVOSTOK": 'до выгрузки в г. Владивосток:',
    "AVERAGE": 'В среднем',
    "CARS": 'Автомобили',
    "MOTO": 'Мотоциклы',
    "MARK": 'В расчет взяты данные всех купленных и отправленных авто за последние 60 дней. Актуально на {{date}}',
    "DAY_PLURALS": {
      "D": 'д.',
      "SINGLE": 'День',
      "SOME": 'Дня',
      "MANY": 'д.',
    },
    "STAGES_TITLE": 'Этапы покупки авто на японском аукционе',
    "STAGES_WARNING": 'Пожалуйста, обратите внимание, что сроки морской доставки могут варьироваться в зависимости от страны и порта назначения',
    "STATISTICS_STEP": {
      "REGISTRATION": "Регистрация и договор",
      "REPLENISHMENT": "Пополнение счета и доступ",
      "SEARCH": "Поиск авто на аукционе",
      "PARTICIPATION": "Участие в торгах",
      "PAYMENT": "Оплата покупки",
      "SHIPPING": "Оформление доставки",
      "SEA": "Морская доставка",
      "CUSTOMS": "Таможенное оформление",
      "DAY": "День",
    },
    "MODAL_INFO": {
      "REGISTRATION": "Зарегистрируйтесь в системе, чтобы получить доступ к личному кабинету. Этот процесс не займет много вашего времени. Менеджер поможет вам при возникновении вопросов. После регистрации на указанный адрес электронной почты придёт письмо со ссылкой для подтверждения аккаунта. Просим перейти по ней для завершения процесса регистрации. Менеджер может запросить ваши паспортные данные для заключения договора с компанией. После подтверждения регистрации вам будет доступен личный кабинет, в котором вы сможете отслеживать и контролировать свои покупки.",
      "REPLENISHMENT": "Чтобы получить доступ к нашей платформе с авто- и мото-аукционам, а также аукционам спецтехники потребуется внести залоговую сумму (депозит). Сумма депозита зависит от стоимости техники. Для дорогостоящих единиц может потребоваться иметь на счету до 30% от планируемой ставки. Депозит должен быть внесен лично тем, на кого открыт личный кабинет. Платежи от третьих лиц не принимаются.",
      "SEARCH": "Выбирайте лоты среди более чем 80 000 единиц техники обновляемых еженедельно на 130+ аукционах Японии. Обратите внимание, помимо самого процесса торгов вы сможете воспользоваться множеством дополнительных услуг. Среди таких: текстовый и/или голосовой перевод аукционного листа, дополнительный видео осмотр интересующего вас лота чтобы получить больше информации о нем, групповые ставки, уточнение даты производства и др.",
      "PARTICIPATION": "Как только вы найдете интересующий вас автомобиль, не теряйте времени и делайте ставку чтобы принять участие в торгах. Компания оставляет за собой право пережима вашей ставки в пределах 20 000–30 000 йен (в зависимости от специфики торга), чтобы повысить шанс на победу. Можно запросить удаление или снижение ставки прямо внутри нашей платформы. Все детали и правила описаны в специальном разделе на самой платформе. Вы сможете ознакомиться с ними в любой момент.",
      "PAYMENT": "Если ваша ставка побеждает, вы получите соответствующее уведомление и лот появится в вашем личном кабинете. Там вы сможете отследить все перемещения вашего объекта, увидеть фотофиксацию на всех этапах от покупки до выгрузки в порту назначения и контролировать документооборот. Осуществить оплату можно в один или два этапа в зависимости от способа доставки. В некоторых случаях полная предоплата требуется до отправки. Оплатить счет необходимо в течение трех дней.",
      "SHIPPING": "После того, как ваш объект будет полностью оплачен и мы получим все необходимые данные получателя, начнётся процесс организации отправки. Техника перевозится на экспортную площадку или в порт. Мы получаем экспортный сертификат и подготавливаем все необходимые документы для транспортировки и таможенного оформления, исходя из специфики документооборота страны назначения. Логисты подбирают оптимальный маршрут, бронируют суда или контейнеры (если требуется или в зависимости от выбора клиента) и координируют работу с таможенными агентами. Вы получите уведомление о дате погрузки, ориентировочном времени отправления и примерном сроке прибытия в порт назначения. Все эти сведения будут доступны в вашем личном кабинете.",
      "SEA": "Специалисты загружают технику на судно, обеспечивая безопасную фиксацию и соблюдение всех требований перевозки. В этот момент вы можете запросить дополнительный фото- или видеоотчет, если хотите проконтролировать состояние транспортного средства. После выхода судна мы отправляем все необходимые документы авиа-почтой, DHL или FedEx. Если автомобиль будет отправлен контейнером, вы сможете получить трек-номер для отслеживания его движения.",
      "CUSTOMS": "По прибытии, необходимо пройти таможенные процедуры. Рекомендуется обращаться к профессиональным таможенным агентам для ускорения процесса. Если вы не имеете опыта самостоятельного оформления, мы предложим услуги наших партнёров. Все необходимые бумаги будут подготовлены нашей компанией. Если в вашей стране предусмотрены дополнительные сертификаты, мы заранее уточним это и поможем их получить. В каждой стране свои нормы по ввозным пошлинам и акцизам. Менеджеры Akebono подскажут вам, какие платежи нужно произвести и в какие сроки, чтобы избежать штрафов или простоев на таможне. После успешного прохождения таможни мы поможем в организации отправки автомобиля до вашего адреса удобным для вас способом.",
      "MEDIA_WARNING": "Ваш браузер не поддерживает воспроизведение видео.",
    },
    "REGISTRATION": {
      "TITLE": "Готовы к покупке автомобиля? 📢",
      "DESCRIPTION": "Войдите в свой аккаунт или пройдите регистрацию. Это займет не более 5 минут!",
      "LOGIN": "Вход/Регистарция",
    }
  }
}
import { MediaItem } from 'src/app/services/lot-adapter/types/media-item.type';

import {
  AkebonoLotSource,
  AkebonoLotTypeEnum,
} from '../../../../../../../graphql/akebono-main-graphql.service';
import {
  AirCondition,
  EqOption,
  NavigationType,
  TvType,
} from '../../../../../../../graphql/aucnet-main-graphql.service';
import { LotSource } from '../../../../../../../services/lot-adapter/enums/lot-source.enum';
import { LotType } from '../../../../../../../services/lot-adapter/enums/lot-type.enum';
import { ILotBase } from '../../../../../../../services/lot-adapter/interfaces/lot-base.interface';
import { ILot } from '../../../../../../../services/lot-adapter/interfaces/lot.interface';
import { Value } from '../../../../../../../services/lot-adapter/types/value.type';

export interface EquipmentItem {
  label: string;
  property: keyof ILot;
  typeProperty?: keyof ILot;
}

export enum EquipmentStatus {
  NotSet = 'NOT_SET',
  Off = '0',
  On = '1',
  Genuine = 'GENUINE',
  ThirdParty = 'THIRD_PARTY',
  Available = 'AVAILABLE',
}

export type LotPropValue =
  | string
  | undefined
  | number
  | AirCondition
  | EqOption
  | NavigationType
  | TvType
  | boolean
  | string[]
  | MediaItem[]
  | Value
  | LotType
  | LotSource
  | AkebonoLotTypeEnum
  | AkebonoLotSource
  | Set<keyof ILotBase>;

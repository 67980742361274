import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ObservableRef } from './observable.ref';

@Injectable({
  providedIn: 'root',
})
export class ObservableHandlingService {
  public toReference<T>(observable: Observable<T>): ObservableRef<T> {
    return new ObservableRef(observable);
  }
}

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { LotType } from 'src/app/services/lot-adapter/enums/lot-type.enum';

@Component({
  selector: 'app-category-section',
  templateUrl: './category-section.component.html',
  styleUrl: './category-section.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategorySectionComponent {
  LotType = LotType;

  grid = {
    xxl: 12,
    xl: 12,
    lg: 12,
    md: 12,
    sm: 24,
    xs: 24,
  };
}

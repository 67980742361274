import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-pagination-status',
  templateUrl: './pagination-status.component.html',
  styleUrl: './pagination-status.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginationStatusComponent {
  @Input({ required: true }) total!: number;
  @Input({ required: true }) pageSize!: number;
  @Input({ required: true }) offset!: number;

  get pageStartOffset(): number {
    return this.offset + 1;
  }

  get pageEndOffset (): number {
    return Math.min(this.offset + this.pageSize, this.total);
  }
}

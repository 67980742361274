<nz-row class="links" [nzGutter]="[15, 15]">
  <nz-col>
    <a
      app-button
      appType="primary"
      [routerLink]="['/', 'information', 'cis']"
      [routerLinkActive]="'active'"
      shape="round"
      [class.active]="countryInformationType === 'cis'"
    >
      <span>{{ 'USEFUL_INFORMATION_SECTION.CIS_GUIDE' | translate }}</span>
    </a>
  </nz-col>
  <nz-col>
    <a
      app-button
      appType="primary"
      [routerLink]="['/', 'information', 'other']"
      [routerLinkActive]="'active'"
      shape="round"
      [class.active]="countryInformationType  === 'other'"
    >
      <span>{{ 'USEFUL_INFORMATION_SECTION.OTHER_COUNTRIES_GUIDE' | translate }}</span>
    </a>
  </nz-col>
</nz-row>

export const ru = {
  "CATEGORY_SECTION": {
    "JAPAN_TITLE": "Автомобили из Японии",
    "JAPAN_TEXT": "Автомобили в наличии в Японии. Широкий выбор транспортных средств, предлагаемых напрямую с японских ярдов",
    "GREEN_TITLE": "Зеленый угол 2.0",
    "GREEN_TEXT": "Автомобили в Японии готовые для экспорта в Россию. Эти машины уже находятся на наших ярдах и готовы к погрузке на ближайшие рейсы до Владивосток",
    "MONGOL_TITLE": "Автомобили для Монголии",
    "MONGOL_TEXT": "Широкий ассортимент автомобилей в пути или доступных для немедленной отправки в Монголию",
    "JAPAN_MOTIK_TITLE": "Мотоциклы из Японии",
    "JAPAN_MOTIK_TEXT": "Актуальный ассортимент мото-техники на аукционах Японии. Найдите идеальный вариант для себя благодаря широкому выбору",
    "TRUCKS_TITLT": "Грузовые авто",
    "TRUCKS_TEXT": "Грузовые, пассажирские и другие спец-авто в наличии в Японии. Раздел включает в себя прицепы и полуприцепы",
    "TOYAMA_TITLE": "Тояма утилизация",
    "TOYAMA_TEXT": "Автомобили, предназначенные для вывоза из Японии как целыми, так и в виде запчастей. Отличный источник запасных частей и материалов для ремонта",
    "USA_TITLE": "Автомобили в США",
    "USA_TEXT": "Автомобили в наличии на нашей стоянке в г. Маями. Ознакомьтесь с актуальным списком машин готовых к продаже",
    "CATEGORY_CARD": {
      "AUTO": "авто",
      "BUTTON_TXT": "Подробнее"
    }
  }
};

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found-page.component.html',
  styleUrl: './not-found-page.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotFoundPageComponent {
  previousPage: string;

  constructor(private route: ActivatedRoute) {
    this.previousPage = this.removeLastSegment(
      this.route.snapshot.queryParams['previousPage'] || '/'
    );
  }

  private removeLastSegment(url: string): string {
    const segments = url.split('/').filter(segment => segment !== '');
    if (segments.length > 1) {
      segments.pop();
    }
    return '/' + segments.join('/');
  }
}

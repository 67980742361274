import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MediaItem } from 'src/app/services/lot-adapter/types/media-item.type';

@Component({
  selector: 'app-thumbs-slider-item',
  templateUrl: './thumbs-slider-item.component.html',
  styleUrl: './thumbs-slider-item.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ThumbsSliderItemComponent {
  @Input({ required: true }) media!: MediaItem;
}

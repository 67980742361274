import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { RxPush } from '@rx-angular/template/push';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { ButtonModule } from 'src/app/components/button/button.module';
import { ImageModule } from 'src/app/components/image/image.module';
import { SwiperInitializerDirectiveModule } from 'src/app/directives/swiper-initializer-directive/swiper-initializer-directive.module';
import { LanguageService } from 'src/app/services/language.service';

import { MainSliderImageItemComponent } from './components/main-slider-image-item/main-slider-image-item.component';
import { MainSliderItemComponent } from './components/main-slider-item/main-slider-item.component';
import { MainSliderNotAuthorizedOverlayComponent } from './components/main-slider-not-authorized-overlay/main-slider-not-authorized-overlay.component';
import { MainSliderVideoItemComponent } from './components/main-slider-video-item/main-slider-video-item.component';
import { MainSliderVideoViewModalComponent } from './components/main-slider-video-view-modal/main-slider-video-view-modal.component';
import { MainSliderComponent } from './components/main-slider/main-slider.component';
import { ThumbsSliderComponent } from './components/thumbs-slider/thumbs-slider.component';
import { en } from './locale/en';
import { ru } from './locale/ru';
import { LotGalleryComponent } from './lot-gallery.component';
import { ThumbsSliderNotAuthorizedOverlayComponent } from './components/thumbs-slider-not-authorized-overlay/thumbs-slider-not-authorized-overlay.component';
import { ThumbsSliderItemComponent } from './components/thumbs-slider-item/thumbs-slider-item.component';
import { ThumbsSliderImageItemComponent } from './components/thumbs-slider-image-item/thumbs-slider-image-item.component';
import { ThumbsSliderVideoItemComponent } from './components/thumbs-slider-video-item/thumbs-slider-video-item.component';

@NgModule({
  declarations: [
    LotGalleryComponent,
    MainSliderComponent,
    ThumbsSliderComponent,
    MainSliderItemComponent,
    MainSliderImageItemComponent,
    MainSliderVideoItemComponent,
    MainSliderVideoViewModalComponent,
    MainSliderNotAuthorizedOverlayComponent,
    ThumbsSliderNotAuthorizedOverlayComponent,
    ThumbsSliderItemComponent,
    ThumbsSliderImageItemComponent,
    ThumbsSliderVideoItemComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    ImageModule,
    ButtonModule,
    SwiperInitializerDirectiveModule,
    NzModalModule,
    NzIconModule,
    RxPush,
  ],
  exports: [LotGalleryComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class LotGalleryModule {
  constructor(languageService: LanguageService) {
    languageService.addLocales({ en, ru });
  }
}

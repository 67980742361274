import { ChangeDetectionStrategy, Component } from '@angular/core';
import { EnvironmentService } from 'src/app/services/environment.service';

@Component({
  selector: 'app-goods-and-parts-section',
  templateUrl: './goods-and-parts-section.component.html',
  styleUrl: './goods-and-parts-section.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GoodsAndPartsSectionComponent {
  shopUrl: string;

  constructor(environment: EnvironmentService) {
    this.shopUrl = `//shop.${environment.currentEnvironment.domain}`;
  }
}

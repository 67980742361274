<p>
  <strong>Политика Компании </strong><strong>Akebono Co., Ltd. </strong
  ><strong>по соблюдению санкционных ограничений</strong><strong><br /> </strong>
</p>
<strong>1. Общие положения</strong>
<p>
  1.1. Политика Akebono Co., Ltd. по соблюдению Санкционных ограничений (далее - Политика) является
  основополагающим документом верхнего уровня, устанавливающим минимальные требования в отношении
  соблюдения Санкционных ограничений во всех юрисдикциях, в которых Компания осуществляет свою
  деятельность.
</p>

<p>1.2. Целями Политики являются:</p>
<ul>
  <li>
    минимизация риска наложения Санкций, в том числе вторичных, на Компанию, которые могут повлиять
    на выполнение обязательств Компании перед государством, обществом, бизнес-партнерами, клиентами
    и сотрудниками;
  </li>
  <li>сдерживание действий, которые могут привести к нарушениям Санкционных ограничений;</li>
  <li>
    создание в Компании единообразной эффективной системы контроля в отношении Санкционных
    ограничений.
  </li>
</ul>

<p>
  1.3. Политика отражает приверженность руководства и работников Компании этическим стандартам
  ведения законного и прозрачного бизнеса, следования лучшим международным практикам управления и
  поддержания деловой репутации на должном уровне.
</p>

<p>1.4. Основными принципами Политики являются:</p>
<ul>
  <li>
    Соблюдение Санкционных ограничений юрисдикций, в которых Akebono Co., Ltd. работает,
    зарегистрировано и/или лицензировано;
  </li>
  <li>
    Соблюдение требований режима Санкций Японии, где бы Компания ни работала, и отказ от бизнеса,
    который нарушил бы режимы этих Санкций;
  </li>
  <li>
    Учет Компанией Санкций, введенных другими юрисдикциями, если для совершения сделок это
    целесообразно;
  </li>
  <li>
    Непредоставление Компанией товара, работ или услуг, даже если это разрешено соответствующими
    нормативными актами, если обстоятельства представляют собой репутационный риск;
  </li>
  <li>
    Отказ Компании и ее работников от деловых отношений с любым лицом, на которое наложены
    ограничения, чтобы не нарушить любые применимые законы о Санкциях;
  </li>
  <li>
    Отказ Компании от бизнеса, который нарушал бы применимые к ним законы об экспорте.<br />
    <br />
  </li>
</ul>

<strong>2. Термины и определения</strong>
<p>2.1. Вторичные санкции:</p>
<p>
  Вторичные санкции представляют собой ограничительные меры экономического характера, применяемые
  международными организациями, страной или группой стран против нарушителей уже существующих
  Санкционных ограничений.
</p>

<p>2.2. Санкции/ Санкционные ограничения:</p>
<p>
  Санкции, или санкционные ограничения, представляют собой ограничительные меры экономического
  характера, применяемые международными организациями, страной или группой стран к другой стране или
  группе стран, включая её физические и юридические лица. Целью этих мер является воспрепятствование
  определённой деятельности.
</p>

<p>2.3. Контрагент:</p>
<p>
  Контрагент &ndash; сторона, с которой заключается контракт, соглашение или сделка. Контрагентом
  может быть физическое или юридическое лицо.<br />
  <br />
</p>

<strong>3. Меры по соблюдению санкционных ограничений</strong>
<p>
  3.1. Руководство Akebono Co., Ltd. определяет общую стратегию по санкционным ограничениям и
  регулярно обновляет ее.
</p>

<p>
  3.2. Руководители Akebono Co., Ltd. распределяют полномочия, обязанности и ответственность в
  рамках настоящей Политики между работниками и/или структурными подразделениями Компании. Они также
  обеспечивают организацию мероприятий, направленных на реализацию принципов и требований Политики,
  включая назначение лиц, ответственных за разработку, внедрение и контроль процедур по соблюдению
  санкционных ограничений.
</p>

<p>
  3.3. Прежде чем вступать в какие-либо деловые отношения или транзакции с лицами или организациями,
  Компания должна предпринять меры, чтобы убедиться, что такие отношения и транзакции соответствуют
  нормам законодательства Японии и других стран (если потребуется). Это включает проверку этих лиц
  или организаций на соответствие специальному черному списку лиц и другим соответствующим
  санкционным спискам (например, опубликованным OFAC).
</p>

<p>
  3.4. Компания обязана проверять свои деловые отношения и транзакции на предмет возможных нарушений
  санкций в отношении конкретной страны, юридического или физического лица.
</p>

<p>3.5. Контракты с контрагентами должны включать положения:</p>

<p>
  подтверждающие, что контрагент не значится в санкционных списках или иным образом не является
  объектом санкционных ограничений;
</p>
<p>
  требующие соблюдения резолюций ООН, санкционного законодательства Японии и настоящей Политики;
</p>
<p>
  требующие от контрагентов воздерживаться от участия в деловой деятельности, которая может привести
  к нарушению обязательств по санкциям;
</p>
<p>
  предоставляющие компании право отказаться от контракта и не выполнять свои обязательства в случае
  нарушения контрагентом настоящей Политики, включения его в санкционные списки или иного наложения
  санкций.
</p>
<p>
  3.6. Компания не должна вступать в деловые отношения или транзакции, которые прямо или косвенно
  включают страны или лица, находящиеся под санкционными ограничениями.
</p>

<p>
  3.7. Факт того, что страна находится под санкциями или лицо принадлежит такой стране, не означает
  автоматически, что Компания не может вступать в деловые отношения или транзакции с участием любой
  такой страны или лица. Однако сделка сначала должна быть тщательно проверена, чтобы убедиться в
  отсутствии нарушений юридических обязательств, связанных с санкциями.<br />
  <br />
</p>

<strong>4. Последствия несоблюдения санкционных ограничений</strong>
<p>
  4.1. Несоблюдение соответствующих санкционных ограничений представляет собой нарушение правовых
  и/или нормативных требований, что может привести к значительному репутационному ущербу для
  Компании, возможным судебным искам, финансовым потерям, а также подвергнуть отдельных работников,
  причастных к нарушению, значительным штрафам или тюремному заключению.
</p>

<p>
  4.2. Компания придерживается подхода абсолютной нетерпимости к преднамеренным нарушениям данной
  Политики или применимых режимов санкций. В случае несоблюдения работником данной Политики, к нему
  могут быть применены дисциплинарные взыскания, вплоть до увольнения с работы. Дисциплинарные меры
  будут зависеть от обстоятельств нарушения и применяться в соответствии с применимым правом и
  внутренними нормативными документами Компании.
</p>

<p>
  4.3. Компания вправе прекратить деловые отношения с любым контрагентом (включая расторжение всех
  действующих контрактов) посредством письменного уведомления контрагента, с немедленным вступлением
  в силу, без необходимости обращения в суд и без каких-либо обязательств для компенсации убытков
  (прямых и/или косвенных) любого типа или характера в пользу указанного контрагента, в случае,
  если:
</p>
<p>
  - контрагент не выполняет какое-либо положение настоящей Политики и не может исправить ситуацию
  (если её возможно исправить) в течение 10 дней с момента письменного уведомления контрагента о
  нарушении;
</p>
<p>
  - контрагент включается в какой-либо санкционный список или становится объектом иных санкционных
  ограничений.
</p>

<strong>5. Заключительные положения</strong>
<p>
  5.1. Контракты и соглашения, заключенные между Компанией и контрагентами, могут содержать более
  конкретные положения (включая индивидуальные), касающиеся некоторых вопросов, изложенных в
  настоящей Политике. Ничто в настоящей Политике не заменяет какое-либо конкретное положение в
  конкретном контракте или соглашении, заключенном между Компанией и контрагентом. В случае
  несоответствия между настоящей Политикой и любым другим положением конкретного контракта или
  соглашения, положение в контракте или соглашении будет иметь преимущественную силу.
</p>

<p>
  5.2. Настоящая Политика предназначена для дополнения, а не для замены других кодексов поведения,
  политик, правил и процедур, которые время от времени применяются к работникам и контрагентам. Если
  какой-либо работник или контрагент имеет какие-либо сомнения относительно кодексов, политик,
  правил и процедур, применимых в определенной ситуации, или если какой-либо работник или контрагент
  обнаруживает какой-либо конфликт или несоответствие между настоящей Политикой и любым другим
  кодексом поведения Компании или любыми другими политиками, он должен обратиться за разъяснениями в
  головной офис компании в г. Тояма.
</p>

<strong>6. Порядок пересмотра и внесения изменений</strong>
<p>
  6.1. Поскольку режимы санкций могут меняться быстро и без предварительного уведомления, настоящая
  Политика может обновляться по мере необходимости.
</p>

<p>
  6.2. Внесение изменений в Политику осуществляется в установленном соответствующими внутренними
  нормативными документами порядке, в том числе при совершенствовании комплекса мер по
  предотвращению санкционных рисков.
</p>

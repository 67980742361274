import {Injectable} from '@angular/core';
import {distinctUntilChanged, map, shareReplay} from 'rxjs';

import {
  AucnetAutoLotGQL,
  AucnetAutoLotsGQL,
  AucnetAutoLotsQueryVariables,
} from '../../graphql/aucnet-main-graphql.service';
import {
  AucnetMotoLotGQL,
  AucnetMotoLotsGQL,
  AucnetMotoLotsQueryVariables,
} from '../../graphql/aucnet-moto-graphql.service';
import {
  AucnetTruckLotGQL,
  AucnetTruckLotsGQL,
  AucnetTruckLotsQueryVariables,
} from '../../graphql/aucnet-trucks-graphql.service';
import {MochironLotGQL, MochironLotsGQL, MochironLotsQueryVariables,} from '../../graphql/mochiron-graphql.service';
import {departmentIdByLotType} from '../lot-adapter/constants/department-id-by-lot-type';
import {LotType} from '../lot-adapter/enums/lot-type.enum';
import {LotAdapterService} from '../lot-adapter/lot-adapter.service';
import {LotMinimalFragments} from '../lot-adapter/types/lot-minimal-fragments.type';
import {QueryHandlingService} from '../query-handling-service/query-handling.service';
import {FetchLotReference} from './types/fetch-lot-reference.type';
import {FetchLotsQueryVariables} from './types/fetch-lots-query-variables.type';
import {FetchLotsReference} from './types/fetch-lots-reference.type';

@Injectable()
export class LotFetcherService {
  constructor(
    private readonly qhs: QueryHandlingService,
    private readonly lotAdapterService: LotAdapterService,
    private readonly mochironLotGQL: MochironLotGQL,
    private readonly aucnetAutoLotGQL: AucnetAutoLotGQL,
    private readonly aucnetMotoLotGQL: AucnetMotoLotGQL,
    private readonly aucnetTruckLotGQL: AucnetTruckLotGQL,
    private readonly mochironLotsGQL: MochironLotsGQL,
    private readonly aucnetAutoLotsGQL: AucnetAutoLotsGQL,
    private readonly aucnetMotoLotsGQL: AucnetMotoLotsGQL,
    private readonly aucnetTruckLotsGQL: AucnetTruckLotsGQL,
  ) {
  }

  fetchLots(type: LotType, variables: FetchLotsQueryVariables): FetchLotsReference;
  fetchLots(type: LotType.Japan, variables: AucnetAutoLotsQueryVariables): FetchLotsReference;
  fetchLots(type: LotType.Motorcycle, variables: AucnetMotoLotsQueryVariables): FetchLotsReference;
  fetchLots(type: LotType.Truck, variables: AucnetTruckLotsQueryVariables): FetchLotsReference;
  fetchLots(type: LotType.Dubai, variables: MochironLotsQueryVariables): FetchLotsReference;
  fetchLots(type: LotType.GreenCorner, variables: MochironLotsQueryVariables): FetchLotsReference;
  fetchLots(type: LotType.Recycling, variables: MochironLotsQueryVariables): FetchLotsReference;
  fetchLots(type: LotType.Mongolia, variables: MochironLotsQueryVariables): FetchLotsReference;
  fetchLots(type: LotType.Usa, variables: MochironLotsQueryVariables): FetchLotsReference;
  fetchLots(type: LotType, variables: FetchLotsQueryVariables): FetchLotsReference {
    const query = this.lotsQueryByLotTypeMap[type];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const queryRef = this.qhs.watch<any, any>(query, this.complementVariables(type, variables));

    const data$ = queryRef.data.pipe(
      map((data) => data.lotsPaginatedList),
      shareReplay(1),
    );
    const total$ = data$.pipe(map((result) => result.totalCount || 0));
    const lots$ = data$.pipe(
      map((result) => result.items || []),
      map((lots) =>
        lots.map((lot: LotMinimalFragments) => this.lotAdapterService.adaptMinimal(lot)),
      ),
      shareReplay(1),
    );

    return {
      loading$: queryRef.loading.pipe(distinctUntilChanged()),
      lots$,
      total$,
    };
  }

  fetchLot(type: LotType, id: string): FetchLotReference {
    const query = this.lotQueryByLotTypeMap[type];
    const normalizedId = this.isMochironType(type) ? Number(id) : id;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const queryRef = this.qhs.watch<any, any>(query, {id: normalizedId});

    const lot$ = queryRef.data.pipe(
      map((data) => {
        if (!data?.lot) {
          throw new Error('Lot not found');
        }
        return this.lotAdapterService.adapt(data.lot);
      }),
      shareReplay(1)
    );

    return {
      loading$: queryRef.loading,
      lot$: lot$,
    };
  }

  private complementVariables(
    type: LotType,
    variables: FetchLotsQueryVariables,
  ): FetchLotsQueryVariables {
    switch (type) {
      case LotType.Dubai:
      case LotType.GreenCorner:
      case LotType.Recycling:
      case LotType.Mongolia:
      case LotType.Usa:
        return <MochironLotsQueryVariables>{
          ...variables,
          filter: {
            departmentId: {
              eq: departmentIdByLotType[type],
            },
            ...variables?.filter,
          },
        };
      default:
        return variables;
    }
  }

  private get lotsQueryByLotTypeMap() {
    return {
      [LotType.Japan]: this.aucnetAutoLotsGQL,
      [LotType.Truck]: this.aucnetTruckLotsGQL,
      [LotType.Motorcycle]: this.aucnetMotoLotsGQL,
      [LotType.Dubai]: this.mochironLotsGQL,
      [LotType.GreenCorner]: this.mochironLotsGQL,
      [LotType.Recycling]: this.mochironLotsGQL,
      [LotType.Mongolia]: this.mochironLotsGQL,
      [LotType.Usa]: this.mochironLotsGQL,
    };
  }

  private get lotQueryByLotTypeMap() {
    return {
      [LotType.Japan]: this.aucnetAutoLotGQL,
      [LotType.Truck]: this.aucnetTruckLotGQL,
      [LotType.Motorcycle]: this.aucnetMotoLotGQL,
      [LotType.Dubai]: this.mochironLotGQL,
      [LotType.GreenCorner]: this.mochironLotGQL,
      [LotType.Recycling]: this.mochironLotGQL,
      [LotType.Mongolia]: this.mochironLotGQL,
      [LotType.Usa]: this.mochironLotGQL,
    };
  }

  private isMochironType(type: LotType): boolean {
    return [LotType.Dubai, LotType.GreenCorner, LotType.Recycling, LotType.Mongolia, LotType.Usa].includes(type);
  }
}

import { BehaviorSubject, defer, Observable } from 'rxjs';
import { finalize, publishReplay, refCount } from 'rxjs/operators';

export class ObservableRef<T> {
  private loadingSubject = new BehaviorSubject(true);

  private data$: Observable<T>;
  private loading$: Observable<boolean>;

  constructor(private observable: Observable<T>) {
    this.loading$ = this.loadingSubject.asObservable();
    this.data$ = defer(() => {
      this.loadingSubject.next(true);
      return this.observable.pipe(finalize(() => this.loadingSubject.next(false)));
    }).pipe(publishReplay(), refCount());
  }

  get data(): Observable<T> {
    return this.data$;
  }

  get loading(): Observable<boolean> {
    return this.loading$;
  }
}

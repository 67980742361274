import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MediaItem } from 'src/app/services/lot-adapter/types/media-item.type';

@Component({
  selector: 'app-main-slider-item',
  templateUrl: './main-slider-item.component.html',
  styleUrl: './main-slider-item.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainSliderItemComponent {
  @Input({ required: true }) media!: MediaItem;
}

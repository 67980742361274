import {ru} from './ru';

export const en: typeof ru = {
  "NEWS_PAGE": {
    "TITLE": "News",
    "TO_MAIN": "To main",
    "NEWS_NOT_FOUND": "News not found",
    "FILTER": {
      "DATE_FROM": "Published date from",
      "DATE_TO": "Published date to",
      "APPLY": "Apply",
      "RESET": "Reset",
    },
  }
};

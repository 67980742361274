<nz-divider></nz-divider>
<h2>{{ 'LOT.PACKAGE.TITLE' | translate }}</h2>
<nz-row>
  <div class="indicators" nz-flex nzGap="middle">
    <span class="eqGenuine">{{ 'LOT.PACKAGE.FIELDS.GENUINE_PART' | translate }}</span>
    <span class="eqThirdParty">{{ 'LOT.PACKAGE.FIELDS.THIRD_PARTY_PART' | translate }}</span>
    <span class="eqAvailable">{{ 'LOT.PACKAGE.FIELDS.GENUINE_OR_THIRD_PART' | translate }}</span>
  </div>
  <nz-row>
    <div class="indicators" nz-flex nzGap="middle">
      <span class="eqGenuine">{{ 'LOT.PACKAGE.FIELDS.GENUINE_PART' | translate }}</span>
      <span class="eqThirdParty">{{ 'LOT.PACKAGE.FIELDS.THIRD_PARTY_PART' | translate }}</span>
      <span class="eqAvailable">{{ 'LOT.PACKAGE.FIELDS.GENUINE_OR_THIRD_PART' | translate }}</span>
    </div>
    <nz-row>
      @for (item of equipmentItems; track item.property) {
      <nz-col
        nzXs="24"
        nzSm="12"
        nzMd="8"
        nzLg="12"
        nzXl="12"
        nzXXl="8"
        [ngClass]="defineCardColor(lot[item.property])"
      >
        <span>
          @if (item.typeProperty) {
          {{ formatPropertyValue(item) }}
          }
          <span>{{ item.label | translate }}</span>
        </span>
      </nz-col>
      }
    </nz-row>
  </nz-row>
</nz-row>

<h1>{{ 'DELIVERY_STATISTICS_SECTION.STAGES_TITLE' | translate }}</h1>
<app-delivery-statistics-steps>
  <app-delivery-statistics-step
    [title]="'REGISTRATION'"
    [days]="'1'"
    [icon]="'step-user'"
    [videoUrl]="'registration'"
    [bottomTitle]="'REPLENISHMENT'"
    [bottomDays]="'2-3'"
    [bottomIcon]="'step-shield'"
  ></app-delivery-statistics-step>
  <app-delivery-statistics-step
    [title]="'SEARCH'"
    [days]="'3-4'"
    [icon]="'step-car'"
    [videoUrl]="'search'"
    [bottomTitle]="'PARTICIPATION'"
    [bottomDays]="'4-5'"
    [bottomIcon]="'step-4'"
  ></app-delivery-statistics-step>
  <app-delivery-statistics-step
    [title]="'PAYMENT'"
    [days]="'6-7'"
    [icon]="'step-home'"
    [bottomTitle]="'SHIPPING'"
    [bottomDays]="'7-10'"
    [bottomIcon]="'step-registration'"
    [bottomVideoUrl]="'shipping'"
  ></app-delivery-statistics-step>
  
  <app-delivery-statistics-step
    [title]="'SEA'"
    [days]="'10-50'"
    [icon]="'step-sea-delivery'"
    [videoUrl]="'sea-freight'"
    [bottomTitle]="'CUSTOMS'"
    [bottomDays]="'50-55'"
    [bottomIcon]="'step-customs-clearance'"
  ></app-delivery-statistics-step>
</app-delivery-statistics-steps>
<span>{{ 'DELIVERY_STATISTICS_SECTION.STAGES_WARNING' | translate }}</span>

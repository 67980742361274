export const ru = {
  "HEADER_SECTION": {
    "TIME": "Токио",
    "TITLE": "Akebono - Ваш надежный партнер для доставки авто",
    "TEXT": "Доставка авто с японских аукционов по всему миру",
    "TRACK_PURCHASE": "Отследить покупку",
    "MINI_FILTERING_FORM": {
      "MARKA": "Марка",
      "MODEL": "Модель",
      "YEAR": "Год",
      "PRICE_FROM": "Цена от",
      "PRICE_TO": "Цена до",
      "FIND_CAR": "Найти авто"
    }
  }
};

import { GraphQLFormattedError } from 'graphql';

import { BaseMutationHandlingException } from './base-mutation-handling.exception';

export class MutationGraphqlException extends BaseMutationHandlingException {
  constructor(private graphqlErrors: readonly GraphQLFormattedError[]) {
    super('Error when executing graphql');
  }

  public log(): void {
    console.error(this.message, this.graphqlErrors);
  }
}

import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Maybe } from 'graphql/jsutils/Maybe';
import { CurrentUserService } from 'src/app/services/current-user/current-user.service';

@Pipe({
  name: 'vinMask',
  pure: false,
})
export class VinMaskPipe implements PipeTransform {
  private isCurrentUserManager = false;

  constructor(currentUserService: CurrentUserService, cdr: ChangeDetectorRef) {
    currentUserService.isManager$.pipe(takeUntilDestroyed()).subscribe((isCurrentUserManager) => {
      this.isCurrentUserManager = isCurrentUserManager;
      cdr.markForCheck();
    });
  }

  transform(vin: Maybe<string>): Maybe<string> {
    if (vin) {
      const maskedVin = vin.replace(/....$/, '****');
      return this.isCurrentUserManager ? vin : maskedVin;
    } else {
      return null;
    }
  }
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Observable } from 'rxjs';

import { LayoutConfigService } from '../../services/layout-config.service';

@Component({
  selector: 'app-main-contacts',
  templateUrl: './main-contacts.component.html',
  styleUrl: './main-contacts.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainContactsComponent {
  @Input({ required: true }) type!: 'horizontal' | 'vertical';
  @Input() hideText = false;

  canRenderTelegram$: Observable<boolean>;

  constructor(layoutConfigService: LayoutConfigService) {
    this.canRenderTelegram$ = layoutConfigService.canRenderTelegram$;
  }
}

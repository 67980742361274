import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DateTime } from 'luxon';
import { combineLatest, map, Observable, timer } from 'rxjs';

import { LanguageService } from '../../../../../../services/language.service';

@Component({
  selector: 'app-japan-time',
  templateUrl: './japan-time.component.html',
  styleUrl: './japan-time.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JapanTimeComponent {
  currentTime$: Observable<string>;

  constructor(languageService: LanguageService) {
    this.currentTime$ = combineLatest([languageService.currentLocale$, timer(0, 1000)]).pipe(
      map(([locale]) =>
        DateTime.now().setZone('Asia/Tokyo').setLocale(locale).toFormat('LLL dd, HH:mm:ss'),
      ),
    );
  }
}

<section class="who-we-are-card">
  <h1>{{ 'WHO_WE_ARE.TITLE' | translate }}</h1>
  <p [innerHTML]="'WHO_WE_ARE.PARAGRAPHS.1' | translate"></p>
  <p [innerHTML]="'WHO_WE_ARE.PARAGRAPHS.2' | translate"></p>
  <p>
    <a [routerLink]="['/sanctions-policy']">
      {{ 'WHO_WE_ARE.SANCTIONS_POLICY' | translate }}
      <span nz-icon nzType="right" nzTheme="outline"></span>
    </a>
  </p>
</section>

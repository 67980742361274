import {
  AkebonoLotSource,
  AkebonoLotTypeEnum,
} from '../../../graphql/akebono-main-graphql.service';
import { MochironLotFragment } from '../../../graphql/mochiron-graphql.service';
import { LotSource } from '../enums/lot-source.enum';
import { LotType } from '../enums/lot-type.enum';
import { ILotBase } from '../interfaces/lot-base.interface';
import { ILot } from '../interfaces/lot.interface';
import { MediaItem } from '../types/media-item.type';
import { Value } from '../types/value.type';
import { LotAdapterUtilities } from '../utilities/lot-adapter.utilities';
import { MochironLotMinimalAdapter } from './mochiron-lot-minimal.adapter';

export class MochironLotAdapter implements ILot {
  id?: string | undefined;
  media?: MediaItem[] | undefined;
  maker?: string | undefined;
  mark?: string | undefined;
  model?: string | undefined;
  grade?: string | undefined;
  year?: number | undefined;
  engineVolume?: Value | undefined;
  transmission?: string | undefined;
  fuel?: string | undefined;
  bodyType?: string | undefined;
  drive?: string | undefined;
  rating?: string | undefined;
  priceInStock: Value | undefined;
  priceInJapan: Value | undefined;
  priceOnDelivery?: Value | undefined;
  mileage?: Value | undefined;
  color?: string | undefined;
  location?: string | undefined;
  vin?: string | undefined;
  accident?: string | undefined;
  isSold?: boolean | undefined;
  description?: string | undefined;
  type: LotType;
  source: LotSource;
  akebonoLotType: AkebonoLotTypeEnum;
  akebonoLotSource: AkebonoLotSource;
  fields: Set<keyof ILotBase>;

  phone?: string | undefined;
  guid?: string | undefined;
  akebonoLotId?: string | undefined;

  constructor(lot: MochironLotFragment) {
    const minimalLot = new MochironLotMinimalAdapter(lot);

    this.type = minimalLot.type;
    this.source = minimalLot.source;
    this.akebonoLotType = minimalLot.akebonoLotType;
    this.akebonoLotSource = minimalLot.akebonoLotSource;

    this.id = minimalLot.id;
    this.media = minimalLot.media;
    this.maker = minimalLot.maker;
    this.mark = minimalLot.mark;
    this.model = minimalLot.model;
    this.grade = minimalLot.grade;
    this.year = minimalLot.year;
    this.engineVolume = minimalLot.engineVolume;
    this.transmission = minimalLot.transmission;
    this.fuel = minimalLot.fuel;
    this.bodyType = minimalLot.bodyType;
    this.drive = minimalLot.drive;
    this.rating = minimalLot.rating;
    this.priceInJapan = minimalLot.priceInJapan;
    this.priceInStock = minimalLot.priceInStock;
    this.priceOnDelivery = minimalLot.priceOnDelivery;
    this.mileage = minimalLot.mileage;
    this.color = minimalLot.color;
    this.location = minimalLot.location;
    this.vin = minimalLot.vin;
    this.accident = minimalLot.accident;
    this.isSold = minimalLot.isSold;
    this.description = minimalLot.description;

    this.phone = lot.phone || undefined;
    this.guid = lot.guid1C || undefined;
    this.akebonoLotId = lot.akebonoLotSourceOriginalId || undefined;

    this.fields = LotAdapterUtilities.mergeFieldSet(minimalLot.fields, [
      'phone',
      'guid',
      'akebonoLotId',
    ]);
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TabsComponent } from './tabs.component';
import { TabButtonComponent } from './components/tab-button/tab-button.component';
import { TabBodyComponent } from './components/tab-body/tab-body.component';
import { TabComponent } from './components/tab/tab.component';
import { TabDirective } from './directives/tab.directive';

@NgModule({
  declarations: [TabsComponent, TabComponent, TabButtonComponent, TabBodyComponent, TabDirective],
  imports: [CommonModule],
  exports: [TabsComponent, TabComponent, TabDirective],
})
export class TabsModule {}

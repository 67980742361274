import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { RxPush } from '@rx-angular/template/push';
import { RouterLink } from '@angular/router';
import { AuctionBreadcrumbComponent } from './auction-breadcrumb.component';
import { LanguageService } from 'src/app/services/language.service';
import { TranslateModule } from '@ngx-translate/core';
import { en } from './locale/en';
import { ru } from './locale/ru';

@NgModule({
  declarations: [AuctionBreadcrumbComponent],
  imports: [CommonModule, NzBreadCrumbModule, NzIconModule, TranslateModule, RxPush, RouterLink],
  exports: [AuctionBreadcrumbComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AuctionBreadcrumbModule {
  constructor(languageService: LanguageService) {
    languageService.addLocales({ en, ru });
  }
}

export const ru = {
  LOT: {
    VISITED: 'Посещено',
    SHARE: 'Поделиться',
    SOLD: 'Продано!',
    ON_REQUEST: 'По запросу',
    COPY_LINK: 'Копировать',
    PRICE_TOOLTIP: 'Цены указаны из расчета основных расходов. Окончательные значения стоимости могут отличаться от указанных.',
    FIELDS: {
      PRICE: 'Цена',
      MONGOLIA_PRICE: 'Цена в пункте назначения',
      JAPAN_PRICE: 'Цена в Японии',
      TRANSIT_PRICE: 'Цена в пути',
      AUCTION_GRADE: 'Аукционная оценка',
      YEAR: 'Год',
      COLOR: 'Цвет',
      MILEAGE: 'Пробег',
      CLASS: 'Модель',
      TRANSMISSION: 'Трансмиссия',
      LOCATION: 'Местоположение',
      ENGINE: 'Объем и тип двигателя',
      VIN: 'Вин код',
    },
    ACTIONS: {
      SELL_CONTACTS: 'Контакты продавца',
      SUBMIT_APPLICATION: 'Оформить заявку',
    },
    NOT_AUTHORIZED_ACTIONS: {
      TITLE: 'Зарегистрируйтесь или Войдите в свой аккаунт для просмотра всех фото',
      ACCESS_LIMITED: 'Доступ ограничен',
    },
    DESCRIPTION: {
      TITLE: 'Описание',
    },
    UPCOMING_SAILINGS: {
      BUTTON: 'Ближайшие рейсы',
      MODAL_TITLE: 'Ближайшие рейсы',
      UPDATED_AT: 'Обновлено',
      TABLE: {
        HEADER: {
          TITLE: 'Расписание рейсов',
          VESSEL: 'Судно',
          VOYAGE: 'Рейс',
          DEPARTURE: 'Отход',
          TOYAMA: 'Тояма',
          ARRIVAL: 'Прибытие',
        },
      },
    },
    CONTACT_NUMBER_MODAL: {
      MANAGERS: 'Контакты менеджеров',
      LIVE_INSPECTION: 'Осмотр в Live режиме',
      WHATSAPP: 'Чат поддержки (WA)',
    },
    INSPECTION: {
      TITLE: 'Посмотреть инспекцию',
      FIELDS: {
        AIS_INFO: 'Информация AIS',
        AUCNET_INFO: 'Информация AUCNET',
        SELLING_POINT: 'Заметки',
        NOTICE: 'Уведомление',
        TOTAL_SCORE: 'Общий балл',
        EXTERIOR_SCORE: 'Балл за экстерьер',
        INTERIOR_SCORE: 'Балл за интерьер',
      },
    },
    PACKAGE: {
      TITLE: 'Комплектация',
      FIELDS: {
        GENUINE_PART: 'Оригинальная запчасть',
        THIRD_PARTY_PART: 'Запчасть стороннего производителя',
        GENUINE_OR_THIRD_PART: 'Оригинальный или сторонний',
        AIR_CONDITIONER: 'Кондиционер',
        AIR_BAG: 'Подушка безопасности',
        SUNROOF: 'Люк',
        ALUMINUM_WHEEL: 'Алюминиевое литье',
        LEATHER_SEAT: 'Кожаный салон',
        NAVIGATION: 'Навигация',
        KEYLESS_ENTRY: 'Бесключевой доступ',
        SMART_KEY: 'Смарт-ключ',
        FLOOR_MAT: 'Коврик для ног',
        BACK_CAMERA: 'Задняя камера',
        ETC: 'Прочее',
        GUARANTEE_BOOK: 'Гарантийная книжка',
        MANUAL_BOOK: 'Руководство пользователя',
        RECORD_BOOK: 'Сервисная книжка',
        MAINTENANCE_NOTE: 'Заметка о техническом обслуживании',
      },
    },
    MOTO_DAMAGE_SCHEME: {
      TITLE: 'Схема повреждений',
    },
    SIMILAR_ADS: {
      TITLE: 'Похожие объявления',
      EMPTY: 'К сожалению нет похожих объявлений',
    },
  },
  LOT_APPLICATION_MODAL: {
    FILL_OUT_APPLICATION: 'Заполните заявку',
    SELLER_CONTACTS: 'Контакты',
    MESSENGERS: {
      TITLE: 'Отметьте привязанные к данному номеру мессенджеры',
    },
    FORM: {
      PRICE: 'Цена',
      CURRENCY: 'Валюта',
      FIRST_NAME: 'Имя',
      LAST_NAME: 'Фамилия',
      PHONE: 'Телефон',
      EMAIL: 'Email',
      COUNTRY: 'Страна',
      SHIPPING_TYPE: 'Тип доставки',
      PORT_OF_DELIVERY: 'Порт доставки',
      FREIGHT_PAYMENT: 'Фрахт',
      COMPANY: 'Компания',
      COMMENT: 'Комментарий',
      ERROR_TIP: {
        PRICE: 'Пожалуйста, введите цену',
        CURRENCY: 'Пожалуйста, выберите валюту',
        FIRST_NAME: 'Пожалуйста, введите имя',
        LAST_NAME: 'Пожалуйста, введите фамилию',
        PHONE: 'Пожалуйста, введите телефон',
        EMAIL: 'Пожалуйста, введите адрес электронной почты',
      },
    },
    BTN: 'Отправить заявку',
    BTN_CLOSE: 'Закрыть',
    APPLICATION_SEND: 'Заявка отправлена!',
    APPLICATION_SEND_CONTACT: 'Наши менеджеры свяжутся с вами в ближайшее время. Спасибо!',
  },
};

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';

import { LayoutConfigService } from '../../services/layout-config.service';

@Component({
  selector: 'app-layout-footer-copyright',
  templateUrl: './layout-footer-copyright.component.html',
  styleUrl: './layout-footer-copyright.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutFooterCopyrightComponent {
  currentYear = new Date().getFullYear();

  canRenderTelegram$: Observable<boolean>;

  constructor(layoutConfigService: LayoutConfigService) {
    this.canRenderTelegram$ = layoutConfigService.canRenderTelegram$;
  }
}

<div class="contacts-item">
  <p>{{ 'LOT.CONTACT_NUMBER_MODAL.MANAGERS' | translate }}</p>
  @for (phone of managersPhones; track $index) {
  <p class="phone-number">
    <a [href]="'https://wa.me/' + phone" target="_blank">{{ phone }}</a>
  </p>
  }
</div>
<div class="contacts-item">
  <p>{{ 'LOT.CONTACT_NUMBER_MODAL.LIVE_INSPECTION' | translate }}</p>
  <p class="phone-number">
    <a [href]="'https://wa.me/' + liveInspectionPhone" target="_blank"
      >{{ liveInspectionPhone }}
    </a>
  </p>
</div>
<div class="contacts-item">
  <p>{{ 'LOT.CONTACT_NUMBER_MODAL.WHATSAPP' | translate }}</p>
  <p class="phone-number">
    <a [href]="'https://wa.me/' + whatsAppPhone" target="_blank">
      {{ whatsAppPhone }}
    </a>
  </p>
</div>

import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-lot-contacts-modal',
  templateUrl: './lot-contacts-modal.component.html',
  styleUrl: './lot-contacts-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotContactsModalComponent {
  managersPhones = ['+817037692929', '+818093573030', '+818069153232', '+817022833434'];
  liveInspectionPhone = '+817088082323';
  whatsAppPhone = '+817035132626';
}

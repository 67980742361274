import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** The `DateTime` scalar type represents time data, ISO-8601 encoded UTC date string. */
  DateTime: { input: any; output: any; }
};

export type Auction = {
  __typename?: 'Auction';
  address?: Maybe<Scalars['String']['output']>;
  auctionData?: Maybe<AuctionData>;
  auctionsCountryRestrictions?: Maybe<Array<AuctionsCountryRestrictions>>;
  auctionsDealerFees?: Maybe<Array<AuctionsDealerFees>>;
  auctionsDeliveryCosts?: Maybe<Array<AuctionsDeliveryCosts>>;
  /** Лого банка */
  bankLogo?: Maybe<S3File>;
  fax?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  rating?: Maybe<AuctionRating>;
  stock?: Maybe<Stock>;
  ticketRules?: Maybe<Scalars['String']['output']>;
};

export type AuctionData = {
  __typename?: 'AuctionData';
  auctionDealer?: Maybe<Scalars['Int']['output']>;
  auctionDelivery?: Maybe<Scalars['Int']['output']>;
  auctionDeliveryUndriveable?: Maybe<Scalars['Int']['output']>;
  auctionSbor?: Maybe<Scalars['Int']['output']>;
  auctionSborByNegotiations?: Maybe<Scalars['Int']['output']>;
  carrier?: Maybe<Carrier>;
  costDelivery?: Maybe<Scalars['Float']['output']>;
  destinationStock?: Maybe<Stock>;
  freight?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  isNoGroup?: Maybe<Scalars['Boolean']['output']>;
  isNoPallet?: Maybe<Scalars['Boolean']['output']>;
  port?: Maybe<Port>;
  remoteness?: Maybe<AuctionRemotenessEnum>;
  sourceStocks?: Maybe<Array<Stock>>;
};

export type AuctionRating = {
  __typename?: 'AuctionRating';
  /** Аукцион */
  auction?: Maybe<Auction>;
  /** Колличество оценок */
  evaluationCount?: Maybe<Scalars['Int']['output']>;
  /** Колличество отзывов */
  feedbackCount?: Maybe<Scalars['Int']['output']>;
  /** Id */
  id?: Maybe<Scalars['Int']['output']>;
  /** Рейтинг аукциона */
  rating?: Maybe<Scalars['Float']['output']>;
};

export enum AuctionRemotenessEnum {
  High = 'HIGH',
  Low = 'LOW',
  Medium = 'MEDIUM'
}

export type AuctionsCountryRestrictions = {
  __typename?: 'AuctionsCountryRestrictions';
  countryIso?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type AuctionsDealerFees = {
  __typename?: 'AuctionsDealerFees';
  commission?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  planId?: Maybe<Scalars['Int']['output']>;
};

export type AuctionsDeliveryCosts = {
  __typename?: 'AuctionsDeliveryCosts';
  costDelivery?: Maybe<Scalars['Float']['output']>;
  destinationCountryIso?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type AverageObjectAucToRussia = {
  __typename?: 'AverageObjectAucToRussia';
  avgDays?: Maybe<Scalars['Int']['output']>;
  carType?: Maybe<TypeOfExport>;
};

export type AverageObjectToTrip = {
  __typename?: 'AverageObjectToTrip';
  all?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  stockToPort?: Maybe<Scalars['Int']['output']>;
  toStock?: Maybe<Scalars['Int']['output']>;
};

export type AverageObjectToTripByAuction = {
  __typename?: 'AverageObjectToTripByAuction';
  auctions?: Maybe<Array<ObjectToTripByAuction>>;
  name?: Maybe<Scalars['String']['output']>;
};

export type AverageTimeDeliveryFromAuction = {
  __typename?: 'AverageTimeDeliveryFromAuction';
  maxAvg?: Maybe<Scalars['Int']['output']>;
  minAvg?: Maybe<Scalars['Int']['output']>;
  nameStock?: Maybe<Scalars['String']['output']>;
};

export type CarType = {
  __typename?: 'CarType';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nameEng?: Maybe<Scalars['String']['output']>;
};

export type Carrier = {
  __typename?: 'Carrier';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
};

export type Chassis = {
  __typename?: 'Chassis';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type Country = {
  __typename?: 'Country';
  alfa2?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  engName?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type CustomType = {
  __typename?: 'CustomType';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type EngineType = {
  __typename?: 'EngineType';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** Статусы загрузки файлов для таможни */
export enum FileCustomDocumentStatusEnum {
  /** Подтвержден */
  Accepted = 'ACCEPTED',
  /** Новый файл */
  New = 'NEW',
  /** Не изменялся */
  NotChanged = 'NOT_CHANGED'
}

export type Maker = {
  __typename?: 'Maker';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type Mark = {
  __typename?: 'Mark';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type Model = {
  __typename?: 'Model';
  height?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  length?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  width?: Maybe<Scalars['Int']['output']>;
};

export type ObjectToTripByAuction = {
  __typename?: 'ObjectToTripByAuction';
  all?: Maybe<Scalars['Int']['output']>;
  auction?: Maybe<Scalars['String']['output']>;
  stockToPort?: Maybe<Scalars['Int']['output']>;
  toStock?: Maybe<Scalars['Int']['output']>;
};

export type Port = {
  __typename?: 'Port';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  portData?: Maybe<PortData>;
  stock?: Maybe<Stock>;
};

export type PortData = {
  __typename?: 'PortData';
  countryIso?: Maybe<Scalars['String']['output']>;
  freight?: Maybe<Scalars['Float']['output']>;
  freightM3?: Maybe<Scalars['Float']['output']>;
  freightPart?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type Query = {
  __typename?: 'Query';
  /** Получить аукцион по id */
  auction?: Maybe<Auction>;
  auctions?: Maybe<Array<Auction>>;
  averageObjectAucToRussia?: Maybe<Array<AverageObjectAucToRussia>>;
  averageObjectToTrip?: Maybe<Array<AverageObjectToTrip>>;
  averageObjectToTripByAuction?: Maybe<Array<AverageObjectToTripByAuction>>;
  averageTimeDeliveryDocs?: Maybe<Scalars['Int']['output']>;
  averageTimeDeliveryFromAuction?: Maybe<Array<AverageTimeDeliveryFromAuction>>;
  carType?: Maybe<Array<CarType>>;
  chassisType?: Maybe<Array<Chassis>>;
  countries?: Maybe<Array<Country>>;
  customType?: Maybe<Array<CustomType>>;
  engines?: Maybe<Array<EngineType>>;
  makers?: Maybe<Array<Maker>>;
  marks?: Maybe<Array<Mark>>;
  models?: Maybe<Array<Model>>;
  /** Курс по банку MUFG JPT/CNY */
  mufgJpyToCnyExhangeRate?: Maybe<Scalars['Float']['output']>;
  /** Курс по банку MUFG JPT/EUR */
  mufgJpyToEurExhangeRate?: Maybe<Scalars['Float']['output']>;
  /** Курс по банку MUFG JPT/USD */
  mufgJpyToUsdExhangeRate?: Maybe<Scalars['Float']['output']>;
  ports?: Maybe<Array<Port>>;
  /** Получить круговое время доставки для аукциона */
  roundDeliveryTime?: Maybe<RoundDeliveryTime>;
  sawType?: Maybe<Array<SawType>>;
};


export type QueryAuctionArgs = {
  id: Scalars['Int']['input'];
};


export type QueryAverageObjectAucToRussiaArgs = {
  aucId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryAverageObjectToTripArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryAverageObjectToTripByAuctionArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryAverageTimeDeliveryDocsArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryAverageTimeDeliveryFromAuctionArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryCustomTypeArgs = {
  carTypeId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryMarksArgs = {
  makerId?: InputMaybe<Scalars['Int']['input']>;
  makerName?: InputMaybe<Scalars['String']['input']>;
};


export type QueryModelsArgs = {
  markId?: InputMaybe<Scalars['Int']['input']>;
  markName?: InputMaybe<Scalars['String']['input']>;
};


export type QueryRoundDeliveryTimeArgs = {
  auctionId: Scalars['Int']['input'];
};


export type QuerySawTypeArgs = {
  chassisTypeId?: InputMaybe<Scalars['Int']['input']>;
  objectTypeId?: InputMaybe<Scalars['Int']['input']>;
};

export type RoundDeliveryTime = {
  __typename?: 'RoundDeliveryTime';
  /** Ближайший рейс */
  nearTrip?: Maybe<RoundDeliveryTimeItem>;
  /**
   * Следующий ближайший рейс
   * @deprecated Will be delete
   */
  nextNearTrip?: Maybe<RoundDeliveryTimeItem>;
};

export type RoundDeliveryTimeItem = {
  __typename?: 'RoundDeliveryTimeItem';
  /** @deprecated Will be delete */
  dateArrivalVladivostok?: Maybe<Scalars['DateTime']['output']>;
  /** @deprecated Will be delete */
  dateBuy?: Maybe<Scalars['DateTime']['output']>;
  /** @deprecated Will be delete */
  dateNearTrip?: Maybe<Scalars['DateTime']['output']>;
  dateOfIssue?: Maybe<Scalars['DateTime']['output']>;
  /** @deprecated Will be delete */
  dateWithAuction?: Maybe<Scalars['DateTime']['output']>;
};

export type S3File = {
  __typename?: 'S3File';
  /** Base64 файл */
  binaryBase64?: Maybe<Scalars['String']['output']>;
  /** Ссылка через клаудфронт */
  cloudFrontPath?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Состояние файлов для файлов таможни */
  customDocumentStatus?: Maybe<FileCustomDocumentStatusEnum>;
  /** CDN c учётом разрешения файла и бакета (для старых файлов) */
  fullPathByExtension?: Maybe<Scalars['String']['output']>;
  /** Ссылка на файл с учётом бакета */
  fullPathForFile?: Maybe<Scalars['String']['output']>;
  /** Ссылка через бакет на прямую */
  fullPathForFileWithoutCdn?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  /** Путь в бакете */
  key?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  user?: Maybe<UserShortInfo>;
};

export type SawType = {
  __typename?: 'SawType';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type Sector = {
  __typename?: 'Sector';
  /** Вместимость */
  capacity?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  isDeleted?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nameEng?: Maybe<Scalars['String']['output']>;
  /** Порядок вывода секторов в списках (сортировка) */
  order?: Maybe<Scalars['Int']['output']>;
  /** Кол-во объектов в секторе */
  totalCars?: Maybe<Scalars['Int']['output']>;
};

export type Stock = {
  __typename?: 'Stock';
  /** Список активных секторов */
  activeSectors?: Maybe<Array<Sector>>;
  /** Вместимость */
  capacity?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  /** Склад в Отару */
  inOtaru?: Maybe<Scalars['Boolean']['output']>;
  /** Склад в Томакомай */
  inTomakomai?: Maybe<Scalars['Boolean']['output']>;
  /** Склад в Тояме */
  inToyama?: Maybe<Scalars['Boolean']['output']>;
  /** Склад аукциона */
  isAuction?: Maybe<Scalars['Boolean']['output']>;
  /** Склад в ЯП */
  isJapan?: Maybe<Scalars['Boolean']['output']>;
  /** Склад порта */
  isPort?: Maybe<Scalars['Boolean']['output']>;
  /** Хранение на складе платное */
  isStoragePaid?: Maybe<Scalars['Boolean']['output']>;
  /** Склад судна */
  isVessel?: Maybe<Scalars['Boolean']['output']>;
  /** Виртуальный склад */
  isVirtual?: Maybe<Scalars['Boolean']['output']>;
  /** Склад с которых можно делать выдачу */
  issueAllow?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nameEng?: Maybe<Scalars['String']['output']>;
  /** Флаг отправки уведомления о прибытии на склад клиенту */
  notificationClient?: Maybe<Scalars['Boolean']['output']>;
  /** Флаг отправки уведомления о прибытии на склад менеджеру */
  notificationManager?: Maybe<Scalars['Boolean']['output']>;
  /** Список всех секторов */
  sectors?: Maybe<Array<Sector>>;
  /** Стоимость хранения */
  storageCost?: Maybe<Scalars['Int']['output']>;
  /** Количество дней бесплатного хранения */
  storageFreeDays?: Maybe<Scalars['Int']['output']>;
  /** Кол-во объектов в секторе */
  totalCars?: Maybe<Scalars['Int']['output']>;
};

export type TypeOfExport = {
  __typename?: 'TypeOfExport';
  id?: Maybe<Scalars['Int']['output']>;
  isDefaultForScrap?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nameEng?: Maybe<Scalars['String']['output']>;
  outerNameEng?: Maybe<Scalars['String']['output']>;
  outerNameRu?: Maybe<Scalars['String']['output']>;
};

export type UserShortInfo = {
  __typename?: 'UserShortInfo';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type DeliveryStatisticsQueryVariables = Exact<{ [key: string]: never; }>;


export type DeliveryStatisticsQuery = { __typename?: 'Query', averageObjectToTrip?: Array<{ __typename?: 'AverageObjectToTrip', all?: number | null, name?: string | null }> | null, averageObjectAucToRussia?: Array<{ __typename?: 'AverageObjectAucToRussia', avgDays?: number | null, carType?: { __typename?: 'TypeOfExport', id?: number | null, name?: string | null } | null }> | null };

export type HeaderSectionExchangeRatesQueryVariables = Exact<{ [key: string]: never; }>;


export type HeaderSectionExchangeRatesQuery = { __typename?: 'Query', jpyToEur?: number | null, jpyToUsd?: number | null };

export const DeliveryStatisticsDocument = gql`
    query deliveryStatistics {
  averageObjectToTrip {
    all
    name
  }
  averageObjectAucToRussia {
    avgDays
    carType {
      id
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeliveryStatisticsGQL extends Apollo.Query<DeliveryStatisticsQuery, DeliveryStatisticsQueryVariables> {
    override document = DeliveryStatisticsDocument;
    override client = 'common/open';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const HeaderSectionExchangeRatesDocument = gql`
    query headerSectionExchangeRates {
  jpyToEur: mufgJpyToEurExhangeRate
  jpyToUsd: mufgJpyToUsdExhangeRate
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class HeaderSectionExchangeRatesGQL extends Apollo.Query<HeaderSectionExchangeRatesQuery, HeaderSectionExchangeRatesQueryVariables> {
    override document = HeaderSectionExchangeRatesDocument;
    override client = 'common/open';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    
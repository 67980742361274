<a
  nz-dropdown
  nzTrigger="click"
  nzPlacement="bottomRight"
  [nzDropdownMenu]="menu"
  [(nzVisible)]="dropdownVisible"
>
  {{ currentSortTypeName$ | push | translate }}
  <span nz-icon nzType="down"></span>
</a>

<nz-dropdown-menu #menu="nzDropdownMenu">
  <ul class="dropdown-list">
    @for (sortType of sortTypes; track sortType) {
    <li tabindex="0" (click)="changeSort(sortType)">
      {{ 'LOTS_LIST_SORT.SORT_TYPES.' + sortType | translate }}
    </li>
    }
  </ul>
</nz-dropdown-menu>

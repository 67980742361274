import { ru } from './ru';

export const en: typeof ru = {
  "GLOBALS": {
    "MUTATION_SUCCEEDED": "Success!",
    "LINK_COPIED": "Link copied",
    "ACTIONS": {
      "OK": "Ok",
      "CREATE": "Create",
      "REMOVE": "Remove",
      "SAVE": "Save",
      "SELECT": "Select",
      "SELECTED": "Selected",
      "SEND": "Send",
      "CANCEL": "Cancel",
      "ROLL_UP": "Roll up",
      "SEARCH": "Search",
      "APPLY": "Apply",
      "RESET": "Reset",
      "UPDATE": "Update",
      "CONFIRM": "Confirm",
      "BACK": "Back",
      "NEXT": "Next",
      "OPEN": "Open"
    }
  }
};

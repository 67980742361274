<nz-layout class="module-layout">
  <header>
    <div class="header-wrapper">
      <div class="mob-menu">
        <i nz-icon tabindex="0" (click)="openMobileMenu()" nzType="icons:menu-burger"></i>
      </div>
      <div class="logo-contacts-block">
        <a class="logo" routerLink="/">
          <img alt="Akebono" src="/assets/logos/svg/akebono-logo.svg" />
        </a>
        <div class="main-contacts">
          <app-main-contacts
            type="horizontal"
            [hideText]="hideMainContactsText$ | push"
          ></app-main-contacts>
        </div>
      </div>

      <div class="selector-block">
        <app-authorization-actions></app-authorization-actions>
        <div class="selector-language">
          <app-language-selector></app-language-selector>
        </div>
      </div>
    </div>
  </header>

  <main [class.paddings]="hasPaddings$ | push">
    <div class="content-wrapper" [class.width-limit]="hasWidthLimit$ | push">
      <router-outlet></router-outlet>
    </div>
  </main>

  <footer>
    <div class="footer-wrapper">
      <app-layout-contacts></app-layout-contacts>
      <app-layout-footer-copyright></app-layout-footer-copyright>
    </div>
  </footer>
</nz-layout>

<div class="fixed-utilities">
  <app-scroll-to-top></app-scroll-to-top>
  <app-support-chats></app-support-chats>
</div>

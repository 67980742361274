<nz-row [nzGutter]="[20, 20]" nzJustify="center">
  <nz-col
    [nzXs]="grid.xs"
    [nzSm]="grid.sm"
    [nzMd]="grid.md"
    [nzLg]="grid.lg"
    [nzXl]="grid.xl"
    [nzXXl]="grid.xl"
  >
    <app-category-card
      [title]="'CATEGORY_SECTION.JAPAN_TITLE' | translate"
      [text]="'CATEGORY_SECTION.JAPAN_TEXT' | translate"
      [lotType]="LotType.Japan"
    ></app-category-card>
  </nz-col>
  <nz-col
    [nzXs]="grid.xs"
    [nzSm]="grid.sm"
    [nzMd]="grid.md"
    [nzLg]="grid.lg"
    [nzXl]="grid.xl"
    [nzXXl]="grid.xl"
  >
    <app-category-card
      [title]="'CATEGORY_SECTION.GREEN_TITLE' | translate"
      [text]="'CATEGORY_SECTION.GREEN_TEXT' | translate"
      [lotType]="LotType.GreenCorner"
      [count]=""
    ></app-category-card>
  </nz-col>

  <nz-col
    [nzXs]="grid.xs"
    [nzSm]="grid.sm"
    [nzMd]="grid.md"
    [nzLg]="grid.lg"
    [nzXl]="grid.xl"
    [nzXXl]="grid.xl"
  >
    <app-category-card
      [title]="'CATEGORY_SECTION.MONGOL_TITLE' | translate"
      [text]="'CATEGORY_SECTION.MONGOL_TEXT' | translate"
      [lotType]="LotType.Mongolia"
    ></app-category-card>
  </nz-col>
  <nz-col
    [nzXs]="grid.xs"
    [nzSm]="grid.sm"
    [nzMd]="grid.md"
    [nzLg]="grid.lg"
    [nzXl]="grid.xl"
    [nzXXl]="grid.xl"
  >
    <app-category-card
      [title]="'CATEGORY_SECTION.JAPAN_MOTIK_TITLE' | translate"
      [text]="'CATEGORY_SECTION.JAPAN_MOTIK_TEXT' | translate"
      [lotType]="LotType.Motorcycle"
    ></app-category-card>
  </nz-col>

  <nz-col
    [nzXs]="grid.xs"
    [nzSm]="grid.sm"
    [nzMd]="grid.md"
    [nzLg]="grid.lg"
    [nzXl]="grid.xl"
    [nzXXl]="grid.xl"
  >
    <app-category-card
      [title]="'CATEGORY_SECTION.TRUCKS_TITLT' | translate"
      [text]="'CATEGORY_SECTION.TRUCKS_TEXT' | translate"
      [lotType]="LotType.Truck"
    ></app-category-card>
  </nz-col>
  <nz-col
    [nzXs]="grid.xs"
    [nzSm]="grid.sm"
    [nzMd]="grid.md"
    [nzLg]="grid.lg"
    [nzXl]="grid.xl"
    [nzXXl]="grid.xl"
  >
    <app-category-card
      [title]="'CATEGORY_SECTION.TOYAMA_TITLE' | translate"
      [text]="'CATEGORY_SECTION.TOYAMA_TEXT' | translate"
      [lotType]="LotType.Recycling"
    ></app-category-card>
  </nz-col>
  <nz-col
    [nzXs]="grid.xs"
    [nzSm]="grid.sm"
    [nzMd]="grid.md"
    [nzLg]="grid.lg"
    [nzXl]="grid.xl"
    [nzXXl]="grid.xl"
  >
    <app-category-card
      [title]="'CATEGORY_SECTION.USA_TITLE' | translate"
      [text]="'CATEGORY_SECTION.USA_TEXT' | translate"
      [lotType]="LotType.Usa"
    ></app-category-card>
  </nz-col>
</nz-row>

import { ChangeDetectionStrategy, Component } from '@angular/core';
@Component({
  selector: 'app-header-section',
  templateUrl: './header-section.component.html',
  styleUrl: './header-section.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderSectionComponent {
  value?: string;
  demoValue = 3;
  date = null;

  onChange(result: Date): void {
    console.log('onChange: ', result);
  }
}

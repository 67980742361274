import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MediaItem } from 'src/app/services/lot-adapter/types/media-item.type';
import { Swiper, SwiperOptions } from 'swiper/types';

@Component({
  selector: 'app-main-slider',
  templateUrl: './main-slider.component.html',
  styleUrl: './main-slider.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainSliderComponent implements OnChanges {
  @Input({ required: true }) media!: MediaItem[];
  @Input() slidesLimited = false;
  @Input() activeSlideIndex = 0;
  @Input()
  set thumbs(swiper: Swiper | undefined) {
    if (swiper) {
      this.swiperConfiguration = {
        ...this.swiperConfiguration,
        thumbs: {
          swiper,
        },
      };
    }
  }

  @Output() activeSlideIndexChange = new EventEmitter<number>();

  defaultMediaItem: MediaItem = {
    type: 'image',
    source: '/assets/img/default-fon.jpg',
  };

  private swiperInitialized = false;
  private swiper!: Swiper;

  swiperConfiguration: SwiperOptions = {
    spaceBetween: 10,
    navigation: {
      disabledClass: 'swiper-button-hidden',
    },
    lazyPreloadPrevNext: 3,
    pagination: {
      clickable: true,
      type: 'fraction',
    },
    observer: true,
    observeParents: true,
    on: {
      init: (swiper) => {
        this.swiper = swiper;
        this.swiper.activeIndex = this.activeSlideIndex;
        this.swiperInitialized = true;
      },
      slideChange: () => {
        this.activeSlideIndex = this.swiper.activeIndex;
        this.activeSlideIndexChange.emit(this.activeSlideIndex);
      },
    },
  };

  ngOnChanges(changes: SimpleChanges): void {
    if (this.swiperInitialized && changes['activeSlideIndex']) {
      this.swiper.slideTo(this.activeSlideIndex);
    }
  }
}

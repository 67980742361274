import {ChangeDetectionStrategy, Component, ElementRef, ViewChild,} from '@angular/core';
import {NewsService} from "../../../../services/news/news.service";
import {
  NewsFilterInput, NewsItemFragment,
  NewsQueryVariables,
  SortEnumType
} from "../../../../graphql/content-auto-graphql.service";
import {map, Observable, shareReplay, switchMap, tap} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {NewsPageFilterBuilderService} from "./services/news-page-filter-builder.service";

@Component({
  selector: 'app-news-page',
  templateUrl: './news-page.component.html',
  styleUrl: './news-page.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [NewsPageFilterBuilderService],
})
export class NewsPageComponent {
  news$: Observable<NewsItemFragment[]>;
  loading$: Observable<boolean>;
  total$: Observable<number>;

  pageSize = 21;
  currentPage = 1;
  filter: NewsFilterInput | undefined;

  grid = {
    xxl: 8,
    xl: 8,
    lg: 10,
    md: 12,
    sm: 12,
    xs: 24,
  };

  @ViewChild('newsList')
  newsList!: ElementRef<HTMLDivElement>;

  constructor(
    private router: Router,
    newsService: NewsService,
    route: ActivatedRoute,
    filterBuilder: NewsPageFilterBuilderService,
  ) {
    const newsData$ = route.queryParams.pipe(
      tap((params) => {
        this.currentPage = +params['page'] || 1;
        this.pageSize = +params['pageSize'] || 21;
        this.filter = filterBuilder.buildFilter(params);
      }),
      map(() => {
        return <NewsQueryVariables>{
          skip: (this.currentPage - 1) * this.pageSize,
          take: this.pageSize,
          where: this.filter,
          order: {
            publishedAt: SortEnumType.Desc,
          }
        };
      }),
      map((variables) => newsService.getNews(variables)),
      shareReplay(1),
    );

    this.loading$ = newsData$.pipe(switchMap(({loading$}) => loading$));
    this.news$ = newsData$.pipe(switchMap(({news$}) => news$));
    this.total$ = newsData$.pipe(
      switchMap(({total$}) => total$),
    );
  }

  onPageChange(page: number): void {
    this.newsList.nativeElement.scrollIntoView({behavior: 'smooth'});
    this.router.navigate([], {
      queryParams: {page, pageSize: this.pageSize},
      queryParamsHandling: 'merge',
    });
  }
}

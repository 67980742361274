import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { LanguageService } from 'src/app/services/language.service';

import { en } from './locale/en';
import { ru } from './locale/ru';
import { UsefulInformationSectionComponent } from './useful-information-section.component';

@NgModule({
  declarations: [UsefulInformationSectionComponent],
  imports: [CommonModule, RouterModule, TranslateModule, NzIconModule],
  exports: [UsefulInformationSectionComponent],
})
export class UsefulInformationSectionModule {
  constructor(languageService: LanguageService) {
    languageService.addLocales({ en, ru });
  }
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-buhanka-donate-result',
  templateUrl: './buhanka-donate-result.component.html',
  styleUrl: './buhanka-donate-result.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BuhankaDonateResultComponent {
  @Input({ required: true }) loading = false;
  @Input({ required: true }) total = 0;
  @Input({ required: true }) collected = 0;

  get percentage(): number {
    if (this.total === 0) return 0;
    return Math.floor((this.collected / this.total) * 100);
  }
}

import {ChangeDetectorRef, Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {LanguageService} from "../../services/language.service";
import {Language} from "../../utilities/types/language.type";
import {distinctUntilChanged, skip} from "rxjs";
import {ArticleFragment, NewsItemFragment} from "../../graphql/content-auto-graphql.service";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";

@Pipe({
  name: 'newsContent',
  pure: false,
})
export class NewsContentPipe implements PipeTransform {
  private currentLanguage!: Language;

  constructor(
    private translate: TranslateService,
    cdr: ChangeDetectorRef,
    languageService: LanguageService,
  ) {
    this.currentLanguage = languageService.currentLanguage;
    languageService.currentLanguage$.pipe(
      distinctUntilChanged(),
      skip(1),
      takeUntilDestroyed()
    ).subscribe((language) => {
      this.currentLanguage = language;
      cdr.markForCheck();
    });
  }

  transform(item: (NewsItemFragment | null | undefined) | ArticleFragment, type: 'title' | 'content'): string {
    if (!item || !this.currentLanguage) {
      return this.getFallback(type);
    }

    const text = item?.[type]?.[this.currentLanguage]?.trim()
    return text || this.getFallback(type);
  }

  private getFallback(type: 'title' | 'content'): string {
    return this.translate.instant(
      type === 'title' ? 'NEWS_ITEM_PAGE.NO_TITLE' : 'NEWS_ITEM_PAGE.NO_CONTENT'
    );
  }
}

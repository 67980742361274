import { FormArray, FormControl, FormGroup } from '@angular/forms';

export function validateAllFormFields(formGroup: FormGroup) {
  Object.keys(formGroup.controls).forEach((field) => {
    const control = formGroup.get(field);
    if (control instanceof FormControl) {
      control.markAsTouched({ onlySelf: true });
      control.markAsDirty({ onlySelf: true });
      control.updateValueAndValidity({ onlySelf: true });
    } else if (control instanceof FormArray) {
      control.controls.forEach((ctrl) => {
        ctrl.markAsTouched({ onlySelf: true });
        ctrl.markAsDirty({ onlySelf: true });
        ctrl.updateValueAndValidity({ onlySelf: true });
      });
    } else if (control instanceof FormGroup) {
      validateAllFormFields(control);
    }
  });
}

<app-main-slider
  [media]="media$ | push"
  [slidesLimited]="limitSlides$ | push"
  [thumbs]="thumbsSwiper"
></app-main-slider>
<app-thumbs-slider
  [media]="media$ | push"
  [slidesLimited]="limitSlides$ | push"
  (swiperInitialized)="thumbsSwiperInitialized($event)"
></app-thumbs-slider>

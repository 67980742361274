import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-lot-info-description',
  templateUrl: './lot-info-description.component.html',
  styleUrl: './lot-info-description.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LotInfoDescriptionComponent {
  @Input({required: true}) description: string | undefined;
}

import {ru} from './ru';

export const en: typeof ru = {
  "INFORMATION_PAGE": {
    "TITLE": "Information",
    "TO_MAIN": "To main",
    "CATEGORIES": "Categories",
    "ARTICLE_NOT_FOUND": "Article not found",
    "ARTICLES_LIST": "Articles list",
    "REFERENCE_INFO": "Reference information",
    "REFERENCE_INFO_TXT": "In this section, we have collected for you the entire Akebono library. Here, you can study the activities of auto auctions, structure, history, types of auctions, auction news and the auto industry in general.",
  }
};

import {
  AkebonoLotSource,
  AkebonoLotTypeEnum,
} from '../../../graphql/akebono-main-graphql.service';
import { AucnetMotoLotMinimalFragment } from '../../../graphql/aucnet-moto-graphql.service';
import { LotSource } from '../enums/lot-source.enum';
import { LotType } from '../enums/lot-type.enum';
import { ILotMinimalBase } from '../interfaces/lot-minimal-base.interface';
import { ILotMinimal } from '../interfaces/lot-minimal.interface';
import { MediaItem } from '../types/media-item.type';
import { Value } from '../types/value.type';
import { LotAdapterUtilities } from '../utilities/lot-adapter.utilities';

export class AucnetMotoLotMinimalAdapter implements ILotMinimal {
  id?: string | undefined;
  media?: MediaItem[] | undefined;
  maker?: string;
  mark?: string;
  model?: string | undefined;
  grade?: string | undefined;
  year?: number | undefined;
  engineVolume?: Value | undefined;
  transmission?: string | undefined;
  fuel?: string | undefined;
  bodyType?: string | undefined;
  drive?: string | undefined;
  rating?: string | undefined;
  price?: Value | undefined;
  mileage?: Value | undefined;
  color?: string | undefined;
  location?: string | undefined;
  vin?: string | undefined;
  accident?: string | undefined;
  isSold?: boolean | undefined;
  type: LotType;
  source: LotSource;
  akebonoLotType: AkebonoLotTypeEnum;
  akebonoLotSource: AkebonoLotSource;
  fields: Set<keyof ILotMinimalBase>;

  constructor(lot: AucnetMotoLotMinimalFragment) {
    this.type = LotType.Motorcycle;
    this.source = LotSource.AucnetMoto;
    this.akebonoLotType = AkebonoLotTypeEnum.Moto;
    this.akebonoLotSource = AkebonoLotSource.Aucnet;

    this.id = lot.id || undefined;
    this.media = this.normalizeMedia(lot.images);
    this.maker = lot.company?.trim() || undefined;
    this.mark = this.normalizeMark(lot);
    this.engineVolume = LotAdapterUtilities.buildValue(lot.engineCc, 'cc');
    this.rating = lot.evaluation || undefined;
    this.price = LotAdapterUtilities.buildValue(lot.wholesalePrice, 'JPY');
    this.mileage = LotAdapterUtilities.buildValue(lot.mileage, lot.mileageUnits);
    this.vin = lot.frameNumber || undefined;
    this.color = lot.color || undefined;
    this.fields = new Set([
      'id',
      'media',
      'maker',
      'mark',
      'engineVolume',
      'rating',
      'price',
      'mileage',
      'color',
      'vin',
    ]);
  }

  private normalizeMark(lot: AucnetMotoLotMinimalFragment): string | undefined {
    if (!lot.modelName) {
      return undefined;
    }

    return lot.modelName.replace(lot.company?.trim() || '', '').trim();
  }

  private normalizeMedia(
    sourceImages: AucnetMotoLotMinimalFragment['images'],
  ): MediaItem[] | undefined {
    if (!sourceImages) {
      return undefined;
    }

    const images = sourceImages.split('#')?.map((path) => `https://img.akebono.world/${path}`);
    return LotAdapterUtilities.normalizeMedia(images);
  }
}

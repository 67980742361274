import { Injectable } from '@angular/core';
import { ApolloQueryResult, OperationVariables } from '@apollo/client/core';
import { Apollo, Mutation, Query } from 'apollo-angular';
import { combineLatest, defer, from, Observable } from 'rxjs';
import { map, switchMap, toArray } from 'rxjs/operators';

import { NotificationService } from '../notification.service';
import { ObservableHandlingService } from '../observable-handling-service/observable-handling.service';
import { MutationHandlerOptions } from './interfaces/mutation-handling-options.interface';
import { MultipleMutationRef } from './mutation-refs/multiple.mutation-ref';
import { SingleMutationRef } from './mutation-refs/single.mutation-ref';

@Injectable({
  providedIn: 'root',
})
export class MutationHandlingServiceV2 extends ObservableHandlingService {
  constructor(private notificationService: NotificationService, private apollo: Apollo) {
    super();
  }

  mutate<T, R extends OperationVariables>(
    mutation: Mutation<T, R>,
    input: R,
    options: MutationHandlerOptions = {
      refetch: false,
      renderError: true,
      renderSuccess: true,
    },
  ): SingleMutationRef<T> {
    console.log('mutation', mutation);
    const mutationObservable = mutation.mutate(input);

    return new SingleMutationRef(
      mutationObservable,
      options,
      this.apollo,
      mutation.client,
      this.notificationService,
    );
  }

  mutateAll<T, R extends OperationVariables>(
    mutation: Mutation<T, R>,
    input: R[],
    options: MutationHandlerOptions = {
      refetch: false,
      renderError: true,
      renderSuccess: true,
    },
  ): MultipleMutationRef<T> {
    const mutationObservables = input.map((inp) => mutation.mutate(inp));
    const combined = combineLatest(mutationObservables);

    return new MultipleMutationRef(
      combined,
      options,
      this.apollo,
      mutation.client,
      this.notificationService,
    );
  }

  public refetchQueries(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    clientOrQuery: Query<unknown, any> | string,
    lazy = false,
  ): Observable<unknown[]> {
    let client: string | null = null;
    let observable: Observable<ApolloQueryResult<unknown>[]> | null = null;

    if (typeof clientOrQuery === 'string') {
      client = clientOrQuery;
    } else {
      client = clientOrQuery.client;
    }

    if (lazy) {
      observable = defer(() => from(this.apollo.use(client).client.reFetchObservableQueries()));
    } else {
      observable = from(this.apollo.use(client).client.reFetchObservableQueries());
    }

    return observable.pipe(
      switchMap((results) => from(results)),
      map((result) => result.data),
      toArray(),
    );
  }
}

<a
  nz-dropdown
  nzTrigger="click"
  nzPlacement="bottomRight"
  [nzDropdownMenu]="menu"
  class="mobile-actions"
  [class.dropdown-open]="dropdownOpen"
  [(nzVisible)]="dropdownOpen"
>
  <div class="user-icon">
    <span>{{ currentUserName$ | push | firstSymbol | uppercase }}</span>
  </div>
  <div class="user-name">
    <span>{{ currentUserName$ | push }}</span>
  </div>
  <i nz-icon nzType="down" class="icon-down"></i>
</a>

<nz-dropdown-menu #menu="nzDropdownMenu">
  <ul class="dropdown-list">
    <li>
      <a [href]="personalAcc" class="dropdown-link">
        {{ 'AUTHORIZED_ACTIONS.PERSONAL_ACC' | translate }}
      </a>
    </li>
    <li class="exit-button" (click)="signOut()" tabindex="0">
      {{ 'AUTHORIZED_ACTIONS.EXIT' | translate }}
    </li>
  </ul>
</nz-dropdown-menu>

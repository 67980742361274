import { GraphQLFormattedError } from 'graphql';

import { BaseQueryHandlingException } from './base-query-handling.exception';

export class GraphqlQueryException extends BaseQueryHandlingException {
  constructor(private graphqlErrors: readonly GraphQLFormattedError[]) {
    super('Error when executing graphql');
  }

  public log(): void {
    console.error(this.message, this.graphqlErrors);
  }
}

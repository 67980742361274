import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ParseHtmlPipe } from './parse-html.pipe';

@NgModule({
  declarations: [ParseHtmlPipe],
  imports: [CommonModule],
  exports: [ParseHtmlPipe],
})
export class ParseHtmlPipeModule {}

export const ru = {
  "LOTS_LIST_SORT": {
    "SORT_TYPES": {
      "new": "Дата создания, сначала новые",
      "old": "Дата создания, сначала старые",
      "year-new": "Год, сначала новые",
      "year-old": "Год, сначала старые",
      "price-ascending": "Цена, сначала дешевые",
      "price-descending": "Цена, сначала дорогие"
    }
  }
};

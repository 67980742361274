import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { LotType } from 'src/app/services/lot-adapter/enums/lot-type.enum';
import { ILotMinimal } from 'src/app/services/lot-adapter/interfaces/lot-minimal.interface';
import { PopularLotFetcherService } from 'src/app/services/popular-lot-fetcher/popular-lot-fetcher.service';

@Component({
  selector: 'app-popular-cars-list',
  templateUrl: './popular-cars-list.component.html',
  styleUrl: './popular-cars-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopularCarsListComponent {
  @Input({ required: true })
  set lotType(lotType: LotType) {
    this._lotType = lotType;
    this.refetchLots();
  }
  
  _lotType!: LotType;
  lots$!: Observable<ILotMinimal[]>;
  isLoading$!: Observable<boolean>;

  grid = {
    xxl: 6,
    xl: 6,
    lg: 10,
    md: 12,
    sm: 12,
    xs: 28,
  };

  constructor(private popularLotFetcherService: PopularLotFetcherService) {}

  private refetchLots() {
    const data = this.popularLotFetcherService.fetchPopularLots(this._lotType);
    this.lots$ = data.lots$;
    this.isLoading$ = data.loading$;
  }
}

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** Represents non-fractional signed whole numeric values. Since the value may exceed the size of a 32-bit integer, it's encoded as a string. */
  BigInt: { input: any; output: any; }
  /** A valid DateTime, transported as a iso8601 string */
  DateTime: { input: any; output: any; }
  /** A valid JSON object */
  JSON: { input: any; output: any; }
  /** An lot ID */
  LotID: { input: any; output: any; }
  /** A secure, escaped string! */
  SecureString: { input: any; output: any; }
};

/** Autogenerated input type of AcceptAgreement */
export type AcceptAgreementInput = {
  agreementId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of AcceptAgreement. */
export type AcceptAgreementPayload = {
  __typename?: 'AcceptAgreementPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Error>;
};

export type Address = {
  __typename?: 'Address';
  address1?: Maybe<Scalars['String']['output']>;
  address2?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Country>;
  countryIso?: Maybe<Scalars['String']['output']>;
  default?: Maybe<Scalars['Boolean']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  middleName?: Maybe<Scalars['String']['output']>;
  passportDateOfBirth?: Maybe<Scalars['String']['output']>;
  passportDateOfIssue?: Maybe<Scalars['String']['output']>;
  passportIssuer?: Maybe<Scalars['String']['output']>;
  passportNo?: Maybe<Scalars['String']['output']>;
  passportSerial?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  tin?: Maybe<Scalars['String']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

export type Agreement = {
  __typename?: 'Agreement';
  contentEn?: Maybe<Scalars['String']['output']>;
  contentRu?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isAccepted?: Maybe<Scalars['Boolean']['output']>;
  titleEn?: Maybe<Scalars['String']['output']>;
  titleRu?: Maybe<Scalars['String']['output']>;
};

export type AkebonoAlert = {
  __typename?: 'AkebonoAlert';
  activeUntil?: Maybe<Scalars['DateTime']['output']>;
  akebonoOfficeBranch?: Maybe<OfficeBranch>;
  akebonoOfficeBranchId?: Maybe<Scalars['ID']['output']>;
  alertGroup?: Maybe<AkebonoAlertGroup>;
  cartrackAuctionIds?: Maybe<Array<Scalars['ID']['output']>>;
  client?: Maybe<UserInfo>;
  contentEn?: Maybe<Scalars['String']['output']>;
  contentRu?: Maybe<Scalars['String']['output']>;
  countryIso?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  enabled?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isOnetime?: Maybe<Scalars['Boolean']['output']>;
  isSanctioned?: Maybe<Scalars['Boolean']['output']>;
  lotCodesFrom?: Maybe<Scalars['Int']['output']>;
  lotCodesTo?: Maybe<Scalars['Int']['output']>;
  style?: Maybe<AkebonoAlertStyleEnum>;
  target?: Maybe<AkebonoAlertTargetEnum>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type AkebonoAlertGroup = {
  __typename?: 'AkebonoAlertGroup';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export enum AkebonoAlertStyleEnum {
  Error = 'error',
  Info = 'info',
  Success = 'success',
  Warning = 'warning'
}

export enum AkebonoAlertTargetEnum {
  Bidding = 'bidding',
  LotPage = 'lot_page',
  LotPageOneprice = 'lot_page_oneprice',
  MainPage = 'main_page',
  Profile = 'profile'
}

export type AkebonoAuction = {
  __typename?: 'AkebonoAuction';
  akebonoAuctionDescription?: Maybe<AkebonoAuctionDescription>;
  aliasAkebonoAuctions?: Maybe<Array<AkebonoAuction>>;
  aliasOfAkebonoAuctionId?: Maybe<Scalars['ID']['output']>;
  auctionSheetZones?: Maybe<Array<AkebonoAuctionSheetZone>>;
  cartrackAuctionId?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type AkebonoAuctionDescription = {
  __typename?: 'AkebonoAuctionDescription';
  akebonoAuction?: Maybe<AkebonoAuction>;
  auctionSheetExample?: Maybe<S3File>;
  cartrackAuctionId?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  fax?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  negotiationFormTemplate?: Maybe<S3File>;
  negotiationMethod?: Maybe<AkebonoAuctionNegotiationMethodEnum>;
  negotiationTemplateConfig?: Maybe<Scalars['JSON']['output']>;
  tel?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  venueIdentifier?: Maybe<Scalars['String']['output']>;
};

export enum AkebonoAuctionNegotiationMethodEnum {
  Fax = 'fax',
  Tel = 'tel'
}

export type AkebonoAuctionSheetZone = {
  __typename?: 'AkebonoAuctionSheetZone';
  akebonoAuction?: Maybe<AkebonoAuction>;
  code?: Maybe<Scalars['String']['output']>;
  h?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  w?: Maybe<Scalars['Float']['output']>;
  x?: Maybe<Scalars['Float']['output']>;
  y?: Maybe<Scalars['Float']['output']>;
  zoneType?: Maybe<AuctionSheetZoneTypeEnum>;
};

export type AkebonoBid = {
  __typename?: 'AkebonoBid';
  akebonoBidComments?: Maybe<Array<AkebonoBidComment>>;
  akebonoBidCondition?: Maybe<AkebonoBidCondition>;
  akebonoBidGroup?: Maybe<AkebonoBidGroup>;
  akebonoBidLapseComment?: Maybe<AkebonoBidLapseComment>;
  akebonoLot?: Maybe<AkebonoLot>;
  amount?: Maybe<Scalars['Float']['output']>;
  autoBidEnabled?: Maybe<Scalars['Boolean']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  commission?: Maybe<Scalars['Float']['output']>;
  createTranslation?: Maybe<Scalars['Boolean']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  fullAmount?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isGreenCorner?: Maybe<Scalars['Boolean']['output']>;
  isNegotiationsAccepted?: Maybe<Scalars['Boolean']['output']>;
  isParticipationAccepted?: Maybe<Scalars['Boolean']['output']>;
  needPriorityPass?: Maybe<Scalars['Boolean']['output']>;
  notify?: Maybe<Scalars['Boolean']['output']>;
  objectGuid?: Maybe<Scalars['String']['output']>;
  prevSameUserAkebonoBidId?: Maybe<Scalars['ID']['output']>;
  prevUserId?: Maybe<Scalars['ID']['output']>;
  relatedAkebonoBids?: Maybe<Array<AkebonoBidInfo>>;
  status?: Maybe<AkebonoBidStatusEnum>;
  translationComplete?: Maybe<Scalars['Boolean']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  user?: Maybe<UserInfo>;
  versions?: Maybe<Array<AkebonoBidInfo>>;
  wasOverbid?: Maybe<Scalars['Boolean']['output']>;
  winAmount?: Maybe<Scalars['Float']['output']>;
  withLimitsOverride?: Maybe<Scalars['Boolean']['output']>;
  yokohamaStatus?: Maybe<AkebonoBidYokohamaStatusEnum>;
};

export type AkebonoBidAccessForm = {
  __typename?: 'AkebonoBidAccessForm';
  address?: Maybe<Address>;
  akebonoOfficeBranch?: Maybe<OfficeBranch>;
  akebonoOfficeBranchId?: Maybe<Scalars['ID']['output']>;
  companyReplyComment?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  middleName?: Maybe<Scalars['String']['output']>;
  skype?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  userComment?: Maybe<Scalars['String']['output']>;
};

export type AkebonoBidComment = {
  __typename?: 'AkebonoBidComment';
  content?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isManager?: Maybe<Scalars['Boolean']['output']>;
  isRead?: Maybe<Scalars['Boolean']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  user?: Maybe<UserInfo>;
};

export type AkebonoBidCondition = {
  __typename?: 'AkebonoBidCondition';
  calculatorType?: Maybe<AkebonoBidConditionCalculatorTypeEnum>;
  cartrackCarTypeId?: Maybe<Scalars['Int']['output']>;
  cartrackCustomTypeId?: Maybe<Scalars['Int']['output']>;
  cartrackSawTypeId?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  freightPaymentCountryIso?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isAuto?: Maybe<Scalars['Boolean']['output']>;
  isDisassembly?: Maybe<Scalars['Boolean']['output']>;
  isMoto?: Maybe<Scalars['Boolean']['output']>;
  isOnePrice?: Maybe<Scalars['Boolean']['output']>;
  minYear?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export enum AkebonoBidConditionCalculatorTypeEnum {
  Automobile = 'automobile',
  ElectroAuto = 'electro_auto',
  Hybrid = 'hybrid',
  MongoliaAuto = 'mongolia_auto',
  Motorcycle = 'motorcycle',
  Parts = 'parts',
  WorldWide = 'world_wide'
}

export type AkebonoBidDeleteRequestInfo = {
  __typename?: 'AkebonoBidDeleteRequestInfo';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  status?: Maybe<AkebonoUserRequestStatusEnum>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  user?: Maybe<UserInfo>;
};

export type AkebonoBidGroup = {
  __typename?: 'AkebonoBidGroup';
  bought: Scalars['Int']['output'];
  date: Scalars['DateTime']['output'];
  id?: Maybe<Scalars['ID']['output']>;
  isBought: Scalars['Boolean']['output'];
  letter: Scalars['String']['output'];
  quantity: Scalars['String']['output'];
};

export type AkebonoBidInfo = {
  __typename?: 'AkebonoBidInfo';
  akebonoBidCondition?: Maybe<AkebonoBidCondition>;
  akebonoBidDeleteRequests?: Maybe<Array<AkebonoBidDeleteRequestInfo>>;
  akebonoBidGroup?: Maybe<AkebonoBidGroup>;
  akebonoLot?: Maybe<AkebonoLot>;
  amount?: Maybe<Scalars['Float']['output']>;
  autoBidEnabled?: Maybe<Scalars['Boolean']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  createTranslation?: Maybe<Scalars['Boolean']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isNegotiationsAccepted?: Maybe<Scalars['Boolean']['output']>;
  isParticipationAccepted?: Maybe<Scalars['Boolean']['output']>;
  notify?: Maybe<Scalars['Boolean']['output']>;
  status?: Maybe<AkebonoBidStatusEnum>;
  translationComplete?: Maybe<Scalars['Boolean']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  user?: Maybe<UserInfo>;
  version?: Maybe<TrailEvent>;
  winAmount?: Maybe<Scalars['Float']['output']>;
  yokohamaStatus?: Maybe<AkebonoBidYokohamaStatusEnum>;
};

export type AkebonoBidLapseComment = {
  __typename?: 'AkebonoBidLapseComment';
  akebonoBid?: Maybe<AkebonoBidInfo>;
  comment?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type AkebonoBidPaginatedList = {
  __typename?: 'AkebonoBidPaginatedList';
  nodes: Array<AkebonoBid>;
  total: Scalars['Int']['output'];
};

export enum AkebonoBidStatusEnum {
  Canceled = 'canceled',
  Confirmed = 'confirmed',
  Initial = 'initial',
  Won = 'won'
}

export enum AkebonoBidYokohamaStatusEnum {
  Approved = 'approved',
  Created = 'created',
  Dreamer = 'dreamer',
  Finished = 'finished',
  Translate = 'translate',
  Undefined = 'undefined'
}

export type AkebonoCurrentUserAkebonoBidsFilter = {
  createdAt?: InputMaybe<CreatedAtFilter>;
};

export type AkebonoCurrentUserAkebonoBidsSort = {
  createdAt?: InputMaybe<CreatedAtSort>;
  updatedAt?: InputMaybe<UpdatedAtSort>;
};

export type AkebonoLot = {
  __typename?: 'AkebonoLot';
  akebonoAuction?: Maybe<AkebonoAuction>;
  akebonoBiddersCount?: Maybe<Scalars['Int']['output']>;
  akebonoBidsCount?: Maybe<Scalars['Int']['output']>;
  akebonoLotTranslationsCount?: Maybe<Scalars['Int']['output']>;
  auctionDate?: Maybe<Scalars['DateTime']['output']>;
  auctionDatetime?: Maybe<Scalars['DateTime']['output']>;
  auctionName?: Maybe<Scalars['String']['output']>;
  auctionSystem?: Maybe<Scalars['String']['output']>;
  canRecognizeAuctionList?: Maybe<Scalars['Boolean']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  colorEn?: Maybe<Scalars['String']['output']>;
  companyEn?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  currentTranslator?: Maybe<UserInfo>;
  currentUserAkebonoAuctionTotalTradeAmount?: Maybe<Scalars['Int']['output']>;
  currentUserAkebonoAuctionTotalTradeLimit?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  engineVolumeNum?: Maybe<Scalars['Int']['output']>;
  equipmentEn?: Maybe<Scalars['String']['output']>;
  hasAkebonoBids?: Maybe<Scalars['Boolean']['output']>;
  icons?: Maybe<Array<AkebonoLotIconEnum>>;
  id?: Maybe<Scalars['LotID']['output']>;
  images?: Maybe<Array<Scalars['String']['output']>>;
  jupiterLotData?: Maybe<Scalars['JSON']['output']>;
  link?: Maybe<Scalars['String']['output']>;
  mileageNum?: Maybe<Scalars['Int']['output']>;
  modelDetailsEn?: Maybe<Scalars['String']['output']>;
  modelEn?: Maybe<Scalars['String']['output']>;
  modelGradeEn?: Maybe<Scalars['String']['output']>;
  modelTypeEn?: Maybe<Scalars['String']['output']>;
  modelYearEn?: Maybe<Scalars['String']['output']>;
  negotiationsPrice?: Maybe<Scalars['BigInt']['output']>;
  originalId?: Maybe<Scalars['String']['output']>;
  productionDate?: Maybe<Scalars['DateTime']['output']>;
  provider?: Maybe<AkebonoLotSource>;
  ratingEn?: Maybe<Scalars['String']['output']>;
  realNegotiationsPrice?: Maybe<Scalars['BigInt']['output']>;
  refusalNotificationSent?: Maybe<Scalars['Boolean']['output']>;
  soldPrice?: Maybe<Scalars['BigInt']['output']>;
  startPriceNum?: Maybe<Scalars['BigInt']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  subgroup?: Maybe<AkebonoLotTypeEnum>;
  thumbnails?: Maybe<Array<Scalars['String']['output']>>;
  title?: Maybe<Scalars['String']['output']>;
  translations?: Maybe<Array<AkebonoLotTranslation>>;
  transmissionEn?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  versions?: Maybe<Array<TrailEvent>>;
  vin?: Maybe<Scalars['String']['output']>;
  year?: Maybe<Scalars['Int']['output']>;
};


export type AkebonoLotHasAkebonoBidsArgs = {
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export enum AkebonoLotIconEnum {
  Check = 'check',
  Damaged = 'damaged',
  DashboardLampOn = 'dashboard_lamp_on',
  Diesel = 'diesel',
  Drowned = 'drowned',
  EquippedForDisabled = 'equipped_for_disabled',
  Forklift = 'forklift',
  LeftDrive = 'left_drive',
  NaturalGas = 'natural_gas',
  Repainted = 'repainted',
  Sanctions = 'sanctions',
  SecondKeys = 'second_keys'
}

export enum AkebonoLotSource {
  Aleado = 'aleado',
  Aucnet = 'aucnet',
  Manual = 'manual',
  Mikhail = 'mikhail',
  Mochiron = 'mochiron'
}

export type AkebonoLotTranslation = {
  __typename?: 'AkebonoLotTranslation';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  file?: Maybe<S3File>;
  id?: Maybe<Scalars['ID']['output']>;
  language?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export enum AkebonoLotTypeEnum {
  Auto = 'auto',
  Moto = 'moto',
  Oneprice = 'oneprice',
  Truck = 'truck'
}

export type AkebonoMainAkebonoRuLotFilter = {
  akebonoRuLotStatus?: InputMaybe<AkebonoRuLotStatusFilter>;
};

export type AkebonoMainAkebonoRuLotSort = {
  createdAt?: InputMaybe<CreatedAtSort>;
  updatedAt?: InputMaybe<UpdatedAtSort>;
};

export type AkebonoPlan = {
  __typename?: 'AkebonoPlan';
  id?: Maybe<Scalars['ID']['output']>;
  title?: Maybe<Scalars['SecureString']['output']>;
};

export type AkebonoRuBid = {
  __typename?: 'AkebonoRuBid';
  akebonoRuLotId?: Maybe<Scalars['ID']['output']>;
  amount: Scalars['Int']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  status?: Maybe<AkebonoRuBidStatusEnum>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  userId?: Maybe<Scalars['ID']['output']>;
};

/** Autogenerated input type of AkebonoRuBidCreate */
export type AkebonoRuBidCreateInput = {
  akebonoRuLotId: Scalars['ID']['input'];
  amount: Scalars['Int']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of AkebonoRuBidCreate. */
export type AkebonoRuBidCreatePayload = {
  __typename?: 'AkebonoRuBidCreatePayload';
  akebonoRuLot?: Maybe<AkebonoRuLot>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Error>;
};

export enum AkebonoRuBidStatusEnum {
  Canceled = 'canceled',
  Overbid = 'overbid',
  Placed = 'placed',
  Won = 'won'
}

export type AkebonoRuLot = {
  __typename?: 'AkebonoRuLot';
  akebonoLot?: Maybe<AkebonoLot>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  currentPrice: Scalars['Int']['output'];
  currentUserAkebonoRuBid?: Maybe<AkebonoRuBid>;
  description?: Maybe<Scalars['String']['output']>;
  finishAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  images?: Maybe<Array<Scalars['String']['output']>>;
  initialPrice: Scalars['Int']['output'];
  startAt?: Maybe<Scalars['DateTime']['output']>;
  status?: Maybe<AkebonoRuLotStatusEnum>;
  totalBidsCount?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  vin?: Maybe<Scalars['String']['output']>;
};

export type AkebonoRuLotPaginatedList = {
  __typename?: 'AkebonoRuLotPaginatedList';
  nodes: Array<AkebonoRuLot>;
  total: Scalars['Int']['output'];
};

export enum AkebonoRuLotStatusEnum {
  Active = 'active',
  Canceled = 'canceled',
  Finished = 'finished',
  Initial = 'initial',
  Sold = 'sold'
}

export type AkebonoRuLotStatusFilter = {
  eq: Array<AkebonoRuLotStatusEnum>;
};

export enum AkebonoUserRequestStatusEnum {
  Deleted = 'deleted',
  Initial = 'initial',
  Processed = 'processed',
  Rejected = 'rejected'
}

export enum AuctionSheetZoneTypeEnum {
  TranslatableInfo = 'translatable_info',
  Vin = 'vin'
}

export type Country = {
  __typename?: 'Country';
  alpha2: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type CreatedAtFilter = {
  eq?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  neq?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CreatedAtSort = {
  direction?: InputMaybe<Scalars['String']['input']>;
};

export enum CurrencyEnum {
  /** Euro */
  Eur = 'EUR',
  /** Japan Yen */
  Jpy = 'JPY',
  /** Russian Ruble */
  Rub = 'RUB',
  /** US Dollar */
  Usd = 'USD'
}

export type CurrencyInfo = {
  __typename?: 'CurrencyInfo';
  buy?: Maybe<Scalars['Float']['output']>;
  centralBank?: Maybe<Scalars['Float']['output']>;
  currency: CurrencyEnum;
  date: Scalars['DateTime']['output'];
  nominal: Scalars['Float']['output'];
  sell?: Maybe<Scalars['Float']['output']>;
};

export type CurrentUser = {
  __typename?: 'CurrentUser';
  acceptedAgreements?: Maybe<Array<Agreement>>;
  addresses?: Maybe<Array<Address>>;
  akebonoBidAccessForms?: Maybe<Array<AkebonoBidAccessForm>>;
  akebonoBids?: Maybe<AkebonoBidPaginatedList>;
  akebonoOfficeBranch?: Maybe<OfficeBranch>;
  akebonoOfficeBranchId?: Maybe<Scalars['ID']['output']>;
  akebonoPlan?: Maybe<AkebonoPlan>;
  akebonoRuLot?: Maybe<AkebonoRuLot>;
  akebonoRuLots?: Maybe<AkebonoRuLotPaginatedList>;
  bestBidViewServiceSubscription?: Maybe<ServiceSubscription>;
  birthdate?: Maybe<Scalars['String']['output']>;
  birthdayToday?: Maybe<Scalars['Boolean']['output']>;
  countryIso?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  emailVerified: Scalars['Boolean']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  guid?: Maybe<Scalars['ID']['output']>;
  hasActiveAkebonoBidsAccessForm?: Maybe<Scalars['Boolean']['output']>;
  hasActiveAkebonoGuidanceRequest?: Maybe<Scalars['Boolean']['output']>;
  hasNotAcceptedAgreements?: Maybe<Scalars['Boolean']['output']>;
  hasPermission?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isEnoughFundsToSubscribeToViewTheBestBid?: Maybe<Scalars['Boolean']['output']>;
  isTrusted?: Maybe<Scalars['Boolean']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  middleName?: Maybe<Scalars['String']['output']>;
  notAcceptedAgreements?: Maybe<Array<Agreement>>;
  permissions?: Maybe<Array<Permission>>;
  personalAccountStartingPage?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  phoneVerified: Scalars['Boolean']['output'];
  preferredLang?: Maybe<Scalars['String']['output']>;
  sharedInventoryContactForms?: Maybe<SharedInventoryContactFormPaginatedList>;
  skype?: Maybe<Scalars['String']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};


export type CurrentUserAkebonoBidsArgs = {
  filter?: InputMaybe<AkebonoCurrentUserAkebonoBidsFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<AkebonoCurrentUserAkebonoBidsSort>;
};


export type CurrentUserAkebonoRuLotArgs = {
  id: Scalars['ID']['input'];
};


export type CurrentUserAkebonoRuLotsArgs = {
  filter?: InputMaybe<AkebonoMainAkebonoRuLotFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<AkebonoMainAkebonoRuLotSort>;
};


export type CurrentUserHasPermissionArgs = {
  code: Scalars['String']['input'];
};


export type CurrentUserSharedInventoryContactFormsArgs = {
  filter?: InputMaybe<SharedInventoryContactFormFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SharedInventoryContactFormSort>;
};

export type Error = {
  __typename?: 'Error';
  backtrace?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export type ExchangeRate = {
  __typename?: 'ExchangeRate';
  date: Scalars['DateTime']['output'];
  nominal: Scalars['Float']['output'];
  value: Scalars['Float']['output'];
};

/** Autogenerated input type of GuidanceRequestCreate */
export type GuidanceRequestCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['SecureString']['input']>;
};

/** Autogenerated return type of GuidanceRequestCreate. */
export type GuidanceRequestCreatePayload = {
  __typename?: 'GuidanceRequestCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Error>;
};

/** Autogenerated input type of LeadCreate */
export type LeadCreateInput = {
  branchId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of LeadCreate. */
export type LeadCreatePayload = {
  __typename?: 'LeadCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Error>;
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptAgreement?: Maybe<AcceptAgreementPayload>;
  akebonoRuBidCreate?: Maybe<AkebonoRuBidCreatePayload>;
  guidanceRequestCreate?: Maybe<GuidanceRequestCreatePayload>;
  leadCreate?: Maybe<LeadCreatePayload>;
  plugViewBestBidSubscriptionOnAdvanceUpdateRequestCreate?: Maybe<PlugViewBestBidSubscriptionOnAdvanceUpdateRequestCreatePayload>;
  sharedInventoryContactFormAgreeToPrecalc?: Maybe<SharedInventoryContactFormAgreeToPrecalcPayload>;
  sharedInventoryContactFormCreate?: Maybe<SharedInventoryContactFormCreatePayload>;
  sharedInventoryContactFormDisagreeToPrecalc?: Maybe<SharedInventoryContactFormDisagreeToPrecalcPayload>;
  userViewBestBidSubscribe?: Maybe<UserViewBestBidSubscribePayload>;
};


export type MutationAcceptAgreementArgs = {
  input: AcceptAgreementInput;
};


export type MutationAkebonoRuBidCreateArgs = {
  input: AkebonoRuBidCreateInput;
};


export type MutationGuidanceRequestCreateArgs = {
  input: GuidanceRequestCreateInput;
};


export type MutationLeadCreateArgs = {
  input: LeadCreateInput;
};


export type MutationPlugViewBestBidSubscriptionOnAdvanceUpdateRequestCreateArgs = {
  input: PlugViewBestBidSubscriptionOnAdvanceUpdateRequestCreateInput;
};


export type MutationSharedInventoryContactFormAgreeToPrecalcArgs = {
  input: SharedInventoryContactFormAgreeToPrecalcInput;
};


export type MutationSharedInventoryContactFormCreateArgs = {
  input: SharedInventoryContactFormCreateInput;
};


export type MutationSharedInventoryContactFormDisagreeToPrecalcArgs = {
  input: SharedInventoryContactFormDisagreeToPrecalcInput;
};


export type MutationUserViewBestBidSubscribeArgs = {
  input: UserViewBestBidSubscribeInput;
};

export type OfficeBranch = {
  __typename?: 'OfficeBranch';
  code?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isWorldwide?: Maybe<Scalars['Boolean']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ParentUserInfo = {
  __typename?: 'ParentUserInfo';
  akebonoOfficeBranch?: Maybe<OfficeBranch>;
  akebonoOfficeBranchId?: Maybe<Scalars['ID']['output']>;
  akebonoPlan?: Maybe<AkebonoPlan>;
  fullName?: Maybe<Scalars['String']['output']>;
  guid?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type Permission = {
  __typename?: 'Permission';
  code?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Int']['output']>;
};

/** Autogenerated input type of PlugViewBestBidSubscriptionOnAdvanceUpdateRequestCreate */
export type PlugViewBestBidSubscriptionOnAdvanceUpdateRequestCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['SecureString']['input']>;
};

/** Autogenerated return type of PlugViewBestBidSubscriptionOnAdvanceUpdateRequestCreate. */
export type PlugViewBestBidSubscriptionOnAdvanceUpdateRequestCreatePayload = {
  __typename?: 'PlugViewBestBidSubscriptionOnAdvanceUpdateRequestCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Error>;
};

export type Query = {
  __typename?: 'Query';
  agreement?: Maybe<Agreement>;
  akebonoLot?: Maybe<AkebonoLot>;
  akebonoRuLot: AkebonoRuLot;
  akebonoRuLots?: Maybe<AkebonoRuLotPaginatedList>;
  alerts?: Maybe<Array<AkebonoAlert>>;
  altecarExchangeRate?: Maybe<ExchangeRate>;
  atbExchangeRate?: Maybe<CurrencyInfo>;
  countries: Array<Country>;
  currentUser?: Maybe<CurrentUser>;
  exchangeRate: ExchangeRate;
  khanbankExchangeRate?: Maybe<CurrencyInfo>;
  maybankExchangeRate: CurrencyInfo;
  officeBranches?: Maybe<Array<OfficeBranch>>;
  primbankExchangeRate?: Maybe<CurrencyInfo>;
  pskbExchangeRate?: Maybe<CurrencyInfo>;
  stripeExchangeRate?: Maybe<ExchangeRate>;
};


export type QueryAgreementArgs = {
  id: Scalars['ID']['input'];
};


export type QueryAkebonoLotArgs = {
  id: Scalars['ID']['input'];
};


export type QueryAkebonoRuLotArgs = {
  id: Scalars['ID']['input'];
};


export type QueryAkebonoRuLotsArgs = {
  filter?: InputMaybe<AkebonoMainAkebonoRuLotFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<AkebonoMainAkebonoRuLotSort>;
};


export type QueryAlertsArgs = {
  auctionId?: InputMaybe<Scalars['ID']['input']>;
  target?: InputMaybe<AkebonoAlertTargetEnum>;
};


export type QueryAltecarExchangeRateArgs = {
  date?: InputMaybe<Scalars['DateTime']['input']>;
  iso?: InputMaybe<CurrencyEnum>;
};


export type QueryAtbExchangeRateArgs = {
  iso: CurrencyEnum;
};


export type QueryExchangeRateArgs = {
  date?: InputMaybe<Scalars['DateTime']['input']>;
  iso: CurrencyEnum;
};


export type QueryKhanbankExchangeRateArgs = {
  iso: CurrencyEnum;
};


export type QueryMaybankExchangeRateArgs = {
  iso: CurrencyEnum;
};


export type QueryPrimbankExchangeRateArgs = {
  iso: CurrencyEnum;
};


export type QueryPskbExchangeRateArgs = {
  iso: CurrencyEnum;
};


export type QueryStripeExchangeRateArgs = {
  date?: InputMaybe<Scalars['DateTime']['input']>;
  iso?: InputMaybe<CurrencyEnum>;
};

export type S3File = {
  __typename?: 'S3File';
  filename?: Maybe<Scalars['String']['output']>;
  fullPath?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ServiceSubscription = {
  __typename?: 'ServiceSubscription';
  expiresAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  serviceId?: Maybe<Scalars['ID']['output']>;
  startsAt?: Maybe<Scalars['DateTime']['output']>;
  userId?: Maybe<Scalars['ID']['output']>;
};

export type SharedInventoryContactForm = {
  __typename?: 'SharedInventoryContactForm';
  akebonoLot?: Maybe<AkebonoLot>;
  amount?: Maybe<Scalars['BigInt']['output']>;
  carsPerMonth?: Maybe<Scalars['Int']['output']>;
  comments?: Maybe<Scalars['String']['output']>;
  company?: Maybe<Scalars['String']['output']>;
  countryIso?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  deliveryPort?: Maybe<Scalars['String']['output']>;
  departmentCode?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  freightPaymentType?: Maybe<Scalars['String']['output']>;
  hasTelegram?: Maybe<Scalars['Boolean']['output']>;
  hasViber?: Maybe<Scalars['Boolean']['output']>;
  hasWhatsapp?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  middleName?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  shippingType?: Maybe<Scalars['String']['output']>;
  status?: Maybe<SharedInventoryContactFormStatusEnum>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  userId?: Maybe<Scalars['ID']['output']>;
};

/** Autogenerated input type of SharedInventoryContactFormAgreeToPrecalc */
export type SharedInventoryContactFormAgreeToPrecalcInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of SharedInventoryContactFormAgreeToPrecalc. */
export type SharedInventoryContactFormAgreeToPrecalcPayload = {
  __typename?: 'SharedInventoryContactFormAgreeToPrecalcPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Error>;
};

/** Autogenerated input type of SharedInventoryContactFormCreate */
export type SharedInventoryContactFormCreateInput = {
  amount: Scalars['BigInt']['input'];
  carsPerMonth?: InputMaybe<Scalars['Int']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  comments?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<Scalars['String']['input']>;
  countryIso?: InputMaybe<Scalars['String']['input']>;
  currency: Scalars['String']['input'];
  deliveryPort?: InputMaybe<Scalars['String']['input']>;
  departmentCode?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  freightPaymentType?: InputMaybe<Scalars['String']['input']>;
  hasTelegram: Scalars['Boolean']['input'];
  hasViber: Scalars['Boolean']['input'];
  hasWhatsapp: Scalars['Boolean']['input'];
  lastName: Scalars['String']['input'];
  lotId: Scalars['LotID']['input'];
  lotSource: AkebonoLotSource;
  lotType: AkebonoLotTypeEnum;
  middleName?: InputMaybe<Scalars['String']['input']>;
  objectGuid?: InputMaybe<Scalars['String']['input']>;
  phoneNumber: Scalars['String']['input'];
  shippingType?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of SharedInventoryContactFormCreate. */
export type SharedInventoryContactFormCreatePayload = {
  __typename?: 'SharedInventoryContactFormCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Error>;
};

export type SharedInventoryContactFormDepartmentCodeFilter = {
  eq?: InputMaybe<Array<Scalars['String']['input']>>;
  neq?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** Autogenerated input type of SharedInventoryContactFormDisagreeToPrecalc */
export type SharedInventoryContactFormDisagreeToPrecalcInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of SharedInventoryContactFormDisagreeToPrecalc. */
export type SharedInventoryContactFormDisagreeToPrecalcPayload = {
  __typename?: 'SharedInventoryContactFormDisagreeToPrecalcPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Error>;
};

export type SharedInventoryContactFormFilter = {
  sharedInventoryContactFormDepartmentCode?: InputMaybe<SharedInventoryContactFormDepartmentCodeFilter>;
  sharedInventoryContactFormStatus?: InputMaybe<SharedInventoryContactFormStatusFilter>;
};

export type SharedInventoryContactFormPaginatedList = {
  __typename?: 'SharedInventoryContactFormPaginatedList';
  nodes: Array<SharedInventoryContactForm>;
  total: Scalars['Int']['output'];
};

export type SharedInventoryContactFormSort = {
  createdAt?: InputMaybe<CreatedAtSort>;
  updatedAt?: InputMaybe<UpdatedAtSort>;
};

export enum SharedInventoryContactFormStatusEnum {
  Canceled = 'canceled',
  ClientAgreed = 'client_agreed',
  ClientBought = 'client_bought',
  ClientInvoiced = 'client_invoiced',
  ClientNotified = 'client_notified',
  ClientPrepaid = 'client_prepaid',
  InWork = 'in_work',
  Initial = 'initial'
}

export type SharedInventoryContactFormStatusFilter = {
  eq?: InputMaybe<Array<SharedInventoryContactFormStatusEnum>>;
  neq?: InputMaybe<Array<SharedInventoryContactFormStatusEnum>>;
};

export type TrailEvent = {
  __typename?: 'TrailEvent';
  author?: Maybe<TrailEventAuthor>;
  changeset?: Maybe<Scalars['JSON']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  versionNumber?: Maybe<Scalars['Int']['output']>;
};

export type TrailEventAuthor = {
  __typename?: 'TrailEventAuthor';
  countryIso?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type UpdatedAtSort = {
  direction?: InputMaybe<Scalars['String']['input']>;
};

export type UserInfo = {
  __typename?: 'UserInfo';
  akebonoOfficeBranch?: Maybe<OfficeBranch>;
  akebonoOfficeBranchId?: Maybe<Scalars['ID']['output']>;
  akebonoPlan?: Maybe<AkebonoPlan>;
  countryIso?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  guid?: Maybe<Scalars['ID']['output']>;
  hasPassport?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  instagram?: Maybe<Scalars['String']['output']>;
  isOtherCountries?: Maybe<Scalars['Boolean']['output']>;
  parentUser?: Maybe<ParentUserInfo>;
  permanentParentUser?: Maybe<ParentUserInfo>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  username?: Maybe<Scalars['String']['output']>;
  version?: Maybe<TrailEvent>;
  website?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of UserViewBestBidSubscribe */
export type UserViewBestBidSubscribeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  plugWhenAdvanceUpdate: Scalars['Boolean']['input'];
};

/** Autogenerated return type of UserViewBestBidSubscribe. */
export type UserViewBestBidSubscribePayload = {
  __typename?: 'UserViewBestBidSubscribePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Error>;
};

export type SharedInventoryContactFormCreateMutationVariables = Exact<{
  input: SharedInventoryContactFormCreateInput;
}>;


export type SharedInventoryContactFormCreateMutation = { __typename?: 'Mutation', sharedInventoryContactFormCreate?: { __typename?: 'SharedInventoryContactFormCreatePayload', error?: { __typename?: 'Error', code?: string | null, message?: string | null } | null } | null };

export type CurrentUserServiceDataQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserServiceDataQuery = { __typename?: 'Query', currentUser?: { __typename?: 'CurrentUser', id?: string | null, username?: string | null, firstName?: string | null, lastName?: string | null, phoneNumber?: string | null, email?: string | null, countryIso?: string | null, permissions?: Array<{ __typename?: 'Permission', id?: string | null, code?: string | null }> | null } | null };

export const SharedInventoryContactFormCreateDocument = gql`
    mutation SharedInventoryContactFormCreate($input: SharedInventoryContactFormCreateInput!) {
  sharedInventoryContactFormCreate(input: $input) {
    error {
      code
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SharedInventoryContactFormCreateGQL extends Apollo.Mutation<SharedInventoryContactFormCreateMutation, SharedInventoryContactFormCreateMutationVariables> {
    override document = SharedInventoryContactFormCreateDocument;
    override client = 'akebono/main';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CurrentUserServiceDataDocument = gql`
    query currentUserServiceData {
  currentUser {
    id
    username
    firstName
    lastName
    phoneNumber
    email
    countryIso
    permissions {
      id
      code
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CurrentUserServiceDataGQL extends Apollo.Query<CurrentUserServiceDataQuery, CurrentUserServiceDataQueryVariables> {
    override document = CurrentUserServiceDataDocument;
    override client = 'akebono/main';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    
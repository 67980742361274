@let newsItem = newsItem$ | push;

<nz-spin [nzSpinning]="loading$ | push" [nzSize]="'large'">
  <nz-breadcrumb [nzSeparator]="iconTemplate">
    <nz-breadcrumb-item>
      <a routerLink="/news">{{ 'NEWS_ITEM_PAGE.NEWS' | translate }}</a>
    </nz-breadcrumb-item>
    @if (newsItem) {
      <nz-breadcrumb-item>
        {{ newsItem | newsContent: 'title' }}
      </nz-breadcrumb-item>
    }
  </nz-breadcrumb>
  <ng-template #iconTemplate><i nz-icon nzType="icons:vector"></i></ng-template>

  <div class="content">
    <span class="published-date">{{ newsItem?.publishedAt | date: 'dd.MM.yyyy' }}</span>
    <h1>{{ newsItem | newsContent: 'title' }}</h1>
    <div [innerHTML]="newsItem | newsContent: 'content' "></div>
  </div>
</nz-spin>

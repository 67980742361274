import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzInputModule } from 'ng-zorro-antd/input';
import { PhoneInputComponent } from './phone-input.component';
import { NzFormControlComponent, NzFormItemComponent } from 'ng-zorro-antd/form';

@NgModule({
  declarations: [PhoneInputComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NzSelectModule,
    NzInputModule,
    NzFormItemComponent,
    NzFormControlComponent,
  ],
  exports: [PhoneInputComponent]
})
export class PhoneInputModule {}

import { inject, Injectable } from '@angular/core';

import { en } from '../locale/en';
import { ru } from '../locale/ru';
import { ApolloInitializerService } from './apollo-initializer.service';
import { LanguageService } from './language.service';
import { register as registerSwiper } from 'swiper/element/bundle';

@Injectable()
export class AppInitializerService {
  private readonly languageService = inject(LanguageService);
  private readonly apolloInitializer = inject(ApolloInitializerService);

  public initialize(): void {
    this.languageService.addLocales({ ru, en });
    this.apolloInitializer.initialize();
    registerSwiper();
  }
}

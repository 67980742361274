import { ApolloQueryResult } from '@apollo/client/core';
import { Observable } from 'rxjs';
import { catchError, filter, map, tap } from 'rxjs/operators';

import { NotificationService } from '../../notification.service';
import { ApolloQueryException } from '../exceptions/apollo-query.exception';
import { BaseQueryHandlingException } from '../exceptions/base-query-handling.exception';
import { GraphqlQueryException } from '../exceptions/graphql-query.exception';

export abstract class BaseQueryRef<T> {
  protected loading$!: Observable<boolean>;
  protected data$!: Observable<T>;

  constructor(
    protected queryObservable: Observable<ApolloQueryResult<T>>,
    private notificationService?: NotificationService,
  ) {}

  public get loading(): Observable<boolean> {
    return this.loading$;
  }

  public get data(): Observable<T> {
    return this.data$;
  }

  protected validateResult = (result: ApolloQueryResult<T>) => {
    if (result.error) {
      throw new ApolloQueryException(result.error);
    }

    if (result.errors) {
      throw new GraphqlQueryException(result.errors);
    }
  };

  protected logError = (error: BaseQueryHandlingException | Error): never => {
    if (error instanceof BaseQueryHandlingException) {
      this.notificationService?.error('Error', error.message);
      error.log();
    } else {
      this.notificationService?.error('Error', error);
      console.error(error);
    }

    throw error;
  };

  protected compileDataObservable(): Observable<T> {
    return this.queryObservable.pipe(
      filter((result) => !result.loading),
      tap((result) => console.log('Query result', result)),
      tap(this.validateResult),
      map((result) => result.data),
      catchError(this.logError),
    );
  }

  protected compileLoadingObservable(): Observable<boolean> {
    return this.queryObservable.pipe(map((result) => result.loading));
  }
}

import {ChangeDetectionStrategy, Component} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {NewsPageFilterBuilderService} from "../../services/news-page-filter-builder.service";
import {NewsPageFilterForm} from "./types/news-page-filter.types";

@Component({
  selector: 'app-news-page-filter',
  templateUrl: './news-page-filter.component.html',
  styleUrl: './news-page-filter.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [NewsPageFilterBuilderService],
})
export class NewsPageFilterComponent {
  form: FormGroup<NewsPageFilterForm>;

  constructor(
    fb: FormBuilder,
    private router: Router,
    route: ActivatedRoute,
    private filterBuilder: NewsPageFilterBuilderService
  ) {
    this.form = fb.nonNullable.group<NewsPageFilterForm>({
      dateFrom: fb.control(null, Validators.required),
      dateTo: fb.control(null, Validators.required),
    });

    route.queryParams
      .pipe(takeUntilDestroyed())
      .subscribe((params) => this.filterBuilder.patchFilterForm(this.form, params));
  }

  apply(): void {
    if (this.form.invalid) {
      return;
    }

    this.router.navigate([], {
      queryParams: this.filterBuilder.getParams(this.form.value),
      queryParamsHandling: 'merge',
    });
  }

  reset(): void {
    this.router.navigate([]);
    this.form.reset();
    this.apply();
  }
}

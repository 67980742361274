<nz-spin [nzSpinning]="loading$ | push" nzSize="large">
  <h2>{{ 'LOT.SIMILAR_ADS.TITLE' | translate }}</h2>
  <div class="lots">
    <nz-row [nzGutter]="[20, 20]">
      @for (lot of similarLots$ | async; track lot.id) {
      <nz-col [nzXs]="28" [nzSm]="12" [nzMd]="12" [nzLg]="8" [nzXl]="6" [nzXXl]="6">
        <app-lot-card [lot]="lot"></app-lot-card>
      </nz-col>
      } @empty {
      <div [style.margin]="'0 auto'">
        <nz-empty nzNotFoundImage="/assets/img/empty.svg" [nzNotFoundContent]="contentTpl">
          <ng-template #contentTpl>
            <span>
              {{ 'LOT.SIMILAR_ADS.EMPTY' | translate }}
            </span>
          </ng-template>
        </nz-empty>
      </div>
      }
    </nz-row>
  </div>
</nz-spin>

export const ru = {
  "GLOBALS": {
    "MUTATION_SUCCEEDED": "Успешно!",
    "LINK_COPIED": "Ссылка скопирована!",
    "ACTIONS": {
      "OK": "Ок",
      "CREATE": "Создать",
      "REMOVE": "Удалить",
      "SAVE": "Сохранить",
      "SELECT": "Выбрать",
      "SELECTED": "Выбрано",
      "SEND": "Отправить",
      "CANCEL": "Отменить",
      "ROLL_UP": "Свернуть",
      "SEARCH": "Поиск",
      "APPLY": "Применить",
      "RESET": "Сбросить",
      "UPDATE": "Обновить",
      "CONFIRM": "Подтвердить",
      "BACK": "Назад",
      "NEXT": "Далее",
      "OPEN": "Открыть"
    }
  }
};

@let categoriesTree = categoriesTree$ | push;

<nz-drawer
  [nzClosable]="true"
  [nzVisible]="drawerCategoriesVisible"
  [nzPlacement]="'left'"
  (nzOnClose)="onCloseCategoriesDrawer()"
>
  <ng-container *nzDrawerContent>
    <ng-container *ngTemplateOutlet="categoriesMenu"></ng-container>
  </ng-container>
</nz-drawer>

<ng-template #categoriesMenu>
  <nz-spin [nzSpinning]="categoriesTreeLoading$ | push">
    @if (categoriesTree) {
      <ul class="sidebar" nz-menu nzMode="inline" [nzInlineCollapsed]="false" [nzTheme]="'light'">
        <ng-template #recursiveCategoriesList let-list>
          @for (item of list; track item) {
            <li
              nz-submenu
              nzOpen
              [nzTitle]="categoryTitle"
              [nzPaddingLeft]="item.menuLevel * categoriesMenuPaddingLeft"
              [nzDisabled]="!(item.childCategories.length || item.category.articles?.length)"
            >
              <ng-template #categoryTitle>
                <span nz-typography [nzType]="'warning'">{{ item.category.title?.[lang] }}</span>
              </ng-template>

              @if (item.childCategories.length) {
                <ul>
                  <ng-container
                    *ngTemplateOutlet="recursiveCategoriesList; context: { $implicit: item.childCategories }"></ng-container>
                </ul>
              }

              @if (item.category.articles?.length) {
                <ul>
                  @for (article of item.category.articles; track article) {
                    <li
                      nz-menu-item
                      [nzPaddingLeft]="(item.menuLevel + 1) * categoriesMenuPaddingLeft"
                      [routerLink]="['/information/', countryInformationType, article.page]"
                      [nzMatchRouter]="true"
                      (click)="onCloseCategoriesDrawer()"
                    >
                      <span>{{ article?.title?.[lang] }}</span>
                    </li>
                  }
                </ul>
              }
            </li>
          }
        </ng-template>
        <ng-container
          *ngTemplateOutlet="recursiveCategoriesList; context: { $implicit: categoriesTree }"></ng-container>
      </ul>
    }
  </nz-spin>
</ng-template>

@if (hasArticle) {
<div class="open-category-menu-btn">
  <button nz-button (click)="onClickCategoriesVisible()">
    {{ 'INFORMATION_PAGE.ARTICLES_LIST' | translate }}
  </button>
</div>
} @else {
  <div class="categories-menu-mob">
    <ng-container *ngTemplateOutlet="categoriesMenu"></ng-container>
  </div>
}

<div class="categories-menu">
  <ng-container *ngTemplateOutlet="categoriesMenu"></ng-container>
</div>

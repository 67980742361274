import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';
import { Language } from '../../../../utilities/types/language.type';
import { LanguageService } from '../../../../services/language.service';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrl: './language-selector.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LanguageSelectorComponent {
  currentLanguage$: Observable<Language>;
  availableLanguages: readonly Language[] = [];
  dropdownOpen = false;

  constructor(private languageService: LanguageService) {
    this.currentLanguage$ = this.languageService.currentLanguage$;
    this.availableLanguages = this.languageService.availableLanguages;
  }

  changeLanguage(language: Language): void {
    this.languageService.setLanguage(language);
    this.dropdownOpen = false;
  }
}

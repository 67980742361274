import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import {
  SharedInventoryCarSortInput,
  SortEnumType,
} from 'src/app/graphql/aucnet-main-graphql.service';
import { SharedInventoryMotoSortInput } from 'src/app/graphql/aucnet-moto-graphql.service';
import { StockCarSortInput } from 'src/app/graphql/mochiron-graphql.service';
import {
  LotSource,
  lotSourceByLotType,
} from 'src/app/services/lot-adapter/constants/lot-soruce-by-lot-type';
import { LotType } from 'src/app/services/lot-adapter/enums/lot-type.enum';

import { SortType } from '../enums/sort-type.enum';
import { LotsSorts } from '../types/lots-sorts.type';

@Injectable()
export class LotsListSortBuilderService {
  parseParams(params: Params): SortType {
    return params['sort'] || SortType.YearNew;
  }

  getParams(sortType: SortType): Params {
    return { sort: sortType };
  }

  buildSort(params: Params, lotType: LotType): LotsSorts[] {
    const sortType = this.parseParams(params);
    const lotSource = lotSourceByLotType[lotType];
    let sorts: (LotsSorts | undefined)[] = [];

    switch (lotSource) {
      case LotSource.AucnetAuto:
      case LotSource.AucnetTruck:
        sorts = this.buildAucnetAutoTruckSort(sortType);
        break;

      case LotSource.AucnetMoto:
        sorts = this.buildAucnetMotoSort(sortType);
        break;

      case LotSource.Mochiron:
        sorts = this.buildMochironSort(sortType);
        break;
    }

    return sorts.filter((sort) => !!sort);
  }

  private buildAucnetAutoTruckSort(
    sortType: SortType,
  ): (SharedInventoryCarSortInput | undefined)[] {
    const configuration: Partial<Record<SortType, SharedInventoryCarSortInput>> = {
      [SortType.New]: { updatedDate: SortEnumType.Desc },
      [SortType.Old]: { updatedDate: SortEnumType.Asc },
      [SortType.PriceAscending]: { wholesalePrice: SortEnumType.Asc },
      [SortType.PriceDescending]: { wholesalePrice: SortEnumType.Desc },
      [SortType.YearNew]: { registration: SortEnumType.Desc },
      [SortType.YearOld]: { registration: SortEnumType.Asc },
    };

    return [configuration[sortType]];
  }

  private buildAucnetMotoSort(sortType: SortType): (SharedInventoryMotoSortInput | undefined)[] {
    const configuration: Partial<Record<SortType, SharedInventoryMotoSortInput>> = {
      [SortType.New]: { id: SortEnumType.Desc },
      [SortType.Old]: { id: SortEnumType.Asc },
      [SortType.PriceAscending]: { wholesalePrice: SortEnumType.Asc },
      [SortType.PriceDescending]: { wholesalePrice: SortEnumType.Desc },
      [SortType.YearNew]: { modelYear: SortEnumType.Desc },
      [SortType.YearOld]: { modelYear: SortEnumType.Asc },
    };

    return [configuration[sortType]];
  }

  private buildMochironSort(sortType: SortType): (StockCarSortInput | undefined)[] {
    const configuration: Partial<Record<SortType, StockCarSortInput>> = {
      [SortType.New]: { id: SortEnumType.Desc },
      [SortType.Old]: { id: SortEnumType.Asc },
      [SortType.PriceAscending]: { priceInJapan: SortEnumType.Asc },
      [SortType.PriceDescending]: { priceInJapan: SortEnumType.Desc },
      [SortType.YearNew]: { year: SortEnumType.Desc },
      [SortType.YearOld]: { year: SortEnumType.Asc },
    };

    return [{ isSold: SortEnumType.Asc }, configuration[sortType]];
  }
}

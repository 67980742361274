import { ChangeDetectionStrategy, Component } from '@angular/core';
import { EnvironmentService } from '../../../../services/environment.service';

@Component({
  selector: 'app-not-authorized-actions',
  templateUrl: './not-authorized-actions.component.html',
  styleUrl: './not-authorized-actions.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotAuthorizedActionsComponent {
  loginUrl: string;
  registrationUrl: string;
  dropdownOpen = false;

  constructor(environmentService: EnvironmentService){
    this.loginUrl = environmentService.currentEnvironment.loginUrl;
    this.registrationUrl = environmentService.currentEnvironment.registrationUrl;
  }
}

export const ru = {
  "POPULAR_CARS_SECTION": {
    "TITLE": "Популярные автомобили",
    "JAPAN": "Япония",
    "MONGOLIA": "Монголия",
    "GREEN": "Зеленый угол 2.0",
    "MOTORCYCLE": "Мотоциклы",
    "TRUCK": "Грузовые авто",
    "BUTTON": "Смотреть все"
  }
};

import { ru } from './ru';

export const en: typeof ru = {
  "FOOTER_CONTACTS": {
    "HEAD": "Head Office",
    "BRANCH": "Yokohama Branch Office",
    "BRANCH2": "Philippines Representative Office",
    "USA_OFFICE": "Official representative in the USA",
    "PHONE": "Phone",
    "CUSTOMER_SERVICE_DEPARTMENT_CONTACTS": "Customer service dept. contacts",
    "HEAD_OF_SALES": "Head of sales",
    "OTHER_COUNTRIES_IMPORT": {
      "TITLE": "Import to other countries",
      "NEWS": "News",
      "INFORMATION": "Information",
      "ABOUT_COMPANY": "About us"
    },
    "PERSONAL_ACCOUNT": {
      "TITLE": "Personal account",
      "HOME": "Home",
      "CHATS": "Chats",
      "ARCHIVE": "Archive"
    },
    "GOODS_AND_PARTS": {
      "TITLE": "Goods and spare parts",
      "ORDERS": "Orders",
      "FAVORITES": "Favorites",
      "SHIPMENTS": "Shipments",
      "DELIVERY": "Delivery",
      "PAYMENT": "Payment",
      "FAQ": "F.A.Q.",
      "ADDITIONAL_SERVICES": "Additional services",
      "BONUSES": "Bonus points"
    }
  },
  "LANGUAGE_SELECTOR": {
    "SELECT_LANG": "Select language",
    "EN": "English",
    "RU": "Russian"
  },
  "AUTHORIZED_ACTIONS": {
    "EXIT": "Exit",
    "PERSONAL_ACC": "Personal account"
  },
  "NOT_AUTHORIZED_ACTIONS": {
    "REGISTER": "Registration",
    "LOGIN": "Sign-in"
  }
};

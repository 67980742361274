import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Subject } from 'rxjs';

import { TabDirective } from '../../directives/tab.directive';

@Component({
  selector: 'app-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabComponent implements OnChanges, OnDestroy {
  @Input({ required: true }) title!: string;

  @ViewChild('content', { static: true })
  contentTemplate!: TemplateRef<unknown>;

  @ContentChild(TabDirective, { static: false, read: TemplateRef })
  template: TemplateRef<unknown> | null = null;

  readonly inputChange$ = new Subject<void>();

  get content(): TemplateRef<unknown> {
    return this.template || this.contentTemplate;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (Object.keys(changes)) {
      this.inputChange$.next();
    }
  }

  ngOnDestroy(): void {
    this.inputChange$.complete();
  }
}

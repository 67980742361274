import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {LocalStorageService} from '../../../../../../services/local-storage.service';
import {environment} from "../../../../../../../environments/environment";
import {ILot} from "../../../../../../services/lot-adapter/interfaces/lot.interface";
import {LotType} from "../../../../../../services/lot-adapter/enums/lot-type.enum";
import {CurrentUserService} from "../../../../../../services/current-user/current-user.service";
import {Observable} from "rxjs";
import { PageTitleService } from 'src/app/services/page-title/page-title.service';
import {
  LotSocMediaShareLinksModalComponent
} from "../lot-soc-media-share-links-modal/lot-soc-media-share-links-modal.component";
import {NzModalService} from "ng-zorro-antd/modal";

@Component({
  selector: 'app-lot-header',
  templateUrl: './lot-header.component.html',
  styleUrl: './lot-header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotHeaderComponent implements OnInit {
  @Input({required: true}) lot!: ILot;
  lastVisitedTimestamp = '';
  hasAccessToAkbOffice$: Observable<boolean>;
  link: string | null = null;

  constructor(
    private localStorageService: LocalStorageService,
    private nzModalService: NzModalService,
    private currentUserService: CurrentUserService,
    private pageTitle: PageTitleService,
  ) {
    this.hasAccessToAkbOffice$ = this.currentUserService.hasAccessToAkbOffice$;
  }

  ngOnInit(): void {
    this.updateLastVisitedDate();
    this.generateLink();
    this.updatePageTitle();
  }

  private generateLink(): void {
    if ([LotType.Mongolia, LotType.GreenCorner].includes(this.lot.type)) {
      this.link = `${environment.managerAkebonoOfficeUrl}${this.lot.id}`;
    } else {
      this.link = null;
    }
  }

  private updateLastVisitedDate(): void {
    const now = new Date();
    const storageKey = `last-visited-${this.lot?.id}`;

    const existingLastVisit = this.localStorageService.get<string>(storageKey);
    this.lastVisitedTimestamp = existingLastVisit || now.toISOString();
    this.localStorageService.set(storageKey, now.toISOString());
  }

  private updatePageTitle(): void {
    const carInfo = this.lot
    const carData = [carInfo.maker, carInfo.mark, carInfo.year, carInfo.color]
      .filter(Boolean)
      .join(' ');
    this.pageTitle.setTitle(carData);
  }

  copyToClipboard(): void {
    this.nzModalService.create({
      nzContent: LotSocMediaShareLinksModalComponent,
      nzWidth: '50%',
      nzFooter: null,
    });
  }
}

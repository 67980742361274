import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-thumbs-slider-image-item',
  templateUrl: './thumbs-slider-image-item.component.html',
  styleUrl: './thumbs-slider-image-item.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ThumbsSliderImageItemComponent {
  @Input({ required: true }) thumbnail!: string;
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'parseHtml',
})
export class ParseHtmlPipe implements PipeTransform {
  private readonly domParser = new DOMParser();

  transform(value: string): string {
    return this.domParser.parseFromString(value, 'text/html').body.innerText;
  }
}

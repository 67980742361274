import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {NotFoundPageComponent} from "./not-found-page.component";
import {LanguageService} from "../../../../services/language.service";
import {en} from "./locale/en";
import {ru} from "./locale/ru";
import {TranslateModule} from "@ngx-translate/core";
import {RouterLink} from "@angular/router";
import {NzIconDirective} from "ng-zorro-antd/icon";

@NgModule({
  declarations: [NotFoundPageComponent],
    imports: [CommonModule, TranslateModule, RouterLink, NzIconDirective],
})
export class NotFoundPageModule {
  constructor(languageService: LanguageService) {
    languageService.addLocales({en, ru});
  }
}

import {Injectable} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
  Observable,
  shareReplay,
  Subject,
  switchMap,
} from 'rxjs';

import {
  CurrentUserServiceDataGQL,
  CurrentUserServiceDataQuery,
  CurrentUserServiceDataQueryVariables,
} from '../../graphql/akebono-main-graphql.service';
import {exists} from '../../utilities/rx-pipes/exists.rx-pipe';
import {AuthenticationService} from '../authentication.service';
import {QueryHandlingService} from '../query-handling-service/query-handling.service';

@Injectable()
export class CurrentUserService {
  readonly loading$: Observable<boolean>;
  readonly currentUser$: Observable<CurrentUserServiceDataQuery['currentUser']>;

  private readonly refreshSubject = new Subject<void>();

  constructor(
    qhs: QueryHandlingService,
    currentUserServiceDataGQL: CurrentUserServiceDataGQL,
    private readonly authenticationService: AuthenticationService,
  ) {
    const queryRef$ = this.refreshSubject.pipe(
      map(() =>
        qhs.fetch<CurrentUserServiceDataQuery, CurrentUserServiceDataQueryVariables>(
          currentUserServiceDataGQL,
        ),
      ),
      shareReplay(1),
    );

    this.loading$ = queryRef$.pipe(switchMap(({loading}) => loading));
    this.currentUser$ = queryRef$.pipe(
      switchMap(({data}) => data),
      map((data) => data.currentUser),
      shareReplay(1),
    );

    this.authenticationService
      .isAuthorized()
      .pipe(
        distinctUntilChanged(),
        filter((authorized) => !authorized),
        takeUntilDestroyed(),
      )
      .subscribe(() => this.refresh());

    this.currentUser$.pipe(takeUntilDestroyed()).subscribe();
    this.refresh();
  }

  hasPermission(permissionCode: string): Observable<boolean> {
    return this.currentUser$.pipe(
      map((user) => user?.permissions || []),
      map((permissions) => permissions.map((p) => p.code)),
      map((permissionCodes) => permissionCodes.includes(permissionCode)),
    );
  }

  get isManager$(): Observable<boolean> {
    return this.hasPermission('world.akebono.auto.auctioneer');
  }

  get hasAccessToAkbOffice$(): Observable<boolean> {
    return this.hasPermission('world.akebono.auto.office');
  }

  get isAuthenticated$(): Observable<boolean> {
    return combineLatest([
      this.currentUser$.pipe(exists()),
      this.authenticationService.isAuthorized(),
    ]).pipe(map((flags) => flags.every((f) => f)));
  }

  get isRussian$(): Observable<boolean> {
    return this.currentUser$.pipe(map((user) => user?.countryIso === 'ru'));
  }

  refresh(): Observable<CurrentUserServiceDataQuery['currentUser']> {
    this.refreshSubject.next();
    return this.currentUser$;
  }

  signOut(): void {
    this.authenticationService.signOut();
  }
}

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { LotType } from 'src/app/services/lot-adapter/enums/lot-type.enum';

@Component({
  selector: 'app-popular-cars-section',
  templateUrl: './popular-cars-section.component.html',
  styleUrl: './popular-cars-section.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopularCarsSectionComponent {
  availableLotTypes = [LotType.GreenCorner, LotType.Japan, LotType.Motorcycle, LotType.Truck];
}

import { AucnetAutoLotFragment } from '../../../graphql/aucnet-main-graphql.service';
import { AucnetMotoLotFragment } from '../../../graphql/aucnet-moto-graphql.service';
import { AucnetTruckLotFragment } from '../../../graphql/aucnet-trucks-graphql.service';
import { MochironLotFragment } from '../../../graphql/mochiron-graphql.service';

export abstract class LotGuards {
  static isAucnetAutoLot(lot: unknown): lot is AucnetAutoLotFragment {
    return this.isTypename(lot, 'SharedInventoryCar');
  }

  static isAucnetMotoLot(lot: unknown): lot is AucnetMotoLotFragment {
    return this.isTypename(lot, 'SharedInventoryMoto');
  }

  static isAucnetTruckLot(lot: unknown): lot is AucnetTruckLotFragment {
    return this.isTypename(lot, 'SharedInventoryTruck');
  }

  static isMochironLot(lot: unknown): lot is MochironLotFragment {
    return this.isTypename(lot, 'StockCar');
  }

  private static isTypename(lot: unknown, typename: string): boolean {
    return this.isObject(lot) && '__typename' in lot && lot.__typename === typename;
  }

  private static isObject(lot: unknown): lot is object {
    return Boolean(lot && typeof lot === 'object');
  }
}

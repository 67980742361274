<div
  class="header"
  tabindex="0"
  role="button"
  aria-controls="content"
  [class]="type"
  (click)="toggle()"
>
  <ng-container *nzStringTemplateOutlet="title">
    <span class="title">{{ title }}</span>
  </ng-container>
  <i
    nz-icon
    [nzType]="type === 'wide' ? 'down' : 'caret-down'"
    class="icon"
    aria-label="Toggle icon"
    [class.active]="active"
  ></i>
</div>

<div class="content" #content [style.max-height.px]="active ? content.scrollHeight : 0">
  <ng-content></ng-content>
</div>

import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {LOCATION} from "../../../../../../constants/tokens/location.token";
import {NzMessageService} from "ng-zorro-antd/message";
import {Clipboard} from "@angular/cdk/clipboard";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-lot-soc-media-share-links-modal',
  templateUrl: './lot-soc-media-share-links-modal.component.html',
  styleUrl: './lot-soc-media-share-links-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotSocMediaShareLinksModalComponent {
  lotUrl = '';

  socialMediaLinks = [
    {
      platform: 'telegram',
      url: 'https://t.me/share/url?url=',
      icon: '/assets/icons/social-media-icons/telegram.svg',
    },
    {
      platform: 'whatsapp',
      url: 'https://api.whatsapp.com/send?text=',
      icon: '/assets/icons/social-media-icons/whatsapp.svg',
    },
    {
      platform: 'facebook',
      url: 'https://www.facebook.com/sharer/sharer.php?u=',
      icon: '/assets/icons/social-media-icons/facebook.svg',
    },
    {
      platform: 'x',
      url: 'https://twitter.com/intent/tweet?url=',
      icon: '/assets/icons/social-media-icons/x.svg',
    },
    {
      platform: 'vk',
      url: 'https://vk.com/share.php?url=',
      icon: '/assets/icons/social-media-icons/vk.svg',
    },
    {
      platform: 'linked-in',
      url: 'https://www.linkedin.com/sharing/share-offsite/?url=',
      icon: '/assets/icons/social-media-icons/linked-in.svg',
    },
  ];

  constructor(
    private readonly clipboard: Clipboard,
    private translateService: TranslateService,
    private readonly message: NzMessageService,
    @Inject(LOCATION) private location: Location
  ) {
    this.lotUrl = this.location.href;
  }

  copyToClipboard(): void {
    this.clipboard.copy(this.lotUrl);
    this.message.success(this.translateService.instant('GLOBALS.LINK_COPIED'), {
      nzDuration: 2000,
    });
  }

  shareTo(platformUrl: string): void {
    const shareLink = platformUrl + encodeURIComponent(this.lotUrl);
    window.open(shareLink, '_blank', 'noopener,noreferrer');
  }
}

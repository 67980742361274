export const mainAutomobileCompanies = [
  'TOYOTA',
  'NISSAN',
  'MAZDA',
  'MITSUBISHI',
  'HONDA',
  'SUZUKI',
  'SUBARU',
  'ISUZU',
  'DAIHATSU',
  'MITSUOKA',
  'LEXUS',
];

import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  combineLatest,
  distinctUntilChanged,
  map,
  Observable,
  shareReplay,
  startWith,
  switchMap,
} from 'rxjs';
import {
  Company,
  Model,
  SharedInventoryTruckFilterInput,
  TruckBodyTypeCd,
} from 'src/app/graphql/aucnet-trucks-graphql.service';
import { LotsListFilterBuilderService } from 'src/app/modules/auction/components/lots-list-filter/services/lots-list-filter-builder.service';
import { LotType } from 'src/app/services/lot-adapter/enums/lot-type.enum';

import { LotListFilterDataService } from '../../services/lots-list-filter-data.service';
import { LotsListTruckFilteringForm } from '../../types/lots-list-filtering-form.type';

@Component({
  selector: 'app-lots-list-aucnet-truck-filter',
  templateUrl: './lots-list-aucnet-truck-filter.component.html',
  styleUrl: './lots-list-aucnet-truck-filter.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotsListAucnetTruckFilterComponent {
  @Output() closeDrawer = new EventEmitter<void>();
  formGroup: FormGroup<LotsListTruckFilteringForm>;
  loading$: Observable<boolean> | undefined;

  companies$: Observable<Company[]>;
  models$: Observable<Model[]>;
  bodyTypes$: Observable<(TruckBodyTypeCd | null)[]>;
  years: number[];

  lotType = LotType;

  constructor(
    fb: FormBuilder,
    lotListFilterDataService: LotListFilterDataService,
    private lotsListFilterBuilder: LotsListFilterBuilderService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.formGroup = fb.nonNullable.group<LotsListTruckFilteringForm>({
      companyId: fb.control(null),
      modelId: fb.control(null),
      bodyType: fb.control(null),
      idObject: fb.control(null),
      priceFrom: fb.control(null),
      priceTo: fb.control(null),
      yearFrom: fb.control(null),
      yearTo: fb.control(null),
      mileageFrom: fb.control(null),
      mileageTo: fb.control(null),
    });

    this.route.queryParams.pipe(takeUntilDestroyed()).subscribe((params) => {
      const parsedParams = this.lotsListFilterBuilder.parseParams(params);
      this.formGroup.patchValue(parsedParams);
    });

    const { companyId, modelId } = this.formGroup.controls;
    this.loading$ = lotListFilterDataService.loading$;

    const filterData$ = combineLatest([
      this.formGroup.controls.companyId.valueChanges.pipe(
        startWith(this.route.snapshot.queryParams['companyId'] || null),
      ),
      this.formGroup.controls.modelId.valueChanges.pipe(
        startWith(this.route.snapshot.queryParams['modelId'] || null),
      ),
    ]).pipe(
      switchMap(([companyId, modelId]) => {
        const filter: SharedInventoryTruckFilterInput = {};

        if (companyId) filter.makerEn = { eq: companyId };
        if (modelId) filter.carnameEn = { eq: modelId };

        return lotListFilterDataService.getAucnetTrucksFilterData(filter);
      }),
      shareReplay(1),
    );

    this.companies$ = filterData$.pipe(map((data) => data.companies));
    const allModels$ = filterData$.pipe(map((data) => data.models));

    this.models$ = combineLatest([
      allModels$,
      this.formGroup.controls.companyId.valueChanges.pipe(
        startWith(this.route.snapshot.queryParams['companyId'] || null),
      ),
    ]).pipe(
      map(([models, companyId]) => {
        if (companyId) {
          return models.filter((model) => model.companyId === companyId);
        }
        return models;
      }),
    );

    this.years = lotListFilterDataService.years;
    this.bodyTypes$ = filterData$.pipe(
      map((data) =>
        data.bodyTypes.map((bodyType) =>
          bodyType ? (bodyType.replace(/_/g, ' ') as TruckBodyTypeCd) : null,
        ),
      ),
    );

    companyId.valueChanges
      .pipe(distinctUntilChanged(), takeUntilDestroyed())
      .subscribe((newCompanyId) => {
        this.formGroup.reset({
          companyId: newCompanyId,
        });
      });

    modelId.valueChanges
      .pipe(distinctUntilChanged(), takeUntilDestroyed())
      .subscribe((newModelId) => {
        this.formGroup.reset({
          companyId: this.formGroup.controls.companyId.value,
          modelId: newModelId,
        });
      });
  }

  applyFilters(): void {
    const formValue = this.formGroup.value;
    const idObject = formValue.idObject?.trim();
    if (idObject) {
      this.router.navigate(['/', this.lotType.Truck, 'lots', idObject]);
      return;
    }
    const params = this.lotsListFilterBuilder.getParams(formValue);
    this.router.navigate([], {
      queryParams: params,
      queryParamsHandling: 'merge',
    });
    this.closeDrawer.emit();
  }

  applyFiltersOnEnter(e: Event): void {
    e.preventDefault();
    this.applyFilters();
  }

  resetFilters(): void {
    this.formGroup.reset();
    this.applyFilters();
  }
}

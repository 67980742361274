import { ApolloQueryResult, OperationVariables } from '@apollo/client/core';
import { QueryRef } from 'apollo-angular';
import { BehaviorSubject, defer, from, Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { NotificationService } from '../../notification.service';
import { RefetchOptions } from '../types/refetch-option.type';
import { BaseQueryRef } from './base.query-ref.';

export class WatchQueryRef<T, R extends OperationVariables> extends BaseQueryRef<T> {
  private loadingSubject = new BehaviorSubject(true);

  constructor(protected queryRef: QueryRef<T, R>, notificationService?: NotificationService) {
    super(queryRef.valueChanges, notificationService);

    this.data$ = this.compileDataObservable();
    this.loading$ = this.compileLoadingObservable();
  }

  public refetch(variables?: R, options?: RefetchOptions): Observable<T> {
    let observable: Observable<ApolloQueryResult<T>> | null = null;

    if (options?.lazy) {
      observable = defer(() => {
        if (options?.triggerLoading) {
          this.loadingSubject.next(true);
        }

        return this.queryRef.refetch(variables);
      });
    } else {
      if (options?.triggerLoading) {
        this.loadingSubject.next(true);
      }

      observable = from(
        // fix loading if result not change
        this.queryRef.refetch(variables).then((result) => {
          this.loadingSubject.next(false);
          return result;
        }),
      );
    }

    return observable.pipe(
      tap(() => this.loadingSubject.next(false)),
      map((result) => result.data),
    );
  }

  protected override compileDataObservable(): Observable<T> {
    return defer(() => {
      this.loadingSubject.next(true);
      return this.queryObservable.pipe(
        tap((result) => console.log('Query result', result)),
        tap(() => this.loadingSubject.next(false)),
        tap(this.validateResult),
        map((result) => result.data),
        catchError(this.logError),
      );
    });
  }

  protected override compileLoadingObservable(): Observable<boolean> {
    return this.loadingSubject.asObservable();
  }
}

import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {NewsSectionComponent} from "./news-section.component";
import {LanguageService} from "../../../../services/language.service";
import {en} from "./locale/en";
import {ru} from "./locale/ru";
import {NzColDirective, NzRowDirective} from "ng-zorro-antd/grid";
import {TranslateModule} from "@ngx-translate/core";
import {RxPush} from "@rx-angular/template/push";
import {NzCardComponent} from "ng-zorro-antd/card";
import {NzSpinComponent} from "ng-zorro-antd/spin";
import {RouterLink} from "@angular/router";
import {ButtonModule} from "../../../../components/button/button.module";
import {NewsCardModule} from "../../../../components/news-card/news-card.module";
import {NzResultComponent} from "ng-zorro-antd/result";

@NgModule({
  imports: [
    CommonModule,
    NzRowDirective,
    TranslateModule,
    RxPush,
    NzColDirective,
    NzCardComponent,
    NzSpinComponent,
    RouterLink,
    ButtonModule,
    NewsCardModule,
    NzResultComponent,
  ],
  declarations: [NewsSectionComponent],
  exports: [NewsSectionComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class NewsSectionModule {
  constructor(languageService: LanguageService) {
    languageService.addLocales({en, ru});
  }
}

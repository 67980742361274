import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[canRender]'
})
export class CanRenderDirective {
  private condition: unknown;
  private hasView = false;

  @Input() set canRender(condition: unknown) {
    this.condition = condition;
    this.updateView();
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
  ) {
  }

  private updateView(): void {
    const shouldRender = this.checkRenderCondition();

    if (shouldRender && !this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (!shouldRender && this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
    }
  }

  private checkRenderCondition(): boolean {
    if (Array.isArray(this.condition)) {
      return this.condition.length > 0;
    }

    if (typeof this.condition === 'object') {
      return this.condition !== null && Object.keys(this.condition).length > 0;
    }

    return !!this.condition;
  }
}

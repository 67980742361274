import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable, of, OperatorFunction, Subject } from 'rxjs';
import { delay, map, shareReplay, startWith, switchMap, throttleTime } from 'rxjs/operators';
import { invert } from '../../../../utilities/rx-pipes/invert.rx-pipe';

@Component({
  selector: 'app-support-chats',
  templateUrl: './support-chats.component.html',
  styleUrls: ['./support-chats.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('slide', [
      state('void', style({ transform: 'translateX(130%)' })),
      transition(':enter', [animate('200ms ease-out', style({ transform: 'translateX(0%)' }))]),
      transition(':leave', [animate('200ms ease-out', style({ transform: 'translateX(130%)' }))]),
    ]),
  ],
})
export class SupportChatsComponent {
  readonly contacts = {
    whatsapp: {
      ru: '817035132626',
      other: '639628456844',
    },
  };

  openWhatsappContacts = new Subject<void>();

  whatsappContactsOpened$: Observable<boolean>;
  whatsappContactsNotOpened$: Observable<boolean>;

  private readonly showContactsTimeout = 5_000;

  constructor() {
    this.whatsappContactsOpened$ = this.openWhatsappContacts.pipe(
      this.temporarilyShow(this.showContactsTimeout),
    );
    this.whatsappContactsNotOpened$ = this.whatsappContactsOpened$.pipe(invert());
  }

  private temporarilyShow(timeout: number): OperatorFunction<void, boolean> {
    return (trigger) => {
      return trigger.pipe(
        throttleTime(timeout),
        switchMap(() => of(false).pipe(delay(timeout), startWith(true))),
        startWith(false),
        shareReplay(1),
      );
    };
  }
}

<nz-spin [nzSpinning]="isLoading$ | push" nzSize="large">
  <div class="lots">
    <nz-row [nzGutter]="[20, 20]" nzJustify="center">
      @for (lot of lots$ | async; track lot.id) {
      <nz-col
        [nzXs]="grid.xs"
        [nzSm]="grid.sm"
        [nzMd]="grid.md"
        [nzLg]="grid.lg"
        [nzXl]="grid.xl"
        [nzXXl]="grid.xl"
      >
        <app-lot-card [lot]="lot"></app-lot-card>
      </nz-col>
      }
    </nz-row>
  </div>
</nz-spin>

<div class="button">
  <a [routerLink]="['/', _lotType, 'lots']" app-button appType="primary" size="large">
    <span>{{ 'POPULAR_CARS_SECTION.BUTTON' | translate }}</span>
  </a>
</div>

import { FetchResult } from '@apollo/client/core';
import { Apollo } from 'apollo-angular';
import { iif, Observable, of, throwError } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mapTo,
  publishReplay,
  refCount,
  startWith,
  switchMap,
  switchMapTo,
  tap,
} from 'rxjs/operators';

import { NotificationService } from '../../notification.service';
import { BaseMutationHandlingException } from '../exceptions/base-mutation-handling.exception';
import { MutationExecutionException } from '../exceptions/mutation-execution.exception';
import { MutationGraphqlException } from '../exceptions/mutation-graphql.exception';
import { MutationHandlerOptions } from '../interfaces/mutation-handling-options.interface';
import { MutationExecutionStatus } from '../types/mutation-execution-status.enum';
import { MutationResultsWithStatus } from '../types/mutation-results-with-status-counters.type';
import { BaseMutationRef } from './base.mutation-ref';

export class MultipleMutationRef<T> extends BaseMutationRef {
  // Must be Observable<MutationResultsWithLoading<T>>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private mutationObservable: Observable<any>;

  protected loading$: Observable<boolean>;
  protected data$: Observable<T[]>;

  constructor(
    mutationObservable: Observable<FetchResult<T>[]>,
    options: MutationHandlerOptions,
    apollo: Apollo,
    client: string,
    notificationService: NotificationService,
  ) {
    super(apollo, client, options, notificationService);

    this.mutationObservable = mutationObservable.pipe(
      tap((results) => console.log('Mutation results', results)),
      map(
        (results) =>
          <MutationResultsWithStatus<T>>{
            results,
            status: this.getResultsStatus(results),
          },
      ),
      tap(this.validateResults),
      switchMap((resultsWithStatus) =>
        iif(
          () => this.options?.refetch ?? false,
          this.refetchQueries().pipe(mapTo(resultsWithStatus)),
          of(resultsWithStatus),
        ),
      ),
      tap((resultsWithStatus) => {
        if (this.options.renderSuccess ?? true) {
          switch (resultsWithStatus.status) {
            case MutationExecutionStatus.Success:
              this.notify('success');
              break;

            case MutationExecutionStatus.Partly:
              this.notify('warning');
              break;
          }
        }
      }),
      map((resultsWithStatus) => resultsWithStatus.results),
      map((results) => results.map((r) => r.data)),
      map((data) => ({ loading: false, data })),
      catchError(this.handleError),
      publishReplay(1),
      refCount(),
    );

    this.data$ = this.compileDataObservable();
    this.loading$ = this.compileLoadingObservable();
  }

  protected validateResult = (result: FetchResult<T>) => {
    if (result.errors) {
      throw new MutationGraphqlException(result.errors);
    }

    const payloadKey = Object.keys(result.data!)[0];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((<any>result.data)[payloadKey]?.error) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      throw new MutationExecutionException((<any>result.data)[payloadKey].error);
    }
  };

  protected handleError = (error: BaseMutationHandlingException | Error): Observable<never> => {
    return of(this.options?.refetch ?? false).pipe(
      switchMap((refetch) => iif(() => refetch, this.refetchQueries(), of(null))),
      tap(() => {
        if (this.options.renderError ?? true) {
          if (error instanceof BaseMutationHandlingException) {
            this.notify('fail', error.message);
            error.log();
          } else {
            this.notify('fail', error.message);
            console.error(error);
          }
        }
      }),
      switchMapTo(throwError(error)),
    );
  };

  public get loading(): Observable<boolean> {
    return this.loading$;
  }

  public get data(): Observable<T[]> {
    return this.data$;
  }

  protected validateResults = (resultsWithStatus: MutationResultsWithStatus<T>) => {
    if (resultsWithStatus.status === MutationExecutionStatus.Fail) {
      const firstResult = resultsWithStatus.results[0];

      if (firstResult.errors) {
        throw new MutationGraphqlException(firstResult.errors);
      }

      const payloadKey = Object.keys(firstResult.data!)[0];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if ((<any>firstResult.data)[payloadKey].error) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        throw new MutationExecutionException((<any>firstResult.data)[payloadKey].error);
      }
    }
  };

  private getResultsStatus(results: FetchResult<T>[]): MutationExecutionStatus {
    const failedResults = results.filter((result) => {
      const key = Object.keys(result.data!)[0];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const error = Boolean((<any>result.data)[key].error);
      return !result?.data || error;
    });

    const successResults = results.filter((result) => {
      const key = Object.keys(result.data!)[0];

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const error = Boolean((<any>result.data)[key].error);
      return result?.data && !error;
    });

    if (failedResults.length === results.length) {
      return MutationExecutionStatus.Fail;
    }

    if (successResults.length === results.length) {
      return MutationExecutionStatus.Success;
    }

    return MutationExecutionStatus.Partly;
  }

  private compileDataObservable(): Observable<T[]> {
    return this.mutationObservable.pipe(
      map((dataWithLoading) => dataWithLoading.data),
      filter((data) => Boolean(data)),
    );
  }

  private compileLoadingObservable(): Observable<boolean> {
    return this.mutationObservable.pipe(
      map((dataOrLoading) => dataOrLoading!.loading),
      startWith(true),
      catchError(() => of(false)),
    );
  }
}

@if (rating && !isSold) {
<div class="status-block rating">
  <span>{{ rating }}</span>
  <i nz-icon nzType="icons:star"></i>
</div>
} @if (isSold) {
<div class="status-block sold">
  <span>SOLD</span>
</div>
}

<div class="swiper-wrapper-container">
  <swiper-container [speed]="500" [navigation]="true" [css-mode]="true" appStopPropagation>
    @if (images) { @for (image of images; track image) {
    <swiper-slide>
      <img app-image [source]="image" [previewEnabled]="false" alt="Lot image" />
    </swiper-slide>
    } } @else {
    <swiper-slide>
      <img class="default-image" [src]="defaultImageUrl" alt="Default image" />
    </swiper-slide>
    }
  </swiper-container>
</div>

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-trips-header',
  templateUrl: './trips-header.component.html',
  styleUrl: './trips-header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TripsHeaderComponent {
  @Input({ required: true }) tripsUpdatedAt!: string;
}


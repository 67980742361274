<div class="lot-list-header">
  <app-category-links
    [activeLotType]="activeLotType$ | push"
    [availableLotTypes]="availableLotTypes"
  ></app-category-links>
  <app-auction-breadcrumb [activeLotType]="activeLotType$ | push"></app-auction-breadcrumb>
</div>

<div class="mob-filter">
  <p>{{ 'AUCTION.LOTS_PAGE.SEARCH_CAR' | translate }}</p>
  <i nz-icon tabindex="0" nzType="icons:filters" (click)="openFilterDrawer()"></i>
</div>

<app-category-info [activeLotType]="activeLotType$ | push"></app-category-info>

<nz-row [nzGutter]="[20, 20]" nzAlign="middle" nzJustify="space-between">
  <nz-col>
    <app-pagination-status
      [total]="totalItems"
      [pageSize]="pageSize"
      [offset]="offset"
    ></app-pagination-status>
  </nz-col>
  <nz-col>
    <app-lots-list-sort></app-lots-list-sort>
  </nz-col>
</nz-row>

<div class="main">
  <nz-row nzAlign="middle">
    <nz-col [nzMd]="6" class="filter">
      <app-lots-list-filter [activeLotType]="activeLotType$ | push"></app-lots-list-filter>
    </nz-col>
    <nz-col class="lot-list" [nzXs]="24" [nzSm]="24" [nzMd]="18">
      <app-lots-list
        [activeLotType]="activeLotType$ | push"
        (totalChange)="totalItems = $event"
        (pageSizeChange)="pageSize = $event"
        (offsetChange)="offset = $event"
      ></app-lots-list>
    </nz-col>
  </nz-row>
</div>

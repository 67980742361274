import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-main-slider-video-view-modal',
  templateUrl: './main-slider-video-view-modal.component.html',
  styleUrl: './main-slider-video-view-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainSliderVideoViewModalComponent {
  constructor(private readonly modal: NzModalRef, @Inject(NZ_MODAL_DATA) readonly source: string) {}

  close(): void {
    this.modal.destroy();
  }
}

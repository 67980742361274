import { ru } from './ru';

export const en: typeof ru = {
  "AUCTION_BREADCRUMB": {
    "DUBAI": "Cars - Dubai",
    "JAPAN": "Cars in stock in Japan",
    "MONGOLIA": "Cars - Mongolia",
    "GREEN": "Green corner 2.0",
    "MOTORCYCLE": "Motorbikes auctions",
    "TRUCK": "Cargo and special-purpose vehicles",
    "RECYCLING": "Cars - recycle",
    "USA": "Cars in USA",
  }
};

import { ChangeDetectionStrategy, Component, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-tab-body',
  templateUrl: './tab-body.component.html',
  styleUrl: './tab-body.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TabBodyComponent {
  @Input() active = false;
  @Input({ required: true }) content!: TemplateRef<unknown>;
}

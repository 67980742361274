import {imageExtensionsSet} from 'src/app/constants/constants/image-exntensions';

import {AucnetAutoLotMinimalFragment} from '../../../graphql/aucnet-main-graphql.service';
import {AucnetTruckLotMinimalFragment} from '../../../graphql/aucnet-trucks-graphql.service';
import {lotMinimalImageSizeLimits} from '../constants/lot-minimal-image-size-limits';
import {Maybe} from '../types/maybe.type';
import {MediaItem} from '../types/media-item.type';
import {Value} from '../types/value.type';

export abstract class LotAdapterUtilities {
  static praseRating(rating: Maybe<string>): string | undefined {
    if (!rating) {
      return undefined;
    }

    return rating.split('').join('.');
  }

  static buildValue(value: Maybe<number>, unit: Maybe<string>): Value | undefined {
    if (!value || !unit) {
      return undefined;
    }

    return {value, unit};
  }

  static normalizeMedia(
    sourceUrls:
      | AucnetAutoLotMinimalFragment['images']
      | AucnetTruckLotMinimalFragment['images']
      | string[],
  ): MediaItem[] | undefined {
    if (!sourceUrls) {
      return undefined;
    }

    const urls = sourceUrls
      .filter((url) => !!url)
      .map((url) => {
        const mediaType = this.getMediaType(url!);
        const { width, height } = lotMinimalImageSizeLimits;

        switch (mediaType) {
          case 'image':
            return <MediaItem>{
              type: 'image',
              source: url,
              thumbnail: this.resizeImage(url!, width, height),
            };

          case 'video':
            return <MediaItem>{
              type: 'video',
              source: url,
            };
        }
      });

    const sortedUrls = this.sortMediaItems(urls);
    return this.moveVideoToPosition(sortedUrls);
  }

  static sortMediaItems(urls: MediaItem[]): MediaItem[] {
    return urls.sort((a, b) => {
      if (a.type === 'image' && b.type !== 'image') {
        return -1;
      } else if (a.type !== 'image' && b.type === 'image') {
        return 1;
      } else {
        return 0;
      }
    });
  }

  static moveVideoToPosition(items: MediaItem[], position: number = 2): MediaItem[] {
    const videoIndex = items.findIndex(item => item.type === 'video');
    if (videoIndex === -1 || videoIndex === position) {
      return items;
    }

    const [videoItem] = items.splice(videoIndex, 1);
    items.splice(position, 0, videoItem);

    return items;
  }

  static getMediaType(url: string): MediaItem['type'] {
    const extension = url!.split('.').pop()!.toLowerCase();
    return imageExtensionsSet.has(extension) ? 'image' : 'video';
  }

  static normalizeRegistrationYear(registration: Maybe<number>): number | undefined {
    if (!registration) {
      return undefined;
    }

    return Number(registration.toString().substring(0, 4));
  }

  static mergeFieldSet<T>(fieldsSet: Set<keyof T>, fields: (keyof T)[]): Set<keyof T> {
    const oldFields = Array.from(fieldsSet);
    const newFields = [...oldFields, ...fields];

    return new Set<keyof T>(newFields);
  }

  static toBoolean(value: Maybe<number | string>): boolean | undefined {
    if (typeof value === 'undefined' || value === null) {
      return undefined;
    }

    if (typeof value === 'string') {
      return value === '1';
    } else {
      return !!value;
    }
  }

  static resizeImage(imageUrl: string, width: number, height: number): string {
    const resize = `${width}x${height}`;

    return imageUrl
      .replace('akebono.world/', `akebono.world/${resize}/`)
      .replace('twilight.link/', `twilight.link/${resize}/`);
  }

  static normalizeBodyType(bodyType: Maybe<string>): string | undefined {
    if (!bodyType) {
      return undefined;
    }

    return bodyType.replaceAll('_', ' ');
  }

  static normalizeDrive(drive: Maybe<string>): string | undefined {
    if (!drive) {
      return undefined;
    }

    return drive.replaceAll('_', ' ').trim();
  }
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MediaItem } from 'src/app/services/lot-adapter/types/media-item.type';

@Component({
  selector: 'app-lot-card-gallery',
  templateUrl: './lot-card-gallery.component.html',
  styleUrl: './lot-card-gallery.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotCardGalleryComponent {
  @Input({ required: true }) rating?: string;
  @Input({ required: true }) isSold?: boolean;
  @Input({ required: true }) media?: MediaItem[];

  defaultImageUrl = '/assets/img/default-fon.jpg';

  get images(): string[] {
    return (
      this.media
        ?.filter((item) => item.type === 'image')
        .map((item) => item.thumbnail!)
        .slice(0, 5) || []
    );
  }
}

@if (hasInspectionData) {
  <h2 class="title">{{ 'LOT.INSPECTION.TITLE' | translate }}</h2>
  <nz-row [nzGutter]="[20, 20]">
    @if (lot.aisScoreTotal) {
      <nz-col nzSpan="24">
        <h3>{{ 'LOT.INSPECTION.FIELDS.AIS_INFO' | translate }}</h3>
        <div nz-flex nzGap="middle">
          <div>
            <app-rating-tag [rating]="lot.aisScoreTotal"></app-rating-tag>
            <span>{{ 'LOT.INSPECTION.FIELDS.TOTAL_SCORE' | translate }}</span>
          </div>
          <div>
            <app-rating-tag [rating]="lot.exteriorRating"></app-rating-tag>
            <span>{{ 'LOT.INSPECTION.FIELDS.EXTERIOR_SCORE' | translate }}</span>
          </div>
          <div>
            <app-rating-tag [rating]="lot.interiorRating"></app-rating-tag>
            <span>{{ 'LOT.INSPECTION.FIELDS.INTERIOR_SCORE' | translate }}</span>
          </div>
        </div>
      </nz-col>
      <nz-divider></nz-divider>
    }

    @if (lot.totalScore) {
      <nz-col nzSpan="24">
        <h3>{{ 'LOT.INSPECTION.FIELDS.AUCNET_INFO' | translate }}</h3>
        <div nz-flex nzGap="middle">
          <div>
            <app-rating-tag [rating]="lot.totalScore"></app-rating-tag>
            <span>{{ 'LOT.INSPECTION.FIELDS.TOTAL_SCORE' | translate }}</span>
          </div>
          <div>
            <app-rating-tag [rating]="lot.interiorScore"></app-rating-tag>
            <span>{{ 'LOT.INSPECTION.FIELDS.INTERIOR_SCORE' | translate }}</span>
          </div>
        </div>
      </nz-col>
      <nz-divider></nz-divider>
    }

    @if (lot.benefits) {
      <nz-col nzSpan="24">
        <h3>{{ 'LOT.INSPECTION.FIELDS.SELLING_POINT' | translate }}</h3>
        <div>{{ lot.benefits }}</div>
      </nz-col>
      <nz-divider></nz-divider>
    }

    @if (hasNotices) {
      <nz-col class="notice" nzSpan="24">
        <h3>{{ 'LOT.INSPECTION.FIELDS.NOTICE' | translate }}</h3>
        <div>{{ lot.auctionNotice }}</div>
        <div>{{ lot.notice }}</div>
      </nz-col>
    }
  </nz-row>
}

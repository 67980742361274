import { BreakpointObserver } from '@angular/cdk/layout';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { map, Observable, shareReplay, startWith } from 'rxjs';

import { WINDOW } from '../../constants/tokens/window.token';
import { invert } from '../../utilities/rx-pipes/invert.rx-pipe';
import { ResizeObserverService } from '../resize-observer.service';
import { Size } from './enums/size.enum';
import { WindowSize } from './types/window-size.type';

@Injectable()
export class SizeService {
  readonly sizes$: Observable<WindowSize>;
  readonly width$: Observable<number>;
  readonly height$: Observable<number>;

  readonly maxMobileWidth = 768;
  readonly maxTabletWidth = 992;

  constructor(
    @Inject(WINDOW)
    window: Window,
    @Inject(DOCUMENT)
    document: Document,
    resizeObserver: ResizeObserverService,
    private readonly breakpointObserver: BreakpointObserver,
  ) {
    this.sizes$ = resizeObserver.observe(document.body).pipe(
      map(() => ({
        width: window.innerWidth,
        height: window.innerHeight,
      })),
      startWith({
        width: window.innerWidth,
        height: window.innerHeight,
      }),
      shareReplay(1),
    );

    this.width$ = this.sizes$.pipe(map((sizes) => sizes.width));
    this.height$ = this.sizes$.pipe(map((sizes) => sizes.height));
  }

  get isMobile$(): Observable<boolean> {
    return this.breakpointObserver
      .observe([`(max-width: ${this.maxMobileWidth}px)`])
      .pipe(map((state) => state.matches));
  }

  get isTablet$(): Observable<boolean> {
    return this.breakpointObserver
      .observe([`(min-width: ${this.maxMobileWidth}px) and (max-width: ${this.maxTabletWidth}px)`])
      .pipe(map((state) => state.matches));
  }

  get isDesktop$(): Observable<boolean> {
    return this.isMobile$.pipe(invert());
  }

  get size$(): Observable<Size> {
    return this.width$.pipe(
      map((width) => {
        if (width < 576) {
          return Size.Xs;
        } else if (width >= 576 && width < 768) {
          return Size.Sm;
        } else if (width >= 768 && width < 992) {
          return Size.Md;
        } else if (width >= 992 && width < 1200) {
          return Size.Lg;
        } else if (width >= 1200 && width < 1600) {
          return Size.Xl;
        } else {
          return Size.Xxl;
        }
      }),
    );
  }

  get Xs$(): Observable<boolean> {
    return this.size$.pipe(map((size) => size === Size.Xs));
  }

  get Sm$(): Observable<boolean> {
    return this.size$.pipe(map((size) => size === Size.Sm));
  }

  get Md$(): Observable<boolean> {
    return this.size$.pipe(map((size) => size === Size.Md));
  }

  get Lg$(): Observable<boolean> {
    return this.size$.pipe(map((size) => size === Size.Lg));
  }

  get Xl$(): Observable<boolean> {
    return this.size$.pipe(map((size) => size === Size.Xl));
  }

  get Xxl$(): Observable<boolean> {
    return this.size$.pipe(map((size) => size === Size.Xxl));
  }
}

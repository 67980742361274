import { Environment } from '../app/utilities/types/environment.type';

const domain = 'twilight.link';

export const environment: Environment = {
  environmentName: 'staging',
  domain,
  loginUrl: `https://id.${domain}?referrer=//${domain}`,
  registrationUrl: `https://id.${domain}/sign-up?referrer=//${domain}`,
  personalAccountUrl: `https://my.${domain}/`,
  managerAkebonoOfficeUrl: `https://manager.${domain}/akebono-office/object/view/`,
  autoNewsDomain: domain,
  cognitoUserPool: {
    ClientId: '7flkej10110e8bbhrdo9co0of2',
    UserPoolId: 'us-east-1_XGTHN4PBv',
  },
  graphqlClients: [
    {
      clientName: 'akebono/main',
      endpoint: `https://${domain}/akebono/main`,
      fetchPolicy: 'cache-first',
    },
    {
      clientName: 'common/open',
      endpoint: `https://${domain}/graphql/directory/common/open`,
      fetchPolicy: 'cache-first',
    },
    {
      clientName: 'shared/familiar',
      endpoint: `https://${domain}/graphql/akebono/shared/familiar`,
      fetchPolicy: 'cache-first',
    },
    {
      clientName: 'exchange-rate/open',
      endpoint: `https://${domain}/graphql/directory/exchange-rate/open`,
      fetchPolicy: 'cache-first',
    },
    {
      clientName: 'aucnet/main',
      endpoint: `https://${domain}/aucnet/main`,
      fetchPolicy: 'cache-first',
      method: 'GET',
    },
    {
      clientName: 'aucnet/moto',
      endpoint: `https://${domain}/aucnet/moto`,
      fetchPolicy: 'cache-first',
      method: 'GET',
    },
    {
      clientName: 'aucnet/trucks',
      endpoint: `https://${domain}/aucnet/trucks`,
      fetchPolicy: 'cache-first',
      method: 'GET',
    },
    {
      clientName: 'mochiron',
      endpoint: `https://${domain}/dubai/main`,
      fetchPolicy: 'cache-first',
      method: 'GET',
    },
    {
      clientName: 'navis/main/open',
      endpoint: 'https://cartrack.nvs-staging.link/graphql/navis/main/open',
      fetchPolicy: 'cache-first',
    },
    {
      clientName: 'content/auto',
      endpoint: `https://${domain}/content/auto-minimal`,
      fetchPolicy: 'cache-first',
    },
  ],
};

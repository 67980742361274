import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NzNotificationRef, NzNotificationService } from 'ng-zorro-antd/notification';

@Injectable()
export class NotificationService {
  private readonly translate = inject(TranslateService);
  private readonly notificationService = inject(NzNotificationService);

  error(title: string, error?: string | unknown): NzNotificationRef {
    return this.renderNotification(title, error, 'custom-notification error-notification');
  }

  success(title: string, message?: string): NzNotificationRef {
    return this.renderNotification(title, message, 'custom-notification success-notification');
  }

  info(title: string, message?: string): NzNotificationRef {
    return this.renderNotification(title, message, 'custom-notification info-notification');
  }

  warning(title: string, message?: string): NzNotificationRef {
    return this.renderNotification(title, message, 'custom-notification warning-notification');
  }

  blank(title: string, message?: string): NzNotificationRef {
    return this.renderNotification(title, message);
  }

  private renderNotification(
    title: string,
    message?: string | unknown,
    bodyClass?: string,
  ): NzNotificationRef {
    const translatedTitle = this.translate.instant(title);
    const translatedMessage = this.translateMessage(message);

    return this.notificationService.blank(translatedTitle, translatedMessage, {
      nzClass: bodyClass,
    });
  }

  private translateMessage(message?: string | unknown): string {
    if (!message) {
      return '';
    }

    if (typeof message === 'object') {
      return JSON.stringify(message, null, 2);
    }

    return this.translate.instant(<string>message);
  }
}

import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  HostListener,
  Inject,
} from '@angular/core';
import { WINDOW } from 'src/app/constants/tokens/window.token';

@Component({
  selector: 'app-scroll-to-top',
  templateUrl: './scroll-to-top.component.html',
  styleUrl: './scroll-to-top.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScrollToTopComponent {
  @HostBinding('style.display')
  display: 'block' | 'none' = 'none';

  private readonly scrollLimit = 500;

  constructor(
    @Inject(WINDOW)
    private window: Window,
  ) {}

  @HostListener('window:scroll', [])
  onWindowScroll(): void {
    const renderButton = this.window.scrollY > this.scrollLimit;
    this.changeShow(renderButton);
  }

  scrollToTop(): void {
    this.window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  private changeShow(render: boolean): void {
    this.display = render ? 'block' : 'none';
  }
}

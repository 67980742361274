import { Injectable } from '@angular/core';

import { AucnetAutoLotMinimalAdapter } from './adapters/aucnet-auto-lot-minimal.adapter';
import { AucnetAutoLotAdapter } from './adapters/aucnet-auto-lot.adapter';
import { AucnetMotoLotMinimalAdapter } from './adapters/aucnet-moto-lot-minimal.adapter';
import { AucnetMotoLotAdapter } from './adapters/aucnet-moto-lot.adapter';
import { AucnetTruckLotMinimalAdapter } from './adapters/aucnet-truck-lot-minimal.adapter';
import { AucnetTruckLotAdapter } from './adapters/aucnet-truck-lot.adapter';
import { MochironLotMinimalAdapter } from './adapters/mochiron-lot-minimal.adapter';
import { MochironLotAdapter } from './adapters/mochiron-lot.adapter';
import { ILotMinimal } from './interfaces/lot-minimal.interface';
import { ILot } from './interfaces/lot.interface';
import { LotFragments } from './types/lot-fragments.type';
import { LotMinimalFragments } from './types/lot-minimal-fragments.type';
import { LotGuards } from './utilities/lot-guards.utilities';

@Injectable()
export class LotAdapterService {
  adaptMinimal(lot: LotMinimalFragments): ILotMinimal {
    if (LotGuards.isAucnetAutoLot(lot)) {
      return new AucnetAutoLotMinimalAdapter(lot);
    } else if (LotGuards.isAucnetMotoLot(lot)) {
      return new AucnetMotoLotMinimalAdapter(lot);
    } else if (LotGuards.isAucnetTruckLot(lot)) {
      return new AucnetTruckLotMinimalAdapter(lot);
    } else if (LotGuards.isMochironLot(lot)) {
      return new MochironLotMinimalAdapter(lot);
    } else {
      throw new Error('Unsupported lot fragment');
    }
  }

  adapt(lot: LotFragments): ILot {
    if (LotGuards.isAucnetAutoLot(lot)) {
      return new AucnetAutoLotAdapter(lot);
    } else if (LotGuards.isAucnetMotoLot(lot)) {
      return new AucnetMotoLotAdapter(lot);
    } else if (LotGuards.isAucnetTruckLot(lot)) {
      return new AucnetTruckLotAdapter(lot);
    } else if (LotGuards.isMochironLot(lot)) {
      return new MochironLotAdapter(lot);
    } else {
      throw new Error('Unsupported lot fragment');
    }
  }
}

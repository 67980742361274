import { ChangeDetectionStrategy, Component } from '@angular/core';
import { EnvironmentService } from 'src/app/services/environment.service';

@Component({
  selector: 'app-tracking-link',
  templateUrl: './tracking-link.component.html',
  styleUrl: './tracking-link.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrackingLinkComponent {
  trackingUrl: string;

  constructor(environmentService: EnvironmentService) {
    const domain = environmentService.currentEnvironment.domain;
    this.trackingUrl = `//tracking.${domain}/`;
  }
}

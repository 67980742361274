<div [formGroup]="phoneForm" class="phone-input-wrapper">
  <nz-form-item>
    <nz-form-control
      [nzValidateStatus]="phoneForm.controls['phoneNumber']"
      nzErrorTip="Please input your phone number!"
    >
      <nz-input-group [nzAddOnBefore]="addOnBeforeTemplate">
        <input
          nz-input
          type="tel"
          formControlName="number"
          autocomplete="tel"
          [placeholder]="getPlaceholder()"
          (blur)="markAsTouched()"
        />
      </nz-input-group>

      <ng-template #addOnBeforeTemplate>
        <nz-select
          formControlName="countryCode"
          class="phone-select"
          (blur)="markAsTouched()"
          nzShowSearch
          [nzDropdownMatchSelectWidth]="false"
        >
          @for (country of countries; track country) {
            <nz-option
              [nzValue]="country.code"
              [nzLabel]="'(+' + country.dialCode + ')'"
            ></nz-option>
          }
        </nz-select>
      </ng-template>
    </nz-form-control>
  </nz-form-item>
</div>

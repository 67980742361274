import { Directive, ElementRef, Input } from '@angular/core';
import { SwiperContainer } from 'swiper/swiper-element';
import { SwiperOptions } from 'swiper/types';

@Directive({
  selector: 'swiper-container[appSwiperInitializer]',
})
export class SwiperInitializerDirective {
  @Input({ required: true })
  set configuration(swiperConfiguration: SwiperOptions) {
    Object.assign(this.swiper, swiperConfiguration);
  }

  private readonly swiper: SwiperContainer;

  constructor(swiperElementRef: ElementRef<SwiperContainer>) {
    this.swiper = swiperElementRef.nativeElement;
    this.swiper.init = false;
  }

  ngOnInit(): void {
    this.swiper.initialize();
  }
}

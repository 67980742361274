import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { RxPush } from '@rx-angular/template/push';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { ButtonModule } from 'src/app/components/button/button.module';
import { LanguageService } from 'src/app/services/language.service';

import { BuhankaDonateSectionComponent } from './buhanka-donate-section.component';
import { BuhankaDonateBannerComponent } from './components/buhanka-donate-banner/buhanka-donate-banner.component';
import { BuhankaDonateButtonComponent } from './components/buhanka-donate-button/buhanka-donate-button.component';
import { BuhankaDonateModalComponent } from './components/buhanka-donate-modal/buhanka-donate-modal.component';
import { BuhankaDonateResultComponent } from './components/buhanka-donate-result/buhanka-donate-result.component';
import { BuhankaDonateTitleComponent } from './components/buhanka-donate-title/buhanka-donate-title.component';
import { en } from './locale/en';
import { ru } from './locale/ru';
import {NzInputDirective} from "ng-zorro-antd/input";
import {NzCheckboxComponent} from "ng-zorro-antd/checkbox";

@NgModule({
  declarations: [
    BuhankaDonateSectionComponent,
    BuhankaDonateModalComponent,
    BuhankaDonateTitleComponent,
    BuhankaDonateResultComponent,
    BuhankaDonateButtonComponent,
    BuhankaDonateBannerComponent,
  ],
	imports: [
		CommonModule,
		FormsModule,
		RxPush,
		TranslateModule,
		ButtonModule,
		NzRadioModule,
		NzProgressModule,
		NzModalModule,
		NzSpinModule,
		NzGridModule,
		NzInputDirective,
		NzCheckboxComponent,
	],
  exports: [BuhankaDonateSectionComponent],
})
export class BuhankaDonateSectionModule {
  constructor(languageService: LanguageService) {
    languageService.addLocales({ ru, en });
  }
}

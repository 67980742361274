<nz-row [nzGutter]="[40, 40]">
  <nz-col [nzXs]="24" [nzSm]="12" [nzMd]="12" [nzLg]="16" class="banner">
    <app-buhanka-donate-banner></app-buhanka-donate-banner>
  </nz-col>

  <nz-col [nzXs]="24" [nzSm]="12" [nzMd]="12" [nzLg]="8" class="info">
    <app-buhanka-donate-title></app-buhanka-donate-title>
    <app-buhanka-donate-result
      [loading]="loading$ | push"
      [total]="total$ | push"
      [collected]="collected$ | push"
    ></app-buhanka-donate-result>
    <app-buhanka-donate-button [enabled]="donateEnabled$ | push"></app-buhanka-donate-button>
  </nz-col>
</nz-row>

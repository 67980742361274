import { ChangeDetectionStrategy, Component } from '@angular/core';
import { EnvironmentService } from 'src/app/services/environment.service';

@Component({
  selector: 'app-main-slider-not-authorized-overlay',
  templateUrl: './main-slider-not-authorized-overlay.component.html',
  styleUrl: './main-slider-not-authorized-overlay.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainSliderNotAuthorizedOverlayComponent {
  registrationUrl: string;
  loginUrl: string;

  constructor(environmentService: EnvironmentService) {
    const env = environmentService.currentEnvironment;
    this.loginUrl = env.loginUrl;
    this.registrationUrl = env.registrationUrl;
  }
}

@if (!(isSent$ | push)) {
  <form nz-form [formGroup]="form" nzLayout="vertical">
    <nz-row [nzGutter]="[20, 20]">
      <nz-col [nzXs]="24" [nzMd]="24" [nzLg]="12" class="form-items-wrapper">
<!--        <nz-form-item>-->
<!--          <nz-input-group nzCompact>-->
<!--            <nz-form-control-->
<!--              class="price-form-control"-->
<!--              [nzErrorTip]="'LOT_APPLICATION_MODAL.FORM.ERROR_TIP.PRICE' | translate"-->
<!--            >-->
<!--              <nz-input-number-->
<!--                formControlName="price"-->
<!--                [nzPlaceHolder]="'LOT_APPLICATION_MODAL.FORM.PRICE' | translate"-->
<!--                [nzStep]="1"-->
<!--              ></nz-input-number>-->
<!--            </nz-form-control>-->
<!--            <nz-form-control-->
<!--              class="currency-form-control"-->
<!--              [nzErrorTip]="'LOT_APPLICATION_MODAL.FORM.CURRENCY' | translate"-->
<!--            >-->
<!--              <nz-select-->
<!--                formControlName="currency"-->
<!--                [nzPlaceHolder]="'LOT_APPLICATION_MODAL.FORM.CURRENCY' | translate"-->
<!--              >-->
<!--                <nz-option nzLabel="MNT" nzValue="MNT"></nz-option>-->
<!--                <nz-option nzLabel="JPY" nzValue="JPY"></nz-option>-->
<!--                <nz-option nzLabel="USD" nzValue="USD"></nz-option>-->
<!--              </nz-select>-->
<!--            </nz-form-control>-->
<!--          </nz-input-group>-->
<!--        </nz-form-item>-->

        <nz-form-item>
          <nz-form-control
            [nzErrorTip]="'LOT_APPLICATION_MODAL.FORM.ERROR_TIP.FIRST_NAME' | translate"
          >
            <input
              nz-input
              formControlName="firstName"
              [placeholder]="'LOT_APPLICATION_MODAL.FORM.FIRST_NAME' | translate"
              autocomplete="given-name"
            />
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-control
            [nzErrorTip]="'LOT_APPLICATION_MODAL.FORM.ERROR_TIP.LAST_NAME' | translate"
          >
            <input
              nz-input
              formControlName="lastName"
              autocomplete="family-name"
              [placeholder]="'LOT_APPLICATION_MODAL.FORM.LAST_NAME' | translate"
            />
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-control [nzErrorTip]="'LOT_APPLICATION_MODAL.FORM.ERROR_TIP.PHONE' | translate">
            <app-phone-input formControlName="phone"></app-phone-input>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-control [nzErrorTip]="'LOT_APPLICATION_MODAL.FORM.ERROR_TIP.EMAIL' | translate">
            <input
              nz-input
              type="email"
              formControlName="email"
              autocomplete="email"
              [placeholder]="'LOT_APPLICATION_MODAL.FORM.EMAIL' | translate"
            />
          </nz-form-control>
        </nz-form-item>

        @if (activeLotType !== 'green') {
          <div class="form-items-wrapper-block">
            <div class="form-items-wrapper-block-label">
              {{ 'LOT_APPLICATION_MODAL.MESSENGERS.TITLE' | translate }}
            </div>

            <nz-row [nzGutter]="16">
              <nz-col [nzSpan]="8">
                <nz-form-item>
                  <nz-form-control>
                    <label nz-checkbox formControlName="isTelegram">Telegram</label>
                  </nz-form-control>
                </nz-form-item>
              </nz-col>
              <nz-col [nzSpan]="8">
                <nz-form-item>
                  <nz-form-control>
                    <label nz-checkbox formControlName="isWhatsApp">WhatsApp</label>
                  </nz-form-control>
                </nz-form-item>
              </nz-col>
              <nz-col [nzSpan]="8">
                <nz-form-item>
                  <nz-form-control>
                    <label nz-checkbox formControlName="isViber">Viber</label>
                  </nz-form-control>
                </nz-form-item>
              </nz-col>
            </nz-row>
          </div>
        }
      </nz-col>

      <nz-col [nzXs]="24" [nzMd]="24" [nzLg]="12" class="form-items-wrapper">
        @if (activeLotType !== 'green') {
          <nz-form-item>
            <nz-form-control>
              <input
                nz-input
                formControlName="company"
                autocomplete="company"
                [placeholder]="'LOT_APPLICATION_MODAL.FORM.COMPANY' | translate"
              />
            </nz-form-control>
          </nz-form-item>
        }

        <nz-form-item>
          <nz-form-control>
            <nz-select
              [nzPlaceHolder]="'LOT_APPLICATION_MODAL.FORM.COUNTRY' | translate"
              formControlName="country"
              nzShowSearch
            >
              @for (country of countries; track country) {
                <nz-option [nzLabel]="country | countryName" [nzValue]="country"></nz-option>
              }
            </nz-select>
          </nz-form-control>
        </nz-form-item>

        @if (activeLotType !== 'green') {
          <nz-form-item>
            <nz-form-control>
              <nz-select
                [nzPlaceHolder]="'LOT_APPLICATION_MODAL.FORM.PORT_OF_DELIVERY' | translate"
                formControlName="portOfDelivery"
                nzShowSearch
              >
                @for (port of ports$ | push; track port.name) {
                  <nz-option [nzLabel]="port.name" [nzValue]="port.name + ' / ' + port.city"></nz-option>
                }
              </nz-select>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-control>
              <nz-select
                [nzPlaceHolder]="'LOT_APPLICATION_MODAL.FORM.SHIPPING_TYPE' | translate"
                formControlName="shippingType"
                nzShowSearch
              >
                <nz-option nzLabel="RO-RO" nzValue="RO-RO"></nz-option>
                <nz-option nzLabel="CONTAINER" nzValue="CONTAINER"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-control>
              <nz-select
                [nzPlaceHolder]="'LOT_APPLICATION_MODAL.FORM.FREIGHT_PAYMENT' | translate"
                formControlName="freightPayment"
                nzShowSearch
              >
                <nz-option nzLabel="FOB" nzValue="FOB"></nz-option>
                <nz-option nzLabel="CNF" nzValue="CNF"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        }

        <nz-form-item>
          <nz-form-control>
          <textarea
            nz-input
            formControlName="comment"
            autocomplete="off"
            [nzAutosize]="{ minRows: 2, maxRows: 6 }"
            [placeholder]="'LOT_APPLICATION_MODAL.FORM.COMMENT' | translate"
          ></textarea>
          </nz-form-control>
        </nz-form-item>

        @if (activeLotType === 'green') {
          <div class="form-items-wrapper-block">
            <div class="form-items-wrapper-block-label">
              {{ 'LOT_APPLICATION_MODAL.MESSENGERS.TITLE' | translate }}
            </div>
            <nz-row [nzGutter]="16">
              <nz-col [nzSpan]="8">
                <nz-form-item>
                  <nz-form-control>
                    <label nz-checkbox formControlName="isTelegram">Telegram</label>
                  </nz-form-control>
                </nz-form-item>
              </nz-col>
              <nz-col [nzSpan]="8">
                <nz-form-item>
                  <nz-form-control>
                    <label nz-checkbox formControlName="isWhatsApp">WhatsApp</label>
                  </nz-form-control>
                </nz-form-item>
              </nz-col>
              <nz-col [nzSpan]="8">
                <nz-form-item>
                  <nz-form-control>
                    <label nz-checkbox formControlName="isViber">Viber</label>
                  </nz-form-control>
                </nz-form-item>
              </nz-col>
            </nz-row>
          </div>
        }
      </nz-col>
    </nz-row>
  </form>
} @else {
  <nz-result
    nzStatus="success"
    nzTitle="{{ 'LOT_APPLICATION_MODAL.APPLICATION_SEND' | translate }}"
    nzSubTitle="{{ 'LOT_APPLICATION_MODAL.APPLICATION_SEND_CONTACT' | translate }}"
  ></nz-result>
}

<nz-row *nzModalFooter nzJustify="center" nzAlign="middle">
  @if (!(isSent$ | push)) {
    <button
      app-button
      appType="primary"
      size="large"
      [loading]="isSending$ | push"
      (click)="submit()"
      [disabled]="form.invalid"
    >
      {{ 'LOT_APPLICATION_MODAL.BTN' | translate }}
    </button>
  } @else {
    <button app-button appType="primary" (click)="close()">
      {{ 'LOT_APPLICATION_MODAL.BTN_CLOSE' | translate }}
    </button>
  }
</nz-row>

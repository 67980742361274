import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { FormValue } from 'src/app/utilities/types/form-value.type';
import { LotsListFilterFormValue } from '../types/lots-list-filter-form-value.type';
import { LotsFilters } from '../types/lots-filters.type';
import {
  LotSource,
  lotSourceByLotType,
  LotType,
} from 'src/app/services/lot-adapter/constants/lot-soruce-by-lot-type';
import {
  ColorCd,
  SharedInventoryCarFilterInput,
} from 'src/app/graphql/aucnet-main-graphql.service';
import { SharedInventoryMotoFilterInput } from 'src/app/graphql/aucnet-moto-graphql.service';
import {
  SharedInventoryTruckFilterInput,
  TruckBodyTypeCd,
} from 'src/app/graphql/aucnet-trucks-graphql.service';
import { StockCarFilterInput } from 'src/app/graphql/mochiron-graphql.service';

@Injectable()
export class LotsListFilterBuilderService {
  getParams(formValue: FormValue<LotsListFilterFormValue>): Params {
    return {
      ...formValue,
      page: 1,
    };
  }

  parseParams(params: Params): LotsListFilterFormValue {
    const {
      companyId,
      modelId,
      frame,
      idObject,
      bodyType,
      yearFrom,
      yearTo,
      mileageFrom,
      mileageTo,
      priceFrom,
      priceTo,
      engineSizeFrom,
      engineSizeTo,
      score,
      color,
      transmission,
      notSold,
    } = params;
    return {
      companyId: companyId,
      modelId: modelId,
      frame: this.parseArrayParam(frame),
      idObject: idObject,
      bodyType: bodyType,
      yearFrom: this.paramToNumber(yearFrom),
      yearTo: this.paramToNumber(yearTo),
      mileageFrom: this.paramToNumber(mileageFrom),
      mileageTo: this.paramToNumber(mileageTo),
      priceFrom: this.paramToNumber(priceFrom),
      priceTo: this.paramToNumber(priceTo),
      engineSizeFrom: this.paramToNumber(engineSizeFrom),
      engineSizeTo: this.paramToNumber(engineSizeTo),
      score: score,
      color: color,
      transmission: transmission,
      notSold: notSold === 'true',
    };
  }

  buildFilter(params: Params, lotType: LotType): LotsFilters {
    const parsedParams = this.parseParams(params);
    const lotSource = lotSourceByLotType[lotType];

    switch (lotSource) {
      case LotSource.AucnetAuto:
        return this.buildAucnetAutoFilter(parsedParams);

      case LotSource.AucnetMoto:
        return this.buildAucnetMotoFilter(parsedParams);

      case LotSource.AucnetTruck:
        return this.buildAucnetTruckFilter(parsedParams);

      case LotSource.Mochiron:
        return this.buildMochironFilter(parsedParams);
    }
  }

  private buildAucnetAutoFilter(params: LotsListFilterFormValue): SharedInventoryCarFilterInput {
    const filter: SharedInventoryCarFilterInput = {};
    const {
      companyId,
      modelId,
      frame,
      mileageFrom,
      mileageTo,
      engineSizeFrom,
      engineSizeTo,
      yearFrom,
      yearTo,
      priceFrom,
      priceTo,
      color,
      transmission,
      // notSold,
    } = params;

    if (companyId) filter.makerEn = { eq: companyId };
    if (modelId) filter.carnameEn = { eq: modelId };
    if (frame?.length) filter.modelCd = { in: frame };
    if (mileageFrom) filter.mileage = { gte: mileageFrom };
    if (mileageTo) filter.mileage = { ...filter.mileage, lte: mileageTo };
    if (engineSizeFrom) filter.exhaust = { gte: engineSizeFrom };
    if (engineSizeTo) filter.exhaust = { ...filter.exhaust, lte: engineSizeTo };
    if (yearFrom) filter.registration = { gte: yearFrom * 100 };
    if (yearTo) filter.registration = { ...filter.registration, lte: (yearTo + 1) * 100 };
    if (priceFrom) filter.wholesalePrice = { gte: priceFrom };
    if (priceTo) filter.wholesalePrice = { ...filter.wholesalePrice, lte: priceTo };
    if (color) filter.colorCd1 = { eq: color as ColorCd };
    if (transmission) filter.missionCd = { eq: transmission };
    // if (notSold) filter.isSold = { eq: notSold };
    return filter;
  }

  private buildAucnetMotoFilter(params: LotsListFilterFormValue): SharedInventoryMotoFilterInput {
    const filter: SharedInventoryMotoFilterInput = {};
    const { companyId, modelId, priceFrom, priceTo, mileageFrom, mileageTo, engineSizeFrom, engineSizeTo } = params;

    if (companyId) filter.company = { eq: companyId };
    if (modelId) filter.modelGroup = { eq: modelId };
    if (priceFrom) filter.wholesalePrice = { gte: priceFrom };
    if (priceTo) filter.wholesalePrice = { ...filter.wholesalePrice, lte: priceTo };
    if (mileageFrom) filter.mileage = { gte: mileageFrom };
    if (mileageTo) filter.mileage = { ...filter.mileage, lte: mileageTo };
    if (engineSizeFrom) filter.engineCc = { gte: engineSizeFrom };
    if (engineSizeTo) filter.engineCc = { ...filter.engineCc, lte: engineSizeTo };

    return filter;
  }

  private buildAucnetTruckFilter(params: LotsListFilterFormValue): SharedInventoryTruckFilterInput {
    const filter: SharedInventoryTruckFilterInput = {};
    const {
      companyId,
      modelId,
      priceFrom,
      priceTo,
      bodyType,
      yearFrom,
      yearTo,
      mileageFrom,
      mileageTo,
    } = params;
    if (companyId) filter.makerEn = { eq: companyId };
    if (modelId) filter.carnameEn = { eq: modelId };
    if (priceFrom) filter.wholesalePrice = { gte: priceFrom };
    if (priceTo) filter.wholesalePrice = { ...filter.wholesalePrice, lte: priceTo };
    if (bodyType) {
      filter.bodyTypeCd = { eq: bodyType as TruckBodyTypeCd };
    }
    if (yearFrom) filter.registration = { gte: yearFrom };
    if (yearTo) filter.registration = { ...filter.registration, lte: yearTo };
    if (mileageFrom) filter.mileage = { gte: mileageFrom };
    if (mileageTo) filter.mileage = { ...filter.mileage, lte: mileageTo };

    return filter;
  }

  private buildMochironFilter(params: LotsListFilterFormValue): StockCarFilterInput {
    const {
      companyId,
      modelId,
      frame,
      priceFrom,
      priceTo,
      mileageFrom,
      mileageTo,
      engineSizeFrom,
      engineSizeTo,
      yearFrom,
      yearTo,
      transmission,
      notSold,
    } = params;

    const filter: StockCarFilterInput = {};

    if (companyId) filter.companyEn = { eq: companyId };
    if (modelId) filter.modelEn = { eq: modelId };
    if (frame?.length) filter.modelTypeEn = { in: frame };
    if (priceFrom) filter.priceInJapan = { gte: priceFrom };
    if (priceTo) filter.priceInJapan = { ...filter.priceInJapan, lte: priceTo };
    if (mileageFrom) filter.mileageNum = { gte: mileageFrom };
    if (mileageTo) filter.mileageNum = { ...filter.mileageNum, lte: mileageTo };
    if (engineSizeFrom) filter.engineVolumeNum = { gte: engineSizeFrom };
    if (engineSizeTo) filter.engineVolumeNum = { ...filter.engineVolumeNum, lte: engineSizeTo };
    if (yearFrom) filter.year = { gte: yearFrom };
    if (yearTo) filter.year = { ...filter.year, lte: yearTo };
    if (transmission) filter.transmissionEn = { eq: transmission };
    if (notSold) filter.isSold = { eq: false };

    return filter;
  }

  private paramToNumber(param?: string): number | null {
    return param ? Number(param) : null;
  }

  private parseArrayParam(param?: string): string[] {
    if (param) {
      return Array.isArray(param) ? param : [param];
    }
    return [];
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LotsPageComponent } from './pages/lots-page/lots-page.component';
import { LotPageComponent } from './pages/lot-page/lot-page.component';

const routes: Routes = [
  { path: ':type/lots', component: LotsPageComponent },
  { path: ':type/lots/:lotId', component: LotPageComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuctionRoutingModule {}

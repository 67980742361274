import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SwiperInitializerDirective } from './swiper-initializer.directive';

@NgModule({
  declarations: [SwiperInitializerDirective],
  imports: [CommonModule],
  exports: [SwiperInitializerDirective],
})
export class SwiperInitializerDirectiveModule {}

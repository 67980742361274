<nz-spin [nzSpinning]="loading$ | push" nzSize="large">
  @if (lot$ | async; as lot) {
  <section class="lot-page">
    <div class="lot-page__content">
      <app-auction-breadcrumb
        [activeLotType]="activeLotType$ | push"
        [lotName]="lot.maker + ' ' + lot.mark"
      ></app-auction-breadcrumb>

      <div class="lot-page__main-wrapper">
        <app-lot-header [lot]="lot"></app-lot-header>

        <nz-row
          class="lot-page__wrapper"
          [nzGutter]="[
            { xs: 0, sm: 20 },
            { xs: 40, sm: 20 }
          ]"
        >
          <nz-col
            [nzXs]="{ span: 24, order: 2 }"
            [nzSm]="{ span: 24, order: 2 }"
            [nzMd]="{ span: 24, order: 2 }"
            [nzLg]="{ span: 8, order: 1 }"
          >
            <app-lot-info
              [lot]="lot"
              [currentUser]="currentUser$ | push"
              [activeLotType]="activeLotType$ | push"
            ></app-lot-info>
          </nz-col>

          <nz-col
            [nzXs]="{ span: 24, order: 1 }"
            [nzSm]="{ span: 24, order: 1 }"
            [nzMd]="{ span: 24, order: 1 }"
            [nzLg]="{ span: 16, order: 2 }"
          >
            <app-lot-gallery [lot]="lot"></app-lot-gallery>
          </nz-col>
        </nz-row>
      </div>

      <app-lot-info-description
        *canRender="lot.description"
        [description]="lot.description"
      ></app-lot-info-description>
      <app-lot-inspection [lot]="lot"></app-lot-inspection>
      <app-lot-package *canRenderForLotType="activeLotType$ | push" [lot]="lot"></app-lot-package>
      <app-lot-moto-damage-scheme
        *canRender="lot.schemaUrl"
        [schemaUrl]="lot.schemaUrl"
      ></app-lot-moto-damage-scheme>
      <app-lot-similar-ads [lot]="lot"></app-lot-similar-ads>
    </div>
  </section>
  }
</nz-spin>

import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-news-page-breadcrumb',
  templateUrl: './news-page-breadcrumb.component.html',
  styleUrl: './news-page-breadcrumb.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewsPageBreadcrumbComponent {

}

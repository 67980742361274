import {ChangeDetectionStrategy, Component} from '@angular/core';
import {NewsService} from "../../../../services/news/news.service";
import {Observable} from "rxjs";
import {NewsItemFragment} from "../../../../graphql/content-auto-graphql.service";

@Component({
  selector: 'app-news-section',
  templateUrl: './news-section.component.html',
  styleUrl: './news-section.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewsSectionComponent {
  news$: Observable<NewsItemFragment[]> | undefined;
  loading$: Observable<boolean> | undefined;

  constructor(newsService: NewsService) {
    const newsData$ = newsService.getLatestNews();
    this.loading$ = newsData$.loading$;
    this.news$ = newsData$.news$;
  }
}

import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { LotType } from '../../../../../services/lot-adapter/enums/lot-type.enum';

@Directive({
  selector: '[canRenderForLotType]',
})
export class CanRenderForLotTypeDirective {
  private lotType!: LotType;
  private hasView = false;

  @Input() set canRenderForLotType(lotType: LotType) {
    this.lotType = lotType;
    this.updateView();
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) {}

  private updateView(): void {
    const shouldRender = this.checkRenderCondition();

    if (shouldRender && !this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (!shouldRender && this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
    }
  }

  private checkRenderCondition(): boolean {
    return [LotType.Japan, LotType.Truck].includes(this.lotType);
  }
}

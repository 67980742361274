<div class="value" [class.main]="main">
  @if (loading) {
  <span nz-icon nzType="loading"></span>
  } @else { @if (main) { ~{{ value | plural : pluralKeys }}
  } @else {
  {{ value }}
  {{ 'DELIVERY_STATISTICS_SECTION.DAY_PLURALS.D' | translate }}
  } }
</div>

<div class="name">
  {{ name }}
</div>

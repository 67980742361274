<ul [class]="type">
  <li>
    <app-social-contact-link
      icon="instagram"
      link="https://www.instagram.com/akebono.co.ltd/"
      text="akebono.co.ltd"
      [hideText]="hideText"
    ></app-social-contact-link>
  </li>
  <li>
    <app-social-contact-link
      icon="mail"
      link="mailto:auction@akebono.world"
      text="auction@akebono.world"
      [hideText]="hideText"
    ></app-social-contact-link>
  </li>
  @if (canRenderTelegram$ | push) {
  <li>
    <app-social-contact-link
      icon="telegram"
      link="https://t.me/akebonojp"
      text="akebonojp"
      [hideText]="hideText"
    ></app-social-contact-link>
  </li>
  }
</ul>

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { LanguageService } from '../../../../services/language.service';
import { Language } from '../../../../utilities/types/language.type';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-language-selector-collapse',
  templateUrl: './language-selector-collapse.component.html',
  styleUrl: './language-selector-collapse.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguageSelectorCollapseComponent {
  currentLanguage$: Observable<Language>;
  availableLanguages: readonly Language[] = [];

  constructor(private languageService: LanguageService) {
    this.currentLanguage$ = this.languageService.currentLanguage$;
    this.availableLanguages = this.languageService.availableLanguages;
  }

  changeLanguage(language: Language): void {
    this.languageService.setLanguage(language);
  }

}
  
import { map, OperatorFunction, pipe } from 'rxjs';

export function exists<T, R>(selector?: (value: T) => R): OperatorFunction<T, boolean> {
  return pipe(
    map((value) => (selector ? selector(value) : value)),
    map((value: unknown) => {
      if (typeof value === 'boolean') {
        return true;
      } else {
        return Boolean(value);
      }
    }),
  );
}

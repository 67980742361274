import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LotType } from 'src/app/services/lot-adapter/enums/lot-type.enum';

@Component({
  selector: 'app-category-info',
  templateUrl: './category-info.component.html',
  styleUrl: './category-info.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategoryInfoComponent {
  @Input({ required: true }) activeLotType!: LotType;
}

export const ru = {
  "NEWS_PAGE": {
    "TITLE": "Новости",
    "TO_MAIN": "На главную",
    "NEWS_NOT_FOUND": "Новости не найдены",
    "FILTER": {
      "DATE_FROM": "Дата публикации от",
      "DATE_TO": "Дата публикации до",
      "APPLY": "Применить",
      "RESET": "Сбросить",
    },
  }
};

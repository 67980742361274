import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NewsContentPipe} from "./news-content.pipe";

@NgModule({
  declarations: [NewsContentPipe],
  imports: [CommonModule],
  exports: [NewsContentPipe],
})
export class NewsContentPipeModule {
}

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { EnvironmentService } from 'src/app/services/environment.service';

@Component({
  selector: 'app-layout-contacts',
  templateUrl: './layout-contacts.component.html',
  styleUrl: './layout-contacts.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutContactsComponent {
  domain: string;

  grid = {
    mobile: 24,
    desktop: 8,
  };

  constructor(environmentService: EnvironmentService) {
    this.domain = environmentService.currentEnvironment.domain;
  }
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-lot-moto-damage-scheme',
  templateUrl: './lot-moto-damage-scheme.component.html',
  styleUrl: './lot-moto-damage-scheme.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotMotoDamageSchemeComponent {
  @Input({ required: true }) schemaUrl: string | undefined;
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LotType } from 'src/app/services/lot-adapter/enums/lot-type.enum';

@Component({
  selector: 'app-auction-breadcrumb',
  templateUrl: './auction-breadcrumb.component.html',
  styleUrl: './auction-breadcrumb.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuctionBreadcrumbComponent {
  @Input({ required: true }) activeLotType!: LotType;
  @Input() isLoading = false;
  @Input() lotName?: string;
}

import {
  AkebonoLotSource,
  AkebonoLotTypeEnum,
} from '../../../graphql/akebono-main-graphql.service';
import { NavigationType, TvType } from '../../../graphql/aucnet-main-graphql.service';
import {
  AirCondition,
  AucnetTruckLotFragment,
  EqOption,
} from '../../../graphql/aucnet-trucks-graphql.service';
import { LotSource } from '../enums/lot-source.enum';
import { LotType } from '../enums/lot-type.enum';
import { ILotBase } from '../interfaces/lot-base.interface';
import { ILot } from '../interfaces/lot.interface';
import { MediaItem } from '../types/media-item.type';
import { Value } from '../types/value.type';
import { LotAdapterUtilities } from '../utilities/lot-adapter.utilities';
import { AucnetTruckLotMinimalAdapter } from './aucnet-truck-lot-minimal.adapter';

export class AucnetTruckLotAdapter implements ILot {
  id?: string | undefined;
  media?: MediaItem[] | undefined;
  maker?: string;
  mark?: string;
  model?: string | undefined;
  grade?: string | undefined;
  year?: number | undefined;
  engineVolume?: Value | undefined;
  transmission?: string | undefined;
  fuel?: string | undefined;
  bodyType?: string | undefined;
  drive?: string | undefined;
  rating?: string | undefined;
  price?: Value | undefined;
  mileage?: Value | undefined;
  color?: string | undefined;
  location?: string | undefined;
  vin?: string | undefined;
  accident?: string | undefined;
  isSold?: boolean | undefined;
  type: LotType;
  source: LotSource;
  akebonoLotType: AkebonoLotTypeEnum;
  akebonoLotSource: AkebonoLotSource;
  fields: Set<keyof ILotBase>;

  phone?: string;
  guid?: string;
  akebonoLotId?: string;
  exteriorRating?: string;
  interiorRating?: string;
  totalScore?: number;
  interiorScore?: number;
  benefits?: string;
  notice?: string;
  auctionNotice?: string;
  airCondition?: AirCondition;
  airbag?: EqOption;
  abs?: EqOption;
  sunroof?: EqOption;
  aluminumWheels?: EqOption;
  leatherSeat?: EqOption;
  navigation?: EqOption;
  navigationType?: NavigationType;
  tv?: EqOption;
  tvType?: TvType;
  keyless?: EqOption;
  smartKey?: EqOption;
  floorMat?: EqOption;
  backCamera?: EqOption;
  etc?: EqOption;
  guarantee?: boolean;
  manual?: boolean;
  record?: boolean;
  note?: boolean;

  constructor(lot: AucnetTruckLotFragment) {
    const minimalLot = new AucnetTruckLotMinimalAdapter(lot);

    this.type = minimalLot.type;
    this.source = minimalLot.source;
    this.akebonoLotType = minimalLot.akebonoLotType;
    this.akebonoLotSource = minimalLot.akebonoLotSource;

    this.id = minimalLot.id;
    this.maker = minimalLot.maker;
    this.mark = minimalLot.mark;
    this.model = minimalLot.model;
    this.grade = minimalLot.grade;
    this.year = minimalLot.year;
    this.engineVolume = minimalLot.engineVolume;
    this.transmission = minimalLot.transmission;
    this.fuel = minimalLot.fuel;
    this.bodyType = minimalLot.bodyType;
    this.drive = minimalLot.drive;
    this.rating = minimalLot.rating;
    this.price = minimalLot.price;
    this.mileage = minimalLot.mileage;
    this.color = minimalLot.color;
    this.location = minimalLot.location;
    this.vin = minimalLot.vin;
    this.accident = minimalLot.accident;
    this.isSold = minimalLot.isSold;
    this.media = minimalLot.media;

    this.exteriorRating = lot.aisScoreExt || undefined;
    this.interiorRating = lot.aisScoreInt || undefined;
    this.totalScore = lot.selfScoreTotal || undefined;
    this.interiorScore = lot.selfScoreInt || undefined;
    this.benefits = lot.sellingPointEn || undefined;
    this.notice = lot.noticeInfo || undefined;
    this.auctionNotice = lot.aucNoticeInfo || undefined;
    this.airCondition = lot.airCondition || undefined;
    this.airbag = lot.airbag || undefined;
    this.sunroof = lot.sunroof || undefined;
    this.aluminumWheels = lot.aw || undefined;
    this.leatherSeat = lot.leatherSeat || undefined;
    this.navigation = lot.navi || undefined;
    this.navigationType = lot.naviType || undefined;
    this.tv = lot.tv || undefined;
    this.tvType = lot.tvType || undefined;
    this.keyless = lot.keyless || undefined;
    this.smartKey = lot.smartKey || undefined;
    this.floorMat = lot.floormat || undefined;
    this.backCamera = lot.backcamera || undefined;
    this.etc = lot.etc || undefined;
    this.guarantee = LotAdapterUtilities.toBoolean(lot.guaranteeFlag);
    this.manual = LotAdapterUtilities.toBoolean(lot.manualFlag);
    this.record = LotAdapterUtilities.toBoolean(lot.recordFlag);
    this.note = LotAdapterUtilities.toBoolean(lot.noteFlag);

    this.fields = LotAdapterUtilities.mergeFieldSet(minimalLot.fields, [
      'exteriorRating',
      'interiorRating',
      'totalScore',
      'interiorScore',
      'benefits',
      'notice',
      'auctionNotice',
      'airCondition',
      'airbag',
      'sunroof',
      'aluminumWheels',
      'leatherSeat',
      'navigation',
      'navigationType',
      'tv',
      'tvType',
      'keyless',
      'smartKey',
      'floorMat',
      'backCamera',
      'etc',
      'manual',
      'record',
      'note',
    ]);
  }
}

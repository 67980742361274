<nz-spin [nzSpinning]="isLoading$ | push" nzSize="large">
  <form nz-form [formGroup]="formGroup" nzLayout="vertical" (ngSubmit)="onSubmit()">
    <nz-row [nzGutter]="[17, 20]">
      <nz-col [nzSpan]="12">
        <nz-select
          [nzPlaceHolder]="'HEADER_SECTION.MINI_FILTERING_FORM.MARKA' | translate"
          formControlName="companyId"
          nzShowSearch
        >
          @for (company of companies$ | push; track $index) {
          <nz-option [nzValue]="company.id" [nzLabel]="company.name!"> </nz-option>
          }
        </nz-select>
      </nz-col>

      <nz-col [nzSpan]="12">
        <nz-select
          [nzPlaceHolder]="'HEADER_SECTION.MINI_FILTERING_FORM.MODEL' | translate"
          formControlName="modelId"
          [nzDropdownMatchSelectWidth]="false"
          nzShowSearch
        >
          @for (model of models$ | push; track $index) {
          <nz-option [nzValue]="model.id" nzLabel="{{ model.name }} ({{ model.lotsCount }})">
          </nz-option>
          }
        </nz-select>
      </nz-col>

      <nz-col [nzSpan]="8">
        <nz-select
          [nzPlaceHolder]="'HEADER_SECTION.MINI_FILTERING_FORM.YEAR' | translate"
          formControlName="yearFrom"
          [nzDropdownMatchSelectWidth]="false"
          nzShowSearch
        >
          @for (year of years; track year) {
          <nz-option [nzValue]="year" [nzLabel]="year"> </nz-option>
          }
        </nz-select>
      </nz-col>

      <nz-col [nzSpan]="8">
        <nz-input-number
          [nzPlaceHolder]="'HEADER_SECTION.MINI_FILTERING_FORM.PRICE_FROM' | translate"
          formControlName="priceFrom"
          [nzStep]="1"
          [nzMin]="0"
        ></nz-input-number>
      </nz-col>

      <nz-col [nzSpan]="8">
        <nz-input-number
          [nzPlaceHolder]="'HEADER_SECTION.MINI_FILTERING_FORM.PRICE_TO' | translate"
          formControlName="priceTo"
          [nzStep]="1"
          [nzMin]="0"
        ></nz-input-number>
      </nz-col>

      <nz-col [nzSpan]="24">
        <button app-button appType="primary" size="large" [block]="true" type="submit">
          {{ 'HEADER_SECTION.MINI_FILTERING_FORM.FIND_CAR' | translate }}
        </button>
      </nz-col>
    </nz-row>
  </form>
</nz-spin>

<div class="block">
  <div class="content">
    <div class="header-section">
      <app-exchange-rates></app-exchange-rates>
      <app-japan-time></app-japan-time>
    </div>
    <div class="title">
      <h1>{{ 'HEADER_SECTION.TITLE' | translate }}</h1>
      <div>{{ 'HEADER_SECTION.TEXT' | translate }}</div>
    </div>

    <div class="form">
      <app-mini-filtering-form></app-mini-filtering-form>
    </div>

    <div class="tracking">
      <app-tracking-link></app-tracking-link>
    </div>
  </div>
</div>

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** The `DateTime` scalar type represents time data, ISO-8601 encoded UTC date string. */
  DateTime: { input: any; output: any; }
};

/** Информация о банке */
export type Bank = {
  __typename?: 'Bank';
  /** Алиас банка */
  alias?: Maybe<BankEnum>;
  id?: Maybe<Scalars['Int']['output']>;
  /** Валюта банка в которой делаются расчёты */
  isoCode?: Maybe<CurrencyEnum>;
  /** Название банка */
  name?: Maybe<Scalars['String']['output']>;
};

/** Доступные банки */
export enum BankEnum {
  Atb = 'ATB',
  Cbr = 'CBR',
  Khanbank = 'KHANBANK',
  Maybank2U = 'MAYBANK2U',
  Mufg = 'MUFG',
  Primsotsbank = 'PRIMSOTSBANK'
}

/** Доступные валюты */
export enum CurrencyEnum {
  Aed = 'AED',
  Amd = 'AMD',
  Aud = 'AUD',
  Azn = 'AZN',
  Bdt = 'BDT',
  Bgn = 'BGN',
  Bnd = 'BND',
  Brl = 'BRL',
  Byn = 'BYN',
  Cad = 'CAD',
  Chf = 'CHF',
  Cny = 'CNY',
  Czk = 'CZK',
  Dkk = 'DKK',
  Egp = 'EGP',
  Eur = 'EUR',
  Gbp = 'GBP',
  Gel = 'GEL',
  Hkd = 'HKD',
  Huf = 'HUF',
  Idr = 'IDR',
  Inr = 'INR',
  Jpy = 'JPY',
  Kgs = 'KGS',
  Krw = 'KRW',
  Kwd = 'KWD',
  Kzt = 'KZT',
  Lkr = 'LKR',
  Mdl = 'MDL',
  Mnt = 'MNT',
  Mxn = 'MXN',
  Myr = 'MYR',
  Nok = 'NOK',
  Nzd = 'NZD',
  Pgk = 'PGK',
  Php = 'PHP',
  Pkr = 'PKR',
  Pln = 'PLN',
  Qar = 'QAR',
  Ron = 'RON',
  Rsd = 'RSD',
  Rub = 'RUB',
  Sar = 'SAR',
  Sek = 'SEK',
  Sgd = 'SGD',
  Thb = 'THB',
  Tjs = 'TJS',
  Tmt = 'TMT',
  Try = 'TRY',
  Uah = 'UAH',
  Usd = 'USD',
  Uzs = 'UZS',
  Vnd = 'VND',
  Xag = 'XAG',
  Xau = 'XAU',
  Xdr = 'XDR',
  Zar = 'ZAR'
}

/** Информация о курсе */
export type ExchangeRate = {
  __typename?: 'ExchangeRate';
  /** Информация о банке */
  bank?: Maybe<Bank>;
  /** Покупка */
  buy?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  /** Валюта */
  isoCode?: Maybe<CurrencyEnum>;
  /** Последнее время обновления */
  lastUpdate?: Maybe<Scalars['DateTime']['output']>;
  /** Номинал */
  nominal?: Maybe<Scalars['Int']['output']>;
  /** Продажа */
  sell?: Maybe<Scalars['Float']['output']>;
};

export type Query = {
  __typename?: 'Query';
  /** Курс определенной валюты в банке */
  exchangeRate?: Maybe<ExchangeRate>;
  /** Все курсы по банку */
  exchangeRates?: Maybe<Array<ExchangeRate>>;
};


export type QueryExchangeRateArgs = {
  bank: BankEnum;
  currency: CurrencyEnum;
};


export type QueryExchangeRatesArgs = {
  bank: BankEnum;
};

export type ExchangeRateFieldsFragment = { __typename?: 'ExchangeRate', sell?: number | null, nominal?: number | null };

export type ExchangeRateQueryVariables = Exact<{ [key: string]: never; }>;


export type ExchangeRateQuery = { __typename?: 'Query', rubToJpy?: { __typename?: 'ExchangeRate', sell?: number | null, nominal?: number | null } | null, jpyToUsd?: { __typename?: 'ExchangeRate', sell?: number | null, nominal?: number | null } | null, jpyToEur?: { __typename?: 'ExchangeRate', sell?: number | null, nominal?: number | null } | null };

export type ExchangeRatesQueryVariables = Exact<{ [key: string]: never; }>;


export type ExchangeRatesQuery = { __typename?: 'Query', jpyToRub?: { __typename?: 'ExchangeRate', sell?: number | null, nominal?: number | null } | null, usdToRub?: { __typename?: 'ExchangeRate', sell?: number | null, nominal?: number | null } | null };

export const ExchangeRateFieldsFragmentDoc = gql`
    fragment ExchangeRateFields on ExchangeRate {
  sell
  nominal
}
    `;
export const ExchangeRateDocument = gql`
    query exchangeRate {
  rubToJpy: exchangeRate(bank: CBR, currency: JPY) {
    ...ExchangeRateFields
  }
  jpyToUsd: exchangeRate(bank: MUFG, currency: USD) {
    ...ExchangeRateFields
  }
  jpyToEur: exchangeRate(bank: MUFG, currency: EUR) {
    ...ExchangeRateFields
  }
}
    ${ExchangeRateFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ExchangeRateGQL extends Apollo.Query<ExchangeRateQuery, ExchangeRateQueryVariables> {
    override document = ExchangeRateDocument;
    override client = 'exchange-rate/open';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ExchangeRatesDocument = gql`
    query exchangeRates {
  jpyToRub: exchangeRate(bank: CBR, currency: JPY) {
    ...ExchangeRateFields
  }
  usdToRub: exchangeRate(bank: CBR, currency: USD) {
    ...ExchangeRateFields
  }
}
    ${ExchangeRateFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ExchangeRatesGQL extends Apollo.Query<ExchangeRatesQuery, ExchangeRatesQueryVariables> {
    override document = ExchangeRatesDocument;
    override client = 'exchange-rate/open';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    
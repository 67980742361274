<ng-container *nzModalTitle>
  {{ 'LOT.SHARE' | translate }}
</ng-container>

<div class="share-modal-content">
  <div class="share-icons">
    <button
      *ngFor="let social of socialMediaLinks"
      nz-button
      (click)="shareTo(social.url)"
      [attr.aria-label]="'Share on ' + social.platform"
    >
      <img [src]="social.icon" [alt]="social.platform" />
    </button>
  </div>

  <div class="share-link-container">
    <input nz-input [value]="lotUrl" readonly />
    <button nz-button nzType="primary" (click)="copyToClipboard()">
      {{ 'LOT.COPY_LINK' | translate }}
    </button>
  </div>
</div>

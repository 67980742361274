import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CollapseComponent } from './collapse.component';
import { NzOutletModule } from 'ng-zorro-antd/core/outlet';
import { NzIconModule } from 'ng-zorro-antd/icon';

@NgModule({
  declarations: [CollapseComponent],
  imports: [CommonModule, NzOutletModule, NzIconModule],
  exports: [CollapseComponent],
})
export class CollapseModule {}

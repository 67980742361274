import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {NewsItemPageComponent} from "./news-item-page.component";
import {RxPush} from "@rx-angular/template/push";
import {NzBreadCrumbComponent, NzBreadCrumbItemComponent} from "ng-zorro-antd/breadcrumb";
import {NzIconDirective, NzIconModule} from "ng-zorro-antd/icon";
import {RouterLink} from "@angular/router";
import {NzSpinComponent} from "ng-zorro-antd/spin";
import {TranslateModule} from "@ngx-translate/core";
import {LanguageService} from "../../../../services/language.service";
import {en} from "./locale/en";
import {ru} from "./locale/ru";
import {NewsContentPipeModule} from "../../../../pipes/news-content-pipe/news-content-pipe.module";

@NgModule({
  declarations: [NewsItemPageComponent],
  imports: [
    CommonModule,
    RxPush,
    NzIconModule,
    NzBreadCrumbComponent,
    NzBreadCrumbItemComponent,
    NzIconDirective,
    RouterLink,
    NzSpinComponent,
    TranslateModule,
    NewsContentPipeModule,
  ],
})
export class NewsItemPageModule {
  constructor(languageService: LanguageService) {
    languageService.addLocales({en, ru});
  }
}

<div class="title">
  {{ 'LOT_GALLERY.TITLE' | translate }}
</div>

<div class="buttons">
  <a app-button appType="primary" [href]="loginUrl">
    {{ 'NOT_AUTHORIZED_ACTIONS.LOGIN' | translate }}
  </a>
  <a app-button appType="primary" [href]="registrationUrl">
    {{ 'NOT_AUTHORIZED_ACTIONS.REGISTER' | translate }}
  </a>
</div>

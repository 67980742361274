import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-thumbs-slider-video-item',
  templateUrl: './thumbs-slider-video-item.component.html',
  styleUrl: './thumbs-slider-video-item.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ThumbsSliderVideoItemComponent {
  @Input({ required: true }) source!: string;
}

import { ChangeDetectionStrategy, Component, Input, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-delivery-statistics-step',
  templateUrl: './delivery-statistics-step.component.html',
  styleUrl: './delivery-statistics-step.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeliveryStatisticsStepComponent {
  @Input({ required: true }) title?: string;
  @Input({ required: true }) days?: string;
  @Input({ required: true }) icon?: string;
  @Input() videoUrl?: string;
  @Input({ required: true }) bottomTitle?: string;
  @Input({ required: true }) bottomDays?: string;
  @Input({ required: true }) bottomIcon?: string;
  @Input() bottomVideoUrl?: string;

  @ViewChild('content', { static: true })
  content!: TemplateRef<unknown>;
}

<app-news-page-breadcrumb></app-news-page-breadcrumb>

<h1>{{ 'NEWS_PAGE.TITLE' | translate }}</h1>
<app-news-page-filter></app-news-page-filter>

<nz-spin [nzSpinning]="loading$ | push" nzSize="large">
  <div #newsList class="news">
    <nz-row [nzGutter]="[20, 20]" nzJustify="start">
      @for (newsItem of news$ | push; track newsItem.id) {
        <nz-col [nzXs]="24" [nzSm]="12" [nzMd]="8" [nzLg]="8" [nzXl]="8">
          <app-news-card [background]="'white'" [newsItem]="newsItem"></app-news-card>
        </nz-col>
      } @empty {
        <nz-col nzMd="24">
          <nz-result [nzTitle]="'NEWS_PAGE.NEWS_NOT_FOUND' | translate"></nz-result>
        </nz-col>
      }
    </nz-row>
  </div>
</nz-spin>

<div class="pagination">
  <app-pagination
    [pageIndex]="currentPage"
    [pageSize]="pageSize"
    [total]="total$ | push"
    (pageIndexChange)="onPageChange($event)"
  ></app-pagination>
</div>

import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { NZ_MODAL_DATA } from 'ng-zorro-antd/modal';
import { StatisticsStepModalData } from './types/lot-info.types';

@Component({
  selector: 'app-delivery-statistics-step-modal',
  templateUrl: './delivery-statistics-step-modal.component.html',
  styleUrl: './delivery-statistics-step-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeliveryStatisticsStepModalComponent {
  constructor(@Inject(NZ_MODAL_DATA) public data: StatisticsStepModalData) {}

  get description(): string {
    return this.data.description;
  }

  get videoUrl(): string {
    return this.data.videoUrl;
  }
}

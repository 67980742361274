import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CategorySectionComponent } from './category-section.component';
import { CategoryCardComponent } from './components/category-card/category-card.component';
import { ButtonModule } from '../../../../components/button/button.module';
import { LanguageService } from '../../../../services/language.service';
import { TranslateModule } from '@ngx-translate/core';
import { en } from './locale/en';
import { ru } from './locale/ru';
import { RouterLink } from '@angular/router';
import { NzGridModule } from 'ng-zorro-antd/grid';

@NgModule({
  declarations: [CategorySectionComponent, CategoryCardComponent],
  imports: [CommonModule, ButtonModule, TranslateModule, RouterLink, NzGridModule],
  exports: [CategorySectionComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CategorySectionModule {
  constructor(languageService: LanguageService) {
    languageService.addLocales({ en, ru });
  }
}

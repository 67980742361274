<div class="description">
  <div class="stats-container">
    <h1>{{ 'DELIVERY_STATISTICS_SECTION.DELIVERY_FROM' | translate }}</h1>
    <p>{{ 'DELIVERY_STATISTICS_SECTION.BEFORE_TO_TOYAMA' | translate }}</p>
    <nz-row [nzGutter]="[0, 30]">
      <nz-col [nzXs]="12" [nzMd]="{ span: 12, order: 1 }" [nzLg]="{ span: 8, order: 2 }">
        <app-delivery-statistics-stat
          [loading]="loading$ | push"
          [value]="carsDeliveryDays$ | push"
          [name]="'DELIVERY_STATISTICS_SECTION.CARS' | translate"
        ></app-delivery-statistics-stat>
      </nz-col>
      <nz-col [nzXs]="12" [nzMd]="{ span: 12, order: 3 }" [nzLg]="{ span: 8, order: 1 }">
        <app-delivery-statistics-stat
          [loading]="loading$ | push"
          [value]="averageDeliveryDays$ | push"
          [name]="'DELIVERY_STATISTICS_SECTION.AVERAGE' | translate"
          [main]="true"
        ></app-delivery-statistics-stat>
      </nz-col>
      <nz-col [nzXs]="24" [nzMd]="{ span: 12, order: 2 }" [nzLg]="{ span: 8, order: 3 }">
        <app-delivery-statistics-stat
          [loading]="loading$ | push"
          [value]="motoDeliveryDays$ | push"
          [name]="'DELIVERY_STATISTICS_SECTION.MOTO' | translate"
        ></app-delivery-statistics-stat>
      </nz-col>
    </nz-row>
  </div>

  <div class="stats-container">
    <p>{{ 'DELIVERY_STATISTICS_SECTION.BEFORE_TO_VLADIVOSTOK' | translate }}</p>

    <nz-row [nzGutter]="[20, 20]">
      <nz-col [nzSpan]="24">
        <app-delivery-statistics-stat
          [loading]="loading$ | push"
          [value]="toVladivostokDeliveryDays$ | push"
          [name]="'DELIVERY_STATISTICS_SECTION.CARS' | translate"
        ></app-delivery-statistics-stat>
      </nz-col>
    </nz-row>
  </div>

  <div class="note">
    {{
      'DELIVERY_STATISTICS_SECTION.MARK'
        | translate
          : {
              date: today | date : 'dd.MM.yyyy'
            }
    }}
  </div>
</div>

<div class="header">
  <img src="/assets/img/delivery-statistics.jpg" alt="trucks" />
</div>

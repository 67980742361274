<button
  app-button
  appType="secondary"
  size="large"
  [block]="true"
  type="submit"
  (click)="resetFilters.emit()"
>
  {{ 'FILTER.RESET_BTN' | translate }}
</button>
<button
  app-button
  appType="primary"
  size="large"
  [block]="true"
  type="submit"
  (click)="applyFilters.emit()"
>
  {{ 'FILTER.APPLY_BTN' | translate }}
</button>

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-social-contact-link',
  templateUrl: './social-contact-link.component.html',
  styleUrl: './social-contact-link.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SocialContactsLinkComponent {
  @Input({ required: true }) icon!: 'instagram' | 'youtube' | 'facebook' | 'mail' | 'telegram';
  @Input({ required: true }) link!: string;
  @Input() text?: string;
  @Input() hideText = false;
}

import { ChangeDetectionStrategy, Component, HostListener, Input } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';

import { MainSliderVideoViewModalComponent } from '../main-slider-video-view-modal/main-slider-video-view-modal.component';

@Component({
  selector: 'app-main-slider-video-item',
  templateUrl: './main-slider-video-item.component.html',
  styleUrl: './main-slider-video-item.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainSliderVideoItemComponent {
  @Input({ required: true }) source!: string;

  constructor(private readonly modalService: NzModalService) {}

  @HostListener('click')
  open(): void {
    this.modalService.create({
      nzContent: MainSliderVideoViewModalComponent,
      nzData: this.source,
      nzFooter: null,
      nzClosable: false,
      nzCentered: true,
      nzWidth: '100%',
      nzClassName: 'no-paddings',
    });
  }
}

import { Injectable } from '@angular/core';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { LotType } from 'src/app/services/lot-adapter/enums/lot-type.enum';
import { LotListFilterComponent } from '../lots-list-filter.component';

@Injectable()
export class LotsListFilterService {
  constructor(private drawerService: NzDrawerService) {}

  open(activeLotType: LotType): void {
    this.drawerService.create({
      nzContent: LotListFilterComponent,
      nzPlacement: 'top',
      nzHeight: '100%',
      nzClosable: false,
      nzBodyStyle: { padding: 0 },
      nzContentParams: {
        activeLotType,
      },
    });
  }
}

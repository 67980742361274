import { Department } from '../../../graphql/mochiron-graphql.service';
import { LotType } from '../enums/lot-type.enum';

export const lotTypeByDepartmentId = {
  [Department.Dubai]: LotType.Dubai,
  [Department.GreenCorner]: LotType.GreenCorner,
  [Department.Mongolia]: LotType.Mongolia,
  [Department.Utilization]: LotType.Recycling,
  [Department.UsaMiami]: LotType.Usa
};

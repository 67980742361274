<a
  nz-dropdown
  nzTrigger="click"
  nzPlacement="bottomRight"
  [nzDropdownMenu]="menu"
  class="select-head"
  [class.dropdown-open]="dropdownOpen"
  [(nzVisible)]="dropdownOpen"
>
  <i nz-icon nzType="icons:globe-lang"></i>
  <span>{{ currentLanguage$ | push | titlecase }}</span>
  <i nz-icon nzType="down" class="icon_down"></i>
</a>
<nz-dropdown-menu #menu="nzDropdownMenu">
  <ul class="dropdown-list">
    @for (language of availableLanguages; track language) {
    <li tabindex="0" (click)="changeLanguage(language)">
      {{ 'LANGUAGE_SELECTOR.' + language | uppercase | translate }}
    </li>
    }
  </ul>
</nz-dropdown-menu>

import { ChangeDetectionStrategy, Component, DestroyRef, HostBinding, Input } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { map, Observable } from 'rxjs';

import { ILotMinimal } from '../../../../../../services/lot-adapter/interfaces/lot-minimal.interface';
import { ILot } from '../../../../../../services/lot-adapter/interfaces/lot.interface';
import { LotSimilarAdsService } from '../../services/lot-similar-ads/lot-similar-ads.service';

@Component({
  selector: 'app-lot-similar-ads',
  templateUrl: './lot-similar-ads.component.html',
  styleUrl: './lot-similar-ads.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [LotSimilarAdsService],
})
export class LotSimilarAdsComponent {
  @Input({ required: true }) lot!: ILot;

  similarLots$!: Observable<ILotMinimal[]>;
  loading$!: Observable<boolean>;

  @HostBinding('style.display')
  display: 'block' | 'none' = 'block';

  constructor(private destroy: DestroyRef, private lotSimilarAdsService: LotSimilarAdsService) {}

  ngOnInit(): void {
    const similarLotsData$ = this.lotSimilarAdsService.getSimilarAds(this.lot);

    this.loading$ = similarLotsData$.loading$;
    this.similarLots$ = similarLotsData$.lots$;

    this.similarLots$
      .pipe(
        map((lots) => !!lots.length),
        map((hasLots) => (hasLots ? 'block' : 'none')),
        takeUntilDestroyed(this.destroy),
      )
      .subscribe((componentDisplay) => (this.display = componentDisplay));
  }
}

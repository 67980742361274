import { Injectable } from '@angular/core';

import {
  SharedInventoryContactFormCreateGQL,
  SharedInventoryContactFormCreateInput,
  AkebonoLotTypeEnum,
  AkebonoLotSource,
  SharedInventoryContactFormCreateMutation,
} from '../../../../../../graphql/akebono-main-graphql.service';
import {
  MutationHandlingServiceV2,
} from '../../../../../../services/mutation-handling-service-v2/mutation-handling-v2.service';

@Injectable()
export class LotContactFormService {
  constructor(
    private mhs: MutationHandlingServiceV2,
    private sharedInventoryContactFormCreateGQL: SharedInventoryContactFormCreateGQL,
  ) {
  }

  handleSharedInventoryContactFormCreateMutation(
    lotData: {
      lotId: string;
      lotType: AkebonoLotTypeEnum | string;
      lotSource: AkebonoLotSource | string;
      objectGUID?: string;
      departmentId?: string;
    },
    formValue: any,
  ) {
    const input: SharedInventoryContactFormCreateInput = {
      amount: formValue.price || 0,
      currency: formValue.currency || '',
      comments: formValue.comment,
      company: formValue.company,
      email: formValue.email,
      firstName: formValue.firstName,
      hasTelegram: formValue.isTelegram,
      hasViber: formValue.isViber,
      hasWhatsapp: formValue.isWhatsApp,
      lastName: formValue.lastName,
      lotId: lotData.lotId,
      lotType: lotData.lotType as AkebonoLotTypeEnum,
      lotSource: lotData.lotSource as AkebonoLotSource,
      middleName: null,
      phoneNumber: formValue.phone,
      countryIso: formValue.country?.countryCode || null,
      deliveryPort: formValue.portOfDelivery,
      shippingType: formValue.shippingType,
      freightPaymentType: formValue.freightPayment,
      objectGuid: lotData.objectGUID,
      departmentCode: lotData.departmentId,
    };

    return this.mhs.mutate<
      SharedInventoryContactFormCreateMutation,
      { input: SharedInventoryContactFormCreateInput }
    >(this.sharedInventoryContactFormCreateGQL, { input });
  }
}

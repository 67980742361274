<div>
  <div class="header">
    <span></span>
    <img alt="Akebono" src="/assets/logos/svg/akebono-logo.svg" />
    <i
      nz-icon
      class="logo"
      tabindex="0"
      nzType="close"
      nzTheme="outline"
      (click)="closeMobileMenu()"
    ></i>
  </div>
  <div class="main">
    <app-language-selector-collapse></app-language-selector-collapse>
    <app-main-contacts type="vertical"></app-main-contacts>
  </div>
</div>
<div class="footer">
  <app-layout-footer-copyright></app-layout-footer-copyright>
</div>

import { ChangeDetectionStrategy, Component, ContentChildren, QueryList } from '@angular/core';
import { DeliveryStatisticsStepComponent } from '../delivery-statistics-step/delivery-statistics-step.component';

@Component({
  selector: 'app-delivery-statistics-steps',
  templateUrl: './delivery-statistics-steps.component.html',
  styleUrl: './delivery-statistics-steps.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeliveryStatisticsStepsComponent {
  @ContentChildren(DeliveryStatisticsStepComponent)
  steps!: QueryList<DeliveryStatisticsStepComponent>;
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ILotMinimal } from 'src/app/services/lot-adapter/interfaces/lot-minimal.interface';

@Component({
  selector: 'app-lot-card',
  templateUrl: './lot-card.component.html',
  styleUrl: './lot-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotCardComponent {
  @Input({ required: true }) lot!: ILotMinimal;
}

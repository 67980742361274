import { ChangeDetectionStrategy, Component } from '@angular/core';
import { combineLatest, map, Observable, shareReplay } from 'rxjs';
import {
  DonateBuhankaStatusGQL,
  DonateBuhankaStatusQuery,
  DonateBuhankaStatusQueryVariables,
} from 'src/app/graphql/shared-familiar-graphql.service';
import { QueryHandlingService } from 'src/app/services/query-handling-service/query-handling.service';

@Component({
  selector: 'app-buhanka-donate-section',
  templateUrl: './buhanka-donate-section.component.html',
  styleUrl: './buhanka-donate-section.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BuhankaDonateSectionComponent {
  loading$: Observable<boolean>;

  total$: Observable<number>;
  collected$: Observable<number>;

  donateEnabled$: Observable<boolean>;

  constructor(qhs: QueryHandlingService, donateBuhankaStatusGQL: DonateBuhankaStatusGQL) {
    const queryRef = qhs.fetch<DonateBuhankaStatusQuery, DonateBuhankaStatusQueryVariables>(
      donateBuhankaStatusGQL,
    );

    this.loading$ = queryRef.loading;
    const data$ = queryRef.data.pipe(
      map((data) => data.donateBuhankaStatus),
      shareReplay(1),
    );

    this.total$ = data$.pipe(map((data) => data?.total || 0));
    this.collected$ = data$.pipe(map((data) => data?.collected || 0));
    this.donateEnabled$ = combineLatest([this.total$, this.collected$]).pipe(
      map(([total, collected]) => total < collected),
    );
  }
}

import { ErrorMaybe } from '../interfaces/error-maybe.interface';
import { BaseMutationHandlingException } from './base-mutation-handling.exception';

export class MutationExecutionException extends BaseMutationHandlingException {
  constructor(private error: ErrorMaybe) {
    super(error.message ?? 'Unknown execution error');
  }

  log(): void {
    console.error(this.message, this.error);
  }
}

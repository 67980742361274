/* eslint-disable @angular-eslint/component-selector */
import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'button[app-button], a[app-button]',
  host: {
    class: 'app-button',
    '[class.default]': `appType === 'default'`,
    '[class.ghost]': `appType === 'ghost'`,
    '[class.green]': `appType === 'green'`,
    '[class.primary-ghost]': `appType === 'primary-ghost'`,
    '[class.secondary]': `appType === 'secondary'`,
    '[class.primary]': `appType === 'primary'`,
    '[class.large]': `size === 'large'`,
    '[class.default-size]': `size === 'default'`,
    '[class.small-size]': `size === 'small'`,
    '[class.round]': `shape === 'round'`,
    '[class.block]': `block`,
    '[class.disabled]': `disabled`,
    '[attr.disabled]': 'disabled || loading || null',
  },
  templateUrl: './button.component.html',
  styleUrl: './button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ButtonComponent {
  @Input() appType: 'default' | 'ghost' | 'green' | 'primary-ghost' | 'secondary' | 'primary' = 'default';
  @Input() size: 'small' | 'default' | 'large' = 'default';
  @Input() shape: 'default' | 'round' = 'default';
  @Input() disabled = false;
  @Input() block = false;
  @Input() loading = false;
}

export enum LotType {
  Mongolia = 'mongolia',
  GreenCorner = 'green',
  Japan = 'japan',
  Recycling = 'recycling',
  Motorcycle = 'motorcycle',
  Truck = 'truck',
  Dubai = 'dubai',
  Usa = 'usa'
}

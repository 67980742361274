import {ChangeDetectionStrategy, Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NZ_MODAL_DATA, NzModalRef} from 'ng-zorro-antd/modal';
import {
  AkebonoLotSource,
  AkebonoLotTypeEnum,
  CurrentUserServiceDataQuery,
} from '../../../../../../graphql/akebono-main-graphql.service';
import {CountryService} from '../../services/country/country.service';
import {LotContactFormService} from '../../services/lot-contact-form/lot-contact-form.service';
import {Country} from '../../services/country/country.type';
import {catchError, filter, map, Observable, of} from 'rxjs';
import {LotContactForm, LotContactFormModalData, Port,} from './types/lot-contact-form-modal.types';
import seaPorts from 'sea-ports';
import {validateAllFormFields} from '../../../../../../utilities/functions/validate-all-form-fields.function';
import {LotType} from 'src/app/services/lot-adapter/enums/lot-type.enum';
import {LanguageService} from "../../../../../../services/language.service";

@Component({
  selector: 'app-lot-contact-form-modal',
  templateUrl: './lot-contact-form-modal.component.html',
  styleUrl: './lot-contact-form-modal.component.scss',
  providers: [CountryService, LotContactFormService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotContactFormModalComponent implements OnInit {
  lotId!: string;
  lotType!: AkebonoLotTypeEnum | string;
  lotSource!: AkebonoLotSource | string;
  objectGUID!: string;
  departmentId!: string;
  currentUser: CurrentUserServiceDataQuery['currentUser'];
  activeLotType!: LotType;

  countries: Country[] = [];
  ports$: Observable<Port[]> = of([]);

  form!: FormGroup<LotContactForm>;
  isSending$ = of(false);
  isSent$ = of(false);

  constructor(
    private fb: FormBuilder,
    private modal: NzModalRef,
    private countryService: CountryService,
    private languageService: LanguageService,
    private lotContactFormService: LotContactFormService,
    @Inject(NZ_MODAL_DATA) public data: LotContactFormModalData,
  ) {
    this.setModalData(data);
    this.initForm();
    this.patchFormValues(data);
  }

  ngOnInit(): void {
    this.loadCountries();
    this.handlePortUpdates();
  }

  setModalData(data: LotContactFormModalData): void {
    this.lotId = data.lotId;
    this.lotType = data.lotType;
    this.lotSource = data.lotSource;
    this.objectGUID = data.objectGUID;
    this.departmentId = data.departmentId;
    this.activeLotType = data.activeLotType;
    this.currentUser = data.currentUser;
  }

  initForm(): void {
    this.form = this.fb.group<LotContactForm>({
      // price: this.fb.nonNullable.control(null, [Validators.required, Validators.min(0)]),
      // currency: this.fb.nonNullable.control(null, Validators.required),
      firstName: this.fb.nonNullable.control(null, Validators.required),
      lastName: this.fb.nonNullable.control(null, Validators.required),
      email: this.fb.nonNullable.control('', [Validators.required, Validators.email]),
      phone: this.fb.nonNullable.control(null, Validators.required),
      company: this.fb.nonNullable.control(null),
      country: this.fb.nonNullable.control(null),
      portOfDelivery: this.fb.nonNullable.control(null),
      shippingType: this.fb.nonNullable.control(null),
      freightPayment: this.fb.nonNullable.control(null),
      comment: this.fb.nonNullable.control(null),
      isTelegram: this.fb.nonNullable.control(false),
      isWhatsApp: this.fb.nonNullable.control(false),
      isViber: this.fb.nonNullable.control(false),
    });
  }

  patchFormValues(data: LotContactFormModalData): void {
    if (!this.currentUser) return;
    this.form.patchValue({
      // price: data.wholesalePrice,
      // currency: data.wholesalePriceCurrency,
      firstName: data.currentUser?.firstName,
      lastName: data.currentUser?.lastName,
      email: data.currentUser?.email,
      phone: data.currentUser?.phoneNumber,
    });
  }

  private loadCountries(): void {
    this.countries = this.countryService.countries;
    const defaultCountry = this.getDefaultCountry();
    if (defaultCountry) {
      this.form.controls.country.setValue(defaultCountry);
    }
  }

  private getDefaultCountry(): Country | undefined {
    if (this.activeLotType === LotType.GreenCorner) {
      return this.countries.find((c) => c.countryCode === 'ru');
    }
    const countryCode = this.currentUser?.countryIso || this.languageService.browserRegion;
    return this.countries.find((c) => c.countryCode === countryCode);
  }

  private handlePortUpdates(): void {
    this.ports$ = this.form.controls.country.valueChanges
      .pipe(
        filter((selectedCountry) => !!selectedCountry),
        map((selectedCountry: Country) => this.getPortsByCountry(selectedCountry.countryCode))
      );
  }

  private getPortsByCountry(countryCode: string): Port[] {
    return Object.keys(seaPorts.JSON)
      .filter((p) => p.startsWith(countryCode.toUpperCase()))
      .map((p) => seaPorts.JSON[p]);
  }

  close(): void {
    this.modal.destroy();
  }

  submit(): void {
    validateAllFormFields(this.form);

    if (this.form.invalid) {
      console.log('form Invalid');
      return;
    }

    const lotData = {
      lotId: this.lotId,
      lotType: this.lotType,
      lotSource: this.lotSource,
      objectGUID: this.objectGUID,
      departmentId: this.departmentId,
    };

    const smRef = this.lotContactFormService.handleSharedInventoryContactFormCreateMutation(
      lotData,
      this.form.value,
    );

    this.isSending$ = smRef.loading;
    this.isSent$ = smRef.data.pipe(
      map(() => true),
      catchError(() => of(false)),
    );
  }
}

<nz-spin [nzSpinning]="loading$ | push">
  <h2>{{ 'NEWS_SECTION.TITLE' | translate }}</h2>
  <nz-row [nzGutter]="[30, 30]">
    @for (newsItem of news$ | push; track newsItem.id) {
      <nz-col [nzXs]="24" [nzSm]="24" [nzMd]="8" [nzLg]="8" [nzXl]="8">
        <app-news-card [newsItem]="newsItem"></app-news-card>
      </nz-col>
    } @empty {
      <nz-col nzSpan="24">
        <nz-result [nzTitle]="'NEWS_SECTION.NO_NEWS' | translate"></nz-result>
      </nz-col>
    }
  </nz-row>
  <div class="button">
    <a [routerLink]="['/', 'news']" app-button appType="primary" size="large">
      <span>{{ 'NEWS_SECTION.BUTTON' | translate }}</span>
    </a>
  </div>
</nz-spin>

import {ru} from './ru';

export const en: typeof ru = {
  "CATEGORY_SECTION": {
    "JAPAN_TITLE": "Cars from Japan",
    "JAPAN_TEXT": "Cars in stock in Japan. Wide range of vehicles offered directly from Japanese auctions",
    "GREEN_TITLE": "Green corner 2.0",
    "GREEN_TEXT": "Cars in Japan ready for export to Russia. These cars are already in our yards, prepared and ready to be loaded on the upcoming sailings to Vladivostok",
    "MONGOL_TITLE": "Cars for Mongolia",
    "MONGOL_TEXT": "Wide range of vehicles on the road or available for immediate shipment to Mongolia. Choose the one you need",
    "JAPAN_MOTIK_TITLE": "Motorcycles from Japan",
    "JAPAN_MOTIK_TEXT": "Current range of motorcycles at auctions in Japan. Find the perfect option for yourself thanks to a wide selection",
    "TRUCKS_TITLT": "Trucks",
    "TRUCKS_TEXT": "Cargo, passenger and other special vehicles available in Japan. The section includes trailers and semi-trailers",
    "TOYAMA_TITLE": "Toyama recycling",
    "TOYAMA_TEXT": "Cars intended for export from Japan both as whole cars and as spare parts. Excellent source of spare parts and repair materials",
    "USA_TITLE": "Cars in USA",
    "USA_TEXT": "Cars available at our Miami yard. Check out the current list of cars ready for sale",
    "CATEGORY_CARD": {
      "AUTO": "auto",
      "BUTTON_TXT": "See more"
    }
  }
};

@switch (media.type) {
  @case ('image') {
    <app-main-slider-image-item
      [source]="media.source"
      [thumbnail]="media.thumbnail!"
    ></app-main-slider-image-item>
  }
  @case ('video') {
    <app-main-slider-video-item [source]="media.source"></app-main-slider-video-item>
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { combineLatest, map, Observable, ReplaySubject, startWith } from 'rxjs';
import { CurrentUserService } from 'src/app/services/current-user/current-user.service';
import { ILot } from 'src/app/services/lot-adapter/interfaces/lot.interface';
import { MediaItem } from 'src/app/services/lot-adapter/types/media-item.type';
import { invert } from 'src/app/utilities/rx-pipes/invert.rx-pipe';
import { Swiper } from 'swiper/types';

@Component({
  selector: 'app-lot-gallery',
  templateUrl: './lot-gallery.component.html',
  styleUrl: './lot-gallery.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotGalleryComponent implements OnChanges {
  @Input({ required: true }) lot!: ILot;

  limitSlides$: Observable<boolean>;
  media$: Observable<MediaItem[]>;
  lotChanges$ = new ReplaySubject<ILot>(1);

  thumbsSwiper?: Swiper;

  constructor(currentUserService: CurrentUserService, private cdr: ChangeDetectorRef) {
    const isAuthenticated$ = currentUserService.isAuthenticated$;
    this.limitSlides$ = isAuthenticated$.pipe(invert());

    const sourceMedia$ = this.lotChanges$.pipe(map((lot) => lot.media || []));
    this.media$ = combineLatest([sourceMedia$, isAuthenticated$]).pipe(
      map(([media, isAuth]) => (isAuth ? media : media.slice(0, 6))),
      startWith([]),
    );
  }

  thumbsSwiperInitialized(swiper: Swiper): void {
    this.thumbsSwiper = swiper;
    this.cdr.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['lot']) {
      this.lotChanges$.next(this.lot);
    }
  }
}

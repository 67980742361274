<swiper-container #swiper appSwiperInitializer [configuration]="swiperConfiguration">
  @for (item of media; track item.source) {
  <swiper-slide>
    <app-thumbs-slider-item [media]="item"></app-thumbs-slider-item>
    @if ($last && slidesLimited) {
    <app-thumbs-slider-not-authorized-overlay></app-thumbs-slider-not-authorized-overlay>
    }
  </swiper-slide>
  }
</swiper-container>

import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-sanctions-policy-en',
  templateUrl: './sanctions-policy-en.component.html',
  styleUrl: './sanctions-policy-en.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SanctionsPolicyEnComponent {

}

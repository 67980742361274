import { FetchResult } from '@apollo/client/core';
import { Apollo } from 'apollo-angular';
import { iif, Observable, of, throwError } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mapTo,
  shareReplay,
  startWith,
  switchMap,
  switchMapTo,
  tap,
} from 'rxjs/operators';

import { NotificationService } from '../../notification.service';
import { BaseMutationHandlingException } from '../exceptions/base-mutation-handling.exception';
import { MutationExecutionException } from '../exceptions/mutation-execution.exception';
import { MutationGraphqlException } from '../exceptions/mutation-graphql.exception';
import { MutationHandlerOptions } from '../interfaces/mutation-handling-options.interface';
import { MutationResultWithLoading } from '../types/mutation-result-with-loading.type';
import { BaseMutationRef } from './base.mutation-ref';

export class SingleMutationRef<T> extends BaseMutationRef {
  private mutationObservable: Observable<MutationResultWithLoading<T>>;

  protected loading$: Observable<boolean>;
  protected data$: Observable<T>;

  constructor(
    mutationObservable: Observable<FetchResult<T>>,
    options: MutationHandlerOptions,
    apollo: Apollo,
    client: string,
    notificationService: NotificationService,
  ) {
    super(apollo, client, options, notificationService);

    this.mutationObservable = mutationObservable.pipe(
      tap((result) => console.log('Mutation result', result)),
      tap(this.validateResult),
      map((result) => result.data),
      switchMap((data) =>
        iif(
          () => this.options?.refetch ?? false,
          this.refetchQueries().pipe(mapTo(data)),
          of(data),
        ),
      ),
      map((data) => <MutationResultWithLoading<T>>{ loading: false, data }),
      tap(() => {
        if (this.options.renderSuccess ?? true) {
          this.notify('success');
        }
      }),
      catchError(this.handleError),
      shareReplay(1),
    );

    this.data$ = this.compileDataObservable();
    this.loading$ = this.compileLoadingObservable();
  }

  public get loading(): Observable<boolean> {
    return this.loading$;
  }

  public get data(): Observable<T> {
    return this.data$;
  }

  protected validateResult = (result: FetchResult<T>) => {
    if (result.errors) {
      throw new MutationGraphqlException(result.errors);
    }

    const payloadKey = Object.keys(result.data!)[0];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((<any>result.data)[payloadKey]?.error) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      throw new MutationExecutionException((<any>result.data)[payloadKey].error);
    }
  };

  protected handleError = (error: BaseMutationHandlingException | Error): Observable<never> => {
    return of(this.options?.refetch ?? false).pipe(
      switchMap((refetch) => iif(() => refetch, this.refetchQueries(), of(null))),
      tap(() => {
        if (this.options.renderError ?? true) {
          if (error instanceof BaseMutationHandlingException) {
            this.notify('fail', error.message);
            error.log();
          } else {
            this.notify('fail', error.message);
            console.error(error);
          }
        }
      }),
      switchMapTo(throwError(error)),
    );
  };

  private compileDataObservable(): Observable<T> {
    return this.mutationObservable.pipe(
      map((dataWithLoading) => dataWithLoading.data!),
      filter((data) => Boolean(data)),
    );
  }

  private compileLoadingObservable(): Observable<boolean> {
    return this.mutationObservable.pipe(
      map((dataOrLoading) => dataOrLoading.loading),
      startWith(true),
      catchError(() => of(false)),
    );
  }
}

import {Injectable} from "@angular/core";
import {QueryHandlingService} from "../query-handling-service/query-handling.service";
import {
  ArticleGQL,
  ArticleQuery,
  ArticleQueryVariables,
  CategoriesGQL,
  CategoriesQuery,
  CategoriesQueryVariables,
} from "../../graphql/content-auto-graphql.service";
import {FetchQueryRef} from "../query-handling-service/query-refs/fetch.query-ref";
import {EnvironmentService} from "../environment.service";

@Injectable()
export class InformationService {
  constructor(
    private qhs: QueryHandlingService,
    private environmentService: EnvironmentService,
    private categoriesGQL: CategoriesGQL,
    private articleGQL: ArticleGQL,
  ) {
  }

  fetchCategories(country: string): FetchQueryRef<CategoriesQuery> {
    return this.qhs.fetch<CategoriesQuery, CategoriesQueryVariables>(this.categoriesGQL, {
      where: {
        site: {
          domain: {
            eq: this.environmentService.currentEnvironment.autoNewsDomain
          }
        },
        country: {
          eq: country,
        },
        articles: {
          all: {
            country: {
              eq: country
            }
          }
        }
      }
    }, 'cache-first');
  }

  fetchArticle(country: string, articlePage: string): FetchQueryRef<ArticleQuery> {
    return this.qhs.fetch<ArticleQuery, ArticleQueryVariables>(this.articleGQL, {
      where: {page: {eq: articlePage}, country: {eq: country}},
    }, 'cache-first');
  }
}

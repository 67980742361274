<nz-spin [nzSpinning]="loading$ | push" nzSize="large">
  <div #lotsList class="lots">
    <nz-row [nzGutter]="[20, 20]" nzJustify="start">
      @for (lot of lots$ | async; track lot.id) {
        <nz-col
          [nzXs]="grid.xs"
          [nzSm]="grid.sm"
          [nzMd]="grid.md"
          [nzLg]="grid.lg"
          [nzXl]="grid.xl"
          [nzXXl]="grid.xl"
        >
          <app-lot-card [lot]="lot"></app-lot-card>
        </nz-col>
      } @empty {
        <nz-col nzSpan="24">
          <nz-result [nzTitle]="'AUCTION.LOTS_PAGE.LOTS_LIST.NO_RESULTS' | translate"></nz-result>
        </nz-col>
      }
    </nz-row>
  </div>
</nz-spin>

<div class="pagination">
  <app-pagination
    [pageIndex]="currentPage"
    [pageSize]="pageSize"
    [total]="total$ | push"
    (pageIndexChange)="onPageChange($event)"
  ></app-pagination>
</div>

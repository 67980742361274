import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'app-information-page-links',
  templateUrl: './information-page-links.component.html',
  styleUrl: './information-page-links.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InformationPageLinksComponent {
  @Input() countryInformationType: 'cis' | 'other' = 'cis';
}

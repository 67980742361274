import { Injectable } from '@angular/core';
import { catchError, map, Observable, of, shareReplay, startWith, switchMap } from 'rxjs';

import {
  AucnetAutoPopularLotsGQL,
  AucnetAutoPopularLotsQuery,
  AucnetAutoPopularLotsQueryVariables,
  AucnetAutoPopularModelsGQL,
  AucnetAutoPopularModelsQuery,
  AucnetAutoPopularModelsQueryVariables,
} from '../../graphql/aucnet-main-graphql.service';
import {
  AucnetMotoPopularLotsGQL,
  AucnetMotoPopularLotsQuery,
  AucnetMotoPopularLotsQueryVariables,
  AucnetMotoPopularModelsGQL,
  AucnetMotoPopularModelsQuery,
  AucnetMotoPopularModelsQueryVariables,
} from '../../graphql/aucnet-moto-graphql.service';
import {
  AucnetTruckPopularLotsGQL,
  AucnetTruckPopularLotsQuery,
  AucnetTruckPopularLotsQueryVariables,
  AucnetTruckPopularModelsGQL,
  AucnetTruckPopularModelsQuery,
  AucnetTruckPopularModelsQueryVariables,
} from '../../graphql/aucnet-trucks-graphql.service';
import {
  MochironPopularLotsGQL,
  MochironPopularLotsQuery,
  MochironPopularLotsQueryVariables,
  MochironPopularModelsGQL,
  MochironPopularModelsQuery,
  MochironPopularModelsQueryVariables,
} from '../../graphql/mochiron-graphql.service';
import { departmentIdByLotType } from '../lot-adapter/constants/department-id-by-lot-type';
import { LotType } from '../lot-adapter/enums/lot-type.enum';
import { ILotMinimal } from '../lot-adapter/interfaces/lot-minimal.interface';
import { LotAdapterService } from '../lot-adapter/lot-adapter.service';
import { QueryHandlingService } from '../query-handling-service/query-handling.service';
import { FetchPopularLotsReference } from './types/fetch-popular-lots-reference.type';

@Injectable()
export class PopularLotFetcherService {
  private readonly lotsCount = 4;

  constructor(
    private readonly qhs: QueryHandlingService,
    private readonly lotAdapterService: LotAdapterService,
    private readonly aucnetAutoPopularModelsGQL: AucnetAutoPopularModelsGQL,
    private readonly aucnetMotoPopularModelsGQL: AucnetMotoPopularModelsGQL,
    private readonly aucnetTruckPopularModelsGQL: AucnetTruckPopularModelsGQL,
    private readonly mochironPopularModelsGQL: MochironPopularModelsGQL,
    private readonly aucnetAutoPopularLotsGQL: AucnetAutoPopularLotsGQL,
    private readonly aucnetMotoPopularLotsGQL: AucnetMotoPopularLotsGQL,
    private readonly aucnetTruckPopularLotsGQL: AucnetTruckPopularLotsGQL,
    private readonly mochironPopularLotsGQL: MochironPopularLotsGQL,
  ) {}

  fetchPopularLots(type: LotType): FetchPopularLotsReference {
    const lots$ = this.fetchPopularModels(type).pipe(
      switchMap((models) => this.fetchLotsByModels(type, models)),
      catchError(() => of([])),
    );

    const loading$ = lots$.pipe(
      map(() => false),
      catchError(() => of(false)),
      startWith(true),
      shareReplay(1),
    );

    return {
      loading$,
      lots$,
    };
  }

  private fetchPopularModels(type: LotType): Observable<string[]> {
    let queryData$;

    switch (type) {
      case LotType.Japan:
        queryData$ = this.qhs.fetch<
          AucnetAutoPopularModelsQuery,
          AucnetAutoPopularModelsQueryVariables
        >(this.aucnetAutoPopularModelsGQL).data;
        break;
      case LotType.Motorcycle:
        queryData$ = this.qhs.fetch<
          AucnetMotoPopularModelsQuery,
          AucnetMotoPopularModelsQueryVariables
        >(this.aucnetMotoPopularModelsGQL).data;
        break;
      case LotType.Truck:
        queryData$ = this.qhs.fetch<
          AucnetTruckPopularModelsQuery,
          AucnetTruckPopularModelsQueryVariables
        >(this.aucnetTruckPopularModelsGQL).data;
        break;
      case LotType.Dubai:
      case LotType.Mongolia:
      case LotType.Recycling:
      case LotType.GreenCorner:
        queryData$ = this.qhs.fetch<
          MochironPopularModelsQuery,
          MochironPopularModelsQueryVariables
        >(this.mochironPopularModelsGQL, { departmentId: departmentIdByLotType[type] }).data;
        break;
      default:
        throw new Error(`Unsupported lot type '${type}'`);
    }

    return queryData$.pipe(
      map((data) => data.models || []),
      map((models) => [...models]),
      map((models) => models.filter((model) => !!model.id)),
      map((models) => models.sort((a, b) => b.lotsCount - a.lotsCount)),
      map((models) => models.map((model) => model.id!)),
      map((models) => models.slice(0, this.lotsCount)),
      shareReplay(1),
    );
  }

  private fetchLotsByModels(type: LotType, models: string[]): Observable<ILotMinimal[]> {
    if (models.length < this.lotsCount) {
      return of([]);
    }

    const modelsVariables = <AucnetAutoPopularLotsQueryVariables>(
      models.reduce((result, model, index) => ({ ...result, [`model${index + 1}`]: model }), {})
    );
    let queryData$: Observable<
      | AucnetAutoPopularLotsQuery
      | AucnetMotoPopularLotsQuery
      | AucnetTruckPopularLotsQuery
      | MochironPopularLotsQuery
    >;

    switch (type) {
      case LotType.Japan:
        queryData$ = this.qhs.fetch<
          AucnetAutoPopularLotsQuery,
          AucnetAutoPopularLotsQueryVariables
        >(this.aucnetAutoPopularLotsGQL, modelsVariables).data;
        break;
      case LotType.Motorcycle:
        queryData$ = this.qhs.fetch<
          AucnetMotoPopularLotsQuery,
          AucnetMotoPopularLotsQueryVariables
        >(this.aucnetMotoPopularLotsGQL, modelsVariables).data;
        break;
      case LotType.Truck:
        queryData$ = this.qhs.fetch<
          AucnetTruckPopularLotsQuery,
          AucnetTruckPopularLotsQueryVariables
        >(this.aucnetTruckPopularLotsGQL, modelsVariables).data;
        break;
      case LotType.Dubai:
      case LotType.Mongolia:
      case LotType.Recycling:
      case LotType.GreenCorner:
        queryData$ = this.qhs.fetch<MochironPopularLotsQuery, MochironPopularLotsQueryVariables>(
          this.mochironPopularLotsGQL,
          { departmentId: departmentIdByLotType[type], ...modelsVariables },
        ).data;
        break;
      default:
        throw new Error(`Unsupported lot type '${type}'`);
    }

    return queryData$.pipe(
      map((data) =>
        Object.keys(data)
          .filter((key) => key.includes('lot'))
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .map((key) => (<any>data)[key])
          .map((result) => result?.items || [])
          .flat()
          .map((lot) => this.lotAdapterService.adaptMinimal(lot)),
      ),
      shareReplay(1),
    );
  }
}

<div class="tabs-navigation">
  @for (tab of tabs; track tab) {
  <app-tab-button
    [title]="tab.title"
    [index]="$index"
    [active]="selectedIndex === $index"
    (selected)="selectTab($event)"
  ></app-tab-button>
  }
</div>
<div class="tabs-content-holder">
  <div
    class="tabs-content"
    [style.margin-left]="tabContentMargin"
    [style.margin-right]="tabContentMargin"
  >
    @for (tab of tabs; track tab) {
    <app-tab-body [active]="selectedIndex === $index" [content]="tab.content"></app-tab-body>
    }
  </div>
</div>

import { ru } from './ru';

export const en: typeof ru = {
  "POPULAR_CARS_SECTION": {
    "TITLE": "Popular cars",
    "JAPAN": "Japan",
    "MONGOLIA": "Mongolia",
    "GREEN": "Green corner 2.0",
    "MOTORCYCLE": "Motorbikes",
    "TRUCK": "Trucks",
    "BUTTON": "See all"
  }
};

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-main-slider-image-item',
  templateUrl: './main-slider-image-item.component.html',
  styleUrl: './main-slider-image-item.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainSliderImageItemComponent {
  @Input({ required: true }) source!: string;
  @Input({ required: true }) thumbnail!: string;
  @Input() previewEnabled = true;
}

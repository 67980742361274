import { Injectable } from '@angular/core';
import { countries } from 'countries-list';
import { Country } from './country.type';
import { ru } from './locale/ru';

@Injectable()
export class CountryService {
  private readonly countryList: Country[];

  constructor() {
    this.countryList = this.loadCountries();
  }

  get countries(): Country[] {
    return this.countryList;
  }

  private loadCountries(): Country[] {
    return Object.entries(countries).map(([code, countryData]) => ({
      name: {
        en: countryData.name,
        ru: ru[code] || countryData.name,
      },
      dialCode: countryData.phone,
      countryCode: code.toLowerCase(),
    }));
  }
}

import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {PriceDetail} from '../../types/lot-info.types';
import {LotType} from "../../../../../../../../services/lot-adapter/enums/lot-type.enum";
import {CurrencyEnum} from "../../../../../../../../graphql/exchange-rate-open-graphql.service";

@Component({
  selector: 'app-lot-info-price',
  templateUrl: './lot-info-price.component.html',
  styleUrl: './lot-info-price.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotInfoPriceComponent {
  @Input({required: true}) priceDetail: PriceDetail | undefined;
  @Input({required: true}) activeLotType!: LotType;
  @Input() jpyToRubRate: number | undefined = 0;
  @Input() usdToRubRate: number | undefined = 0;
  protected readonly LotType = LotType;

  get convertedPriceToRub(): number | null {
    if (!this.priceDetail?.priceValue?.value) {
      return null;
    }

    const {value, unit} = this.priceDetail.priceValue;

    switch (unit) {
      case CurrencyEnum.Jpy:
        return this.jpyToRubRate && this.jpyToRubRate > 0 ? Math.ceil(value / this.jpyToRubRate) : 0
      case CurrencyEnum.Usd:
        return this.usdToRubRate && this.usdToRubRate > 0 ? Math.ceil(value * this.usdToRubRate) : 0;
      default:
        return null;
    }
  }

  get renderTooltipForMainPrice(): boolean | undefined {
    return this.isGreenCornerType && this.priceDetail?.isMain;
  }

  get isGreenCornerType(): boolean | undefined {
    return this.activeLotType === LotType.GreenCorner;
  }
}

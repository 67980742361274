@import 'variables';

@mixin button-states(
  $background-color,
  $color,
  $focus-border-color,
  $hover-bg-color,
  $active-bg-color,
  $active-opacity: 1
) {
  background-color: $background-color;
  color: $color;

  &:focus {
    border: 1px solid $focus-border-color;
    outline: none;
  }

  &:hover {
    background-color: $hover-bg-color;
  }

  &:active {
    background-color: $active-bg-color;
    opacity: $active-opacity;
  }
}

.app-button {
  font-size: 14px;
  padding: 8px 10px;
  border-radius: 8px;
  font-weight: 500;
  min-width: 109px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &.default {
    @include button-states(
      $light-color,
      $dark-color,
      $light-blue-color,
      $light-hover-color,
      $light-hover-color,
      0.7
    );
  }

  &.ghost {
    border: 1px solid $light-color;

    @include button-states(
      transparent,
      $light-color,
      $light-blue-color,
      $dark-color,
      $dark-active-color
    );
  }

  &.green {
    @include button-states(
      #00da49,
      $light-color,
      #53e383,
      #00da49,
      #00da49,
    );
  }

  &.primary-ghost {
    border: 2px solid $blue-color;

    @include button-states(
        transparent,
        $blue-color,
        $light-blue-color,
        #2c7df638,
        $dark-active-color
    );
  }

  &.primary {
    @include button-states(
      $blue-color,
      $light-color,
      $light-blue-color,
      $blue-background-color,
      $blue-background-active-color
    );
  }

  &.secondary {
    border: 1px solid $light-blue-color;
    @include button-states(
      transparent,
      $dark-color,
      $light-blue-color,
      #2c7df638,
      $dark-active-color
    );
  }

  &.large {
    padding: 15px 0;
    font-size: 16px;
  }

  &.default-size {
    padding: 8px 0;
    font-size: 14px;
  }

  &.small-size {
    padding: 4px 0;
    font-size: 12px;
  }

  &.round {
    border-radius: 50px;
  }

  &.block {
    display: block;
    width: 100%;
  }

  &.disabled {
    background-color: $disabled-bg-color;
    border: 1px solid $disabled-color;
    color: $disabled-color;
    cursor: not-allowed;

    &:hover {
      background-color: $disabled-bg-color;
    }
  }

  .loading-icon {
    margin-right: 5px;
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageTitleService } from './page-title.service';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, TranslateModule],
  providers: [PageTitleService],
})
export class PageTitleServiceModule {}

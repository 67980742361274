import { ChangeDetectionStrategy, Component, Input, OnInit, Optional } from '@angular/core';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { LotType } from 'src/app/services/lot-adapter/enums/lot-type.enum';

@Component({
  selector: 'app-lots-list-filter',
  templateUrl: './lots-list-filter.component.html',
  styleUrl: './lots-list-filter.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotListFilterComponent {
  @Input({ required: true }) activeLotType!: LotType;

  constructor(@Optional() private drawerRef: NzDrawerRef | null) {}

  closeDrawer(): void {
    this.drawerRef?.close();
  }

  get isDrawer(): boolean {
    return !!this.drawerRef;
  }
}

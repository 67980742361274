import { ChangeDetectionStrategy, Component } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import {
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
  Observable,
  shareReplay,
  startWith,
  switchMap,
} from 'rxjs';
import { LotsListFilterBuilderService } from 'src/app/modules/auction/components/lots-list-filter/services/lots-list-filter-builder.service';
import { LotListFilterDataService } from 'src/app/modules/auction/components/lots-list-filter/services/lots-list-filter-data.service';
import { LotType } from 'src/app/services/lot-adapter/enums/lot-type.enum';

import {
  MiniFilterFormYearsGQL,
  MiniFilterFrmCompaniesModelsGQL,
  MiniFilterFrmCompaniesModelsQuery,
  MiniFilterFrmCompaniesModelsQueryVariables,
} from '../../../../../../graphql/aucnet-main-graphql.service';
import { QueryHandlingService } from '../../../../../../services/query-handling-service/query-handling.service';
import { MiniFilteringForm } from '../../types/forms/mini-filtering-form.type';

@Component({
  selector: 'app-mini-filtering-form',
  templateUrl: './mini-filtering-form.component.html',
  styleUrl: './mini-filtering-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MiniFilteringFormComponent {
  companies$: Observable<MiniFilterFrmCompaniesModelsQuery['companies']>;
  models$: Observable<MiniFilterFrmCompaniesModelsQuery['models']>;
  years: number[];

  formGroup: FormGroup<MiniFilteringForm>;
  isLoading$: Observable<boolean>;

  constructor(
    fb: FormBuilder,
    qhs: QueryHandlingService,
    filterDataService: LotListFilterDataService,
    miniFilterFormCompaniesModelsGQL: MiniFilterFrmCompaniesModelsGQL,
    miniFilterFormYearsGQL: MiniFilterFormYearsGQL,
    private router: Router,
    private lotsListFilterBuilder: LotsListFilterBuilderService,
  ) {
    this.formGroup = fb.nonNullable.group<MiniFilteringForm>({
      modelId: fb.control(null),
      companyId: fb.control(null),
      yearFrom: fb.control(null),
      priceFrom: fb.control(null),
      priceTo: fb.control(null),
    });

    const { companyId, modelId, yearFrom } = this.formGroup.controls;

    this.years = filterDataService.years;

    const fetchCompaniesAndModelsRef = qhs.fetch<
      MiniFilterFrmCompaniesModelsQuery,
      MiniFilterFrmCompaniesModelsQueryVariables
    >(miniFilterFormCompaniesModelsGQL);

    const data$ = fetchCompaniesAndModelsRef.data.pipe(shareReplay(1));
    this.companies$ = data$.pipe(
      map((data) => data.companies),
      map((companies) => filterDataService.sortAutomobileCompanies(companies)),
    );
    const allModels$ = data$.pipe(map((data) => data.models));

    this.isLoading$ = fetchCompaniesAndModelsRef.loading;

    this.models$ = combineLatest([allModels$, companyId.valueChanges.pipe(startWith(null))]).pipe(
      map(([models, companyId]) => {
        if (companyId) {
          return models.filter((model) => model.companyId === companyId);
        } else {
          return models;
        }
      }),
    );

    modelId.valueChanges
      .pipe(
        filter((modelId) => !!modelId),
        switchMap((modelId) =>
          allModels$.pipe(
            map((models) => models.find((model) => model.id === modelId)),
            map((model) => model?.companyId || null),
          ),
        ),
        takeUntilDestroyed(),
      )
      .subscribe((companyIdValue) =>
        companyId.setValue(companyIdValue, {
          emitEvent: false,
        }),
      );

    companyId.valueChanges.pipe(distinctUntilChanged(), takeUntilDestroyed()).subscribe(() => {
      modelId.setValue(null);
    });
  }

  onSubmit(): void {
    const params = this.lotsListFilterBuilder.getParams(this.formGroup.value);
    this.router.navigate(['/', LotType.Japan, 'lots'], {
      queryParams: {
        ...params,
        yearTo: params['yearFrom'],
      },
    });
  }
}

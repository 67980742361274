import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  DestroyRef,
  EventEmitter,
  Input,
  Output,
  QueryList,
} from '@angular/core';
import { TabComponent } from './components/tab/tab.component';
import { from, map, merge, mergeAll, startWith, switchMap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrl: './tabs.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabsComponent implements AfterViewInit {
  @Input() selectedIndex = 0;
  @Output() selectedIndexChange = new EventEmitter<number>();

  @ContentChildren(TabComponent) tabs!: QueryList<TabComponent>;

  constructor(private readonly destroy: DestroyRef, private readonly cdr: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    merge(
      this.tabs.changes,
      this.tabs.changes.pipe(
        startWith(this.tabs),
        map((items) => items.toArray()),
        switchMap((items: TabComponent[]) =>
          from(items).pipe(
            map((item) => item.inputChange$),
            mergeAll(),
          ),
        ),
      ),
    )
      .pipe(takeUntilDestroyed(this.destroy))
      .subscribe(() => this.cdr.markForCheck());

    this.cdr.markForCheck();
  }

  selectTab(index: number) {
    this.selectedIndex = index;
    this.selectedIndexChange.next(index);
  }

  get tabContentMargin(): string {
    const marginValue = -(this.selectedIndex || 0) * 100;
    return `${marginValue}%`;
  }
}

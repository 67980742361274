<nz-row [nzGutter]="[20, 20]">
  <nz-col [nzXs]="24" [nzSm]="24" [nzMd]="24" [nzLg]="14" [nzXl]="14" [nzXXl]="16">
    <div class="content">
      <div class="description">
        <h1>
          {{ 'GOODS_AND_PARTS_SECTION.TITLE' | translate }}
        </h1>

        <p>
          {{ 'GOODS_AND_PARTS_SECTION.DESCRIPTION' | translate }}
        </p>

        <a app-button appType="primary" [href]="shopUrl" target="_blank">
          {{ 'GOODS_AND_PARTS_SECTION.LEARN' | translate }}
        </a>
      </div>

      <div class="scales-image">
        <img src="/assets/img/scales.png" alt="scales" />
      </div>
    </div>
  </nz-col>

  <nz-col [nzXs]="24" [nzSm]="24" [nzMd]="24" [nzLg]="10" [nzXl]="10" [nzXXl]="8">
    <div class="stock-image">
      <img src="/assets/img/stock.jpg" alt="stock-image" />
    </div>
  </nz-col>
</nz-row>

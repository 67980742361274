export enum LotType {
  Mongolia = 'mongolia',
  GreenCorner = 'green',
  Japan = 'japan',
  Recycling = 'recycling',
  Motorcycle = 'motorcycle',
  Truck = 'truck',
  Dubai = 'dubai',
  Usa = 'usa'
}

export enum LotSource {
  AucnetAuto = 'AucnetAuto',
  AucnetMoto = 'AucnetMoto',
  AucnetTruck = 'AucnetTruck',
  Mochiron = 'Mochiron',
}

export const lotSourceByLotType: Record<LotType, LotSource> = {
  [LotType.Japan]: LotSource.AucnetAuto,
  [LotType.Motorcycle]: LotSource.AucnetMoto,
  [LotType.Truck]: LotSource.AucnetTruck,
  [LotType.Mongolia]: LotSource.Mochiron,
  [LotType.GreenCorner]: LotSource.Mochiron,
  [LotType.Recycling]: LotSource.Mochiron,
  [LotType.Dubai]: LotSource.Mochiron,
  [LotType.Usa]: LotSource.Mochiron,
};

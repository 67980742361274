import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RxPush } from '@rx-angular/template/push';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { LotCardModule } from 'src/app/components/lot-card/lot-card.module';
import { TabsModule } from 'src/app/components/tabs/tabs.module';

import { BenefitsSectionModule } from '../../sections/benefits-section/benefits-section.module';
import { BuhankaDonateSectionModule } from '../../sections/buhanka-donate-section/buhanka-donate-section.module';
import { CategorySectionModule } from '../../sections/category-section/category-section.module';
import { DeliveryStatisticsSectionModule } from '../../sections/delivery-statistics-section/delivery-statistics-section.module';
import { GoodsAndPartsSectionModule } from '../../sections/goods-and-parts-section/goods-and-parts-section.module';
import { HeaderSectionModule } from '../../sections/header-section/header-section.module';
import { NewsSectionModule } from '../../sections/news-section/news-section.module';
import { PopularCarsSectionModule } from '../../sections/popular-cars-section/popular-cars-section.module';
import { UsefulInformationSectionModule } from '../../sections/useful-information-section/useful-information-section.module';
import { WhoWeAreModule } from '../../sections/who-we-are-section/who-we-are-section.module';
import { MainPageComponent } from './main-page.component';

@NgModule({
  declarations: [MainPageComponent],
  imports: [
    CommonModule,
    RxPush,
    HeaderSectionModule,
    CategorySectionModule,
    PopularCarsSectionModule,
    BenefitsSectionModule,
    TabsModule,
    LotCardModule,
    NzGridModule,
    WhoWeAreModule,
    GoodsAndPartsSectionModule,
    UsefulInformationSectionModule,
    DeliveryStatisticsSectionModule,
    NewsSectionModule,
    BuhankaDonateSectionModule,
  ],
  exports: [MainPageComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class MainPageModule {}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-delivery-statistics-stat',
  templateUrl: './delivery-statistics-stat.component.html',
  styleUrl: './delivery-statistics-stat.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeliveryStatisticsStatComponent {
  @Input({ required: true }) value!: number;
  @Input({ required: true }) name!: string;
  @Input() main = false;
  @Input() loading = false;

  pluralKeys: [string, string, string] = [
    'DELIVERY_STATISTICS_SECTION.DAY_PLURALS.SINGLE',
    'DELIVERY_STATISTICS_SECTION.DAY_PLURALS.SOME',
    'DELIVERY_STATISTICS_SECTION.DAY_PLURALS.MANY',
  ];
}

@if (isDrawer) {
  <div class="search-car-title">
    <i nz-icon tabindex="0" nzType="close" nzTheme="outline" (click)="closeDrawer()"></i>
  </div>
}

@switch (activeLotType) {
  @case ('japan') {
    <app-lots-list-aucnet-auto-filter (closeDrawer)="closeDrawer()"></app-lots-list-aucnet-auto-filter>
  }
  @case ('motorcycle') {
    <app-lots-list-aucnet-moto-filter (closeDrawer)="closeDrawer()"></app-lots-list-aucnet-moto-filter>
  }
  @case ('truck') {
    <app-lots-list-aucnet-truck-filter (closeDrawer)="closeDrawer()"></app-lots-list-aucnet-truck-filter>
  }
  @default {
    <app-lots-list-mochiron-filter
      [activeLotType]="activeLotType"
      (closeDrawer)="closeDrawer()"
    ></app-lots-list-mochiron-filter>
  }
}

import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopularCarsSectionComponent } from './popular-cars-section.component';
import { TabsModule } from 'src/app/components/tabs/tabs.module';
import { LanguageService } from 'src/app/services/language.service';
import { TranslateModule } from '@ngx-translate/core';
import { RxPush } from '@rx-angular/template/push';
import { en } from './locale/en';
import { ru } from './locale/ru';
import { LotCardModule } from 'src/app/components/lot-card/lot-card.module';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { ButtonModule } from 'src/app/components/button/button.module';
import { RouterModule } from '@angular/router';
import { PopularCarsListComponent } from './components/popular-cars-list/popular-cars-list.component';


@NgModule({
  declarations: [PopularCarsSectionComponent, PopularCarsListComponent],
  imports: [CommonModule, TabsModule, TranslateModule, RxPush, NzGridModule, LotCardModule, NzSpinModule, ButtonModule, RouterModule],
  exports: [PopularCarsSectionComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PopularCarsSectionModule {
  constructor(languageService: LanguageService) {
    languageService.addLocales({ en, ru });
  }
}

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { map, Observable } from 'rxjs';
import { LanguageService } from 'src/app/services/language.service';
import { PageTitleService } from 'src/app/services/page-title/page-title.service';
import { Language } from 'src/app/utilities/types/language.type';

@Component({
  selector: 'app-sanctions-policy-page',
  templateUrl: './sanctions-policy-page.component.html',
  styleUrl: './sanctions-policy-page.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SanctionsPolicyPageComponent {
  language$: Observable<'ru' | 'en'>;

  private defaultPolicyLanguage: Language = 'en';
  private availablePolicyLanguages: Language[] = ['ru', 'en'];

  constructor(
    private router: Router,
    languageService: LanguageService,
    pageTitleService: PageTitleService,
  ) {
    pageTitleService.setTitle('SANCTIONS_POLICY_PAGE.TITLE');

    this.language$ = languageService.currentLanguage$.pipe(
      map((currentLanguage) => {
        if (this.availablePolicyLanguages.includes(currentLanguage)) {
          return currentLanguage;
        } else {
          return this.defaultPolicyLanguage;
        }
      }),
    );
  }

  goBack(): void {
    this.router.navigate(['/']);
  }
}

import {ChangeDetectionStrategy, Component} from '@angular/core';
import {NewsService} from "../../../../services/news/news.service";
import {ActivatedRoute} from "@angular/router";
import {map, Observable} from "rxjs";
import {NewsItemFragment} from "../../../../graphql/content-auto-graphql.service";

@Component({
  selector: 'app-news-item-page',
  templateUrl: './news-item-page.component.html',
  styleUrl: './news-item-page.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewsItemPageComponent {
  newsItem$: Observable<NewsItemFragment | null | undefined>;
  loading$: Observable<boolean>;

  constructor(
    route: ActivatedRoute,
    newsService: NewsService,
  ) {
    const newsId = +route.snapshot.params['id'];
    const queryRef = newsService.getNewsItemById(newsId);
    this.loading$ = queryRef.loading;
    this.newsItem$ = queryRef.data.pipe(map(result => result?.newsEntry));
  }
}

<a [routerLink]="['/', lot.type, 'lots', lot.id]">
  <app-lot-card-gallery
    [isSold]="lot.isSold"
    [rating]="lot.rating"
    [media]="lot.media"
  ></app-lot-card-gallery>
  <app-lot-card-title
    [mark]="lot.mark"
    [model]="lot.model"
    [grade]="lot.grade"
    [engineVolume]="lot.engineVolume"
    [bodyType]="lot.bodyType"
    [drive]="lot.drive"
    [maker]="lot.maker"
    [year]="lot.year"
    [price]="lot.price"
    [isSold]="lot.isSold"
  ></app-lot-card-title>

  <app-lot-card-info
    [mileage]="lot.mileage"
    [color]="lot.color"
    [location]="lot.location"
    [vinNumber]="lot.vin"
    [accident]="lot.accident"
  ></app-lot-card-info>
</a>

<div class="logo">
  <img src="/assets/logos/akebono-logo-footer.svg" alt="logo" />
</div>
<div class="links">
  <app-social-contact-link
    icon="instagram"
    link="https://www.instagram.com/akebono.co.ltd/"
  ></app-social-contact-link>
  <app-social-contact-link
    icon="youtube"
    link="https://www.youtube.com/@AKEBONOCOLTD"
  ></app-social-contact-link>
  <app-social-contact-link
    icon="facebook"
    link="https://www.facebook.com/akebonocars.jp/"
  ></app-social-contact-link>

  @if (canRenderTelegram$ | push) {
  <app-social-contact-link icon="telegram" link="https://t.me/akebonojp"></app-social-contact-link>
  }
</div>
<div class="year">Akebono © 2005-{{ currentYear }}</div>

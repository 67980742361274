import { ru } from './ru';

export const en: typeof ru = {
  "LOTS_LIST_SORT": {
    "SORT_TYPES": {
      "new": "Date created, new first",
      "old": "Date created, old first",
      "year-new": "Year, new first",
      "year-old": "Year, old first",
      "price-ascending": "Price, low to high",
      "price-descending": "Price, high to low"
    }
  }
};

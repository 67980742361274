import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map, Observable } from 'rxjs';

import { SortType } from './enums/sort-type.enum';
import { LotsListSortBuilderService } from './services/lots-list-sort-builder.service';

@Component({
  selector: 'app-lots-list-sort',
  templateUrl: './lots-list-sort.component.html',
  styleUrl: './lots-list-sort.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotsListSortComponent {
  currentSortType$: Observable<SortType>;
  currentSortTypeName$: Observable<string>;
  sortTypes = Object.values(SortType);

  dropdownVisible = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private sortBuilderService: LotsListSortBuilderService,
  ) {
    this.currentSortType$ = this.route.queryParams.pipe(
      map((params) => this.sortBuilderService.parseParams(params)),
    );
    this.currentSortTypeName$ = this.currentSortType$.pipe(
      map((sortType) => `LOTS_LIST_SORT.SORT_TYPES.${sortType}`),
    );
  }

  changeSort(sortType: SortType): void {
    this.router.navigate([], {
      queryParams: this.sortBuilderService.getParams(sortType),
      queryParamsHandling: 'merge',
    });

    this.dropdownVisible = false;
  }
}

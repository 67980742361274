export const ru = {
  "FOOTER_CONTACTS": {
    "HEAD": "Головной офис",
    "BRANCH": "Филиал в Йокогаме",
    "BRANCH2": "Представительство на Филиппинах",
    "USA_OFFICE": "Представительство в США",
    "PHONE": "Телефон",
    "CUSTOMER_SERVICE_DEPARTMENT_CONTACTS": "Контактные номера отдела по работе с клиентами",
    "HEAD_OF_SALES": "Руководитель отдела продаж",
    "OTHER_COUNTRIES_IMPORT": {
      "TITLE": "Импорт в другие страны",
      "NEWS": "Новости",
      "INFORMATION": "Информация",
      "ABOUT_COMPANY": "О компании"
    },
    "PERSONAL_ACCOUNT": {
      "TITLE": "Личный кабинет",
      "HOME": "Главная",
      "CHATS": "Чаты",
      "ARCHIVE": "Архив"
    },
    "GOODS_AND_PARTS": {
      "TITLE": "Товары и запчасти",
      "ORDERS": "Заказы",
      "FAVORITES": "Избранное",
      "SHIPMENTS": "Отправки",
      "DELIVERY": "Доставка",
      "PAYMENT": "Оплата",
      "FAQ": "Вопросы и ответы",
      "ADDITIONAL_SERVICES": "Дополнительные услуги",
      "BONUSES": "Бонусные баллы"
    }
  },
  "LANGUAGE_SELECTOR": {
    "SELECT_LANG": "Выбрать язык",
    "EN": "English",
    "RU": "Russian"
  },
  "AUTHORIZED_ACTIONS": {
    "EXIT": "Выход",
    "PERSONAL_ACC": "В личный кабинет"
  },
  "NOT_AUTHORIZED_ACTIONS": {
    "REGISTER": "Регистрация",
    "LOGIN": "Войти"
  }
};

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NzImageModule } from 'ng-zorro-antd/image';

import { ImageGroupComponent } from './image-group.component';
import { ImageDirective } from './image.directive';

@NgModule({
  declarations: [ImageDirective, ImageGroupComponent],
  imports: [CommonModule, NzImageModule],
  exports: [ImageDirective, ImageGroupComponent],
})
export class ImageModule {}

import { ru } from './ru';

export const en: typeof ru = {
  USEFUL_INFORMATION_SECTION: {
    TITLE: 'User guides',
    DESCRIPTION:
      'Follow the links below to go to the sections with useful information regarding the import of vehicles, the terms of use of our services, as well as the nuances of Japanese auctions. This information can help you with your future purchases',
    OTHER_COUNTRIES_GUIDE: 'Guide WorldWide',
    CIS_GUIDE: 'Guide CIS',
  },
};

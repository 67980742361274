import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {RouterLink} from "@angular/router";
import {NzCardComponent} from "ng-zorro-antd/card";
import {NewsCardComponent} from "./news-card.component";
import {RxPush} from "@rx-angular/template/push";
import {TranslateModule} from "@ngx-translate/core";
import {LanguageService} from "../../services/language.service";
import {en} from "./locale/en";
import {ru} from "./locale/ru";
import {NewsContentPipeModule} from "../../pipes/news-content-pipe/news-content-pipe.module";

@NgModule({
  declarations: [NewsCardComponent],
  imports: [CommonModule, RouterLink, NzCardComponent, RxPush, TranslateModule, NewsContentPipeModule],
  exports: [NewsCardComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class NewsCardModule {
  constructor(languageService: LanguageService) {
    languageService.addLocales({en, ru});
  }
}

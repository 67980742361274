import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {NewsPageComponent} from "./news-page.component";
import {NzSpinComponent} from "ng-zorro-antd/spin";
import {NzColDirective, NzRowDirective} from "ng-zorro-antd/grid";
import {RxPush} from "@rx-angular/template/push";
import {NewsCardModule} from "../../../../components/news-card/news-card.module";
import {PaginationModule} from "../../../../components/pagination/pagination.module";
import {TranslateModule} from "@ngx-translate/core";
import {LanguageService} from "../../../../services/language.service";
import {en} from "./locale/en";
import {ru} from "./locale/ru";
import {NzBreadCrumbComponent, NzBreadCrumbItemComponent} from "ng-zorro-antd/breadcrumb";
import {RouterLink, RouterLinkActive} from "@angular/router";
import {NzIconDirective} from "ng-zorro-antd/icon";
import {NewsPageFilterComponent} from './components/news-page-filter/news-page-filter.component';
import {NzDatePickerComponent} from "ng-zorro-antd/date-picker";
import {ReactiveFormsModule} from "@angular/forms";
import {ButtonModule} from "../../../../components/button/button.module";
import {NzSpaceComponent, NzSpaceItemDirective} from "ng-zorro-antd/space";
import {NzFormControlComponent} from "ng-zorro-antd/form";
import {NzResultComponent} from "ng-zorro-antd/result";
import {NewsPageBreadcrumbComponent} from './components/news-page-breadcrumb/news-page-breadcrumb.component';

@NgModule({
  declarations: [NewsPageComponent, NewsPageFilterComponent, NewsPageBreadcrumbComponent],
  imports: [
    CommonModule,
    NzSpinComponent,
    NzRowDirective,
    RxPush,
    NzColDirective,
    NewsCardModule,
    PaginationModule,
    TranslateModule,
    NzBreadCrumbComponent,
    NzBreadCrumbItemComponent,
    RouterLink,
    NzIconDirective,
    NzDatePickerComponent,
    ReactiveFormsModule,
    ButtonModule,
    NzSpaceComponent,
    NzSpaceItemDirective,
    NzFormControlComponent,
    NzResultComponent,
    RouterLinkActive,
  ],
  exports: [NewsPageComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class NewsPageModule {
  constructor(languageService: LanguageService) {
    languageService.addLocales({en, ru});
  }
}

@if (count) {
<div class="orange-block">
  + {{ count }} {{ 'CATEGORY_SECTION.CATEGORY_CARD.AUTO' | translate }}
</div>
}

<div class="desktop-card">
  <div class="information">
    <h1>{{ title }}</h1>
    <p>{{ text }}</p>
    <div>
      <a
        [routerLink]="['/', lotType, 'lots']"
        target="_blank"
        app-button
        appType="primary"
        type="submit"
        >{{ 'CATEGORY_SECTION.CATEGORY_CARD.BUTTON_TXT' | translate }}</a
      >
    </div>
  </div>

  <div class="img">
    <img
      class="cars-logo"
      src="/assets/img/category-cards/desktop/{{ lotType }}.png"
      alt="{{ lotType }}_category"
    />
  </div>
</div>

<div class="mobile-card">
  <h1>{{ title }}</h1>
  <div class="img">
    <img
      class="cars-logo"
      src="/assets/img/category-cards/mobile/{{ lotType }}.png"
      alt="{{ lotType }}_category"
    />
  </div>
  <p>{{ text }}</p>
  <a
    [routerLink]="['/', lotType, 'lots']"
    target="_blank"
    app-button
    appType="primary"
    type="submit"
    >{{ 'CATEGORY_SECTION.CATEGORY_CARD.BUTTON_TXT' | translate }}</a
  >
</div>

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** The `DateTime` scalar type represents time data, ISO-8601 encoded UTC date string. */
  DateTime: { input: any; output: any; }
};

export type CityInfo = {
  __typename?: 'CityInfo';
  id?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type CountryInfo = {
  __typename?: 'CountryInfo';
  engName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type PortInfo = {
  __typename?: 'PortInfo';
  country?: Maybe<CountryInfo>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type Query = {
  __typename?: 'Query';
  navisContainerSchedule?: Maybe<Array<TripContainerSchedule>>;
  navisSchedule?: Maybe<TripSchedule>;
};


export type QueryNavisScheduleArgs = {
  comingSoon?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TripContainerSchedule = {
  __typename?: 'TripContainerSchedule';
  dateFrom?: Maybe<Scalars['DateTime']['output']>;
  dateTo?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  vessel?: Maybe<VesselInfo>;
  wayPoints?: Maybe<Array<WayPointContainer>>;
};

export type TripSchedule = {
  __typename?: 'TripSchedule';
  trips?: Maybe<Array<TripScheduleInfo>>;
  /** Самая последняя дата обновления среди рейсов и их планов погрузки */
  tripsUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type TripScheduleInfo = {
  __typename?: 'TripScheduleInfo';
  id?: Maybe<Scalars['Int']['output']>;
  serialNum?: Maybe<Scalars['String']['output']>;
  ship?: Maybe<VesselInfo>;
  status?: Maybe<TripStatusEnum>;
  wayPoints?: Maybe<Array<WayPoint>>;
};

export enum TripStatusEnum {
  ComingSoon = 'comingSoon',
  DepartingSoon = 'departingSoon',
  OnWay = 'onWay',
  RecentlyEnded = 'recentlyEnded'
}

export type VesselInfo = {
  __typename?: 'VesselInfo';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type WayPoint = {
  __typename?: 'WayPoint';
  city?: Maybe<CityInfo>;
  date?: Maybe<Scalars['String']['output']>;
  info?: Maybe<Scalars['String']['output']>;
};

export type WayPointContainer = {
  __typename?: 'WayPointContainer';
  dateFrom?: Maybe<Scalars['DateTime']['output']>;
  dateTo?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  port?: Maybe<PortInfo>;
};

export type LotUpcomingSailingsDataQueryVariables = Exact<{ [key: string]: never; }>;


export type LotUpcomingSailingsDataQuery = { __typename?: 'Query', navisSchedule?: { __typename?: 'TripSchedule', tripsUpdatedAt?: any | null, trips?: Array<{ __typename?: 'TripScheduleInfo', id?: number | null, serialNum?: string | null, ship?: { __typename?: 'VesselInfo', id?: number | null, name?: string | null } | null, wayPoints?: Array<{ __typename?: 'WayPoint', date?: string | null, info?: string | null, city?: { __typename?: 'CityInfo', title?: string | null } | null }> | null }> | null } | null };

export const LotUpcomingSailingsDataDocument = gql`
    query lotUpcomingSailingsData {
  navisSchedule(comingSoon: true) {
    trips {
      id
      serialNum
      ship {
        id
        name
      }
      wayPoints {
        city {
          title
        }
        date
        info
      }
    }
    tripsUpdatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class LotUpcomingSailingsDataGQL extends Apollo.Query<LotUpcomingSailingsDataQuery, LotUpcomingSailingsDataQueryVariables> {
    override document = LotUpcomingSailingsDataDocument;
    override client = 'navis/main/open';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    
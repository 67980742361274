import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

type RatingKey =
  | 'S'
  | '6'
  | '5'
  | '45'
  | '4'
  | '35'
  | '3'
  | '25'
  | '2'
  | '15'
  | '1'
  | 'R';

@Component({
  selector: 'app-rating-tag',
  templateUrl: './rating-tag.component.html',
  styleUrls: ['./rating-tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RatingTagComponent {
  @Input({ required: true }) rating!: number | string | undefined;

  ratingColors: Record<RatingKey, string> = {
    'S': 'gold',
    '6': '#87d068',
    '5': '#87d068',
    '45': '#87d068',
    '4': '#87d068',
    '35': '#550',
    '3': '#550',
    '25': '#f50',
    '2': '#f50',
    '15': '#f50',
    '1': '#f50',
    'R': '#f50',
  };

  get ratingColor(): string | undefined {
    if (this.rating && this.rating.toString() in this.ratingColors) {
      return this.ratingColors[this.rating.toString() as RatingKey];
    }
    return undefined;
  }
}

import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuctionRoutingModule } from './auction-routing.module';
import { LotsPageModule } from './pages/lots-page/lots-page.module';
import { LotPageModule } from './pages/lot-page/lot-page.module';
import { AuctionBreadcrumbModule } from './components/auction-breadcrumb/auction-breadcrumb.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    AuctionRoutingModule,
    LotsPageModule,
    LotPageModule,
    AuctionBreadcrumbModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AuctionModule {}

import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { mainAutomobileCompanies } from 'src/app/constants/constants/main-automobile-companies';
import {
  Company,
  LotsListAucnetAutoFilterDataGQL,
  LotsListAucnetAutoFilterDataQuery,
  LotsListAucnetAutoFilterDataQueryVariables,
  SharedInventoryCarFilterInput,
  SharedInventoryCarSortInput,
} from 'src/app/graphql/aucnet-main-graphql.service';
import {
  LotsListAucnetMotoFilterDataGQL,
  LotsListAucnetMotoFilterDataQuery,
  LotsListAucnetMotoFilterDataQueryVariables,
  SharedInventoryMotoFilterInput,
  SharedInventoryMotoSortInput,
} from 'src/app/graphql/aucnet-moto-graphql.service';
import {
  LotsListAucnetTrucksFilterDataGQL,
  LotsListAucnetTrucksFilterDataQuery,
  LotsListAucnetTrucksFilterDataQueryVariables,
  SharedInventoryTruckFilterInput,
  SharedInventoryTruckSortInput,
} from 'src/app/graphql/aucnet-trucks-graphql.service';
import {
  Department,
  LotsListMochironFilterDataGQL,
  LotsListMochironFilterDataQuery,
  LotsListMochironFilterDataQueryVariables,
  StockCarFilterInput,
  StockCarSortInput,
} from 'src/app/graphql/mochiron-graphql.service';
import { QueryHandlingService } from 'src/app/services/query-handling-service/query-handling.service';

@Injectable()
export class LotListFilterDataService {
  loading$?: Observable<boolean>;
  readonly minimalYear = 1985;

  constructor(
    private readonly qhs: QueryHandlingService,
    private readonly lotsListAucnetAutoFilterDataGQL: LotsListAucnetAutoFilterDataGQL,
    private readonly lotsListMochironFilterDataGQL: LotsListMochironFilterDataGQL,
    private readonly lotsListAucnetMotoFilterDataGQL: LotsListAucnetMotoFilterDataGQL,
    private readonly lotsListAucnetTrucksFilterDataGQL: LotsListAucnetTrucksFilterDataGQL,
  ) {}

  get years(): number[] {
    const currentYear = new Date().getFullYear();
    const years = [];

    for (let year = currentYear; year >= this.minimalYear; year--) {
      years.push(year);
    }

    return years;
  }

  getAucnetAutoFilterData(
    filter: SharedInventoryCarFilterInput,
    sort: SharedInventoryCarSortInput[] = [],
  ): Observable<LotsListAucnetAutoFilterDataQuery> {
    const fetchAucnetAutoFilterDataRef = this.qhs.fetch<
      LotsListAucnetAutoFilterDataQuery,
      LotsListAucnetAutoFilterDataQueryVariables
    >(this.lotsListAucnetAutoFilterDataGQL, {
      filter,
      sort,
    });
    this.loading$ = fetchAucnetAutoFilterDataRef.loading;
    return fetchAucnetAutoFilterDataRef.data.pipe(
      map((data) => ({
        ...data,
        companies: this.sortAutomobileCompanies(data.companies),
      })),
    );
  }

  getMochironFilterData(
    departmentId: Department,
    filter: StockCarFilterInput,
    sort: StockCarSortInput[] = [],
  ): Observable<LotsListMochironFilterDataQuery> {
    const fetchMochironFilterDataRef = this.qhs.fetch<
      LotsListMochironFilterDataQuery,
      LotsListMochironFilterDataQueryVariables
    >(this.lotsListMochironFilterDataGQL, {
      departmentId,
      filter,
      sort,
    });
    this.loading$ = fetchMochironFilterDataRef.loading;
    return fetchMochironFilterDataRef.data.pipe(
      map((data) => ({
        ...data,
        companies: this.sortAutomobileCompanies(data.companies),
      })),
    );
  }

  getAucnetMotoFilterData(
    filter: SharedInventoryMotoFilterInput,
    sort: SharedInventoryMotoSortInput[] = [],
  ): Observable<LotsListAucnetMotoFilterDataQuery> {
    const fetchAucnetMotoDataRef = this.qhs.fetch<
      LotsListAucnetMotoFilterDataQuery,
      LotsListAucnetMotoFilterDataQueryVariables
    >(this.lotsListAucnetMotoFilterDataGQL, {
      filter,
      sort,
    });
    this.loading$ = fetchAucnetMotoDataRef.loading;
    return fetchAucnetMotoDataRef.data.pipe(map((data) => data));
  }

  getAucnetTrucksFilterData(
    filter: SharedInventoryTruckFilterInput,
    sort: SharedInventoryTruckSortInput[] = [],
  ): Observable<LotsListAucnetTrucksFilterDataQuery> {
    const fetchAucnetTrucksDataRef = this.qhs.fetch<
      LotsListAucnetTrucksFilterDataQuery,
      LotsListAucnetTrucksFilterDataQueryVariables
    >(this.lotsListAucnetTrucksFilterDataGQL, {
      filter,
      sort,
    });
    this.loading$ = fetchAucnetTrucksDataRef.loading;
    return fetchAucnetTrucksDataRef.data.pipe(
      map((data) => ({
        ...data,
        companies: this.sortAutomobileCompanies(data.companies),
      })),
    );
  }

  sortAutomobileCompanies(companies: Company[]): Company[] {
    const clonedCompanies = [...companies];
    let mainCompanies = clonedCompanies.filter((company) =>
      mainAutomobileCompanies.includes(company?.name || ''),
    );
    let otherCompanies = clonedCompanies.filter(
      (company) => !mainAutomobileCompanies.includes(company?.name || ''),
    );

    mainCompanies = this.companyNameSort(mainCompanies).reverse();
    otherCompanies = this.companyNameSort(otherCompanies);

    return [...mainCompanies, ...otherCompanies];
  }

  companyNameSort(companies: Company[]): Company[] {
    return companies.sort((a, b) => {
      const name1 = a?.name?.toUpperCase() || '';
      const name2 = b?.name?.toUpperCase() || '';

      if (name1 === name2) {
        return 0;
      }

      return name1 > name2 ? 1 : -1;
    });
  }
}

<app-header-section></app-header-section>

<div class="section-group default-background">
  <app-who-we-are-section></app-who-we-are-section>
  @if (isRussianClient$ | push) {
    <app-buhanka-donate-section></app-buhanka-donate-section>
  }
  <app-category-section></app-category-section>
  <app-goods-and-parts-section></app-goods-and-parts-section>
  <app-popular-cars-section></app-popular-cars-section>
  <app-delivery-statistics-section></app-delivery-statistics-section>
</div>

<div class="section-group white-background">
  <app-useful-information-section></app-useful-information-section>
  <app-news-section></app-news-section>
</div>

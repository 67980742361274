import { ChangeDetectionStrategy, Component } from '@angular/core';

import { ImageDirective } from './image.directive';

@Component({
  selector: 'app-image-group',
  template: '<ng-content></ng-content>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageGroupComponent {
  images: ImageDirective[] = [];

  addImage(image: ImageDirective): void {
    this.images.push(image);
  }

  removeImage(image: ImageDirective): void {
    const index = this.images.indexOf(image);
    if (index !== -1) {
      this.images.splice(index, 1);
    }
  }
}

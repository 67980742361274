import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { ButtonModule } from 'src/app/components/button/button.module';
import { LanguageService } from 'src/app/services/language.service';

import { GoodsAndPartsSectionComponent } from './goods-and-parts-section.component';
import { en } from './locale/en';
import { ru } from './locale/ru';

@NgModule({
  declarations: [GoodsAndPartsSectionComponent],
  imports: [CommonModule, TranslateModule, ButtonModule, NzGridModule],
  exports: [GoodsAndPartsSectionComponent],
})
export class GoodsAndPartsSectionModule {
  constructor(languageService: LanguageService) {
    languageService.addLocales({ en, ru });
  }
}

import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {
  combineLatest,
  distinctUntilChanged,
  firstValueFrom,
  map,
  Observable,
  ReplaySubject,
  shareReplay,
  skip,
  startWith,
  switchMap,
} from 'rxjs';
import {
  Company,
  Frame,
  Model,
  StockCarFilterInput,
} from 'src/app/graphql/mochiron-graphql.service';
import {departmentIdByLotType} from 'src/app/services/lot-adapter/constants/department-id-by-lot-type';
import {LotType} from 'src/app/services/lot-adapter/enums/lot-type.enum';

import {LotsListFilterBuilderService} from '../../services/lots-list-filter-builder.service';
import {LotListFilterDataService} from '../../services/lots-list-filter-data.service';
import {LotsListMochironFilteringForm} from '../../types/lots-list-filtering-form.type';

@Component({
  selector: 'app-lots-list-mochiron-filter',
  templateUrl: './lots-list-mochiron-filter.component.html',
  styleUrl: './lots-list-mochiron-filter.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotsListMochironFilterComponent {
  @Input({required: true})
  set activeLotType(value: LotType) {
    this.activeLotTypeSubject.next(value);
  }

  @Output() closeDrawer = new EventEmitter<void>();
  formGroup: FormGroup<LotsListMochironFilteringForm>;
  loading$?: Observable<boolean> | undefined;

  activeLotTypeSubject = new ReplaySubject<LotType>(1);

  companies$: Observable<Company[]>;
  models$: Observable<Model[]>;
  frames$: Observable<Frame[]>;
  years: number[];
  scores$: Observable<string[]>;
  transmissions$: Observable<string[]>;

  constructor(
    fb: FormBuilder,
    lotListFilterDataService: LotListFilterDataService,
    private lotsListFilterBuilder: LotsListFilterBuilderService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.formGroup = fb.nonNullable.group<LotsListMochironFilteringForm>({
      companyId: fb.control(null),
      modelId: fb.control(null),
      frame: fb.control([]),
      idObject: fb.control(null),
      priceFrom: fb.control(null),
      priceTo: fb.control(null),
      yearFrom: fb.control(null),
      yearTo: fb.control(null),
      mileageFrom: fb.control(null),
      mileageTo: fb.control(null),
      engineSizeFrom: fb.control(null),
      engineSizeTo: fb.control(null),
      score: fb.control(null),
      transmission: fb.control(null),
      notSold: fb.control(null),
    });

    this.route.queryParams.pipe(takeUntilDestroyed()).subscribe((params) => {
      const parsedParams = this.lotsListFilterBuilder.parseParams(params);
      this.formGroup.patchValue(parsedParams);
    });

    const {companyId, modelId} = this.formGroup.controls;

    this.loading$ = lotListFilterDataService.loading$;

    const filterData$ = combineLatest([
      this.formGroup.controls.companyId.valueChanges.pipe(
        startWith(this.route.snapshot.queryParams['companyId'] || null),
      ),
      this.formGroup.controls.modelId.valueChanges.pipe(
        startWith(this.route.snapshot.queryParams['modelId'] || null),
      ),
      this.activeLotTypeSubject.pipe(distinctUntilChanged()),
    ]).pipe(
      switchMap(([companyId, modelId, activeLotType]) => {
        const departmentId =
          departmentIdByLotType[activeLotType as keyof typeof departmentIdByLotType];

        const filter: StockCarFilterInput = {};

        if (activeLotType === LotType.GreenCorner) {
          filter.transmissionEn = {
            in: ["AT", "MT"],
          };
        }

        if (companyId) filter.companyEn = {eq: companyId};
        if (modelId) filter.modelEn = {eq: modelId};

        return lotListFilterDataService.getMochironFilterData(departmentId, filter);
      }),
      shareReplay(1),
    );

    this.companies$ = filterData$.pipe(map((data) => data.companies));
    const allModels$ = filterData$.pipe(map((data) => data.models));

    this.models$ = combineLatest([
      allModels$,
      this.formGroup.controls.companyId.valueChanges.pipe(
        startWith(this.route.snapshot.queryParams['companyId'] || null),
      ),
    ]).pipe(
      map(([models, companyId]) => {
        if (companyId) {
          return models.filter((model) => model.companyId === companyId);
        }
        return models;
      }),
    );

    this.years = lotListFilterDataService.years;
    this.frames$ = filterData$.pipe(map((data) => data.frames));
    this.scores$ = filterData$.pipe(
      map((data) =>
        data.scores.map((score) =>
          score.toString().length === 2 ? score.split('').join('.') : score,
        ),
      ),
    );
    this.transmissions$ = filterData$.pipe(map((data) => data.transmissions));

    companyId.valueChanges
      .pipe(distinctUntilChanged(), takeUntilDestroyed())
      .subscribe((newCompanyId) => {
        this.formGroup.reset({
          companyId: newCompanyId,
        });
      });

    modelId.valueChanges
      .pipe(distinctUntilChanged(), takeUntilDestroyed())
      .subscribe((newModelId) => {
        this.formGroup.reset({
          companyId: this.formGroup.controls.companyId.value,
          modelId: newModelId,
        });
      });

    this.activeLotTypeSubject
      .pipe(skip(1), takeUntilDestroyed())
      .subscribe(() => this.formGroup.reset());
  }

  async applyFiltersOnEnter(e: Event): Promise<void> {
    e.preventDefault();
    await this.applyFilters();
  }

  async applyFilters(): Promise<void> {
    const formValue = this.formGroup.value;
    const idObject = formValue.idObject?.trim();

    const activeLotType = await firstValueFrom(this.activeLotTypeSubject);

    if (idObject) {
      this.router.navigate(['/', activeLotType, 'lots', idObject]);
      return;
    }

    const params = this.lotsListFilterBuilder.getParams(formValue);
    this.router.navigate([], {
      queryParams: params,
      queryParamsHandling: 'merge',
    });
    this.closeDrawer.emit();
  }

  resetFilters(): void {
    this.formGroup.reset();
    this.applyFilters();
  }
}

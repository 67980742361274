import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {NewsItemFragment} from "../../graphql/content-auto-graphql.service";

@Component({
  selector: 'app-news-card',
  templateUrl: './news-card.component.html',
  styleUrl: './news-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewsCardComponent {
  @Input({required: true}) newsItem: NewsItemFragment | undefined;
  @Input() background: 'white' | 'gray' = 'gray';

  constructor() {
  }
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {NzModalService} from "ng-zorro-antd/modal";
import {BuhankaDonateModalComponent} from "../buhanka-donate-modal/buhanka-donate-modal.component";

@Component({
  selector: 'app-buhanka-donate-button',
  templateUrl: './buhanka-donate-button.component.html',
  styleUrl: './buhanka-donate-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BuhankaDonateButtonComponent {
  @Input({ required: true }) enabled = false;

  constructor(private nzModalService: NzModalService) {
  }

  openBuhankaDonateModal() {
    this.nzModalService.create({
      nzContent: BuhankaDonateModalComponent,
      nzFooter: null,
    });
  }
}

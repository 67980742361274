import { Apollo } from 'apollo-angular';
import { defer, from, Observable } from 'rxjs';
import { map, switchMap, toArray } from 'rxjs/operators';

import { NotificationService } from '../../notification.service';
import { MutationHandlerOptions } from '../interfaces/mutation-handling-options.interface';
import { NotificationType } from '../types/notification.type';

export abstract class BaseMutationRef {
  constructor(
    private apollo: Apollo,
    private client: string,
    protected options: MutationHandlerOptions,
    private notificationService: NotificationService,
  ) {}

  protected refetchQueries(): Observable<unknown[]> {
    return defer(() =>
      from(
        this.apollo
          .use(this.options.refetchClient ?? this.client)
          .client.reFetchObservableQueries(),
      ),
    ).pipe(
      switchMap((results) => from(results)),
      map((result) => result.data),
      toArray(),
    );
  }

  protected notify(type: NotificationType, message?: string) {
    switch (type) {
      case 'fail':
        this.notificationService.error(
          this.options.failureTranslationKey || 'GLOBALS.MUTATION_FAILED',
          message,
        );
        break;

      case 'success':
        this.notificationService.error(
          this.options.successTranslationKey || 'GLOBALS.MUTATION_SUCCEEDED',
          message,
        );
        break;

      case 'warning':
        this.notificationService.warning(
          this.options.successTranslationKey || 'GLOBALS.MUTATION_PARTIALLY',
          message,
        );
        break;
    }
  }
}

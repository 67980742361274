import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';
import { CurrentUserService } from 'src/app/services/current-user/current-user.service';

@Component({
  selector: 'app-delivery-statistics-section',
  templateUrl: './delivery-statistics-section.component.html',
  styleUrl: './delivery-statistics-section.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeliveryStatisticsSectionComponent {
  isUserAuth$: Observable<boolean>;

  constructor(currentUserService: CurrentUserService) {
    this.isUserAuth$ = currentUserService.isAuthenticated$;
  }
}

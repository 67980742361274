import { ru } from './ru';

export const en: typeof ru = {
  DELIVERY_STATISTICS_SECTION: {
    "DELIVERY_FROM": 'Delivery times from the moment of purchase',
    "BEFORE_TO_TOYAMA": 'before loading in Toyama:',
    "BEFORE_TO_VLADIVOSTOK": 'before unloading in Vladivostok:',
    "AVERAGE": 'On average',
    "CARS": 'Cars',
    "MOTO": 'Motorbikes',
    "MARK": 'The calculation includes all cars purchased and shipped over the past 60 days. Updated as of {{date}}',
    "DAY_PLURALS": {
      "D": 'd.',
      "SINGLE": 'Day',
      "SOME": 'Days',
      "MANY": 'd.',
    },
    "STAGES_TITLE": 'Stages of buying a car at a Japanese auction',
    "STAGES_WARNING": 'Please note that sea shipping times may vary depending on the country and port of destination.',
    "STATISTICS_STEP": {
      "REGISTRATION": "Registration and contract",
      "REPLENISHMENT": "Deposit and access",
      "SEARCH": "Searching for a vehicle",
      "PARTICIPATION": "Auction participating",
      "PAYMENT": "Payment for your Purchase",
      "SHIPPING": "Shipping arrangements",
      "SEA": "Sea freight",
      "CUSTOMS": "Customs clearance",
      "DAY": "День",
    },
    "MODAL_INFO": {
      "REGISTRATION": "Register in our system to gain access to your personal account. This process will not take much of your time, and our manager is ready to assist you if any questions arise. After registering, you will receive an email with a link to confirm your account. Please follow that link to complete the registration process. The manager may request your passport details to finalize the contract with our company. After confirming your registration, you will have access to a personal account where you can track and manage your purchases.",
      "REPLENISHMENT": "To access our platform with car, motorcycle, and specialized machinery auctions, you will need to place a deposit. The deposit amount depends on the value of the equipment. For more expensive items, you may be required to have up to 30% of your planned bid in your account. The deposit must be paid personally by the individual whose name is on the personal account. Payments from third parties are not accepted.",
      "SEARCH": "Browse through more than 80,000 items of equipment updated weekly across 130+ Japanese auctions. In addition to the actual bidding process, you can take advantage of numerous additional services. These include text and/or voice translation of the auction sheet, an optional video inspection of the lot you’re interested in to gather more information, group bids, production date verification, and more.",
      "PARTICIPATION": "Once you find a vehicle that interests you, don’t hesitate to place a bid to join the auction. The company reserves the right to slightly increase (“top up”) your bid by about 20,000–30,000 yen (depending on the specifics of the auction) to boost your chances of winning. You can request to remove or reduce your bid directly on our platform. All details and rules are set out in a special section available at any time on the platform.",
      "PAYMENT": "If your bid wins, you will receive a corresponding notification, and the lot will appear in your personal account. There, you can track all movements of your item, view photo documentation from the purchase stage up to unloading at the destination port, and manage document flow. Payment can be made in one or two installments, depending on the shipping method. In some cases, full prepayment is required before shipment. Payment must be completed within three days.",
      "SHIPPING": "After your item is fully paid for and we have received all necessary recipient details, we will begin organizing the shipment. The equipment is transferred either to an export yard or directly to the port. We obtain the export certificate and prepare all necessary documents for transportation and customs clearance, based on the requirements of the destination country’s documentation. Our logistics team selects the optimal route, arranges bookings on ships or in containers (if needed or according to the client’s choice), and coordinates with customs agents. You will be notified of the loading date, approximate departure time, and the expected arrival date at the destination port. All this information will be available in your personal account.",
      "SEA": "Our specialists load the equipment onto the vessel, ensuring it is properly secured and that all shipping regulations are followed. At this point, you can request additional photos or videos if you wish to check the condition of the vehicle. Once the vessel departs, we send all necessary documents via airmail, DHL, or FedEx. If the vehicle is shipped in a container, you can receive a tracking number to monitor its journey.",
      "CUSTOMS": "Upon arrival, the item must undergo customs procedures. We recommend using professional customs agents to speed up the process. If you lack experience in self-clearance, we can offer our partners’ services. All necessary paperwork will be prepared by our company. Should your country require any additional certificates, we will clarify that in advance and help you obtain them. Import duties and excise taxes differ in each country, and Akebono managers can inform you which payments must be made and by when, helping you avoid fines or delays in customs. After successful clearance, we will help arrange shipment of the vehicle to your address by the method most convenient for you.",
      "MEDIA_WARNING": "Your browser does not support video playback.",
    },
    "REGISTRATION": {
      "TITLE": "Ready to buy a car? 📢",
      "DESCRIPTION": "Log in to your account or register. It will take no more than 5 minutes!",
      "LOGIN": "Login/Registration",
    }
  },
};

import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import { map, Observable, ReplaySubject, shareReplay, skip, switchMap, tap } from 'rxjs';
import { LotsListSortBuilderService } from 'src/app/modules/auction/components/lots-list-sort/services/lots-list-sort-builder.service';
import { LotType } from 'src/app/services/lot-adapter/enums/lot-type.enum';
import { ILotMinimal } from 'src/app/services/lot-adapter/interfaces/lot-minimal.interface';
import { LotFetcherService } from 'src/app/services/lot-fetcher/lot-fetcher.service';
import { FetchLotsQueryVariables } from 'src/app/services/lot-fetcher/types/fetch-lots-query-variables.type';
import { LotsListFilterBuilderService } from '../../../../components/lots-list-filter/services/lots-list-filter-builder.service';

@Component({
  selector: 'app-lots-list',
  templateUrl: './lots-list.component.html',
  styleUrl: './lots-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotsListComponent {
  @Input({ required: true })
  set activeLotType(value: LotType) {
    this.activeLotTypeSubject.next(value);
  }
  @Output() totalChange = new EventEmitter<number>();
  @Output() pageSizeChange = new EventEmitter<number>();
  @Output() offsetChange = new EventEmitter<number>();

  @ViewChild('lotsList')
  lotsList!: ElementRef<HTMLDivElement>;

  private activeLotTypeSubject = new ReplaySubject<LotType>(1);

  pageSize = 30;
  currentPage = 1;

  lots$!: Observable<ILotMinimal[]>;
  loading$!: Observable<boolean>;
  total$!: Observable<number>;

  grid = {
    xxl: 8,
    xl: 8,
    lg: 10,
    md: 12,
    sm: 12,
    xs: 24,
  };

  constructor(
    lotFetcherService: LotFetcherService,
    route: ActivatedRoute,
    private router: Router,
    lotsListFilterBuilder: LotsListFilterBuilderService,
    lotsListSortBuilder: LotsListSortBuilderService,
  ) {
    const lotsData$ = route.queryParams.pipe(
      tap((params) => {
        this.currentPage = +params['page'] || 1;
        this.pageSize = +params['pageSize'] || 30;
        this.pageSizeChange.emit(this.pageSize);
        this.offsetChange.emit((this.currentPage - 1) * this.pageSize);
      }),
      switchMap((params) =>
        this.activeLotTypeSubject.pipe(
          map((activeLotType) => ({
            params,
            activeLotType,
          })),
        ),
      ),
      map(({ params, activeLotType }) => {
        const filter = lotsListFilterBuilder.buildFilter(params, activeLotType);
        const sort = lotsListSortBuilder.buildSort(params, activeLotType);

        const isFilterEmpty = Object.values(filter).every(
          (value) => value === null || value === undefined,
        );
        return <FetchLotsQueryVariables>{
          first: this.pageSize,
          offset: (this.currentPage - 1) * this.pageSize,
          filter: isFilterEmpty ? null : filter,
          sort,
        };
      }),
      switchMap((variables) =>
        this.activeLotTypeSubject.pipe(map((activeLotType) => ({ activeLotType, variables }))),
      ),
      map(({ activeLotType, variables }) => lotFetcherService.fetchLots(activeLotType, variables)),
      shareReplay(1),
    );

    this.loading$ = lotsData$.pipe(switchMap(({ loading$ }) => loading$));
    this.lots$ = lotsData$.pipe(switchMap(({ lots$ }) => lots$));
    this.total$ = lotsData$.pipe(
      switchMap(({ total$ }) => total$),
      tap((total) => this.totalChange.emit(total)),
    );

    this.activeLotTypeSubject
      .pipe(skip(1), takeUntilDestroyed())
      .subscribe(() => this.router.navigate([]));
  }

  onPageChange(page: number): void {
    this.lotsList.nativeElement.scrollIntoView({ behavior: 'smooth' });
    this.router.navigate([], {
      queryParams: { page, pageSize: this.pageSize },
      queryParamsHandling: 'merge',
    });
  }
}

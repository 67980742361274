<form [formGroup]="form">
  <nz-row [nzGutter]="[10, 10]" nzAlign="middle">
    <nz-col nzXs="24" nzMd="7" nzLg="6" nzXl="5">
      <nz-form-control>
        <nz-date-picker
          formControlName="dateFrom"
          [nzFormat]="'yyyy-MM-dd'"
          [nzAllowClear]="true"
          [nzPlaceHolder]="'NEWS_PAGE.FILTER.DATE_FROM' | translate"
        ></nz-date-picker>
      </nz-form-control>
    </nz-col>

    <nz-col nzXs="24" nzMd="7" nzLg="6" nzXl="5">
      <nz-form-control>
        <nz-date-picker
          formControlName="dateTo"
          [nzFormat]="'yyyy-MM-dd'"
          [nzAllowClear]="true"
          [nzPlaceHolder]="'NEWS_PAGE.FILTER.DATE_TO' | translate"
        ></nz-date-picker>
      </nz-form-control>
    </nz-col>

    <nz-col nzXs="24" nzMd="9" nzLg="7">
      <nz-row [nzGutter]="[10, 10]">
        <nz-col>
          <button
            app-button
            appType="primary"
            (click)="apply()"
          >
            {{ 'NEWS_PAGE.FILTER.APPLY' | translate }}
          </button>
        </nz-col>
        <nz-col>
          <button app-button (click)="reset()">
            {{ 'NEWS_PAGE.FILTER.RESET' | translate }}
          </button>
        </nz-col>
      </nz-row>
    </nz-col>
  </nz-row>
</form>

import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { RxPush } from '@rx-angular/template/push';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { LotListFilterModule } from 'src/app/modules/auction/components/lots-list-filter/lots-list-filter.module';

import { ButtonModule } from '../../../../components/button/button.module';
import { LanguageService } from '../../../../services/language.service';
import { ExchangeRatesComponent } from './components/exchange-rates/exchange-rates.component';
import { JapanTimeComponent } from './components/japan-time/japan-time.component';
import { MiniFilteringFormComponent } from './components/mini-filtering-form/mini-filtering-form.component';
import { TrackingLinkComponent } from './components/tracking-link/tracking-link.component';
import { HeaderSectionComponent } from './header-section.component';
import { en } from './locale/en';
import { ru } from './locale/ru';

@NgModule({
  declarations: [
    HeaderSectionComponent,
    ExchangeRatesComponent,
    JapanTimeComponent,
    MiniFilteringFormComponent,
    TrackingLinkComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    LotListFilterModule,
    NzIconModule,
    NzSelectModule,
    NzInputModule,
    FormsModule,
    NzGridModule,
    NzInputNumberModule,
    NzDatePickerModule,
    RxPush,
    ButtonModule,
    ReactiveFormsModule,
    NzSpinModule,
    NzFormModule,
  ],
  exports: [HeaderSectionComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class HeaderSectionModule {
  constructor(languageService: LanguageService) {
    languageService.addLocales({ en, ru });
  }
}

<nz-spin [nzSpinning]="loading$ | push" nzSize="large">
  <form
    nz-form
    [formGroup]="formGroup"
    nzLayout="vertical"
    (keydown.enter)="applyFiltersOnEnter($event)"
  >
    <h4>{{ 'FILTER.CAR_SEARCH.SEARCH_CAR' | translate }}</h4>
    <div class="search-car">
      <nz-select
        [nzPlaceHolder]="'FILTER.CAR_SEARCH.MANUFACTURER' | translate"
        nzAllowClear
        nzShowSearch
        formControlName="companyId"
      >
        @for (company of companies$ | push; track company.id) {
          <nz-option
            [nzValue]="company.id"
            [nzLabel]="company.name === '' ? ('FILTER.ALL' | translate) : company.name"
          >
          </nz-option>
        }
      </nz-select>

      <nz-select
        [nzPlaceHolder]="'FILTER.CAR_SEARCH.MODEL' | translate"
        nzAllowClear
        nzShowSearch
        formControlName="modelId"
      >
        @for (model of models$ | push; track $index) {
          <nz-option
            [nzValue]="model.id"
            [nzLabel]="
            model.name === ''
              ? ('FILTER.ALL' | translate)
              : model.name + ' (' + model.lotsCount + ')'
          "
          >
          </nz-option>
        }
      </nz-select>

      <nz-select
        [nzPlaceHolder]="'FILTER.CAR_SEARCH.CHASSIS' | translate"
        nzMode="multiple"
        nzAllowClear
        nzShowSearch
        formControlName="frame"
      >
        @for (frame of frames$ | push; track frame.name) {
          <nz-option [nzValue]="frame.name" [nzLabel]="frame.count + frame.name!"></nz-option>
        }
      </nz-select>

      <input
        nz-input
        [placeholder]="'FILTER.CAR_SEARCH.ID_OBJECT' | translate"
        type="text"
        formControlName="idObject"
        (keydown.enter)="applyFilters()"
      />
    </div>

    @if ((activeLotTypeSubject | async) !== 'recycling') {
      <h4>{{ 'FILTER.CAR_SEARCH.PRICE' | translate }}</h4>
      <div class="from-to">
        <nz-input-number
          [nzPlaceHolder]="'FILTER.CAR_SEARCH.PRICE_FROM' | translate"
          formControlName="priceFrom"
          [nzMin]="0"
        />
        <nz-input-number
          [nzPlaceHolder]="'FILTER.CAR_SEARCH.PRICE_TO' | translate"
          formControlName="priceTo"
          [nzMin]="0"
        />
      </div>
      <label nz-checkbox formControlName="notSold">
        {{ 'FILTER.ADDITIONAL_OPTIONS.NOTE_SOLD' | translate }}
      </label>
    }

    <h4>{{ 'FILTER.YEAR.YEAR' | translate }}</h4>
    <div class="from-to">
      <nz-select
        [nzPlaceHolder]="'FILTER.YEAR.FROM' | translate"
        nzAllowClear
        nzShowSearch
        formControlName="yearFrom"
      >
        @for (year of years; track year) {
          <nz-option [nzValue]="year" [nzLabel]="year!"></nz-option>
        }
      </nz-select>

      <nz-select
        [nzPlaceHolder]="'FILTER.YEAR.TO' | translate"
        nzAllowClear
        nzShowSearch
        formControlName="yearTo"
      >
        @for (year of years; track year) {
          <nz-option [nzValue]="year" [nzLabel]="year!"></nz-option>
        }
      </nz-select>
    </div>

    <h4>{{ 'FILTER.MILEAGE.MILEAGE' | translate }}</h4>
    <div class="from-to">
      <nz-input-number
        [nzPlaceHolder]="'FILTER.MILEAGE.FROM' | translate"
        formControlName="mileageFrom"
        [nzMin]="0"
      />
      <nz-input-number
        [nzPlaceHolder]="'FILTER.MILEAGE.TO' | translate"
        formControlName="mileageTo"
        [nzMin]="0"
      />
    </div>

    <div class="additional-options">
      <app-collapse type="wide" [title]="'FILTER.ADDITIONAL_OPTIONS.ADDITIONAL' | translate">
        <h4>{{ 'FILTER.ENGINE_CAPACITY.TITLE' | translate }}</h4>
        <div class="from-to">
          <nz-input-number
            [nzPlaceHolder]="'FILTER.ENGINE_CAPACITY.FROM' | translate"
            formControlName="engineSizeFrom"
            [nzMin]="0"
          />
          <nz-input-number
            [nzPlaceHolder]="'FILTER.ENGINE_CAPACITY.TO' | translate"
            formControlName="engineSizeTo"
            [nzMin]="0"
          />
        </div>

        <nz-select
          [nzPlaceHolder]="'FILTER.ADDITIONAL_OPTIONS.GRADE' | translate"
          nzAllowClear
          nzShowSearch
          formControlName="score"
        >
          @for (scores of scores$ | push; track scores) {
            <nz-option
              [nzValue]="scores"
              [nzLabel]="scores === '' ? ('FILTER.ALL' | translate) : scores"
            >
            </nz-option>
          }
        </nz-select>

        <nz-select
          [nzPlaceHolder]="'FILTER.ADDITIONAL_OPTIONS.TRANSMISSION' | translate"
          nzAllowClear
          nzShowSearch
          formControlName="transmission"
        >
          @for (transmission of transmissions$ | push; track transmission) {
            <nz-option
              [nzValue]="transmission"
              [nzLabel]="transmission === '' ? ('FILTER.ALL' | translate) : transmission"
            >
            </nz-option>
          }
        </nz-select>
      </app-collapse>
    </div>

    <app-lots-list-filter-buttons
      (resetFilters)="resetFilters()"
      (applyFilters)="applyFilters()"
    ></app-lots-list-filter-buttons>
  </form>
</nz-spin>

import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { Environment } from '../utilities/types/environment.type';

@Injectable()
export class EnvironmentService {
  public readonly currentEnvironment: Readonly<Environment> = Object.freeze(environment);

  public get isDevelopment(): boolean {
    return this.isEnvironment('development');
  }

  public get isStaring(): boolean {
    return this.isEnvironment('staging');
  }

  public get isProduction(): boolean {
    return this.isEnvironment('production');
  }

  public isEnvironment(environmentName: Environment['environmentName']): boolean {
    return this.currentEnvironment.environmentName === environmentName;
  }
}

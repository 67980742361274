import { HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { AuthService } from 'ngx-auth';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable, of, tap } from 'rxjs';

import { LOCATION } from '../constants/tokens/location.token';
import { CognitoService } from './cognito.service';
import { EnvironmentService } from './environment.service';

@Injectable()
export class AuthenticationService implements AuthService {
  private readonly hasAccessTokenSubject: BehaviorSubject<boolean>;

  constructor(
    @Inject(LOCATION)
    private readonly location: Location,
    private readonly apollo: Apollo,
    private readonly cookies: CookieService,
    private readonly cognito: CognitoService,
    private readonly environment: EnvironmentService,
  ) {
    this.hasAccessTokenSubject = new BehaviorSubject<boolean>(!!this.accessTokenCookie);
  }

  public isAuthorized(): Observable<boolean> {
    return this.hasAccessTokenSubject.asObservable();
  }

  public getAccessToken(): Observable<string | null> {
    return of(this.accessTokenCookie);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public refreshToken(): Observable<any> {
    return this.cognito.refreshSession(this.accessTokenCookie!, this.refreshTokenCookie!).pipe(
      tap((result) => {
        if (!result) {
          throw new Error('No authentication result');
        }
      }),
      tap({
        next: (result) => {
          const newToken = result.accessToken.jwtToken;
          this.accessTokenCookie = newToken;
        },
        error: () => this.signOut(),
      }),
    );
  }

  public refreshShouldHappen(response: HttpErrorResponse): boolean {
    return response.status === 401;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public skipRequest(request: HttpRequest<any>): boolean {
    return (
      (request.body && request.body.operationName === 'refreshToken') ||
      request.url.includes('s3.amazonaws.com')
    );
  }

  public getHeaders(token: string): Record<string, string | string[]> {
    return {
      authorization: token,
    };
  }

  public signOut(): void {
    if (!(this.accessTokenCookie || this.refreshTokenCookie)) {
      return;
    }

    this.accessTokenCookie = null;
    this.refreshTokenCookie = null;

    this.resetApolloClients();
  }

  private get accessTokenCookie(): string | null {
    return this.cookies.get('token') || null;
  }

  private set accessTokenCookie(token: string | null) {
    this.setTokenCookieValue('token', token);
    this.hasAccessTokenSubject.next(!!token);
  }

  private get refreshTokenCookie(): string | null {
    return this.cookies.get('refreshToken') || null;
  }

  private set refreshTokenCookie(token: string | null) {
    this.setTokenCookieValue('refreshToken', token);
  }

  private setTokenCookieValue(tokenType: 'token' | 'refreshToken', token: string | null): void {
    const { domain } = this.environment.currentEnvironment;
    const secure = this.location.protocol === 'https:';

    if (token) {
      this.cookies.set(tokenType, token, 31, '/', domain, secure, 'None');
    } else {
      this.cookies.delete(tokenType, '/', domain);
    }
  }

  private resetApolloClients(): void {
    for (const clientConfig of this.environment.currentEnvironment.graphqlClients) {
      const client = this.apollo.use(clientConfig.clientName)?.client;

      if (client) {
        client.stop();
        client.resetStore();
      }
    }
  }
}

<nz-breadcrumb [nzSeparator]="iconTemplate">
  <nz-breadcrumb-item>
    @if (lotName) {
    <a [routerLink]="['/', activeLotType | lowercase, 'lots']">
      {{ 'AUCTION_BREADCRUMB.' + activeLotType | uppercase | translate }}
    </a>
    } @else {
    <span>{{ 'AUCTION_BREADCRUMB.' + activeLotType | uppercase | translate }}</span>
    }
  </nz-breadcrumb-item>

  @if (lotName || isLoading) { @if (lotName) {
  <nz-breadcrumb-item>
    <span>{{ lotName }}</span>
  </nz-breadcrumb-item>
  } @else {
  <nz-breadcrumb-item>
    @if (isLoading) {
    <span nz-icon nzType="loading" class="loading-icon"></span>
    }
  </nz-breadcrumb-item>
  } }

  <ng-template #iconTemplate><i nz-icon nzType="icons:vector"></i></ng-template>
</nz-breadcrumb>

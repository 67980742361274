import { ApolloQueryResult } from '@apollo/client/core';
import { Observable } from 'rxjs';
import { publishReplay, refCount, startWith } from 'rxjs/operators';

import { NotificationService } from '../../notification.service';
import { BaseQueryRef } from './base.query-ref.';

export class FetchQueryRef<T> extends BaseQueryRef<T> {
  constructor(
    queryObservable: Observable<ApolloQueryResult<T>>,
    notificationService?: NotificationService,
  ) {
    super(
      queryObservable.pipe(
        startWith(<ApolloQueryResult<T>>{ loading: true }),
        publishReplay(1),
        refCount(),
      ),
      notificationService,
    );

    this.data$ = this.compileDataObservable();
    this.loading$ = this.compileLoadingObservable();
  }
}

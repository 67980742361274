import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';
import { CurrentUserService } from '../../../../services/current-user/current-user.service';

@Component({
  selector: 'app-authorization-actions',
  templateUrl: './authorization-actions.component.html',
  styleUrl: './authorization-actions.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthorizationActionsComponent {
  isUserAuth$: Observable<boolean>;
  isLoading$: Observable<boolean>;

  constructor(currentUserService: CurrentUserService) {
    this.isUserAuth$ = currentUserService.isAuthenticated$;
    this.isLoading$ = currentUserService.loading$;
  }
}

import { Injectable } from '@angular/core';

import { LotType } from '../../../../../../services/lot-adapter/enums/lot-type.enum';
import { ILot } from '../../../../../../services/lot-adapter/interfaces/lot.interface';
import { LotFetcherService } from '../../../../../../services/lot-fetcher/lot-fetcher.service';
import { FetchLotsQueryVariables } from '../../../../../../services/lot-fetcher/types/fetch-lots-query-variables.type';
import { FetchLotsReference } from '../../../../../../services/lot-fetcher/types/fetch-lots-reference.type';

@Injectable()
export class LotSimilarAdsService {
  constructor(private lotFetcherService: LotFetcherService) {}

  getSimilarAds(lot: ILot): FetchLotsReference {
    console.log('getSimilarAds lot', lot);
    let variables: FetchLotsQueryVariables = {
      first: 4,
      filter: {},
    };

    switch (lot.type) {
      case LotType.Japan:
      case LotType.Truck:
        variables.filter = {
          makerEn: { eq: lot.maker },
          carnameEn: { eq: lot.mark },
        };
        if (lot.inventoryNo) {
          variables.filter.inventoryNo = { neq: lot.inventoryNo };
        }
        if (lot.year) {
          variables.filter.registration = { gte: lot.year * 100, lte: (lot.year + 1) * 100 };
        }
        if (lot.engineVolume?.value) {
          variables.filter.exhaust = { eq: lot.engineVolume?.value };
        }
        break;

      case LotType.Dubai:
      case LotType.GreenCorner:
      case LotType.Recycling:
      case LotType.Mongolia:
        variables.filter = {
          companyEn: { eq: lot.maker },
          modelEn: { eq: lot.mark },
          isSold: { eq: false },
          id: { neq: Number(lot.id) },
        };
        if (lot.year) {
          variables.filter.year = { eq: lot.year };
        }
        if (lot.engineVolume?.value) {
          variables.filter.engineVolumeNum = { eq: lot.engineVolume?.value };
        }
        break;

      case LotType.Motorcycle:
        variables.filter = {
          company: { eq: lot.maker },
          modelGroup: { eq: lot.mark },
          id: { neq: lot.id }
        };
        break;

      default:
        variables.first = 0;
        break;
    }

    return this.lotFetcherService.fetchLots(lot.type, variables);
  }
}

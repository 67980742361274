export const ru = {
  "WHO_WE_ARE": {
    "TITLE": "Кто мы?",
    "SANCTIONS_POLICY": "Санкционная политика компании",
    "PARAGRAPHS": {
      "1": "Akebono Co., Ltd — японская компания, специализирующаяся на экспорте автомобилей с японских аукционов с <span class=\"blue\">опытом более 20 лет</span>. Компания предоставляет полный спектр услуг, включая покупку автомобилей на аукционах, логистику, документальное сопровождение и морскую перевозку.",
      "2": "Помогаем приобрести любые типы транспортных средств, в том числе - легковые автомобили, мотоциклы, грузовые и пассажирские авто, прицепы и спецтехнику. Так же, предлагаем нашим клиентам доступ для приобретения прочих товаров с аукционов Японии."
    }
  }
};

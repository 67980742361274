<nz-row [nzGutter]="[10, 30]" nzJustify="center">
  <nz-col [nzXs]="grid.mobile" [nzMd]="grid.desktop">
    <p class="info-title">
      {{ 'FOOTER_CONTACTS.OTHER_COUNTRIES_IMPORT.TITLE' | translate }}
    </p>
    <p class="info-text menu">
      <a href="//auc.{{ domain }}" target="_blank">
        {{ 'FOOTER_CONTACTS.OTHER_COUNTRIES_IMPORT.NEWS' | translate }}
      </a>
      <a href="//auc.{{ domain }}/support/information" target="_blank">
        {{ 'FOOTER_CONTACTS.OTHER_COUNTRIES_IMPORT.INFORMATION' | translate }}
      </a>
      <a href="//auc.{{ domain }}/articles/about-company" target="_blank">
        {{ 'FOOTER_CONTACTS.OTHER_COUNTRIES_IMPORT.ABOUT_COMPANY' | translate }}
      </a>
    </p>
  </nz-col>

  <nz-col [nzXs]="grid.mobile" [nzMd]="grid.desktop">
    <p class="info-title">
      {{ 'FOOTER_CONTACTS.PERSONAL_ACCOUNT.TITLE' | translate }}
    </p>
    <p class="info-text menu">
      <a href="//my.{{ domain }}" target="_blank">
        {{ 'FOOTER_CONTACTS.PERSONAL_ACCOUNT.HOME' | translate }}
      </a>
      <a href="//my.{{ domain }}/cars/information-center" target="_blank">
        {{ 'FOOTER_CONTACTS.PERSONAL_ACCOUNT.CHATS' | translate }}
      </a>
      <a href="//my.{{ domain }}/cars/archive" target="_blank">
        {{ 'FOOTER_CONTACTS.PERSONAL_ACCOUNT.ARCHIVE' | translate }}
      </a>
    </p>
  </nz-col>

  <nz-col [nzXs]="grid.mobile" [nzMd]="grid.desktop">
    <p class="info-title">
      {{ 'FOOTER_CONTACTS.GOODS_AND_PARTS.TITLE' | translate }}
    </p>
    <p class="info-text menu">
      <a href="//my.{{ domain }}/shop/orders" target="_blank">
        {{ 'FOOTER_CONTACTS.GOODS_AND_PARTS.ORDERS' | translate }}
      </a>
      <a href="//my.{{ domain }}/shop/favorites" target="_blank">
        {{ 'FOOTER_CONTACTS.GOODS_AND_PARTS.FAVORITES' | translate }}
      </a>
      <a href="//my.{{ domain }}/shop/orders?tab=1" target="_blank">
        {{ 'FOOTER_CONTACTS.GOODS_AND_PARTS.SHIPMENTS' | translate }}
      </a>
      <a href="//shop.{{ domain }}/support/delivery" target="_blank">
        {{ 'FOOTER_CONTACTS.GOODS_AND_PARTS.DELIVERY' | translate }}
      </a>
      <a href="//shop.{{ domain }}/support/payment" target="_blank">
        {{ 'FOOTER_CONTACTS.GOODS_AND_PARTS.PAYMENT' | translate }}
      </a>
      <a href="//shop.{{ domain }}/support/faq" target="_blank">
        {{ 'FOOTER_CONTACTS.GOODS_AND_PARTS.FAQ' | translate }}
      </a>
      <a href="//shop.{{ domain }}/support/dop" target="_blank">
        {{ 'FOOTER_CONTACTS.GOODS_AND_PARTS.ADDITIONAL_SERVICES' | translate }}
      </a>
      <a href="//shop.{{ domain }}/support/bonus" target="_blank">
        {{ 'FOOTER_CONTACTS.GOODS_AND_PARTS.BONUSES' | translate }}
      </a>
    </p>
  </nz-col>

  <nz-col [nzXs]="grid.mobile" [nzMd]="grid.desktop">
    <p class="info-title">{{ 'FOOTER_CONTACTS.HEAD' | translate }}</p>
    <p class="info-text">
      Company Reg. №: 1230002007382<br />
      Kusajima 429<br />
      Toyama 930-2201<br />
      Japan<br />
    </p>
    <p class="info-text">
      <span class="contact-text">{{ 'FOOTER_CONTACTS.PHONE' | translate }}:</span
      ><a href="tel:+81764354640"> +81-76-435-4640</a><br />
      <app-social-contact-link
        icon="mail"
        link="mailto:auction@akebono.world"
        text="auction@akebono.world"
      ></app-social-contact-link
      ><br />
    </p>
  </nz-col>

  <nz-col [nzXs]="grid.mobile" [nzMd]="grid.desktop">
    <p class="info-title">{{ 'FOOTER_CONTACTS.BRANCH' | translate }}</p>
    <p class="info-text">
      Akebono-cho Arai Bldg, 12F, 1201-A<br />
      2-19-1 Akebono-cho, Naka Ward,<br />
      Yokohama, Kanagawa 231-0057<br />
      Japan<br />
    </p>
    <p class="info-text">
      <span class="contact-text">{{ 'FOOTER_CONTACTS.PHONE' | translate }}:</span
      ><a href="tel:+81453257947"> +81-45-325-7947</a><br />
      <app-social-contact-link
        link="mailto:sales02@akebonocar.jp"
        text="sales02@akebonocar.jp"
        icon="mail"
      ></app-social-contact-link>
    </p>
  </nz-col>

  <nz-col [nzXs]="grid.mobile" [nzMd]="grid.desktop">
    <p class="info-title">{{ 'FOOTER_CONTACTS.BRANCH2' | translate }}</p>
    <p class="info-text">
      The Orient Square Bldg, 29F<br />
      F. Ortigas Jr. Road, Ortigas Center,<br />
      Pasig City (Manila) 1605<br />
      Philippines<br />
    </p>
    <p class="info-text">
      <span class="contact-text">Phone: </span>
      <a href="tel:+639271578435">+63 927 157 8435</a>
      <br />
      <app-social-contact-link
        icon="mail"
        text="sales@akebono.world"
        link="mailto:sales@akebono.world"
      ></app-social-contact-link>
    </p>
  </nz-col>

  <nz-col [nzXs]="grid.mobile" [nzMd]="grid.desktop">
    <p class="info-title">{{ 'FOOTER_CONTACTS.USA_OFFICE' | translate }}</p>
    <p class="info-text">
      Mochiron Cars Global<br />
      7220 NW 1st CT<br />
      MIAMI, FL, USA, 33150<br />
    </p>
    <p class="info-text">
      <span class="contact-text">{{ 'FOOTER_CONTACTS.PHONE' | translate }}:</span
      ><a href="tel:+17868180400"> +1 (786) 818-0400</a><br />
      <app-social-contact-link
        link="mailto:info@mochironcars.com"
        text="info@mochironcars.com"
        icon="mail"
      ></app-social-contact-link>
    </p>
  </nz-col>
</nz-row>

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { ILot } from '../../../../../../services/lot-adapter/interfaces/lot.interface';
import { EquipmentItem, EquipmentStatus, LotPropValue } from './types/lot-package.types';

@Component({
  selector: 'app-lot-package',
  templateUrl: './lot-package.component.html',
  styleUrl: './lot-package.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotPackageComponent implements OnInit {
  @Input({ required: true }) lot!: ILot;
  protected equipmentItems: EquipmentItem[] = [];

  ngOnInit(): void {
    this.initializeEquipmentItems();
  }

  private initializeEquipmentItems(): void {
    this.equipmentItems = [
      {
        label: 'LOT.PACKAGE.FIELDS.AIR_CONDITIONER',
        property: 'airCondition',
        typeProperty: 'airCondition',
      },
      { label: 'LOT.PACKAGE.FIELDS.AIR_BAG', property: 'airbag' },
      { label: 'ABS', property: 'abs' },
      { label: 'LOT.PACKAGE.FIELDS.SUNROOF', property: 'sunroof' },
      { label: 'LOT.PACKAGE.FIELDS.ALUMINUM_WHEEL', property: 'aluminumWheels' },
      { label: 'LOT.PACKAGE.FIELDS.LEATHER_SEAT', property: 'leatherSeat' },
      {
        label: 'LOT.PACKAGE.FIELDS.NAVIGATION',
        property: 'navigation',
        typeProperty: 'navigationType',
      },
      { label: 'TV', property: 'tv', typeProperty: 'tvType' },
      { label: 'LOT.PACKAGE.FIELDS.KEYLESS_ENTRY', property: 'keyless' },
      { label: 'LOT.PACKAGE.FIELDS.SMART_KEY', property: 'smartKey' },
      { label: 'LOT.PACKAGE.FIELDS.FLOOR_MAT', property: 'floorMat' },
      { label: 'LOT.PACKAGE.FIELDS.BACK_CAMERA', property: 'backCamera' },
      { label: 'LOT.PACKAGE.FIELDS.ETC', property: 'etc' },
      { label: 'LOT.PACKAGE.FIELDS.GUARANTEE_BOOK', property: 'guarantee' },
      { label: 'LOT.PACKAGE.FIELDS.MANUAL_BOOK', property: 'manual' },
      { label: 'LOT.PACKAGE.FIELDS.RECORD_BOOK', property: 'record' },
      { label: 'LOT.PACKAGE.FIELDS.MAINTENANCE_NOTE', property: 'note' },
    ];
  }

  defineCardColor(prop: LotPropValue): string {
    if (typeof prop === 'boolean') {
      return prop ? '' : 'eqOff';
    }

    if (typeof prop === 'number') {
      return prop === 1 ? '' : 'eqOff';
    }

    const propString = prop?.toString();

    switch (propString) {
      case EquipmentStatus.NotSet:
      case EquipmentStatus.Off:
      case '0':
        return 'eqOff';
      case EquipmentStatus.On:
      case '1':
        return '';
      case EquipmentStatus.Genuine:
        return 'eqGenuine';
      case EquipmentStatus.ThirdParty:
        return 'eqThirdParty';
      case EquipmentStatus.Available:
        return 'eqAvailable';
      default:
        return propString?.replaceAll('_', ' ') || '';
    }
  }

  formatPropertyValue(item: EquipmentItem): string {
    const value = this.lot[item.property];
    const typeValue = item.typeProperty
      ? this.lot[item.typeProperty as keyof ILot]?.toString().replaceAll('_', ' ')
      : undefined;

    return typeValue || (typeof value === 'string' ? value : value?.toString()) || '';
  }
}

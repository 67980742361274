export const ru = {
  "AUCTION_BREADCRUMB": {
    "DUBAI": "Автомобили - Дубай",
    "JAPAN": "Автомобили в наличии в Японии",
    "MONGOLIA": "Автомобили - Монголия",
    "GREEN": "Зеленый угол 2.0",
    "MOTORCYCLE": "Мотоциклы на аукционах Японии",
    "TRUCK": "Грузовые авто и спец-техника",
    "RECYCLING": "Автомобили под утилизацию",
    "USA": "Автомобили в США",
  }
};

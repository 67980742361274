import { ru } from './ru';

export const en: typeof ru = {
  BENEFITS_SECTION: {
    TITLE: 'Our advantages',
    BUTTON_TXT: 'Sign-up',
    BENEFITS_CARD: {
      CAR_TITLE: 'Wide selection',
      CAR_TEXT: 'A variety of cars from Japan, suitable for all tastes and needs.',
      DELIVERY_TITLE: 'Reliable delivery',
      DELIVERY_TEXT: 'Professional and safe delivery of cars directly to you.',
      CONDITIONS_TITLE: 'Transparent terms',
      CONDITIONS_TEXT: 'Complete and up-to-date information about each object.',
    },
  },
};

<nz-spin [nzSpinning]="loading$ | push" nzSize="large">
  <form
    nz-form
    [formGroup]="formGroup"
    nzLayout="vertical"
    (keydown.enter)="applyFiltersOnEnter($event)"
  >
    <h4>{{ 'FILTER.CAR_SEARCH.SEARCH_CAR' | translate }}</h4>
    <div class="search-car">
      <nz-select
        [nzPlaceHolder]="'FILTER.CAR_SEARCH.MANUFACTURER' | translate"
        nzAllowClear
        nzShowSearch
        formControlName="companyId"
      >
        @for (company of companies$ | push; track company.id) {
          <nz-option [nzValue]="company.id" [nzLabel]="company.name!"></nz-option>
        }
      </nz-select>

      <nz-select
        [nzPlaceHolder]="'FILTER.CAR_SEARCH.MODEL' | translate"
        nzAllowClear
        nzShowSearch
        formControlName="modelId"
      >
        @for (model of models$ | push; track model.id) {
          <nz-option
            [nzValue]="model.id"
            nzLabel="{{ model.name }} ({{ model.lotsCount }})"
          ></nz-option>
        }
      </nz-select>

      <nz-select
        [nzPlaceHolder]="'FILTER.CAR_SEARCH.BODY' | translate"
        nzAllowClear
        nzShowSearch
        formControlName="bodyType"
      >
        @for (bodyType of bodyTypes$ | push; track bodyType) {
          <nz-option [nzValue]="bodyType" [nzLabel]="bodyType!"></nz-option>
        }
      </nz-select>

      <input
        nz-input
        [placeholder]="'FILTER.CAR_SEARCH.ID_OBJECT' | translate"
        type="text"
        (keydown.enter)="applyFilters()"
        formControlName="idObject"
      />
    </div>

    <h4>{{ 'FILTER.CAR_SEARCH.PRICE' | translate }}</h4>
    <div class="from-to">
      <nz-input-number
        [nzPlaceHolder]="'FILTER.CAR_SEARCH.PRICE_FROM' | translate"
        formControlName="priceFrom"
        [nzMin]="0"
      />
      <nz-input-number
        [nzPlaceHolder]="'FILTER.CAR_SEARCH.PRICE_TO' | translate"
        formControlName="priceTo"
        [nzMin]="0"
      />
    </div>

    <h4>{{ 'FILTER.YEAR.YEAR' | translate }}</h4>
    <div class="from-to">
      <nz-select
        [nzPlaceHolder]="'FILTER.YEAR.FROM' | translate"
        nzAllowClear
        nzShowSearch
        formControlName="yearFrom"
      >
        @for (year of years; track year) {
          <nz-option [nzValue]="year" [nzLabel]="year!"></nz-option>
        }
      </nz-select>

      <nz-select
        [nzPlaceHolder]="'FILTER.YEAR.TO' | translate"
        nzAllowClear
        nzShowSearch
        formControlName="yearTo"
      >
        @for (year of years; track year) {
          <nz-option [nzValue]="year" [nzLabel]="year!"></nz-option>
        }
      </nz-select>
    </div>

    <h4>{{ 'FILTER.MILEAGE.MILEAGE' | translate }}</h4>
    <div class="from-to">
      <nz-input-number
        [nzPlaceHolder]="'FILTER.MILEAGE.FROM' | translate"
        formControlName="mileageFrom"
        [nzMin]="0"
      />

      <nz-input-number
        [nzPlaceHolder]="'FILTER.MILEAGE.TO' | translate"
        formControlName="mileageTo"
        [nzMin]="0"
      />
    </div>

    <app-lots-list-filter-buttons
      (resetFilters)="resetFilters()"
      (applyFilters)="applyFilters()"
    ></app-lots-list-filter-buttons>
  </form>
</nz-spin>

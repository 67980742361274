import { ChangeDetectionStrategy, Component } from '@angular/core';
import { EnvironmentService } from 'src/app/services/environment.service';

@Component({
  selector: 'app-delivery-statistics-registration-block',
  templateUrl: './delivery-statistics-registration-block.component.html',
  styleUrl: './delivery-statistics-registration-block.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeliveryStatisticsRegistrationBlockComponent {
  loginUrl: string;

  constructor(environmentService: EnvironmentService){
    this.loginUrl = environmentService.currentEnvironment.loginUrl;
  }
}

import {ChangeDetectionStrategy, Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {firstValueFrom, map, Observable, tap} from 'rxjs';
import {LotType} from 'src/app/services/lot-adapter/enums/lot-type.enum';
import {LotsListFilterService} from '../../components/lots-list-filter/services/lots-list-filter.service';
import {PageTitleService} from 'src/app/services/page-title/page-title.service';

@Component({
  selector: 'app-lots-page',
  templateUrl: './lots-page.component.html',
  styleUrl: './lots-page.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotsPageComponent {
  activeLotType$: Observable<LotType>;
  totalItems = 0;
  pageSize = 9;
  offset = 0;

  availableLotTypes = [
    LotType.Japan,
    LotType.GreenCorner,
    LotType.Mongolia,
    LotType.Motorcycle,
    LotType.Truck,
    LotType.Recycling,
    LotType.Usa,
  ];

  constructor(
    route: ActivatedRoute,
    private lotsListFilterService: LotsListFilterService,
    private pageTitle: PageTitleService,
  ) {
    this.activeLotType$ = route.paramMap.pipe(
      map((params) => params.get('type') as LotType),
      tap((activeLots) => {
        const pageTitle = 'AUCTION_BREADCRUMB.' + activeLots.toUpperCase();
        this.pageTitle.setTitle(pageTitle);
      }),
    );
  }

  async openFilterDrawer(): Promise<void> {
    const activeLotType = await firstValueFrom(this.activeLotType$);
    this.lotsListFilterService.open(activeLotType);
  }
}

<nz-table
  #table
  nzBordered
  class="table"
  [nzSize]="compact ? 'small' : 'middle'"
  [nzData]="trips"
  [nzFrontPagination]="false"
  [nzShowPagination]="false"
>
  <thead>
  <tr>
    <th>
      {{ 'LOT.UPCOMING_SAILINGS.TABLE.HEADER.VESSEL' | translate }}
    </th>
    <th>
      {{ 'LOT.UPCOMING_SAILINGS.TABLE.HEADER.VOYAGE' | translate }}
    </th>
    <th>
      {{ 'LOT.UPCOMING_SAILINGS.TABLE.HEADER.DEPARTURE' | translate }}
    </th>
    <th>
      {{ 'LOT.UPCOMING_SAILINGS.TABLE.HEADER.TOYAMA' | translate }}
    </th>
    <th>
      {{ 'LOT.UPCOMING_SAILINGS.TABLE.HEADER.ARRIVAL' | translate }}
    </th>
  </tr>
  </thead>
  <tbody>
    @for (item of trips; track $index) {
      <tr>
        <td>
          {{ item.vessel }}
        </td>
        <td>
          {{ item.voyage }}
        </td>
        <td>
          {{ item.departure | date : 'dd.MM' }}
        </td>
        <td>
          {{ item.toyama | date : 'dd.MM' }}
        </td>
        <td>
          {{ item.arrival | date : 'dd.MM' }}
        </td>
      </tr>
    }
  </tbody>
</nz-table>

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'firstSymbol'
})
export class FirstSymbolPipe implements PipeTransform {

  transform(value: string | null): string {
    return value ? value.charAt(0) : '';
  }


}

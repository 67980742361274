import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BenefitsSectionComponent } from './benefits-section.component';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { LanguageService } from '../../../../services/language.service';
import { TranslateModule } from '@ngx-translate/core';
import { en } from './locale/en';
import { ru } from './locale/ru';
import { ButtonModule } from '../../../../components/button/button.module';
import { BenefitCardsComponent } from './components/benefit-cards/benefit-cards.component';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { RxPush } from '@rx-angular/template/push';
import { LotCardModule } from 'src/app/components/lot-card/lot-card.module';

@NgModule({
  declarations: [BenefitsSectionComponent, BenefitCardsComponent],
  imports: [CommonModule, NzGridModule, ButtonModule, TranslateModule, NzIconModule, RxPush, LotCardModule],
  exports: [BenefitsSectionComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class BenefitsSectionModule {
  constructor(languageService: LanguageService) {
    languageService.addLocales({ ru, en });
  }
}

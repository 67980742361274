<p *nzModalTitle>{{ 'BUHANKA_DONATE.DONATE' | translate }}</p>

<div class="message">
  {{ 'BUHANKA_DONATE.MESSAGE' | translate }}
</div>

<input
  nz-input
  type="number"
  [(ngModel)]="donateAmount"
  [placeholder]="'BUHANKA_DONATE.ENTER_AMOUNT' | translate"
  [min]="1000"
  [ngClass]="{ 'error-border': donateAmount < 1000 }"
/>

@if (donateAmount < 1000) {
  <div class="error-text">
    {{ 'BUHANKA_DONATE.MIN_AMOUNT_ERROR' | translate:{ min: 1000 } }}
  </div>
}

<label class="confirm-payment-checkbox" nz-checkbox [(ngModel)]="confirmPayment">
  {{ 'BUHANKA_DONATE.CONFIRM_PAYMENT' | translate }}
</label>

<div class="buttons">
  <button
    app-button
    appType="primary"
    [loading]="loading$ | push"
    [disabled]="!canDonate"
    (click)="donate()"
  >
    {{ 'GLOBALS.ACTIONS.SEND' | translate }}
  </button>
  <button app-button appType="default" [disabled]="loading$ | push" (click)="close()">
    {{ 'GLOBALS.ACTIONS.CANCEL' | translate }}
  </button>
</div>

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { map, Observable } from 'rxjs';
import { CurrentUserService } from '../../../../services/current-user/current-user.service';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-authorized-actions',
  templateUrl: './authorized-actions.component.html',
  styleUrl: './authorized-actions.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthorizedActionsComponent {
  currentUserName$: Observable<string | null>;
  dropdownOpen = false;
  personalAcc = environment.personalAccountUrl;

  constructor(private currentUserService: CurrentUserService) {
    this.currentUserName$ = this.currentUserService.currentUser$.pipe(
      map((user) => user?.username || null),
    );
  }

  signOut(): void {
    this.currentUserService.signOut();
  }
}

import { Injectable, Optional } from '@angular/core';
import {
  ApolloQueryResult,
  FetchPolicy,
  OperationVariables,
  WatchQueryFetchPolicy,
} from '@apollo/client/core';
import { Apollo, Query } from 'apollo-angular';
import { defer, from, Observable } from 'rxjs';
import { map, switchMap, toArray } from 'rxjs/operators';

import { NotificationService } from '../notification.service';
import { ObservableHandlingService } from '../observable-handling-service/observable-handling.service';
import { FetchQueryRef } from './query-refs/fetch.query-ref';
import { WatchQueryRef } from './query-refs/watch.query-ref';

@Injectable({
  providedIn: 'root',
})
export class QueryHandlingService extends ObservableHandlingService {
  constructor(
    private apollo: Apollo,
    @Optional()
    private notificationService?: NotificationService,
  ) {
    super();
  }

  public watch<T, R extends OperationVariables>(
    query: Query<T, R>,
    variables?: R,
    fetchPolicy?: WatchQueryFetchPolicy,
  ): WatchQueryRef<T, R> {
    const queryRef = query.watch(variables, { fetchPolicy });
    return new WatchQueryRef<T, R>(queryRef, this.notificationService);
  }

  public fetch<T, R extends OperationVariables>(
    query: Query<T, R>,
    variables?: R,
    fetchPolicy?: FetchPolicy,
  ): FetchQueryRef<T> {
    const queryObservable = query.fetch(variables, {
      notifyOnNetworkStatusChange: true,
      fetchPolicy,
    });

    return new FetchQueryRef<T>(queryObservable, this.notificationService);
  }

  public refetchAll(client: string, lazy?: boolean): Observable<unknown[]>;
  public refetchAll<T, R extends OperationVariables>(
    query: Query<T, R>,
    lazy?: boolean,
  ): Observable<unknown[]>;
  public refetchAll<T, R extends OperationVariables>(
    clientOrQuery: Query<T, R> | string,
    lazy = false,
  ): Observable<unknown[]> {
    let client: string | null = null;
    let observable: Observable<ApolloQueryResult<unknown>[]> | null = null;

    if (typeof clientOrQuery === 'string') {
      client = clientOrQuery;
    } else {
      client = clientOrQuery.client;
    }

    if (lazy) {
      observable = defer(() => this.apollo.use(client).client.reFetchObservableQueries());
    } else {
      observable = from(this.apollo.use(client).client.reFetchObservableQueries());
    }

    return observable.pipe(
      switchMap((results) => from(results)),
      map((result) => result.data),
      toArray(),
    );
  }
}

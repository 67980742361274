<div class="content">
  <nz-page-header
    nzBackIcon
    [nzTitle]="'SANCTIONS_POLICY_PAGE.TITLE' | translate"
    (nzBack)="goBack()"
  ></nz-page-header>

  @switch (language$ | push) { @case ('ru') {
  <app-sanctions-policy-ru></app-sanctions-policy-ru>
  } @case ('en') {
  <app-sanctions-policy-en></app-sanctions-policy-en>
  } }
</div>

import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {MainPageComponent} from './pages/main-page/main-page.component';
import {SanctionsPolicyPageComponent} from './pages/sanctions-policy-page/sanctions-policy-page.component';
import {InformationPageComponent} from "./pages/information-page/information-page.component";
import {NewsPageComponent} from "./pages/news-page/news-page.component";
import {NewsItemPageComponent} from "./pages/news-item-page/news-item-page.component";

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: MainPageComponent,
  },
  {
    path: 'sanctions-policy',
    pathMatch: 'full',
    component: SanctionsPolicyPageComponent,
  },
  {
    path: 'information/:countryInformationType',
    component: InformationPageComponent,
  },
  {
    path: 'information/:countryInformationType/:articlePage',
    component: InformationPageComponent,
  },
  {
    path: 'news',
    component: NewsPageComponent
  },
  {
    path: 'news/:id',
    component: NewsItemPageComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MainRoutingModule {
}

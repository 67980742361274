<div class="content">
  <div>
    <h1>{{'DELIVERY_STATISTICS_SECTION.REGISTRATION.TITLE' | translate }}</h1>
    <p>{{'DELIVERY_STATISTICS_SECTION.REGISTRATION.DESCRIPTION' | translate }}</p>
  </div>
  <div class="login-btn">
    <a app-button appType="green" size="large" [href]="loginUrl">
      <span>{{'DELIVERY_STATISTICS_SECTION.REGISTRATION.LOGIN' | translate }}</span>
    </a>
  </div>
</div>

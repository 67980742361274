import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ILot } from '../../../../../../services/lot-adapter/interfaces/lot.interface';

@Component({
  selector: 'app-lot-inspection',
  templateUrl: './lot-inspection.component.html',
  styleUrl: './lot-inspection.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotInspectionComponent {
  @Input({ required: true }) lot!: ILot;

  get hasInspectionData(): boolean {
    return !!(
      this.lot.aisScoreTotal ||
      this.lot.totalScore ||
      this.lot.benefits ||
      this.lot.notice ||
      this.lot.auctionNotice
    );
  }

  get hasNotices(): boolean {
    return !!(this.lot.notice?.trim() || this.lot.auctionNotice?.trim());
  }
}

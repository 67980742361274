import {Inject, Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {en_US, NzI18nInterface, NzI18nService, ru_RU} from 'ng-zorro-antd/i18n';
import {CookieService} from 'ngx-cookie-service';
import {map, Observable, shareReplay, startWith} from 'rxjs';

import {LOCATION} from '../constants/tokens/location.token';
import {NAVIGATOR} from '../constants/tokens/navigator.token';
import {Language} from '../utilities/types/language.type';
import {EnvironmentService} from './environment.service';

@Injectable()
export class LanguageService {
  readonly currentLocale$: Observable<string>;
  readonly currentLanguage$: Observable<Language>;
  readonly availableLanguages: readonly Language[] = ['ru', 'en'];
  private readonly i18nByLanguage: Record<Language, NzI18nInterface> = {
    en: en_US,
    ru: ru_RU,
  };
  private readonly localeByLanguage: Record<Language, string> = {
    en: 'en-US',
    ru: 'ru-RU',
  };

  constructor(
    @Inject(LOCATION)
    private readonly location: Location,
    @Inject(NAVIGATOR)
    private readonly navigator: Navigator,
    private readonly cookies: CookieService,
    private readonly i18nService: NzI18nService,
    private readonly translate: TranslateService,
    private readonly environment: EnvironmentService,
  ) {
    if (this.languageCookie) {
      if (this.availableLanguages.includes(this.languageCookie)) {
        this.setLanguage(this.languageCookie);
      } else {
        this.setLanguageInternal(this.defaultLanguage, false);
      }
    } else {
      this.setLanguage(this.defaultLanguage);
    }

    this.currentLanguage$ = this.translate.onLangChange.pipe(
      map((event) => <Language>event.lang),
      startWith(this.currentLanguage),
      shareReplay(1),
    );

    this.currentLocale$ = this.currentLanguage$.pipe(map((lang) => this.localeByLanguage[lang]));
  }

  setLanguage(language: Language): void {
    this.setLanguageInternal(language, true);
  }

  addLocales(locales: Record<Language, object>): void {
    Object.entries(locales).forEach(([language, locale]) => {
      this.translate.setTranslation(language, locale, true);
    });
  }

  get currentLanguage(): Language {
    return <Language>this.translate.currentLang;
  }

  get currentLocale(): string {
    return this.localeByLanguage[this.currentLanguage];
  }

  get defaultLanguage(): Language {
    return this.browserLanguage === 'ru' ? 'ru' : 'en';
  }

  get browserLanguage(): string {
    return this.navigator.language.split('-').shift()!.toLowerCase();
  }

  get browserRegion(): string {
    return this.navigator.language.split('-')[1]?.toLowerCase();
  }

  private get languageCookie(): Language | null {
    return <Language>this.cookies.get('lang');
  }

  private set languageCookie(language: Language) {
    const { domain } = this.environment.currentEnvironment;
    const secure = this.location.protocol === 'https:';

    this.cookies.set('lang', language, 365, '/', domain, secure, 'None');
  }

  private setLanguageInternal(language: Language, writeCookie: boolean): void {
    if (this.currentLanguage === language) {
      return;
    }

    if (writeCookie) {
      this.languageCookie = language;
    }

    this.translate.use(language);
    this.i18nService.setLocale(this.i18nByLanguage[language]);
  }
}

<ng-template #content>
  <div class="step-body">
    <div class="top">
      <h3>{{ 'DELIVERY_STATISTICS_SECTION.STATISTICS_STEP.' + title | translate }}</h3>
      <p>{{ 'DELIVERY_STATISTICS_SECTION.STATISTICS_STEP.DAY' | translate }} {{ days }}</p>
    </div>

    <div class="icons">
      <app-delivery-statistics-step-icon
        icon="{{ icon }}"
        descriptionTranslationKey="{{ title }}"
        videoUrl="{{ videoUrl }}"
      ></app-delivery-statistics-step-icon>
      <div class="line"></div>
      <app-delivery-statistics-step-icon
        icon="{{ bottomIcon }}"
        descriptionTranslationKey="{{ bottomTitle }}"
        videoUrl="{{ bottomVideoUrl }}"
      ></app-delivery-statistics-step-icon>
    </div>

    <div class="bottom">
      <p>{{ 'DELIVERY_STATISTICS_SECTION.STATISTICS_STEP.DAY' | translate }} {{ bottomDays }}</p>
      <h3>{{ 'DELIVERY_STATISTICS_SECTION.STATISTICS_STEP.' + bottomTitle | translate }}</h3>
    </div>
  </div>
</ng-template>

import { ru } from './ru';

export const en: typeof ru = {
  "FILTER": {
    "CAR_SEARCH": {
      "SEARCH_CAR": "Car search:",
      "MANUFACTURER": "Manufacturer",
      "MODEL": "Model",
      "CHASSIS": "Chassis",
      "BODY": "Type",
      "ID_OBJECT": "Lot number",
      "PRICE": "Price, ¥",
      "PRICE_FROM": "From",
      "PRICE_TO": "To"
    },
    "YEAR": {
      "YEAR": "Year",
      "FROM": "From",
      "TO": "To"
    },
    "MILEAGE": {
      "MILEAGE": "Mileage, KM",
      "FROM": "From",
      "TO": "To"
    },
    "ENGINE_CAPACITY": {
      "TITLE": "Engine capacity",
      "FROM": "0",
      "TO": "2000",
    },
    "ADDITIONAL_OPTIONS": {
      "ADDITIONAL": "Additional options",
      "ENGINE_SIZE_FROM": "From",
      "ENGINE_SIZE_TO": "To",
      "GRADE": "Grade",
      "COLOR": "Color",
      "TRANSMISSION": "Transmission",
      "PRICE": "Price",
      "NOTE_SOLD": "Hide sold"
    },
    "RESET_BTN": "Reset",
    "APPLY_BTN": "Apply",
    "ALL": "All"
  }
};

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { MediaItem } from 'src/app/services/lot-adapter/types/media-item.type';
import { SwiperContainer } from 'swiper/swiper-element';
import { Swiper, SwiperOptions } from 'swiper/types';

@Component({
  selector: 'app-thumbs-slider',
  templateUrl: './thumbs-slider.component.html',
  styleUrl: './thumbs-slider.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ThumbsSliderComponent implements AfterViewInit {
  @Input({ required: true }) media!: MediaItem[];
  @Input() slidesLimited = false;
  @Output() swiperInitialized = new EventEmitter<Swiper>();

  @ViewChild('swiper')
  private swiperContainer!: ElementRef<SwiperContainer>;

  readonly swiperConfiguration: SwiperOptions = {
    freeMode: true,
    slideToClickedSlide: true,
    watchSlidesProgress: true,
    lazyPreloadPrevNext: 4,
    navigation: {
      disabledClass: 'swiper-button-hidden',
    },
    slidesPerView: 3.7,
    breakpoints: {
      320: {
        slidesPerView: 2,
        spaceBetween: '15',
      },
      575: {
        slidesPerView: 3.7,
        spaceBetween: '15',
      },
      768: {
        slidesPerView: 3.7,
        spaceBetween: '25',
      },
    },
  };

  ngAfterViewInit(): void {
    this.swiperInitialized.emit(this.swiperContainer.nativeElement.swiper);
  }
}

import {Injectable} from "@angular/core";
import {QueryHandlingService} from "../query-handling-service/query-handling.service";
import {
  NewsEntryGQL,
  NewsEntryQuery,
  NewsEntryQueryVariables,
  NewsGQL,
  NewsQuery,
  NewsQueryVariables,
  SortEnumType
} from "../../graphql/content-auto-graphql.service";
import {FetchQueryRef} from "../query-handling-service/query-refs/fetch.query-ref";
import {distinctUntilChanged, map, shareReplay} from "rxjs";
import {DateTime} from "luxon";
import {FetchNewsReference} from "./types/news-service.types";
import {EnvironmentService} from "../environment.service";

@Injectable()
export class NewsService {
  constructor(
    private qhs: QueryHandlingService,
    private environmentService: EnvironmentService,
    private newsGQL: NewsGQL,
    private newsEntryGQL: NewsEntryGQL,
  ) {
  }

  getNews(variables: NewsQueryVariables): FetchNewsReference {
    const queryRef = this.qhs.fetch<NewsQuery, NewsQueryVariables>(this.newsGQL, variables);

    const data$ = queryRef.data.pipe(
      map((data) => data?.news),
      shareReplay(1),
    );
    const total$ = data$.pipe(map((result) => result?.totalCount || 0));
    const news$ = data$.pipe(
      map((result) => result?.items || []),
      shareReplay(1),
    );
    return {
      loading$: queryRef.loading.pipe(distinctUntilChanged()),
      news$,
      total$,
    };
  }

  getLatestNews(): FetchNewsReference {
    const variables: NewsQueryVariables = {
      take: 6,
      where: {
        site: {
          domain: {
            eq: this.environmentService.currentEnvironment.autoNewsDomain
          }
        },
        publishedAt: {
          gte: DateTime.now().minus({years: 2}).toISO(),
          lte: DateTime.now().toISO(),
        },
      },
      order: {publishedAt: SortEnumType.Desc}
    };
    return this.getNews(variables);
  }

  getNewsItemById(id: number): FetchQueryRef<NewsEntryQuery> {
    return this.qhs.fetch<NewsEntryQuery, NewsEntryQueryVariables>(this.newsEntryGQL, {id});
  }
}

import { Component, forwardRef, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import * as libphonenumber from 'google-libphonenumber';
import { Subject, takeUntil } from 'rxjs';

interface Country {
  code: string;
  dialCode: string;
}

@Component({
  selector: 'app-phone-input',
  templateUrl: './phone-input.component.html',
  styleUrl: './phone-input.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PhoneInputComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PhoneInputComponent),
      multi: true,
    },
  ],
})
export class PhoneInputComponent implements OnInit, OnDestroy, ControlValueAccessor, Validator {
  phoneForm: FormGroup;
  isFieldTouched = false;
  private destroy$ = new Subject<void>();

  countries: Country[] = [
    { code: 'RU', dialCode: '7' },
    { code: 'KG', dialCode: '996' },
  ];

  private phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
  private onChange = (value: string | null): void => {
    console.log('Value changed:', value);
  };

  private onTouched = (): void => {
    this.isFieldTouched = true;
  };

  constructor(private fb: FormBuilder) {
    this.phoneForm = this.fb.group({
      countryCode: ['RU'],
      number: [''],
    });
  }

  ngOnInit(): void {
    this.phoneForm.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        const phoneNumber = this.getFullPhoneNumber();
        this.onChange(phoneNumber);
      });
  }

  writeValue(value: string | null): void {
    if (!value) {
      this.phoneForm.patchValue({
        countryCode: 'RU',
        number: '',
      }, { emitEvent: false });
      return;
    }

    try {
      const phoneNumber = this.phoneUtil.parse(value);
      const countryCode = this.phoneUtil.getRegionCodeForNumber(phoneNumber);
      const nationalNumber = phoneNumber?.getNationalNumber()?.toString();

      this.phoneForm.patchValue({
        countryCode: countryCode,
        number: nationalNumber,
      }, { emitEvent: false });
    } catch (error) {
      console.error('Error parsing phone number:', error);
      this.phoneForm.patchValue({
        countryCode: 'RU',
        number: '',
      }, { emitEvent: false });
    }
  }

  registerOnChange(fn: (value: string | null) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.phoneForm.disable();
    } else {
      this.phoneForm.enable();
    }
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const phoneNumber = control.value;

    if (!phoneNumber) {
      return { invalidPhone: true };
    }

    try {
      const parsedNumber = this.phoneUtil.parse(phoneNumber);
      const isValid = this.phoneUtil.isValidNumber(parsedNumber);
      return isValid ? null : { invalidPhone: true };
    } catch (error) {
      console.error('Validation error:', error);
      return { invalidPhone: true };
    }
  }

  markAsTouched(): void {
    if (!this.isFieldTouched) {
      this.onTouched();
    }
  }

  private getFullPhoneNumber(): string | null {
    const { countryCode, number } = this.phoneForm.value;
    if (!number) return null;

    const country = this.countries.find(c => c.code === countryCode);
    if (!country) return null;

    return `+${country.dialCode}${number}`;
  }

  getPlaceholder(): string {
    const countryCode = this.phoneForm.get('countryCode')?.value;
    switch (countryCode) {
      case 'RU':
        return '999 999 99 99';
      case 'KG':
        return '999 999 999';
      default:
        return 'Enter phone number';
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

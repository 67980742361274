import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, map, Observable, shareReplay, startWith } from 'rxjs';
import { CurrentUserService } from 'src/app/services/current-user/current-user.service';
import { LanguageService } from 'src/app/services/language.service';

import { LayoutConfig } from '../types/layout-config.type';

@Injectable({ providedIn: 'root' })
export class LayoutConfigService {
  readonly layoutConfig$: Observable<Required<LayoutConfig>>;
  readonly canRenderTelegram$: Observable<boolean>;

  constructor(
    route: ActivatedRoute,
    languageService: LanguageService,
    currentUserService: CurrentUserService,
  ) {
    this.layoutConfig$ = route.data.pipe(
      map((data) => <LayoutConfig | undefined>data['layoutConfig']),
      map(
        (initialConfig) =>
          <Required<LayoutConfig>>{
            hasPaddings: initialConfig?.hasPaddings ?? true,
            hasWidthLimit: initialConfig?.hasWidthLimit ?? true,
          },
      ),
      shareReplay(1),
    );

    this.canRenderTelegram$ = combineLatest([
      currentUserService.isRussian$,
      languageService.currentLanguage$,
    ]).pipe(
      map(([isRussian, language]) => isRussian || language === 'ru'),
      startWith(false),
    );
  }

  get hasPaddings$(): Observable<boolean> {
    return this.layoutConfig$.pipe(map((cfg) => cfg.hasPaddings));
  }

  get hasWidthLimit$(): Observable<boolean> {
    return this.layoutConfig$.pipe(map((cfg) => cfg.hasWidthLimit));
  }
}

import {
  ChangeDetectionStrategy,
  Component,
  Input,
  TemplateRef,
  Output,
  EventEmitter,
  HostBinding,
} from '@angular/core';

@Component({
  selector: 'app-collapse',
  templateUrl: './collapse.component.html',
  styleUrl: './collapse.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollapseComponent {
  @Input()
  @HostBinding('class')
  type: 'narrow' | 'wide' = 'wide';

  @Input() title?: string | TemplateRef<void>;
  @Input() active = false;
  @Output() activeChange = new EventEmitter<boolean>();

  toggle(): void {
    this.active = !this.active;
    this.activeChange.emit(this.active);
  }
}

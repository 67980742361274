import { ApolloError } from '@apollo/client/core';
import { BaseQueryHandlingException } from './base-query-handling.exception';

export class ApolloQueryException extends BaseQueryHandlingException {
  constructor(private apolloError: ApolloError) {
    super(`Apollo client error: ${apolloError.message}`);
  }

  public log(): void {
    console.error(this.message, this.apolloError);
  }
}

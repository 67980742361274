import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map, Observable, shareReplay, switchMap, tap } from 'rxjs';

import { CurrentUserServiceDataQuery } from '../../../../graphql/akebono-main-graphql.service';
import { CurrentUserService } from '../../../../services/current-user/current-user.service';
import { LotType } from '../../../../services/lot-adapter/enums/lot-type.enum';
import { ILot } from '../../../../services/lot-adapter/interfaces/lot.interface';
import { LotFetcherService } from '../../../../services/lot-fetcher/lot-fetcher.service';

@Component({
  selector: 'app-lot-page',
  templateUrl: './lot-page.component.html',
  styleUrl: './lot-page.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotPageComponent {
  lot$: Observable<ILot | null>;
  loading$: Observable<boolean>;
  activeLotType$: Observable<LotType>;
  currentUser$: Observable<CurrentUserServiceDataQuery['currentUser']>;

  constructor(
    lotFetcherService: LotFetcherService,
    route: ActivatedRoute,
    currentUserService: CurrentUserService,
    private router: Router,
  ) {
    this.currentUser$ = currentUserService.currentUser$;
    const routeParams$ = route.params.pipe(
      map((params) => ({
        lotType: params['type'] as LotType,
        lotId: params['lotId'],
      })),
    );

    this.activeLotType$ = routeParams$.pipe(map(({ lotType }) => lotType));
    const lotData$ = routeParams$.pipe(
      map(({ lotId, lotType }) => lotFetcherService.fetchLot(lotType, lotId)),
      shareReplay(1),
    );

    this.loading$ = lotData$.pipe(switchMap(({ loading$ }) => loading$));
    this.lot$ = lotData$.pipe(
      switchMap(({ lot$ }) => lot$),
      tap({
        error: () => {
          this.router.navigate(['/error/404'], {
            queryParams: { previousPage: this.router.url },
          });
        }
      })
    );
  }
}

import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {ErrorsRoutingModule} from "./errors-routing.module";
import {NotFoundPageModule} from "./pages/not-found-page/not-found-page.module";

@NgModule({
  declarations: [],
  imports: [CommonModule, ErrorsRoutingModule, NotFoundPageModule],
})
export class ErrorsModule {
}

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { map, Observable, shareReplay } from 'rxjs';
import { TripInfoType } from './types/trip-info.type';
import { SizeService } from '../../../../../../../../services/size/size.service';
import {
  LotUpcomingSailingsDataGQL,
  LotUpcomingSailingsDataQuery,
  LotUpcomingSailingsDataQueryVariables,
  TripScheduleInfo,
} from '../../../../../../../../graphql/navis-main-open-graphql.service';
import {
  QueryHandlingService,
} from '../../../../../../../../services/query-handling-service/query-handling.service';
import { WayPoint } from '../../../../../../../../graphql/main-open-graphql.service';

@Component({
  selector: 'app-lot-upcoming-sailings-modal',
  templateUrl: './lot-upcoming-sailings-modal.component.html',
  styleUrl: './lot-upcoming-sailings-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotUpcomingSailingsModalComponent {
  trips$!: Observable<TripInfoType[]>;
  tripsUpdatedAt$!: Observable<string>;
  loading$!: Observable<boolean>;
  isMobile$: Observable<boolean>;

  constructor(
    sizeService: SizeService,
    lotUpcomingSailingsDataGQL: LotUpcomingSailingsDataGQL,
    qhs: QueryHandlingService,
  ) {
    this.isMobile$ = sizeService.isMobile$;
    const queryRef$ = qhs.fetch<
      LotUpcomingSailingsDataQuery,
      LotUpcomingSailingsDataQueryVariables
    >(lotUpcomingSailingsDataGQL);
    this.loading$ = queryRef$.loading;
    const upcomingSailingsData$ = queryRef$.data.pipe(
      map((data => data.navisSchedule)),
      shareReplay(1),
    );
    this.trips$ = upcomingSailingsData$.pipe(map((data) => data?.trips?.map((item: TripScheduleInfo) => {
      return {
        vessel: item.ship?.name?.toUpperCase() ?? '',
        voyage: item.serialNum ?? '',
        departure: this.getDateByCity(item, 'Vladivostok', 'Departure'),
        toyama: this.getDateByCity(item, 'Toyama'),
        arrival: this.getDateByCity(item, 'Vladivostok', 'Arrival'),
      } as TripInfoType;
    }) ?? []));
    this.tripsUpdatedAt$ = upcomingSailingsData$.pipe(map((data) => data?.tripsUpdatedAt ?? ''));
  }

  getDateByCity(trip: TripScheduleInfo, title: string, info?: string): string {
    const wayPoint = trip?.wayPoints?.find((wp: WayPoint) => (
      (wp?.city?.title?.toLowerCase().includes(title.toLowerCase())) &&
      (!info || (wp.info === info))
    ));
    return wayPoint?.date ?? '';
  }
}
